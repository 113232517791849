import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { useContext, useMemo, useRef } from "react";
import { useSharedValue, withTiming } from "react-native-reanimated";
import config from "../../../config";
import { StoreContext } from "../../../model/Store";
import { VFile } from "../../../model/file/VFile";
import { FileCardStore } from "../../../model/ui/FileCardStore";
import { getVersion } from "../../../model/version/getVersion";
import { moveVersionsToFile } from "../../../model/version/moveVersionsToFile";
import DnDView from "../../components/drag-and-drop/DnDView";
import FileCardInner from "./FileCardInner";

const FileCard = observer((props: { file: VFile }) => {
  const { file } = props;

  const fcStore = useMemo(() => new FileCardStore(), []);

  const dragStartX = useSharedValue(0);
  const dragStartY = useSharedValue(0);

  // is dragging this view
  const isDragging = useSharedValue(0);

  // is dragging this view over receiver
  const dragOver = useSharedValue(0);
  // 1 = right; -1 = left
  const dragOverSide = useSharedValue(0);
  const dragOverMiddle = useSharedValue(0);

  // is dragging other view over this receiver
  const dragReceiveIsOver = useSharedValue(0);
  // 1 = right; -1 = left
  const dragReceiveOverSide = useSharedValue(0);
  const dragReceiveOverSideTarget = useRef(0);
  const dragReceiveOverMiddle = useSharedValue(0);

  const sharedNull = useSharedValue(0);

  const _store = useContext(StoreContext);

  return (
    <DnDView
      draggable
      onDragStart={(e) => {
        e.dataTransfer.setData("application/vidre.file", file.id);
        file.linkToUrl.then((url) => {
          e.dataTransfer.setData("text/uri-list", config.baseUrl + url);
          const latestVersionId = file.versionIds.at(-1);
          if (latestVersionId === undefined) return;
          const version = _store.versions.get(latestVersionId);
          if (!version) return;
          e.dataTransfer.setData("text/plain", version.name);
        });
        isDragging.value = withTiming(1);
        dragStartX.value = e.nativeEvent.offsetX;
        dragStartY.value = e.nativeEvent.offsetY;
      }}
      onDrag={(e) => {
        // e.dataTransfer.setDragImage(document.body, 0, 0);
        // e.dataTransfer.setDragImage(ref.current, 0, 0);
        const id = e.dataTransfer.getData("application/vidre.file");
        const payload = _store.files.get(id);
        if (!payload || id === file.id) return;
        // const x = e.offsetRatioX;
        // if (payload.type === "DIRECTORY") {
        //   dragOverMiddle.value = withTiming(file.type === payload.type ? (isMiddle(x) ? 1 : 0) : 1);
        // }
        // if (file.type === payload.type) {
        //   dragOverSide.value = withTiming(x > 0.5 ? 1 : -1);
        // }
      }}
      onDragEnter={(e) => {
        const id = e.dataTransfer.getData("application/vidre.file");
        const payload = _store.files.get(id);
        if (!payload || id === file.id) return;
        if (file.type === "DIRECTORY" || file.type === payload.type) dragReceiveIsOver.value = withTiming(1);
      }}
      onDragOver={(e) => {
        const id = e.dataTransfer.getData("application/vidre.file");
        const payload = _store.files.get(id);
        if (!payload || id === file.id) return;
        const x = e.offsetRatioX;
        if (file.type === "DIRECTORY") {
          dragReceiveOverMiddle.value = withTiming(file.type === payload.type ? (isMiddle(x) ? 1 : 0) : 1);
        }
        if (file.type === payload.type) {
          const target = x > 0.5 ? 1 : -1;
          if (target !== dragReceiveOverSideTarget.current) {
            dragReceiveOverSideTarget.current = target;
            dragReceiveOverSide.value = withTiming(target);
          }
        }
      }}
      onDragExit={() => {
        dragReceiveIsOver.value = withTiming(0);
        dragReceiveOverSide.value = withTiming(0);
        dragReceiveOverSideTarget.current = 0;
        dragReceiveOverMiddle.value = withTiming(0);
      }}
      onDragEnd={() => {
        dragOver.value = withTiming(0);
        dragOverSide.value = withTiming(0);
        dragOverMiddle.value = withTiming(0);
        isDragging.value = withTiming(0);
        runInAction(() => {
          fcStore.hoveringOver = null;
        });
      }}
      onDrop={(e) => {
        dragReceiveIsOver.value = withTiming(0);
        dragReceiveOverSide.value = withTiming(0);
        dragReceiveOverMiddle.value = withTiming(0);
        (async () => {
          const id = e.dataTransfer.getData("application/vidre.file");
          const payload = _store.files.get(id);
          if (!payload || id === file.id) return;
          const x = e.offsetRatioX;
          if (payload.type === "DIRECTORY" && isMiddle(x)) {
            // move to dir
          } else if (payload.type === file.type) {
            // move versions
            try {
              const [before, after] = x > 0.5 ? [file.versionIds.at(-1), null] : [null, file.versionIds.at(0)];
              if (before === undefined) throw new Error("move versions: before is undefined");
              if (after === undefined) throw new Error("move versions: after is undefined");

              // TODO: getVersions
              const versions = await Promise.all(payload.versionIds.map((id) => getVersion({ id, file: payload })));
              await moveVersionsToFile({ file, before, after, versions });
              _store.files.delete(payload.id);
            } catch (e) {
              console.error("Failed to move versions to file", payload, file, e);
            }
          }
        })();
      }}
      // onDragEnter={(e) => {
      //   const payload = e.receiver.payload;
      //   if (!(payload instanceof VFile)) return;
      //   if (payload.type === "DIRECTORY" || file.type === payload.type) {
      //     dragOver.value = withTiming(1);
      //     runInAction(() => {
      //       fcStore.hoveringOver = payload;
      //     });
      //   }
      // }}
      // onDragExit={() => {
      //   dragOver.value = withTiming(0);
      //   dragOverSide.value = withTiming(0);
      //   dragOverMiddle.value = withTiming(0);
      //   runInAction(() => {
      //     fcStore.hoveringOver = null;
      //   });
      // }}
      // onDragDrop={() => {
      //   dragOver.value = withTiming(0);
      //   isDragging.value = withTiming(0);
      // }}
    >
      <FileCardInner
        file={file}
        clicking={isDragging}
        isHoverContent={false}
        hoverDragging={sharedNull}
        dragStartX={sharedNull}
        dragStartY={sharedNull}
        dragOver={sharedNull}
        dragOverSide={sharedNull}
        dragOverMiddle={sharedNull}
        dragReceiveIsOver={dragReceiveIsOver}
        dragReceiveOverSide={dragReceiveOverSide}
        dragReceiveOverMiddle={dragReceiveOverMiddle}
      />

      {/* <View style={{ opacity: 0, height: 0 }}>
        <FileCardStoreContext.Provider value={fcStore}>
          <FileCardInner
            file={file}
            clicking={sharedNull}
            isHoverContent
            hoverDragging={isDragging}
            dragStartX={dragStartX}
            dragStartY={dragStartY}
            dragOver={dragOver}
            dragOverSide={dragOverSide}
            dragOverMiddle={dragOverMiddle}
            dragReceiveIsOver={sharedNull}
            dragReceiveOverSide={sharedNull}
            dragReceiveOverMiddle={sharedNull}
          />
        </FileCardStoreContext.Provider>
      </View> */}
    </DnDView>
  );
});

export default FileCard;

const isMiddle = (x: number) => Math.round(x * 3.4 - 1.7) === 0;
