import React, { PropsWithChildren } from "react";
import { Text, TouchableOpacity, View } from "react-native";
import { mapReactChildren } from "../../util/mapReactChildren";
import Tab, { TabProps } from "./Tab";

const widthFrac = 0.8;

export default function TabView(
  props: PropsWithChildren<{ labelColor: string; barColor: string; value: number; setValue: (v: number) => void }>
) {
  const { labelColor, barColor, value, setValue, children } = props;
  const d: TabProps[] = mapReactChildren({
    children,
    type: Tab,
    map: ({ props }) => props,
  });
  return (
    <View>
      <View style={{ flexDirection: "row", position: "relative", alignItems: "center" }}>
        {d.map((tab, i) => (
          <View style={{ flex: 0.5 }}>
            <TouchableOpacity
              style={{ width: "100%", flexDirection: "row", alignItems: "center", justifyContent: "center" }}
              onPress={() => setValue(i)}
            >
              <Text style={{ color: labelColor, padding: 8 }}>{tab.label}</Text>
            </TouchableOpacity>
          </View>
        ))}
        <View
          style={{
            position: "absolute",
            backgroundColor: barColor,
            height: 2,
            bottom: 0,
            left: `${(100 / d.length) * (value + (1 - widthFrac) / 2)}%`,
            right: `${(100 / d.length) * (d.length - value - 1 + (1 - widthFrac) / 2)}%`,
            borderRadius: 1,
            // @ts-ignore
            transition: "all 0.5s",
          }}
        />
      </View>
      <View>{d[value].children}</View>
    </View>
  );
}
