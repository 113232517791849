import { useCallback, useRef } from "react";
import { GestureResponderEvent, Platform, Text, View } from "react-native";
import { VFile } from "../../../model/file/VFile";
import DirectoryVersion from "../../../model/version/DirectoryVersion";
import { downloadVersion } from "../../../model/version/downloadVersion";
import { getVersion } from "../../../model/version/getVersion";
import { useVersion } from "../../../model/version/useVersion";
import { useColors } from "../../Colors";
import styles from "../../Styles";
import EllipsisText from "../../components/EllipsisText";
import { useContextMenu } from "../../components/context-menu/useContextMenu";
import UserNameText from "../../components/user/UserNameText";
import VersionSelectorButton from "../../components/version-selector/VersionSelectorButton";
import ModalButton from "../../modals/ModalButton";
import ShareButton from "../../modals/share/ShareButton";
import UserModal from "../../modals/user/UserModal";
import NavbarIconButton from "../../navigation/navbar/NavbarIconButton";

export default function PreviewScreeenHeaderTitle(props: { file: VFile }) {
  const { file } = props;
  const colors = useColors().navBar;
  const { data: version } = useVersion(file);

  const sharedBy = [...file.sharedBy.values()];
  const sharedBy1 = sharedBy.at(0);
  const sharedBy2 = sharedBy.slice(1);

  const rightRef = useRef<View>(null);
  const showMenu = useContextMenu();

  const onDownload = useCallback(
    (e: GestureResponderEvent) => {
      try {
        if (typeof TransformStream === "undefined") {
          alert("Downloading files is currently not supported in your browser");
          return;
        }
        if (version) {
          if (version instanceof DirectoryVersion && version.selectedFile) {
            const { pageX, pageY } = e.nativeEvent;
            console.log(e.nativeEvent);
            showMenu({ x: pageX, y: pageY }, [
              {
                title: "Download File",
                icon: "download-file",
                onPress: async () => {
                  const f = version.selectedFile;
                  const id = f?.versionIds.at(f.selectedVersionNo);
                  if (!f || !id) return;
                  const v = await getVersion({ id, file: f });
                  downloadVersion(v);
                },
              },
              {
                title: "Download Directory",
                icon: "download-directory",
                onPress: async () => {
                  downloadVersion(version);
                },
              },
            ]);
          } else downloadVersion(version);
        }
      } catch (e) {
        console.error("Error while downloading", e);
        alert("An error occured while downloading");
      }
    },
    [version]
  );

  return (
    <View style={{ flexDirection: "row", alignItems: "center", flexGrow: 1, flex: 1 }}>
      <EllipsisText
        useParent
        subtractRefs={[rightRef]}
        style={{ color: colors.text, fontSize: 16, fontWeight: "500", flex: Platform.OS === "web" ? undefined : 1 }}
      >
        {version ? version.name : "Loading version..."}
      </EllipsisText>
      <View ref={rightRef} style={{ flexDirection: "row" }}>
        <View style={{ paddingRight: 8, zIndex: 1 }}>
          <View style={{ flexDirection: "row", zIndex: 1, alignItems: "center", flex: 1 }}>
            <View style={{ paddingHorizontal: 8 }}>
              <VersionSelectorButton file={file} />
            </View>
          </View>
          {sharedBy1 && (
            <View style={{ flexDirection: "row", alignItems: "baseline" }}>
              <Text style={{ fontSize: styles.textUser.fontSize, color: colors.textSharedBy }}>shared by </Text>
              <ModalButton Modal={() => <UserModal user={sharedBy1.from} />}>
                <UserNameText user={sharedBy1.from} />
              </ModalButton>
              {sharedBy2.map((s) => (
                <>
                  <Text style={[{ fontSize: styles.textUser.fontSize, color: colors.textSharedBy }]}>, </Text>
                  <ModalButton Modal={() => <UserModal user={s.from} />}>
                    <UserNameText user={s.from} />
                  </ModalButton>
                </>
              ))}
            </View>
          )}
        </View>
        {file.showShareMenu && <ShareButton file={file} />}
        <NavbarIconButton name="download" onPress={onDownload} />
      </View>
    </View>
  );
}
