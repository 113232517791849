import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { VFile } from "../../../model/file/VFile";
import { Permissions } from "../../../model/share/Permissions";
import { useColors } from "../../Colors";
import Divider from "../../components/Divider";
import ToggleListItem from "../../components/input/ToggleListItem";

const DetailedPermissionsEditor = observer((props: { permissions: Permissions; file: VFile }) => {
  const { permissions, file } = props;
  const colors = useColors().modal.permissions;
  return (
    <>
      {file.type === "DIRECTORY" && (
        <>
          <ToggleListItem
            title="Create File"
            tooltip="Allows creating/uploading new files,"
            value={permissions.create_file}
            setValue={(v) =>
              runInAction(() => {
                if (permissions.detailed) permissions.detailed.create_file = v;
              })
            }
            colorChecked={colors.checked}
            colorUnchecked={colors.unchecked}
            colorText={colors.text}
          />
          <Divider color={colors.divider} />
        </>
      )}
      <ToggleListItem
        title="Add version"
        tooltip="Allows adding new versions to existing files."
        value={permissions.add_version}
        setValue={(v) =>
          runInAction(() => {
            if (permissions.detailed) permissions.detailed.add_version = v;
          })
        }
        colorChecked={colors.checked}
        colorUnchecked={colors.unchecked}
        colorText={colors.text}
      />
      <Divider color={colors.divider} />
      {file.type === "DIRECTORY" && (
        <>
          <ToggleListItem
            title="Rename"
            tooltip="Allows renaming versions/files in this directory."
            value={permissions.rename}
            setValue={(v) =>
              runInAction(() => {
                if (permissions.detailed) permissions.detailed.rename = v;
              })
            }
            colorChecked={colors.checked}
            colorUnchecked={colors.unchecked}
            colorText={colors.text}
          />
          <Divider color={colors.divider} />
          <ToggleListItem
            title="Delete"
            tooltip="Allows deleting files in this directory."
            value={permissions.delete}
            setValue={(v) =>
              runInAction(() => {
                if (permissions.detailed) permissions.detailed.delete = v;
              })
            }
            colorChecked={colors.checked}
            colorUnchecked={colors.unchecked}
            colorText={colors.text}
          />
          <Divider color={colors.divider} />
        </>
      )}
      {/* <ToggleListItem
        title="Create Comment"
        tooltip="Allows creating comments and rplying to other comments."
        value={permissions.create_comment}
        setValue={(v) =>
          runInAction(() => {
            if (permissions.detailed) permissions.detailed.create_comment = v;
          })
        }
        colorChecked={colors.checked}
        colorUnchecked={colors.unchecked}
        colorText={colors.text}
      />
      <Divider color={colors.divider} />
      <ToggleListItem
        title="Edit Comment"
        tooltip="Allows editing comments created by other users."
        value={permissions.edit_comment}
        setValue={(v) =>
          runInAction(() => {
            if (permissions.detailed) permissions.detailed.edit_comment = v;
          })
        }
        colorChecked={colors.checked}
        colorUnchecked={colors.unchecked}
        colorText={colors.text}
      />
      <Divider color={colors.divider} />
      <ToggleListItem
        title="Delete Comment"
        tooltip="Allows deleting comments created by other users."
        value={permissions.delete_comment}
        setValue={(v) =>
          runInAction(() => {
            if (permissions.detailed) permissions.detailed.delete_comment = v;
          })
        }
        colorChecked={colors.checked}
        colorUnchecked={colors.unchecked}
        colorText={colors.text}
      /> */}
      <Divider color={colors.divider} />
      <ToggleListItem
        title="Share with Users"
        tooltip="Allows shareing with other users."
        value={permissions.share_user}
        setValue={(v) =>
          runInAction(() => {
            if (permissions.detailed) permissions.detailed.share_user = v;
          })
        }
        colorChecked={colors.checked}
        colorUnchecked={colors.unchecked}
        colorText={colors.text}
      />
      <Divider color={colors.divider} />
      <ToggleListItem
        title="Share Link"
        tooltip="Allows creating new link shares."
        value={permissions.share_link}
        setValue={(v) =>
          runInAction(() => {
            if (permissions.detailed) permissions.detailed.share_link = v;
          })
        }
        colorChecked={colors.checked}
        colorUnchecked={colors.unchecked}
        colorText={colors.text}
      />
      <Divider color={colors.divider} />
      <ToggleListItem
        title="Delete Share"
        tooltip="Allows deleting shares created by other users"
        value={permissions.delete_share}
        setValue={(v) =>
          runInAction(() => {
            if (permissions.detailed) permissions.detailed.delete_share = v;
          })
        }
        colorChecked={colors.checked}
        colorUnchecked={colors.unchecked}
        colorText={colors.text}
      />
      <Divider color={colors.divider} />
    </>
  );
});

export default DetailedPermissionsEditor;
