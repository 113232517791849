import { ResizeMode, Video } from "expo-av";
import * as FileSystem from "expo-file-system";
import { runInAction } from "mobx";
import { useContext, useEffect, useMemo } from "react";
import { Platform, Text, View } from "react-native";
import useKeyboardShortcut from "use-keyboard-shortcut";
import { useMaybePromise } from "../../../../model/hooks/useMaybePromise";
import { VideoStoreContext } from "../../../../model/ui/VideoStore";
import VideoVersion from "../../../../model/version/VideoVersion";
import { useColors } from "../../../Colors";
import styles from "../../../Styles";
import LoadScreen from "../../LoadScreen";
import CommentsTimeline from "./CommentsTimeline";
import VideoControls from "./VideoControls";
import { initVideo } from "./VideoPlatformSetup";

export default function VideoView(props: { version: VideoVersion }) {
  const { version } = props;
  const colors = useColors().video;
  const _vsStore = useContext(VideoStoreContext);
  const url = useMaybePromise(
    useMemo(
      async () =>
        Platform.OS === "web"
          ? (await import("../../../../model/version/createVideoBlobUrl")).createVideoBlobUrl(version)
          : (await import("../../../../model/version/createVideoFile")).createVideoFile(version),
      [version]
    )
  );

  useEffect(() => {
    if (!url) return;
    console.log("video url", url);
    if (Platform.OS === "ios") {
      console.log("ios");
      FileSystem.readDirectoryAsync(
        FileSystem.documentDirectory + url.slice("http://localhost:8080/".length, url.length - "playlist.m3u8".length)
      )
        .then((r) => {
          console.log("files", r);
        })
        .catch((e) => console.log("ios err", e));
    }
    initVideo(url);
  }, [url]);

  useShortcuts();

  return (
    <>
      <View
        style={{
          justifyContent: "center",
          width: "100%",
          height: 0,
          // aspect ratio (https://css-tricks.com/aspect-ratio-boxes/)
          paddingTop: `${100 / (version.aspectRatio ?? 16 / 9)}%`,
          backgroundColor: colors.background,
        }}
      >
        <View style={styles.absoluteFill}>
          {url !== undefined ? (
            <Video
              ref={_vsStore.video}
              resizeMode={ResizeMode.CONTAIN}
              source={{ uri: url }}
              onPlaybackStatusUpdate={(s) => runInAction(() => (_vsStore.status = s))}
              style={[{ width: "100%", height: "100%" }]}
              videoStyle={{ width: "100%", height: "100%" }}
            />
          ) : (
            <LoadScreen msg="Loading video" backgroundColor={colors.background} />
          )}
          {!_vsStore?.status?.isLoaded || (_vsStore.status.isBuffering && <Text>Buffering</Text>)}
        </View>
      </View>
      <View style={{ backgroundColor: colors.controls.background }}>
        <VideoControls />
        <CommentsTimeline version={version} />
      </View>
    </>
  );
}

function useShortcuts() {
  if (Platform.OS !== "web") return;
  const _vsStore = useContext(VideoStoreContext);

  useKeyboardShortcut([" "], _vsStore.playpause, { overrideSystem: true });
  useKeyboardShortcut(["K"], _vsStore.playpause, { overrideSystem: true });
  useKeyboardShortcut(["."], () => _vsStore.moveFrames(1), { overrideSystem: true });
  useKeyboardShortcut([","], () => _vsStore.moveFrames(-1), { overrideSystem: true });
  useKeyboardShortcut(["ArrowRight"], () => _vsStore.moveMillis(5000), { overrideSystem: true });
  useKeyboardShortcut(["ArrowLeft"], () => _vsStore.moveMillis(-5000), { overrideSystem: true });
  useKeyboardShortcut(["ArrowUp"], () => _vsStore.adjustVolume(+1), { overrideSystem: true });
  useKeyboardShortcut(["ArrowDown"], () => _vsStore.adjustVolume(-1), { overrideSystem: true });
  useKeyboardShortcut(["J"], _vsStore.backward, { overrideSystem: true });
  useKeyboardShortcut(["L"], _vsStore.forward, { overrideSystem: true });
  useKeyboardShortcut(["F"], _vsStore.toggleFullscreen, { overrideSystem: true });
  useKeyboardShortcut(["M"], _vsStore.toggleMute, { overrideSystem: true });
}
