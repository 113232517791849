import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import { VFile } from "../../../model/file/VFile";
import { Permissions } from "../../../model/share/Permissions";
import { useColors } from "../../Colors";
import Divider from "../../components/Divider";
import ToggleListItem from "../../components/input/ToggleListItem";
import Tab from "../../components/tab-view/Tab";
import TabView from "../../components/tab-view/TabView";
import ModalView from "../ModalView";
import DetailedPermissionsEditor from "./DetailedPermissionsEditor";
import SimplePermissionsEditor from "./SimplePermissionsEditor";

const PermissionsModal = observer(
  (props: {
    visible: boolean;
    setVisible: (b: boolean) => void;
    permissions: Permissions;
    file: VFile;
    onSubmit: () => void;
    onCancel: () => void;
  }) => {
    const { visible, setVisible, permissions: originalPermissions, file, onSubmit, onCancel } = props;
    const permissions = useMemo(
      () => new Permissions(Object.assign({}, originalPermissions.data)),
      [originalPermissions]
    );
    const colors = useColors().modal.permissions;
    return (
      <ModalView
        title="Edit permissions"
        visible={visible}
        setVisible={setVisible}
        onSubmit={() => {
          originalPermissions.data = permissions.data;
          onSubmit();
        }}
        onCancel={onCancel}
      >
        <ToggleListItem
          title="Owner"
          tooltip="Allows creating/uploading new files,"
          value={permissions.owner}
          setValue={(v) => permissions.setOwner(v)}
          colorChecked={colors.checked}
          colorUnchecked={colors.unchecked}
          colorText={colors.text}
        />
        {!permissions.owner && (
          <>
            <Divider color={colors.divider} />
            <TabView
              value={permissions.detailed ? 1 : 0}
              setValue={(i) => permissions.setDetailed(i === 1)}
              labelColor={colors.text}
              barColor={colors.accent}
            >
              <Tab label="Simple">
                <SimplePermissionsEditor permissions={permissions} file={file} />
              </Tab>
              <Tab label="Detailed">
                <DetailedPermissionsEditor permissions={permissions} file={file} />
              </Tab>
            </TabView>
          </>
        )}
      </ModalView>
    );
  }
);

export default PermissionsModal;
