import { CheckBox } from "react-native-elements";

export default function Checkbox(props: {
  value: boolean;
  setValue: (v: boolean) => void;
  colorChecked: string;
  colorUnchecked: string;
  size?: number;
}) {
  const { value, setValue, colorChecked, colorUnchecked, size } = props;
  return (
    <CheckBox
      checkedColor={colorChecked}
      uncheckedColor={colorUnchecked}
      containerStyle={{
        paddingTop: 0,
        paddingRight: 0,
        paddingBottom: 0,
        paddingLeft: 0,
        margin: 0,
      }}
      wrapperStyle={{
        paddingTop: 3.5,
        paddingRight: 0,
        paddingBottom: 0,
        paddingLeft: 3,
      }}
      checked={value}
      onIconPress={() => setValue(!value)}
      size={size}
    />
  );
}
