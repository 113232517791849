import { useCallback, useRef } from "react";
import { NativeScrollEvent, NativeSyntheticEvent, findNodeHandle } from "react-native";
import { DraxSubprovider, DraxView, Position } from "react-native-drax";
import { FlatGrid, FlatGridProps } from "react-native-super-grid";
import { useDraxId } from "./useDraxId";

export default function DraxGrid<ItemType>(props: FlatGridProps<ItemType>) {
  const id = useDraxId();
  const nodeHandleRef = useRef<number | null>(null);
  const scrollPositionRef = useRef<Position>({ x: 0, y: 0 });
  const onScroll = useCallback((event: NativeSyntheticEvent<NativeScrollEvent>) => {
    const {
      nativeEvent: { contentOffset },
    } = event;
    scrollPositionRef.current = { ...contentOffset };
    console.log("drag scroll", scrollPositionRef.current);
  }, []);
  const setFlatListRefs = useCallback((ref) => {
    nodeHandleRef.current = ref && findNodeHandle(ref);
  }, []);
  return (
    <DraxView id={id} scrollPositionRef={scrollPositionRef} style={{ flex: 1 }}>
      <DraxSubprovider parent={{ id, nodeHandleRef }}>
        <FlatGrid<ItemType> onScroll={onScroll} ref={setFlatListRefs} {...props} />
      </DraxSubprovider>
    </DraxView>
  );
}
