import { runInAction } from "mobx";
import { Ok, Result } from "ts-results";
import * as Api from "../Api";
import { store } from "../Store";
import { CreateTokenError } from "../generated/CreateTokenError";
import { LoginError } from "../generated/LoginError";
import { parseTranscodingServers } from "../transcoding-server/parseTranscodingServers";
import {
  aesDecrypt,
  base64Decode,
  base64Encode,
  exportRawKey,
  importPrivkey,
  importPubkey,
  importRawKey,
  keyhash,
  pwhash,
  shasum,
} from "../util/CryptoHelper";
import { MeUser, MeUserJson, UserCiphertextJson, getKeychain, meUserGQLFields } from "./MeUser";
import { Token, TokenJson } from "./Token";

export async function login(username: string, password: string): Promise<Result<MeUser, ClientLoginError>> {
  let userResponse: MeUserJson;
  try {
    // prettier-ignore
    userResponse = (await Api.gql(`{
      user(username: "${username}") {
        ${meUserGQLFields}
      }
    }`)).user;
  } catch (e) {
    throw {
      status: 401,
      statusText: "Unauthorized",
      body: { code: 401, status: "Unauthorized", message: "User does not exist" },
    };
  }

  try {
    const startKey = await shasum(password);
    const masterKey = keyhash(startKey, "master");
    const saltKey = keyhash(startKey, "salt");
    const salt = await aesDecrypt(await saltKey, base64Decode(userResponse.salt), new ArrayBuffer(16));
    const ph = await pwhash(password, salt);

    const tokenPromise = Api.req<TokenJson, CreateTokenError>({
      endpoint: "/user/token",
      user: username,
      password: base64Encode(ph),
      body: JSON.stringify({ scope: "all" }),
    }).then((r) => r.map(Token.fromJson));

    const keyKey = await keyhash(await exportRawKey(await masterKey), "key");
    const key = await importRawKey(await aesDecrypt(keyKey, base64Decode(userResponse.key), new ArrayBuffer(16)));
    const keychainPromise = getKeychain(key);
    const ciphertext: UserCiphertextJson = JSON.parse(
      Buffer.from(await aesDecrypt(key, base64Decode(userResponse.ciphertext), new ArrayBuffer(16))).toString("utf-8")
    );

    const pubkey = importPubkey(base64Decode(userResponse.pubkey));
    const privkey = importPrivkey(base64Decode(ciphertext.privkey));

    const keychain = await keychainPromise;
    const transcodingServers = await parseTranscodingServers(
      userResponse.transcodingServers,
      keychain.transcodingServersKey
    );

    const token = (await tokenPromise).expect("Failed to create token");
    if ((ciphertext.transcoding_servers?.length ?? 0) > 0 || ciphertext.transcoding_server) {
      const i = await import("../transcoding-server/migrateLegacyTranscodingServersFromCiphertext");
      i.migrateLegacyTranscodingServersFromCiphertext({ transcodingServers, ciphertext, token, key });
    }

    const user = new MeUser({
      id: userResponse.id,
      username: userResponse.username,
      displayName: userResponse.displayName,
      keychain,
      pubkey: await pubkey,
      privkey: await privkey,
      token,
      transcodingServers,
      alwaysImportShares: null,
    });
    const _store = await store;
    return Ok(
      await runInAction(async () => {
        _store.meUsers.delete(user.id);
        _store.meUsers.set(user.id, user);
        _store.me = user;
        return user;
      })
    );
  } catch (e) {
    throw {
      status: 401,
      statusText: "Unauthorized",
      body: { code: 401, status: "Unauthorized", message: "Invalid password" },
    };
  }
}

export type ClientLoginError = LoginError;
