import { CircularProgress } from "@material-ui/core";
import React from "react";
import { Platform, StyleProp, View, ViewStyle } from "react-native";
import { Icon } from "react-native-elements/dist/icons/Icon";

export default function LoadCircle(props: { size: number; color: string; style?: StyleProp<ViewStyle> }) {
  const { size, color, style } = props;
  // TODO
  return (
    <View style={style}>
      {Platform.OS === "web" ? (
        <CircularProgress size={size} style={{ color }} />
      ) : (
        <Icon name="circle" type="material-icons" color={color} size={size} />
      )}
    </View>
  );
}
