import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { Platform, Text, View } from "react-native";
import { FlatList } from "react-native-gesture-handler";
import Comment, { isExistingCommentVersion } from "../../../model/comment/Comment";
import VideoStore, { VideoStoreContext } from "../../../model/ui/VideoStore";
import { useColors } from "../../Colors";
import ModalButton from "../../modals/ModalButton";
import ModalView from "../../modals/ModalView";
import UserModal from "../../modals/user/UserModal";
import TimecodeText from "../../screens/preview/video/TimecodeText";
import UserCircle from "../user/UserCircle";
import UserNameText from "../user/UserNameText";

const CommentEditsModal = observer(
  (props: { comment: Comment; visible: boolean; setVisible: (b: boolean) => void }) => {
    const { comment, visible, setVisible } = props;
    const colors = useColors().comment;
    const vStore = useContext(VideoStoreContext) as VideoStore | undefined;
    return (
      <ModalView title="Comment Edits" visible={visible} setVisible={setVisible}>
        <FlatList
          data={[...comment.versions.values()].reverse()}
          renderItem={({ item: cv }) => (
            <View style={{ backgroundColor: "#111", borderRadius: 10, margin: 4, padding: 6 }}>
              <View style={{ flexDirection: "row", alignItems: "center", paddingBottom: 8 }}>
                {/* author */}
                <ModalButton Modal={() => <UserModal user={comment.oldestVersion.author} />}>
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <UserCircle user={comment.oldestVersion.author} size={20} />
                    <UserNameText user={comment.oldestVersion.author} style={{ paddingLeft: 8 }} />
                  </View>
                </ModalButton>

                {/* timecode */}
                {isExistingCommentVersion(cv) && cv?.startTimecode !== undefined && (
                  <>
                    <View style={{ flexGrow: 1 }} />
                    <View style={{ padding: 4, borderRadius: 4, backgroundColor: colors.timecode.background }}>
                      <Text style={{ color: colors.text, fontWeight: "600", fontSize: 13 }}>
                        <TimecodeText millis={cv.startTimecode} fps={vStore?.fps} color={colors.text} />
                        {comment.latestVersion?.endTimecode !== undefined && (
                          <>
                            <Text>{" - "}</Text>
                            <TimecodeText
                              millis={comment.latestVersion.endTimecode}
                              fps={vStore?.fps}
                              color={colors.text}
                            />
                          </>
                        )}
                      </Text>
                    </View>
                  </>
                )}
              </View>
              <Text style={{ color: colors.text, ...(Platform.OS === "web" && { userSelect: "text" }) }}>
                {isExistingCommentVersion(cv) ? cv.text : "Deleted comment version"}
              </Text>
            </View>
          )}
        />
      </ModalView>
    );
  }
);

export default CommentEditsModal;
