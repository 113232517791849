export const pickFile = (params?: { multiple?: boolean; directory?: boolean }): Promise<FileList> =>
  new Promise<FileList>((resolve) => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    if (params?.multiple) input.setAttribute("multiple", "");
    if (params?.directory) {
      input.setAttribute("directory", "");
      input.setAttribute("webkitdirectory", "");
    }
    input.style.display = "none";
    document.body.appendChild(input);
    input.click();
    const onChange = () => {
      input.onchange = null;
      document.body.removeChild(input);
      if (input.files) resolve(input.files);
    };
    input.onchange = onChange;
  });
