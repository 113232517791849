import { PointerInteractionView } from "@thefunbots/react-native-pointer-interactions";
import { PropsWithChildren } from "react";
import { GestureResponderEvent, Platform, TouchableOpacity } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";

export default function NavbarButton(
  props: PropsWithChildren<{
    onPress: (e: GestureResponderEvent) => void;
    left?: boolean;
    right?: boolean;
  }>
) {
  const { children, onPress, left, right } = props;
  const insets = useSafeAreaInsets();
  return (
    <PointerInteractionView
      pointerMode="highlight"
      style={
        Platform.OS === "web" ? undefined : { marginRight: right ? -14 : undefined, marginLeft: left ? -14 : undefined }
      }
    >
      <TouchableOpacity
        onPress={onPress}
        style={
          Platform.OS === "web"
            ? {
                paddingVertical: 16,
                paddingRight: right ? Math.max(16, insets.right) : 10,
                paddingLeft: left ? Math.max(16, insets.right) : 10,
                marginLeft: left ? Math.max(16, insets.right) : 0,
                marginRight: right ? -Math.max(16, insets.right) : 0,
              }
            : { paddingVertical: 8, paddingHorizontal: 14 }
        }
      >
        {children}
      </TouchableOpacity>
    </PointerInteractionView>
  );
}
