import { useCallback, useEffect, useState } from "react";
import { VFile } from "../../../model/file/VFile";
import { ShareModalStore } from "../../../model/ui/ShareModalStore";
import ModalView from "../ModalView";
import ShareModalInner from "./ShareModalInner";

export default function ShareModal(props: { file: VFile; visible: boolean; setVisible: (b: boolean) => void }) {
  const { file, visible, setVisible } = props;

  const [shareModalStore, setShareModalStore] = useState<ShareModalStore>();
  useEffect(() => {
    if (shareModalStore) shareModalStore.deinit();
    if (!visible || !shareModalStore) setShareModalStore(new ShareModalStore(file));
  }, [visible, file]);

  const onSubmit = useCallback(() => {
    shareModalStore?.deinit();
  }, [shareModalStore]);

  return (
    <ModalView noButtons title="Share" onSubmit={onSubmit} visible={visible} setVisible={setVisible}>
      <ShareModalInner shareModalStore={shareModalStore!} />
    </ModalView>
  );
}
