import { useEffect, useState } from "react";
import { useColors } from "../Colors";
import Input from "../components/input/Input";
import ModalView from "./ModalView";

export default function InputModal(props: {
  title: string;
  initialValue?: string;
  placeholder?: string;
  onSubmit: (v: string) => void;
  visible: boolean;
  setVisible: (b: boolean) => void;
}) {
  const { title, initialValue, placeholder, onSubmit, visible, setVisible } = props;
  const [text, setText] = useState(initialValue ?? "");
  const colors = useColors().modal;

  useEffect(() => {
    if (!visible) setText(initialValue ?? "");
  }, [visible]);
  return (
    <ModalView title={title} onSubmit={() => onSubmit(text)} visible={visible} setVisible={setVisible}>
      <Input
        autoFocus
        value={text}
        placeholder={placeholder}
        onChangeText={(text) => {
          setText(text);
        }}
        onSubmitEditing={async () => {
          setVisible(false);
          onSubmit(text);
        }}
      />
    </ModalView>
  );
}
