import {
  CreateMultipartUploadCommand,
  CreateMultipartUploadCommandOutput,
  UploadPartCommand,
} from "@aws-sdk/client-s3";
import jsSHA from "jssha";
import config from "../../../config";
import { S3ClientInfo, pickS3Client } from "../../S3";
import { aesEncrypt } from "../../util/CryptoHelper";
import { timeout } from "../../util/timeout";
import { MultipartUploadInfo } from "../types/MultipartUploadInfo";
import { MultipartUploadPart } from "../types/MultipartUploadPart";
import { Fil } from "../types/UploadElement";
import { completeMultipartUpload } from "../util/completeMultipartUpload";

// queue of parts scheduled for uploading
export const uploadQueue: MultipartUploadPart[] = [];

export const currentlyUploading = new Set<MultipartUploadPart>();

/**
 * Start a Multipart Upload and wait for it to complete. Wraps `queueMultipartUpload` with a `Promise` which is resolved in the `onDone` callback.
 *
 * @param params.id Version ID
 * @param params.fil Information about the file to be uploaded.
 * @param params.key AES-128 key used for encrypting the file.
 */
export const executeMultipartUpload = (params: { id: string; fil: Fil; key: CryptoKey }): Promise<void> =>
  new Promise((resolve, _reject) => queueMultipartUpload({ ...params, onDone: resolve }));

/**
 * Queue a new Multipart Upload. This includes sending the CreateMultipartUpload command, splitting the file in parts and adding them to the queue.
 *
 * **Note:**
 * The `Promise` retuned by this function resolves as soon as the upload has been queued and does **not** wait for the upload to finish.
 * To execute code after the upload is complete, the `onDone` callback can be used. Usually `executeMultipartUpload` should be used instead.
 *
 * @param params.id Version ID
 * @param params.fil Information about the file to be uploaded.
 * @param params.key AES-128 key used for encrypting the file.
 * @param params.onDone Callback executed after the Multipart Upload is complete.
 */
export async function queueMultipartUpload(params: {
  id: string;
  fil: Fil;
  key: CryptoKey;
  onDone?: () => void;
}): Promise<void> {
  const { id, fil, key, onDone } = params;
  const numberOfParts = Math.ceil(fil.size / config.upload.partSize);
  const path = `versions/${id}/original`;
  // create multipart upload
  let r: CreateMultipartUploadCommandOutput | undefined;
  let client: S3ClientInfo;
  while (true) {
    client = await pickS3Client();
    try {
      r = await client.client.send(
        new CreateMultipartUploadCommand({
          Bucket: config.upload.bucket,
          Key: path,
        })
      );
      break;
    } catch (e) {
      console.error("S3 error", e);
      client.info.lastError = new Date().valueOf();
    }
  }

  const info: MultipartUploadInfo = {
    uploadId: r.UploadId!,
    key,
    fil,
    path,
    checksum: new jsSHA("SHA-256", "ARRAYBUFFER"),
    checksumCurrentPart: 1,
    uploadedParts: new Array(numberOfParts),
    onDone,
    client,
  };
  let nextPart: MultipartUploadPart | undefined;
  const append = [];
  for (let n = numberOfParts; n >= 1; n--) {
    // callback for resolving the iv Promise
    let resolve: (value: ArrayBuffer) => void;
    // Promise that will be resolved to the iv
    const iv = new Promise<ArrayBuffer>(
      (r) =>
        (resolve = (v) => {
          r(v);
          triggerUpload();
        })
    );
    // function that can be called to resolve the iv Promise
    const resolveIv = await new Promise<(value: ArrayBuffer) => void>((r) => r(resolve));
    // resolve iv for first part
    if (n === 1) resolveIv(new ArrayBuffer(16));
    nextPart = { n, info, nextPart, iv, resolveIv, ivResolved: n === 1 };
    append.push(nextPart);
  }
  for (let i = append.length - 1; i >= 0; i--) uploadQueue.push(append[i]);
  triggerUpload();
}

export function triggerUpload() {
  for (let i = 0; Math.max(i, currentlyUploading.size) < config.upload.maxConcurrentUploads; i++) {
    uploadNextPart();
  }
}

/**
 * Upload the next queued Multipart Upload Part that already has a resolved IV.
 */
export function uploadNextPart() {
  // find first part with resolved IV
  const i = uploadQueue.findIndex((v) => v.ivResolved);
  if (i == -1) {
    console.debug("uploader: uploadNextPart: Could not find part with resolved IV to upload.");
    return;
  }
  const next = uploadQueue.splice(i, 1)[0];
  uploadPart(next);
}

/**
 * Upload a Multipart Upload Part.
 */
export async function uploadPart(part: MultipartUploadPart) {
  currentlyUploading.add(part);
  try {
    const { n, info, iv, nextPart } = part;
    const { fil, checksum, checksumCurrentPart, path, key, uploadId, uploadedParts, onDone, client } = info;
    // slice data
    const d = await fil.file.slice((n - 1) * config.upload.partSize, n * config.upload.partSize).arrayBuffer();
    // add data to checksum
    if (checksum !== undefined && n === checksumCurrentPart) {
      info.checksumCurrentPart++;
      checksum.update(d);
    }
    console.debug(
      `uploading part ${n} to ${path}`,
      "; currentlyUploading:",
      currentlyUploading,
      "; upload queue:",
      uploadQueue
    );
    // encrypt
    let enc = await aesEncrypt(key, d, await iv);

    if (nextPart !== undefined) {
      // remove padding
      enc = enc.slice(0, enc.byteLength - 16);
      // store IV for next part
      nextPart.ivResolved = true;
      nextPart.resolveIv(enc.slice(enc.byteLength - 16, enc.byteLength));
    }
    // upload part
    const v = await client.client.send(
      new UploadPartCommand({
        UploadId: uploadId,
        PartNumber: n,
        Bucket: config.upload.bucket,
        Key: path,
        Body: new Uint8Array(enc),
      })
    );
    // store etag
    uploadedParts[n] = { PartNumber: n, ETag: v.ETag };
    console.debug(`uploaded part ${n} (ETag: ${v.ETag}) to ${path}`);
    // update frac
    fil.fileUploadFrac += d.byteLength / fil.size;
    currentlyUploading.delete(part);
    // complete if no parts remaining
    if (
      [...currentlyUploading].findIndex((v) => v !== part && v.info === info) === -1 &&
      uploadQueue.findIndex((v) => v.info === info) === -1
    ) {
      await timeout(50);
      await completeMultipartUpload({ info, client });
      onDone?.();
    }
  } catch (e) {
    console.error("Error while uploading part", e);
    uploadQueue.push(part);
    currentlyUploading.delete(part);
  }
  triggerUpload();
}
