export function graphqlify(data: any): string {
  if (data instanceof Array) {
    return `[${data.map(graphqlify).join(", ")}]`;
  } else if (data === null || data === undefined) {
    return "null";
  } else if (typeof data === "object") {
    const fields = Object.keys(data)
      .filter((key) => typeof data[key] !== "function" && key.match(/^[A-Za-z0-9_]+$/)?.length === 1)
      .map((key) => `${key}: ${graphqlify(data[key])}`);
    return `{${fields.join(", ")}}`;
  } else return JSON.stringify(data);
}
