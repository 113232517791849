import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { Text, View } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { VFile } from "../../../model/file/VFile";
import { useWindowDimensions } from "../../../model/util/useWindowDimensions.web";
import DirectoryVersion from "../../../model/version/DirectoryVersion";
import FileVersion from "../../../model/version/FileVersion";
import ImageVersion from "../../../model/version/ImageVersion";
import { VersionStateEnum, versionStateMessage } from "../../../model/version/VersionState";
import VideoVersion from "../../../model/version/VideoVersion";
import { useVersion } from "../../../model/version/useVersion";
import { useColors, useRandomBackgroundColor } from "../../Colors";
import styles from "../../Styles";
import Icon from "../../components/icon/Icon";
import { useCardMinWidth } from "./FilesGrid";
import StatusRings from "./StatusRings";
import VideoPreview from "./VideoPreview";

const FilePreview = observer((props: { file: VFile }) => {
  const { file } = props;
  const colors = useColors().fileCard;
  const stateColors = useColors().versionState;
  const { data: latestVersion } = useVersion(file, -1);
  const color = useRandomBackgroundColor(file.id);

  const [numberOfDots, setNumberOfDots] = useState(0);
  useEffect(() => {
    const i = setInterval(() => setNumberOfDots(numberOfDots > 2 ? 0 : numberOfDots + 1), 500);
    return () => clearInterval(i);
  });

  if (latestVersion instanceof FileVersion && latestVersion.state.current !== VersionStateEnum.done)
    return (
      <View style={{ paddingTop: `${(9 / 16) * 100}%`, height: 0, width: "100%" }}>
        <View style={[styles.absoluteFill, { justifyContent: "flex-end" }]}>
          <View style={{ height: "100%", width: "100%", position: "absolute" }}>
            <StatusRings
              rings={[
                { value: latestVersion.state.fileUploadFrac ?? 0.5, color: stateColors.fileUpload },
                { value: latestVersion.state.transcodeDownloadFrac ?? 0.5, color: stateColors.jobDownload },
                { value: latestVersion.state.transcodeFrac ?? 0.5, color: stateColors.jobTranscode },
                { value: latestVersion.state.transcodeUploadFrac ?? 0.5, color: stateColors.jobUpload },
              ]}
            />
          </View>
          <Text style={{ color: colors.text, textAlign: "center", padding: 10, fontSize: 13 }}>
            {versionStateMessage(latestVersion.state.current)}
            <Text style={{ opacity: numberOfDots > 0 ? 1 : 0 }}>.</Text>
            <Text style={{ opacity: numberOfDots > 1 ? 1 : 0 }}>.</Text>
            <Text style={{ opacity: numberOfDots > 2 ? 1 : 0 }}>.</Text>
          </Text>
        </View>
      </View>
    );

  if (latestVersion instanceof VideoVersion) return <VideoPreview version={latestVersion} />;
  return (
    <View style={{ paddingTop: `${(9 / 16) * 100}%`, height: 0 }}>
      <View
        style={[
          styles.fileCardImage,
          styles.absoluteFill,
          { alignItems: "center", justifyContent: "center", backgroundColor: color },
        ]}
      >
        <Icon
          name={
            latestVersion instanceof ImageVersion
              ? "image"
              : latestVersion instanceof DirectoryVersion
              ? "folder"
              : "file"
          }
          size={80}
          color="#aaa"
        />
      </View>
    </View>
  );
});

export default FilePreview;

export function useGridCardInfo() {
  const insets = useSafeAreaInsets();
  const dimensions = useWindowDimensions();
  const gridWidth = dimensions.width - insets.left - insets.right;
  const numberOfColumns = Math.floor((gridWidth - 10) / (useCardMinWidth() + 10));
  const cardWidth = (gridWidth - (numberOfColumns + 1) * 10) / numberOfColumns;
  const cardHeight = ((cardWidth - 10 * 2) / 16) * 9;
  return { numberOfColumns, cardWidth, cardHeight };
}
