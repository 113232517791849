import React, { useCallback, useEffect } from "react";
import { Platform, View } from "react-native";
import {
  GestureEvent,
  HandlerStateChangeEvent,
  PanGestureHandler,
  PanGestureHandlerEventPayload,
  State,
  TapGestureHandler,
  TouchableOpacity,
} from "react-native-gesture-handler";
import Animated, {
  Extrapolate,
  interpolate,
  useAnimatedStyle,
  useDerivedValue,
  useSharedValue,
  withSpring,
  withTiming,
} from "react-native-reanimated";
import { VFile } from "../../../model/file/VFile";
import { useWindowDimensions } from "../../../model/util/useWindowDimensions.web";
import { useColors } from "../../Colors";
import CommentSortDropdown from "../../components/comments/CommentSortDropdown";
import CommentsView from "../../components/comments/CommentsView";
import NewCommentViewMobile from "../../components/comments/NewCommentViewMobile";
import NewCommentViewWrapper from "../../components/comments/NewCommentViewWrapper";
import Icon from "../../components/icon/Icon";
import GalleryContent from "./GalleryContent";
import GalleryPreview from "./GalleryPreview";
import GallerySelector from "./GallerySelector";
import PreviewScreenNavbar from "./PreviewScreenNavbar";

export default function PreviewScreenMobile(props: { file: VFile }) {
  const { file } = props;

  const commentsSeparatorColors = useColors().commentsSeparatorMobile;
  const dimensions = useWindowDimensions();

  const controlsOpacity = useSharedValue(1);
  const controlsStyles = useAnimatedStyle(() => ({ opacity: controlsOpacity.value }));

  const navbarHeight = 64;
  const selectorHeight = file.type === "DIRECTORY" ? 100 : 0;
  const playerHeightExpanded = dimensions.height - navbarHeight - selectorHeight;
  const playerHeightCollapsed = (dimensions.width / 16) * 9 + 42;

  const expansionState = useSharedValue(1);
  const playerExpansion = useSharedValue(1);

  const topOuterStyle = useAnimatedStyle(() => ({
    height: interpolate(
      expansionState.value,
      [0, 1, 2],
      [0, playerHeightCollapsed + selectorHeight, playerHeightExpanded + selectorHeight],
      { extrapolateLeft: Extrapolate.CLAMP }
    ),
    marginTop: interpolate(expansionState.value, [-1, 0], [-playerHeightCollapsed - selectorHeight, 0], {
      extrapolateRight: Extrapolate.CLAMP,
    }),
  }));
  const playerStyle = useAnimatedStyle(() => ({
    height: interpolate(playerExpansion.value, [1, 2], [playerHeightCollapsed, playerHeightExpanded]),
  }));

  const commentsVisible = useDerivedValue(() => interpolate(playerExpansion.value, [1, 2], [1, 0]));

  const toggleControls = useCallback(() => {
    if (expansionState.value === 2) controlsOpacity.value = withTiming(controlsOpacity.value === 1 ? 0 : 1);
  }, [expansionState, controlsOpacity]);

  if (Platform.OS === "web") {
    useEffect(() => {
      const f = (e: WheelEvent) => {
        if (e.deltaY > 0) {
          expansionState.value = withTiming(1);
          playerExpansion.value = withTiming(1);
          controlsOpacity.value = withTiming(1);
        } else if (e.deltaY < 0) {
          expansionState.value = withTiming(2);
          playerExpansion.value = withTiming(2);
        }
      };
      const e = document.getElementById("preview-screen-top");
      e?.addEventListener("wheel", f);
      return () => e?.removeEventListener("wheel", f);
    });
  }

  const toggleExpandComments = useCallback(() => {
    expansionState.value = withTiming(expansionState.value === 0 ? 1 : 0);
  }, [expansionState]);

  // separator handlers
  const separatorOnGestureEvent = useCallback(
    (e: GestureEvent<PanGestureHandlerEventPayload>) => {
      const { velocityY, absoluteY } = e.nativeEvent;
      expansionState.value = (absoluteY - navbarHeight) / (playerHeightCollapsed + selectorHeight);
    },
    [expansionState, navbarHeight, playerHeightCollapsed, selectorHeight]
  );

  const separatorOnHandlerStateChange = useCallback(
    (e: HandlerStateChangeEvent<PanGestureHandlerEventPayload>) => {
      const { state, velocityY, absoluteY } = e.nativeEvent;
      switch (state) {
        case State.END:
          const v0 = velocityY;
          const targetVelocity = 2000;
          // acceleration used to determine wheter to expand or collapse
          const aDet = -100;
          // v(t) = v0 + a*t
          // s(t) = (a * t^2) / 2 + v0*t + p
          // 0 = a/2 * t^2 + v0*t + p
          // t = (-v0 ± sqrt(v0^2 - 4*a*p)) / (2*a)

          // s = v(t) * t
          // v(t) = a * t
          // s = (v0 + a*t) * t = a*t^2 + v0*t
          // t = (-v0 ± sqrt(v0^2 - 4*a*0)) / (2 * a)
          // t = (-v0 ± v0) / (2 * a)
          // t1 = -2v0 / (2 * a) = -v0 / a
          // s =
          const t = Math.abs(v0 / aDet);
          const distance = playerHeightExpanded - playerHeightCollapsed;
          const offset = expansionState.value * distance;
          const ramainingDistance = (aDet * t ** 2) / 2 + v0 * t;
          const estimatedEndPosition = ramainingDistance + offset;
          // console.log(
          //   "distance",
          //   distance,
          //   "v0",
          //   Math.round(v0),
          //   "ramainingDistance",
          //   Math.round(ramainingDistance),
          //   "estimatedEndPosition",
          //   Math.round(estimatedEndPosition),
          //   "offset",
          //   Math.round(offset)
          // );
          expansionState.value = withSpring(estimatedEndPosition > distance / 2 ? 1 : 0, {
            velocity: velocityY / (playerHeightCollapsed + selectorHeight),
            damping: 100,
          });
      }
    },
    [expansionState, playerHeightCollapsed, selectorHeight]
  );

  return (
    <View style={{ height: "100%", overflow: "hidden" }}>
      {/* navbar */}
      <Animated.View style={[controlsStyles, { zIndex: 1 }]}>
        <PreviewScreenNavbar
          file={file}
          commentsVisible={commentsVisible}
          toggleComments={() => {
            if (Math.abs(expansionState.value - 2) > Math.abs(expansionState.value) - 1) {
              expansionState.value = withTiming(2);
              playerExpansion.value = withTiming(2);
            } else {
              expansionState.value = withTiming(1);
              playerExpansion.value = withTiming(1);
            }
          }}
        />
      </Animated.View>

      {/* preview & selcetor */}
      <Animated.View style={[topOuterStyle]} nativeID="preview-screen-top">
        <PanGestureHandler
          onGestureEvent={(e) => {
            if (e.nativeEvent.translationY > 0) {
              expansionState.value = withTiming(2);
              playerExpansion.value = withTiming(2);
            } else {
              expansionState.value = withTiming(1);
              playerExpansion.value = withTiming(1);
            }
          }}
        >
          <Animated.View>
            <TapGestureHandler onEnded={toggleControls}>
              <Animated.View style={[{ width: "100%" }, playerStyle]}>
                {file.type === "DIRECTORY" ? <GalleryPreview file={file} /> : <GalleryContent file={file} />}
              </Animated.View>
            </TapGestureHandler>
            {file.type === "DIRECTORY" && (
              <Animated.View style={[controlsStyles]}>
                <GallerySelector file={file} commentsVisible={commentsVisible} />
              </Animated.View>
            )}
          </Animated.View>
        </PanGestureHandler>
      </Animated.View>

      {/* comments */}
      <View style={[{ flexShrink: 1, flexGrow: 1, zIndex: 1 }]}>
        <PanGestureHandler
          onGestureEvent={separatorOnGestureEvent}
          onHandlerStateChange={separatorOnHandlerStateChange}
        >
          <View
            style={{
              height: 40,
              flexDirection: "row",
              backgroundColor: commentsSeparatorColors.background,
              borderTopLeftRadius: 8,
              borderTopRightRadius: 8,
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <CommentSortDropdown color={commentsSeparatorColors.text} style={{ padding: 12 }} />
            <TouchableOpacity onPress={toggleExpandComments} style={{ padding: 12 }}>
              <Icon name="expand" color={commentsSeparatorColors.icon} size={18} />
            </TouchableOpacity>
          </View>
        </PanGestureHandler>
        <CommentsView file={file} spaceBottom={46 + 16} />

        <NewCommentViewWrapper
          file={file}
          Component={NewCommentViewMobile}
          commentsVisible={commentsVisible}
          toggleExpand={toggleExpandComments}
          separatorOnGestureEvent={separatorOnGestureEvent}
          separatorOnHandlerStateChange={separatorOnHandlerStateChange}
        />
      </View>
    </View>
  );
}
