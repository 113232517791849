import config from "../config";

export async function cacheFile(path: string, get: () => Promise<Response>) {
  const cache = await caches.open(config.filecache);
  const req = new Request(`https://client.storage.vidre.io/${path}`);
  const r = await cache.match(req);
  if (r === undefined) {
    await cache.put(req, await get());
  }
  return req.url;
}
