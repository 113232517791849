import { VFile } from "../../file/VFile";
import { Dir, Fil } from "../types/UploadElement";

export function buildDirMapFromFileList(fileList: FileList, parentDir?: VFile): Dir {
  const dir: Dir = new Dir(parentDir ? { file: parentDir } : {});
  for (let i = 0; i < fileList.length; i++) {
    const file = fileList.item(i)!;
    const path = file.webkitRelativePath.split("/");
    if (checkFilePath(path)) dirsRecursive({ file, path, dir });
  }
  return dir;
}

function dirsRecursive({ file, path, dir }: { file: File; path: string[]; dir: Dir }) {
  if (path.length === 1) {
    dir.files.set(path[0], new Fil(file));
  } else {
    let d = dir.files.get(path[0]);
    if (!d) {
      d = new Dir({ name: path[0] });
      dir.files.set(path[0], d);
    }
    dirsRecursive({ file, path: path.slice(1), dir: d as Dir });
  }
}

// TODO: test
const ignoreFiles = [
  /\.DS_Store/,
  /\.AppleDouble/,
  /\.LSOverride/,
  /Icon\r\r/,
  /\._.*/,
  /\.DocumentRevisions-V100/,
  /\.fseventsd/,
  /\.Spotlight-V100/,
  /\.TemporaryItems/,
  /\.Trashes/,
  /\.VolumeIcon\.icns/,
  /\.com\.apple\.timemachine\.donotpresent/,
];

/** returns true if the file should be uploaded */
export function checkFilePath(path: string[]): boolean {
  for (const c of ignoreFiles) {
    for (const p of path) {
      const m = p.match(c);
      if (m?.length === 1 && m[0].length === p.length) return false;
    }
  }
  return true;
}
