import { observer } from "mobx-react-lite";
import { createContext, useEffect } from "react";
import { Text, TextInput, TextStyle, View, ViewStyle } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import { loginStore } from "../../../model/ui/LoginStore";
import { useColors } from "../../Colors";
import ColoredBars from "../../screens/auth/ColoredBars";
import ModalView from "../ModalView";

const AuthModal = observer((props: { visible: boolean; setVisible: (b: boolean) => void }) => {
  const { visible, setVisible } = props;
  const { displayName, username, password, confirmPassword, warnings, passwordScore } = loginStore;
  const colors = useColors().authModal;
  const loginButtonColors = useColors().loginButton;
  const passwordBarsColors = useColors().passwordBars;

  useEffect(() => {
    loginStore.extendedChecks = true;
  }, []);

  const submit = () => {
    (loginStore.extendedChecks ? loginStore.signup() : loginStore.login())
      .then(() => setVisible(false))
      .catch(() => {});
  };

  const inputStyle: ViewStyle = {
    width: "100%",
    borderRadius: 8,
    padding: 14,
    marginVertical: 3,
  };
  const textInputStyle: TextStyle = {
    backgroundColor: colors.inputBackground,
    color: colors.text,
    borderWidth: 1,
    borderColor: colors.border,
  };

  return (
    <ModalView
      title={loginStore.extendedChecks ? "Signup" : "Login"}
      titleStyle={{ textAlign: "center" }}
      visible={visible}
      setVisible={setVisible}
      noButtons
    >
      <View
        style={{
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
          width: 320,
          maxWidth: "100%",
        }}
      >
        {loginStore.extendedChecks && (
          <TextInput
            spellCheck={false}
            style={[inputStyle, textInputStyle]}
            onChangeText={(s) => (loginStore.displayName = s)}
            value={displayName}
            placeholder="Display Name"
            placeholderTextColor={colors.placeholder}
          />
        )}
        <TextInput
          spellCheck={false}
          style={[inputStyle, textInputStyle]}
          onChangeText={(s) => (loginStore.username = s)}
          value={username}
          placeholder="Username"
          placeholderTextColor={colors.placeholder}
        />
        <TextInput
          spellCheck={false}
          style={[inputStyle, textInputStyle]}
          onChangeText={(s) => (loginStore.password = s)}
          value={password}
          placeholder="Password"
          placeholderTextColor={colors.placeholder}
          secureTextEntry
        />
        {loginStore.extendedChecks && (
          <>
            <TextInput
              spellCheck={false}
              style={[inputStyle, textInputStyle]}
              onChangeText={(s) => (loginStore.confirmPassword = s)}
              onSubmitEditing={submit}
              value={confirmPassword}
              placeholder="Confirm Password"
              placeholderTextColor={colors.placeholder}
              secureTextEntry
            />
            <ColoredBars
              style={{ width: "100%", height: 2, marginTop: 5 }}
              barStyle={{ marginHorizontal: 1.5 }}
              n={passwordScore}
              colors={passwordBarsColors}
            />
          </>
        )}
        <View style={{ width: "100%" }}>
          <TouchableOpacity
            style={[inputStyle, { backgroundColor: loginButtonColors.background, marginTop: 10 }]}
            onPress={submit}
          >
            <Text style={[{ textAlign: "center", color: loginButtonColors.text, fontWeight: "700" }]}>
              {loginStore.extendedChecks ? "Signup" : "Login"}
            </Text>
          </TouchableOpacity>
        </View>
        <TouchableOpacity
          style={{ margin: 8 }}
          onPress={() => (loginStore.extendedChecks = !loginStore.extendedChecks)}
        >
          <Text style={{ color: colors.switchView }}>Already have an account? Login</Text>
        </TouchableOpacity>
        {[...new Array(2).keys()].map((v) => (
          <Text style={{ color: colors.error, textAlign: "center" }}>{[...warnings][v] ?? " "}</Text>
        ))}
        {[...warnings].slice(2).map((w) => (
          <Text style={{ color: colors.error, textAlign: "center" }}>{w}</Text>
        ))}
      </View>
    </ModalView>
  );
});

export default AuthModal;

// @ts-ignore
export const AuthModalContext = createContext<{ setAuthVisible: (b: boolean) => void }>();
