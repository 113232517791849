import { observable, runInAction } from "mobx";
import { store } from "../../Store";
import { VFileDirectory } from "../../file/VFile";
import { uploadFileTree } from "../uploader/uploadFileTree";
import { buildDirMapFromFileList } from "../util/buildDirMap";

/**
 * Recursively uploads a directoy
 * @param fileList from HTML input
 * @param parentDir parent dir `VFile`
 */
export async function uploadDirectory(fileList: FileList, parentDir: VFileDirectory | null) {
  const dir = buildDirMapFromFileList(fileList);
  const _store = await store;
  return runInAction(() => {
    const u = _store.uploads.get(parentDir?.id ?? null);
    if (u) u.push(dir);
    else _store.uploads.set(parentDir?.id ?? null, observable.array([dir]));

    console.log("file list:", fileList, "dir map:", dir);
    return uploadFileTree(dir.files, parentDir);
  });
}
