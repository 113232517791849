import { Component, ReactNode, RefObject, createRef } from "react";
import { LayoutChangeEvent, StyleProp, Text, TextStyle } from "react-native";

export default class EllipsisText extends Component<{
  style?: StyleProp<TextStyle>;
  children: string;
  useParent?: boolean;
  subtractRefs?: RefObject<HTMLElement>[];
}> {
  ref = createRef<Text & HTMLElement>();
  ref1 = createRef<Text & HTMLSpanElement>();
  ref2 = createRef<Text & HTMLSpanElement>();

  render(): ReactNode {
    this.renderText();
    return (
      // {/* <canvas id="mycanvas" width={400} height={50} style={{ width: 400 }} /> */}
      <Text ref={this.ref} style={[this.props.style, {}]} onLayout={this.renderText} numberOfLines={1}>
        <Text ref={this.ref1} numberOfLines={1} style={{ flexGrow: 1 }} />
        <Text ref={this.ref2} numberOfLines={1} style={{ overflow: "visible" }} />
      </Text>
    );
  }

  renderText = (e?: LayoutChangeEvent) => {
    if (!this.ref.current?.parentElement || !this.ref1.current || !this.ref2.current) return;
    const text = this.props.children;
    // const text = "iiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const w =
      (this.props.useParent
        ? this.ref.current.parentElement.clientWidth
        : e?.nativeEvent.layout.width ?? this.ref.current.clientWidth) -
      (this.props.subtractRefs ?? []).map((v) => v.current?.offsetWidth ?? 0).reduce((a, b) => a + b, 0);
    const font = getCanvasFont(this.ref.current);
    const dotsWidth = getTextWidth("…", font);
    const t1 = longestTextThatFits({ text, width: (w - dotsWidth) / 2, font, rtl: false });
    const t2 = longestTextThatFits({ text, width: (w - dotsWidth) / 2, font, rtl: true });
    this.ref1.current.innerText = t1 + t2 < text.length ? text.slice(0, t1).trimEnd() : text;
    this.ref2.current.innerText = t1 + t2 < text.length ? "…" + text.slice(-t2).trimStart() : "";
  };
}

function longestTextThatFits(params: { text: string; width: number; rtl: boolean; font: string }): number {
  const { text, width, rtl, font } = params;

  let w = Number.POSITIVE_INFINITY;
  let len = text.length;
  let r = text;

  while (width > 0) {
    r = text.slice(rtl ? text.length - len : 0, rtl ? text.length : len);
    r = rtl ? r.trimEnd() : r.trimStart();

    w = getTextWidth(r, font);
    // console.log("longestTextThatFits 1", "len:", len, "w:", w, "width:", width, "text:", r);
    if (w > width) len = Math.floor((len * width) / w);
    else break;
  }
  while (true) {
    r = text.slice(rtl ? text.length - len : 0, rtl ? text.length : len);
    r = rtl ? r.trimEnd() : r.trimStart();

    w = getTextWidth(r, font);
    // console.log("longestTextThatFits 2", "len:", len, "w:", w, "width:", width, "text:", r, "text.length:", text.length);
    if (w < width && len < text.length) len++;
    else {
      len--;
      break;
    }
  }
  r = text.slice(rtl ? text.length - len : 0, rtl ? text.length : len);
  // console.log("longestTextThatFits 3", "len:", len, "w:", w, "width:", width, "text:", r);
  return len;
}

/**
 * Text measurement
 * https://stackoverflow.com/a/21015393/18683794
 */

/**
 * Measure the width of a rendered text
 * @param text Text to be measured
 * @returns
 */
function getTextWidth(text: string, font: string) {
  // re-use canvas object for better performance
  // @ts-ignore
  const canvas: HTMLCanvasElement = getTextWidth.canvas || (getTextWidth.canvas = document.createElement("canvas"));
  // const canvas: HTMLCanvasElement = document.querySelector("#mycanvas");
  const context = canvas.getContext("2d")!;
  context.clearRect(0, 0, 400, 200);
  context.font = font;
  context.fillStyle = "#fff";
  const metrics = context.measureText(text);
  // context.fillText(text, 0, 20);
  return metrics.width;
}

function getCssStyle(element: HTMLElement, prop: string) {
  return window.getComputedStyle(element, null).getPropertyValue(prop);
}

function getCanvasFont(el = document.body) {
  const fontWeight = getCssStyle(el, "font-weight") || "normal";
  const fontSize = getCssStyle(el, "font-size") || "16px";
  const fontFamily = getCssStyle(el, "font-family") || "Times New Roman";

  return `${fontWeight} ${fontSize} ${fontFamily}`;
}
