import { Localized } from "@fluent/react";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useCallback, useContext, useState } from "react";
import { TouchableOpacity, View } from "react-native";
import Animated, { interpolate, useAnimatedStyle } from "react-native-reanimated";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { StoreContext } from "../../../model/Store";
import { createComment } from "../../../model/comment/createComment";
import { VideoStoreContext } from "../../../model/ui/VideoStore";
import { useColors } from "../../Colors";
import { AuthModalContext } from "../../modals/auth/AuthModal";
import LoadCircle from "../../util/LoadCircle";
import Icon from "../icon/Icon";
import CommentContextToggle from "./CommentContextToggle";
import MultilineTextInput from "./MultilineTextInput";
import { NewCommentViewProps } from "./NewCommentViewProps";

const NewCommentViewDesktop = observer((props: NewCommentViewProps) => {
  const { commentContext, commentContextIsDir, showingDirGallery, toggleCommentContext, visible } = props;
  const colors = useColors();
  const _store = useContext(StoreContext);
  const vStore = useContext(VideoStoreContext);

  const wipComment = commentContext?.wipComment;
  const text = wipComment?.text ?? "";

  const onChangeText = useCallback((t: string) => runInAction(() => commentContext?.setComment(t)), [commentContext]);

  const authModalContext = useContext(AuthModalContext);

  const [isSending, setIsSending] = useState(false);
  const submit = useCallback(async () => {
    if (!_store.me) authModalContext.setAuthVisible(true);
    else if (wipComment?.canSend && commentContext) {
      setIsSending(true);
      await createComment({
        commentContext,
        wipComment,
        currentTimecode: vStore.positionMillis,
      });
      setIsSending(false);
    }
  }, [_store, authModalContext, wipComment]);

  const insets = useSafeAreaInsets();
  const animatedStyle = useAnimatedStyle(() => ({
    marginRight: interpolate(visible.value, [0, 1], [Math.max(10, insets.right), 10]),
  }));

  return (
    <Animated.View
      style={[
        animatedStyle,
        {
          borderRadius: 10,
          backgroundColor: colors.newCommentDesktop.background,
          margin: 10,
          marginLeft: Math.max(10, insets.left),
        },
      ]}
    >
      <View style={{ paddingHorizontal: 10, paddingVertical: 4 }}>
        <CommentContextToggle
          commentContext={commentContext}
          commentContextIsDir={commentContextIsDir}
          showingDirGallery={showingDirGallery}
          toggleCommentContext={toggleCommentContext}
        />
      </View>

      <View style={{ flexDirection: "row", width: "100%" }}>
        {/* text input */}
        <View style={{ paddingLeft: 10, paddingBottom: 10, flexGrow: 1, flex: 1 }}>
          <Localized id="comment-reply" attrs={{ placeholder: true }}>
            <MultilineTextInput
              text={text}
              color={colors.newCommentDesktop.text}
              //editable={!createMutation.isLoading} // TODO
              placeholderTextColor={colors.newCommentDesktop.placeholder}
              onChangeText={onChangeText}
              onSubmitEditing={submit}
            />
          </Localized>
        </View>

        {/* submit button */}
        <View style={{ padding: 10, alignSelf: "flex-end" }}>
          {isSending ? (
            <LoadCircle size={20} color={colors.commentContextToggle.disabled} />
          ) : (
            <TouchableOpacity onPress={submit} activeOpacity={wipComment?.canSend ? undefined : 1}>
              <Icon
                name="send"
                size={20}
                color={wipComment?.canSend ? colors.commentContextToggle.accent : colors.commentContextToggle.disabled}
              />
            </TouchableOpacity>
          )}
        </View>
      </View>
    </Animated.View>
  );
});

export default NewCommentViewDesktop;
