import * as Api from "../Api";
import { store } from "../Store";
import { aesEncrypt, base64Encode, exportRawKey, generateAesKey, getRelation } from "../util/CryptoHelper";
import Version from "../version/Version";
import Comment, { WipComment } from "./Comment";
import { Commentable } from "./Commentable";

export async function createComment(params: {
  commentContext: Commentable;
  wipComment: WipComment;
  currentTimecode?: number;
}): Promise<Comment> {
  const { commentContext: ctx, wipComment: c, currentTimecode } = params;

  const _store = await store;
  const me = _store.me!;

  const replyTo = ctx instanceof Comment ? ctx : undefined;
  const version = replyTo?.version ?? (ctx as Version);

  const encKey = await generateAesKey();
  const encryptedKey = aesEncrypt(ctx.encKey, await exportRawKey(encKey), new ArrayBuffer(16));
  const ciphertext = {
    text: c.text,
    startTimecode: (_store.commentLinkTimecode ? currentTimecode : c.startTimecode) ?? undefined,
    endTimecode: c.endTimecode ?? undefined,
  };
  const encryptedCiphertext = aesEncrypt(encKey, Buffer.from(JSON.stringify(ciphertext), "utf-8"), new ArrayBuffer(16));
  const relationNo = ctx.maxExistingRelation + 1;
  const relation = await getRelation(ctx.id, ctx.encKey, relationNo);

  const r: { id: string; versions: { createdAt: string }[] } =
    // prettier-ignore
    (await Api.gql(`mutation {
    createComment(comment: {
      relation: "${base64Encode(relation)}",
      key: "${base64Encode(await encryptedKey)}",
      ciphertext: "${base64Encode(await encryptedCiphertext)}",
    }) {
      id
      versions {
        createdAt
      }
    }
  }`, `Bearer ${me.token.t}`)).createComment;
  const comment = new Comment({
    id: r.id,
    encKey,
    relationNo,
    version,
    replyTo,
    versions: new Map(
      r.versions.map((v) => [
        0,
        {
          text: ciphertext.text,
          author: me,
          startTimecode: ciphertext.startTimecode,
          endTimecode: ciphertext.endTimecode,
          createdAt: new Date(v.createdAt),
        },
      ])
    ),
  });
  ctx.comments.set(r.id, comment);
  ctx.wipComment = null;
  return comment;
}
