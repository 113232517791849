import { observer } from "mobx-react-lite";
import { useState } from "react";
import { Platform, Text } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import Comment from "../../../model/comment/Comment";
import { useColors } from "../../Colors";
import CommentEditsModal from "./CommentEditsModal";

const CommentViewText = observer((props: { comment: Comment }) => {
  const { comment } = props;
  const colors = useColors().comment;
  const [visible, setVisible] = useState(false);
  return comment.isDeleted ? (
    <Text style={{ color: colors.deletedText, paddingVertical: 4 }}>Deleted comment</Text>
  ) : (
    <>
      <Text
        style={{
          color: colors.text,
          paddingVertical: 4,
          ...(Platform.OS === "web" && { userSelect: "text" }),
        }}
      >
        {comment.latestVersion?.text}
        {comment.isEdited && (
          <TouchableOpacity onPress={() => setVisible(!visible)}>
            <Text style={{ color: colors.editedText, fontSize: 12 }}>{"  (edited)"}</Text>
          </TouchableOpacity>
        )}
      </Text>
      <CommentEditsModal comment={comment} visible={visible} setVisible={setVisible} />
    </>
  );
});

export default CommentViewText;
