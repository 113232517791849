import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons";
import MaterialIcons from "@expo/vector-icons/MaterialIcons";
import { Component } from "react";
import { StyleProp, TextStyle, ViewStyle } from "react-native";
import Animated from "react-native-reanimated";
import { IconData, iconData } from "./Icon";
import { IconProps } from "./icons";

const AnimatedMaterialIcons = Animated.createAnimatedComponent(MaterialIcons);
const AnimatedMaterialCommunityIcons = Animated.createAnimatedComponent(MaterialCommunityIcons);

type AnimatedIconProps = Partial<IconProps> & { name: string } & {
  animatedProps?: Partial<{ size: number; color: string; style?: StyleProp<ViewStyle> }>;
};

export default class AnimatedIcon extends Component<AnimatedIconProps, { data: IconData }> {
  constructor(props: AnimatedIconProps) {
    super(props);
    this.state = AnimatedIcon.getDerivedStateFromProps(props);
  }

  static getDerivedStateFromProps(props: AnimatedIconProps) {
    return { data: iconData(props.name) };
  }

  render() {
    const { size, color } = this.props;
    const { data } = this.state;
    const style: StyleProp<TextStyle> = [
      this.props.style,
      // @ts-ignore
      { transition: "color 0.2s" },
    ];
    return data.type === "material" ? (
      <AnimatedMaterialIcons name={data.name} size={size} color={color} style={style} />
    ) : (
      <AnimatedMaterialCommunityIcons name={data.name} size={size} color={color} style={style} />
    );
  }
}
