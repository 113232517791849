import { observer } from "mobx-react-lite";
import { useCallback, useState } from "react";
import { Text, View } from "react-native";
import TranscodingServer from "../../../model/transcoding-server/TranscodingServer";
import { useColors } from "../../Colors";
import Icon from "../../components/icon/Icon";
import TranscodingServerModal from "../../modals/transcoding-server/TranscodingServerModal";

const TranscodingServerListItem = observer((props: { data: TranscodingServer }) => {
  const { data } = props;
  const colors = useColors().menu.item;
  const [visible, setVisible] = useState(false);
  const [name, setName] = useState("");
  const onSubmit = useCallback(() => {
    if (name) data.name = name;
    // TODO: persist changes
  }, [name, data]);
  return (
    <View
      style={{
        flexDirection: "row",
        alignItems: "center",
        height: 33 + 8 * 2 + 1,
        paddingHorizontal: 8,
        paddingTop: 9,
        paddingBottom: 8,
      }}
    >
      <View style={{ justifyContent: "center" }}>
        <Text style={{ color: colors.text, fontWeight: "500" }}>{data.name ?? data.url}</Text>
        {data.name && <Text style={{ color: colors.secondary, fontSize: 13 }}>{data.url}</Text>}
      </View>
      <Icon name="edit" size={18} color={colors.icon} style={{ padding: 8, flexGrow: 1 }} />
      <Icon name="reorder" size={20} color={colors.reorder} />
      <TranscodingServerModal
        title="Edit Transcoding Server"
        name={name}
        setName={setName}
        url={data.url}
        setUrl={() => {}}
        visible={visible}
        setVisible={setVisible}
      />
    </View>
  );
});

export default TranscodingServerListItem;
