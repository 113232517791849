import Hls from "hls.js";

export function initVideo(url: string) {
  const el = document.getElementsByTagName("video")[0];
  console.log(
    "native HLS support",
    el.canPlayType("application/vnd.apple.mpegurl"),
    "Hls.isSupported()",
    Hls.isSupported()
  );
  if (Hls.isSupported() && !el.canPlayType("application/vnd.apple.mpegurl")) {
    const hls = new Hls();
    hls.loadSource(url);
    hls.attachMedia(el);
  }
}
