import * as Api from "../../Api";
import { FileType, VFile, VFileDirectory } from "../../file/VFile";
import { Permissions } from "../../share/Permissions";
import { Share, ShareFromJson, shareGQLFields, ShareJson, UserShare } from "../../share/Share";
import { store } from "../../Store";
import { aesEncrypt, base64Encode, exportRawKey, generateAesKey, rsaEncrypt } from "../../util/CryptoHelper";
import { graphqlify } from "../../util/graphqlify";
import DirectoryVersion, { DirectoryVersionJson } from "../../version/DirectoryVersion";
import { VersionCiphertextJson, versionGQLFields } from "../../version/Version";

export type CreateVersionResult = {
  id: string;
  type: FileType;
  key: CryptoKey;
  fileKey: CryptoKey;
  shares: Map<string, Share>;
  latestVersion: CreateVersionVersionResult;
  createdAt: Date;
};

export type CreateVersionVersionResult = {
  id: string;
  createdAt: string;
  fileTypes: FileType[];
};

type CreateVersionResultJson = {
  id: string;
  createdAt: string;
  latestVersion: CreateVersionVersionResult;
};

const createVersionResultGQL = `
    id
    createdAt
    latestVersion {
        id
        createdAt
        fileTypes
    }
`;

export type CreateVersionData<J extends VersionCiphertextJson> = {
  ciphertext: J;
  type: FileType;
  size?: number;
};

/**
 * Creates multiple versions in a directory
 * @param params
 * @returns
 */
export const createVersions = async <J extends VersionCiphertextJson>(params: {
  data: CreateVersionData<J>[];
  parentDir: VFileDirectory | null;
}) => {
  const { data, parentDir } = params;
  const me = (await store).me;
  if (!me) throw new Error("Must be signed in to create new Version");

  const partial: { type: FileType; key: CryptoKey; fileKey: CryptoKey }[] = [];

  const data2 = await Promise.all(
    data.map(async (d) => {
      const key = await generateAesKey();
      const fileKey = await generateAesKey();
      const encryptedKey = aesEncrypt(fileKey, await exportRawKey(key), new ArrayBuffer(16));

      const ciphertext = await aesEncrypt(key, Buffer.from(JSON.stringify(d.ciphertext), "utf-8"), new ArrayBuffer(16));

      const r: any = {
        type: d.type,
        size: d.size ?? null,
        key: base64Encode(await encryptedKey),
        ciphertext: base64Encode(ciphertext),
      };
      if (parentDir) {
        const encryptedFileKey = await aesEncrypt(parentDir.encKey, await exportRawKey(fileKey), new ArrayBuffer(16));
        r.fileKey = base64Encode(encryptedFileKey);
      } else {
        const shareKey = rsaEncrypt(me.pubkey, await exportRawKey(fileKey!));
        r.shareKey = base64Encode(await shareKey);
      }
      partial.push({ type: d.type, key, fileKey });
      return r;
    })
  );

  let result: CreateVersionResult[];

  if (parentDir) {
    // create files & version in dir
    const query = `mutation {
      createVersionsInDir(
        data: ${graphqlify(data2)},
        directory: "${parentDir.id}"
      ) {
        dirVersion { ${versionGQLFields}, childrenIds }
        newFiles {
          ${createVersionResultGQL}
          shares { id, createdAt }
        }
      }
    }`;
    const rj: { dirVersion: DirectoryVersionJson; newFiles: CreateVersionResultJson[] } = (
      await Api.gql(query, `Bearer ${me.token.t}`)
    ).createVersionsInDir;

    result = rj.newFiles.map((r, i) => ({
      id: r.id,
      shares: new Map(),
      latestVersion: r.latestVersion,
      ...partial[i],
      createdAt: new Date(r.createdAt),
    }));

    (await store).versions.set(rj.dirVersion.id, await DirectoryVersion.fromJson(rj.dirVersion, { file: parentDir }));
    parentDir.versionIds.push(rj.dirVersion.id);
  } else {
    // top level file with share
    const query = `mutation {
      createVersionsAndShares(
        data: ${graphqlify(data2)}
      ) {
        ${createVersionResultGQL}
        shares { id, createdAt }
      }
    }`;
    const rj: (CreateVersionResultJson & { shares: { id: string; createdAt: string }[] })[] = (
      await Api.gql(query, `Bearer ${me.token.t}`)
    ).createVersionsAndShares;

    result = rj.map((r, i) => {
      const shares = new Map<string, Share>(
        r.shares.map((v) => [
          v.id,
          new UserShare({
            id: v.id,
            from: me,
            to: me,
            createdAt: new Date(v.createdAt),
            permissions: new Permissions({ type: "owner" }),
          }),
        ])
      );
      return {
        id: r.id,
        shares,
        latestVersion: r.latestVersion,
        ...partial[i],
        createdAt: new Date(r.createdAt),
      };
    });
  }
  return result;
};

/**
 * Creates a new version of a file or creates a new file.
 * File and parent may not be specified at once.
 * @param {VersionCiphertextJson} params.ciphertext Information about the Version (will be encrypted)
 * @param {FileType} params.type File type.
 * @param {number} [params.size] File size in bytes. undefined if directory.
 * @param {VFile} [params.file] File to add a new version to.
 * @returns {Promise<VFile>} the file created or updated.
 */
export async function createVersionForFile<T extends VersionCiphertextJson>(params: {
  /** @property Information about the Version (will be encrypted) */
  ciphertext: T;
  type: FileType;
  /** @property File size in bytes. undefined if directory. */
  size?: number;
  /** @property File to add a new version to. */
  file: VFile;
}): Promise<CreateVersionResult> {
  const me = (await store).me;
  if (!me) throw new Error("Must be signed in to create new Version");

  const key = await generateAesKey();
  const fileKey = params.file?.encKey ?? (await generateAesKey());
  const encryptedKey = await aesEncrypt(fileKey, await exportRawKey(key), new ArrayBuffer(16));

  const ciphertext = await aesEncrypt(
    key,
    Buffer.from(JSON.stringify(params.ciphertext), "utf-8"),
    new ArrayBuffer(16)
  );

  // prettier-ignore
  const r: CreateVersionResultJson & {shares: ShareJson[]} = (await Api.gql(`mutation {
    createVersionForFile(data: {
      type: ${params.type},
      size: ${params.size ?? "null"},
      key: "${base64Encode(encryptedKey)}",
      ciphertext: "${base64Encode(ciphertext)}",
      file: "${params.file}",
    }) {
      ${createVersionResultGQL}
      shares { ${shareGQLFields}, to }
    }
  }`, `Bearer ${me.token.t}`)).createVersionForFile;

  const shares = new Map<string, Share>(
    await Promise.all(r.shares.map(async (v) => [v.id, await ShareFromJson(v)] as [string, Share]))
  );

  let result: CreateVersionResult = {
    id: r.id,
    type: params.type,
    key,
    fileKey,
    shares,
    latestVersion: r.latestVersion,
    createdAt: new Date(r.createdAt),
  };
  return result;
}
