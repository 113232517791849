import { CircularProgress } from "@material-ui/core";
import { CSSProperties } from "react";
import { ActivityIndicator, Platform, ViewStyle } from "react-native";
import { TouchableHighlight } from "react-native-gesture-handler";
import { useColors } from "../Colors";
import Icon from "./icon/Icon";

export type SaveStatus = "unsaved" | "saving" | "ok" | "failed";

const size = 20;
const style: ViewStyle = {
  padding: 8,
  justifyContent: "center",
  alignItems: "center",
  aspectRatio: 1,
};

export default function SaveStatusIndicator(props: { status: SaveStatus; save: () => void }) {
  const { status, save } = props;
  const colors = useColors().saveStatusIndicator;
  switch (status) {
    case "unsaved":
      return (
        <TouchableHighlight style={[style, { borderRadius: 800 }]} underlayColor={colors.underlay} onPress={save}>
          <Icon name="save" color={colors.unsaved} size={size} />
        </TouchableHighlight>
      );
    case "saving":
      return Platform.OS === "web" ? (
        <CircularProgress style={{ ...(style as CSSProperties), color: colors.saving }} size={size} />
      ) : (
        <ActivityIndicator style={style} color={colors.saving} />
      );
    case "ok":
      return <Icon name="check" color={colors.ok} size={size} style={style} />;
    case "failed":
      return (
        <Icon name={Platform.OS === "web" ? "close" : "close-circle"} color={colors.failed} size={size} style={style} />
      );
  }
}
