import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { VFile } from "../../../model/file/VFile";
import { Permissions } from "../../../model/share/Permissions";
import { useColors } from "../../Colors";
import Divider from "../../components/Divider";
import ToggleListItem from "../../components/input/ToggleListItem";

const SimplePermissionsEditor = observer((props: { permissions: Permissions; file: VFile }) => {
  const { permissions, file } = props;
  const colors = useColors().modal.permissions;
  const simple = permissions.asSimple;
  return (
    <>
      {file.type === "DIRECTORY" && (
        <>
          <ToggleListItem
            title="Upload"
            tooltip="Allows uploading new files."
            value={simple.upload}
            setValue={(v) => runInAction(() => (permissions.simple!.upload = v))}
            colorChecked={colors.checked}
            colorUnchecked={colors.unchecked}
            colorText={colors.text}
          />
          <Divider color={colors.divider} />
        </>
      )}
      <ToggleListItem
        title="Edit"
        tooltip="Allows adding new versions to files, renaming and deleting versions, files and directories."
        value={simple.edit}
        setValue={(v) => runInAction(() => (permissions.simple!.edit = v))}
        colorChecked={colors.checked}
        colorUnchecked={colors.unchecked}
        colorText={colors.text}
      />
      <Divider color={colors.divider} />
      {/* <ToggleListItem
        title="Comment"
        tooltip="Allows creating comments and rplying to other comments."
        value={simple.comment}
        setValue={(v) => runInAction(() => (permissions.simple!.comment = v))}
        colorChecked={colors.checked}
        colorUnchecked={colors.unchecked}
        colorText={colors.text}
      />
      <Divider color={colors.divider} />
      <ToggleListItem
        title="Moderate"
        value={simple.moderate}
        tooltip="Allows editing and deleting comments created by other users."
        setValue={(v) => runInAction(() => (permissions.simple!.moderate = v))}
        colorChecked={colors.checked}
        colorUnchecked={colors.unchecked}
        colorText={colors.text}
      /> */}
      <Divider color={colors.divider} />
      <ToggleListItem
        title="Share"
        tooltip="Allows sharing the file with other user and creating new share links with the same or lower permissions."
        value={simple.share}
        setValue={(v) => runInAction(() => (permissions.simple!.share = v))}
        colorChecked={colors.checked}
        colorUnchecked={colors.unchecked}
        colorText={colors.text}
      />
    </>
  );
});

export default SimplePermissionsEditor;
