import { TextInput, TextInputProps } from "react-native";
import { useColors } from "../../Colors";
import styles from "../../Styles";

export default function AuthInput(props: TextInputProps) {
  const colors = useColors().loginInput;
  return (
    <TextInput
      spellCheck={false}
      {...props}
      style={[
        styles.loginInput,
        {
          borderStyle: "solid",
          borderWidth: 1,
          color: colors.text,
          backgroundColor: colors.background,
          borderColor: colors.border,
        },
        props.style,
      ]}
      placeholderTextColor={colors.placeholder}
    />
  );
}
