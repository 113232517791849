import { observable, runInAction } from "mobx";
import { store } from "../../Store";
import { VFileDirectory } from "../../file/VFile";
import { Dir, Fil, UploadElement } from "../types/UploadElement";
import { uploadFileTree } from "../uploader/uploadFileTree";
import { checkFilePath } from "../util/buildDirMap";

export async function uploadFileList(fileList: FileList, parentDir: VFileDirectory | null) {
  const _store = await store;
  let u = _store.uploads.get(parentDir?.id ?? null);
  if (!u && fileList.length > 0) {
    u = observable.array();
    _store.uploads.set(parentDir?.id ?? null, u);
  }

  const files = new Map<string, Fil>();
  for (let i = 0; i < fileList.length; i++) {
    const file = fileList.item(i)!;
    if (files.has(file.name)) throw new Error(`You cannot upload two files with the same name at once: ${file.name}`);
    const fil = new Fil(file);
    u?.push(fil);
    files.set(file.name, fil);
  }
  return uploadFileTree(files, parentDir);
}

export async function uploadDataTransferItemList(items: DataTransferItemList, parentDir: VFileDirectory | null) {
  const _store = await store;
  let u = _store.uploads.get(parentDir?.id ?? null);
  if (!u && items.length > 0) {
    u = observable.array();
    _store.uploads.set(parentDir?.id ?? null, u);
  }

  for (let i = 0; i < items.length; i++) {
    const entry = items[i].webkitGetAsEntry();
    if (!entry) continue;
    fsEntryToFileTree(entry)?.then((ue) => {
      const u = _store.uploads.get(parentDir?.id ?? null);
      console.log("uploadDataTransferItemList entry", ue);

      if (!ue) return;

      runInAction(() => {
        if (u) u.push(ue[1]);
        else _store.uploads.set(parentDir?.id ?? null, observable.array([ue[1]]));
        uploadFileTree(new Map([ue]), parentDir);
      });
    });
  }
}

async function fsEntryToFileTree(entry: FileSystemEntry): Promise<[string, UploadElement] | undefined> {
  if (entry.isDirectory) {
    const children = await Promise.all(
      (await fsEntryReadEntries(entry as FileSystemDirectoryEntry)).map(fsEntryToFileTree)
    );
    if (!children) return;
    const dir = new Dir({ name: entry.name });
    for (const child of children) {
      if (child) dir.files.set(...child);
    }
    return [entry.name, dir];
  } else if (entry.isFile && checkFilePath([entry.name])) {
    return [entry.name, new Fil(await fsEntryGetFile(entry as FileSystemFileEntry))];
  }
}

const fsEntryGetFile = (entry: FileSystemFileEntry) =>
  new Promise<File>((resolve, reject) => entry.file(resolve, reject));
const fsEntryReadEntries = (entry: FileSystemDirectoryEntry) =>
  new Promise<FileSystemEntry[]>((resolve, reject) => entry.createReader().readEntries(resolve, reject));
