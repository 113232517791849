import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { Text, View } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import { VFile } from "../../../model/file/VFile";
import { LinkShare, UserShare } from "../../../model/share/Share";
import { WipUserShare } from "../../../model/share/WipUserShare";
import { updateShare } from "../../../model/share/updateShare";
import { useColors } from "../../Colors";
import styles from "../../Styles";
import Icon from "../../components/icon/Icon";
import UserListItem from "../../components/user/UserListItem";
import ModalView from "../ModalView";
import LinkListItem from "./LinkListItem";
import PermissionsModal from "./PermissionsModal";

const ShareListItem = observer((props: { share: WipUserShare | UserShare | LinkShare; file: VFile }) => {
  const { share, file } = props;
  const colors = useColors().modal;
  const [permissionsVisible, setPermissionsVisible] = useState(false);
  const [deleteVisible, setDeleteVisible] = useState(false);
  useEffect(() => {
    if (share instanceof WipUserShare) setPermissionsVisible(true);
  }, [share]);

  return (
    <View style={styles.iconListItem}>
      {share instanceof LinkShare ? <LinkListItem share={share} file={file} /> : <UserListItem user={share.to} />}
      <View style={{ flexGrow: 1 }} />
      <TouchableOpacity onPress={() => setPermissionsVisible(!permissionsVisible)}>
        <Icon color={colors.share.icon} name="permissions-edit" size={20} />
      </TouchableOpacity>
      <TouchableOpacity onPress={() => setDeleteVisible(!permissionsVisible)}>
        <Icon color={colors.share.icon} name="delete" size={20} />
      </TouchableOpacity>
      <PermissionsModal
        visible={permissionsVisible}
        setVisible={setPermissionsVisible}
        permissions={share.permissions}
        file={file}
        onSubmit={() => {
          if (share instanceof WipUserShare) share.create();
          else updateShare({ share, file, permissions: share.permissions });
        }}
        onCancel={() => {
          if (share instanceof WipUserShare) share.store.newUserShares.remove(share);
        }}
      />
      <ModalView
        title="Delete Share"
        visible={deleteVisible}
        setVisible={setDeleteVisible}
        submitText="Delete"
        onSubmit={() => {
          if (share instanceof WipUserShare) share.store.newUserShares.remove(share);
          else file.deleteShare(share.id);
        }}
      >
        <Text style={{ color: colors.text }}>Are you sure you want to permanently delete this share?</Text>
        <View style={styles.iconListItem}>
          {share instanceof LinkShare ? <LinkListItem share={share} file={file} /> : <UserListItem user={share.to} />}
        </View>
      </ModalView>
    </View>
  );
});

export default ShareListItem;
