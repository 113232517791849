import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import { Platform, View } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useFiles } from "../../../model/file/useFiles";
import { useWindowDimensions } from "../../../model/util/useWindowDimensions.web";
import DirectoryVersion from "../../../model/version/DirectoryVersion";
import DraxGrid from "../../components/drax/DraxGrid";
import ErrorScreen from "../ErrorScreen";
import LoadScreen from "../LoadScreen";
import FileCard from "./FileCard";

const FilesGrid = observer((props: { parentDir: DirectoryVersion | null | undefined }) => {
  const { parentDir: parentDir } = props;
  const insets = useSafeAreaInsets();
  const cardWidth = useCardMinWidth();

  const { data: files, error: filesError, fetching: filesFetching } = useFiles({ parentDir });
  if (filesError) console.warn(`Error while getting files for dir ${parentDir?.file.id}: ${filesError}`);

  return files && files.length > 0 ? (
    <View style={{ flex: 1 }}>
      <DraxGrid
        style={{
          height: Platform.OS === "web" ? 0 : undefined,
          marginLeft: insets.left + 5,
          marginRight: insets.right + 5,
          paddingTop: 10,
          paddingBottom: insets.bottom + 5,
        }}
        spacing={5}
        data={[...files, ...(filesFetching || filesError !== undefined ? [undefined] : [])]}
        keyExtractor={(item, index) => `${item?.id ?? "error"}`}
        itemDimension={cardWidth}
        renderItem={({ item }) =>
          item !== undefined ? (
            <FileCard file={item} />
          ) : (
            <>
              {filesFetching && <LoadScreen msg="Loading files" />}
              {filesError && <ErrorScreen msg="Failed to load files" />}
            </>
          )
        }
      />
    </View>
  ) : (
    <>
      {filesFetching && <LoadScreen msg="Loading files" />}
      {filesError && <ErrorScreen msg="Failed to load files" />}
    </>
  );
});

export default FilesGrid;

export function useCardMinWidth() {
  const dimensions = useWindowDimensions();
  const insets = useSafeAreaInsets();
  return useMemo(() => {
    const windowStartWidth = 300;
    const windowEndWidth = 1600;
    const cardStartWidth = 150;
    const cardEndWidth = 300;
    return Math.min(
      cardEndWidth,
      Math.max(
        cardStartWidth,
        cardStartWidth +
          ((cardEndWidth - cardStartWidth) / (windowEndWidth - windowStartWidth)) *
            (dimensions.width - insets.left - insets.right - windowStartWidth)
      )
    );
  }, [dimensions.width, insets.left, insets.right]);
}
