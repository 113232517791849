import * as Api from "../Api";
import { VFile } from "../file/VFile";
import BinaryVersion from "./BinaryVersion";
import DirectoryVersion, { DirectoryVersionJson } from "./DirectoryVersion";
import { FileVersionJson } from "./FileVersion";
import ImageVersion from "./ImageVersion";
import Version, { versionGQLFields, VersionJson } from "./Version";
import VideoVersion from "./VideoVersion";

export async function fetchVersion<V extends Version>(params: { id: string; file: VFile }) {
  const { id, file } = params;
  // prettier-ignore
  const r: VersionJson = (await Api.gql(`{
    version(id: "${id}") {
      ${versionGQLFields}
    }
  }`)).version;
  switch (file.type) {
    case "BINARY":
      return (await BinaryVersion.fromJson(r as FileVersionJson, { file })) as unknown as V;
    case "DIRECTORY":
      return (await DirectoryVersion.fromJson(r as DirectoryVersionJson, { file })) as unknown as V;
    case "IMAGE":
      return (await ImageVersion.fromJson(r as FileVersionJson, { file })) as unknown as V;
    case "VIDEO":
      return (await VideoVersion.fromJson(r as FileVersionJson, { file })) as unknown as V;
  }
}
