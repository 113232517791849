import { runInAction } from "mobx";
import { store } from "../Store";
import { VFile } from "../file/VFile";
import FileVersion, { FileVersionCiphertextJson, FileVersionJson, FileVersionParams } from "./FileVersion";
import { versionParamsFromJson } from "./Version";
import { VersionState } from "./VersionState";

export default class ImageVersion extends FileVersion {
  aspectRatio?: number;

  get ciphertext(): ImageVersionCiphertextJson {
    return {
      ...super.ciphertext,
      aspect_ratio: this.aspectRatio,
    };
  }

  constructor(params: {
    id: string;
    file: VFile;
    name: string;
    originalMimeType: string;
    aspectRatio?: number;
    size: number;
    encKey: CryptoKey;
    state: VersionState;
    createdAt: Date;
  }) {
    const { id, file, name, originalMimeType, aspectRatio, size, encKey, state, createdAt } = params;
    super({ id, file, name, originalMimeType, size, encKey, state, createdAt });
    this.aspectRatio = aspectRatio;
  }

  static async fromJson(json: FileVersionJson, params: { file: VFile }): Promise<ImageVersion> {
    const { id, ciphertext, encKey, state, createdAt } = (await versionParamsFromJson(
      json,
      params
    )) as FileVersionParams & {
      ciphertext: ImageVersionCiphertextJson;
    };
    const _store = await store;
    const v = _store.versions.get(id) as ImageVersion;
    if (v) {
      return runInAction(() => {
        console.assert(v instanceof ImageVersion);
        console.assert(v.id === id);
        console.assert(v.file === params.file);
        v.size = json.size;
        v.encKey = encKey;
        // TODO: update state
        console.assert(v.createdAt.valueOf() === createdAt.valueOf());
        v.name = ciphertext.name;
        v.aspectRatio = ciphertext.aspect_ratio;
        v.originalMimeType = ciphertext.original_mime_type;
        return v;
      });
    }
    return runInAction(() => {
      const v = new ImageVersion({
        id,
        file: params.file,
        size: json.size,
        encKey,
        state,
        createdAt,
        name: ciphertext.name,
        aspectRatio: ciphertext.aspect_ratio,
        originalMimeType: ciphertext.original_mime_type,
      });
      _store.versions.set(v.id, v);
      return v;
    });
  }
}

type ImageVersionCiphertextJson = FileVersionCiphertextJson & {
  aspect_ratio?: number;
};
