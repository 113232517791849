import { runInAction } from "mobx";
import { useContext } from "react";
import { TouchableOpacity, View } from "react-native";
import { VideoStoreContext } from "../../../../model/ui/VideoStore";
import VideoVersion from "../../../../model/version/VideoVersion";
import { useUserColor } from "../../../Colors";
import UserCircle from "../../../components/user/UserCircle";
import { CommentTimelineElementData, padding } from "./CommentsTimeline";

export default function CommentsTimelineItem(props: {
  cd: CommentTimelineElementData;
  version: VideoVersion;
  timelineWidth: number;
}) {
  const { cd, version, timelineWidth } = props;
  const _vsStore = useContext(VideoStoreContext);
  const userColor = cd.comment.latestVersion?.author ? useUserColor(cd.comment.latestVersion?.author) : undefined;
  return (
    <TouchableOpacity
      key={cd.comment.id}
      style={{
        flexDirection: "row",
        alignItems: "center",
      }}
      onPress={async () => {
        const c = cd.comment.latestVersion!;
        if (
          _vsStore.positionMillis === undefined ||
          _vsStore.positionMillis < c.startTimecode! ||
          _vsStore.positionMillis > (c.endTimecode ?? c.startTimecode!)
        )
          _vsStore.setPosition(cd.comment.latestVersion!.startTimecode!);
        // TODO: does not work correcty with replys
        version.commentsListRef.current?.scrollToLocation({
          sectionIndex: 0,
          itemIndex: version.commentsListRef.current.props.sections[0].data.findIndex((v) => v === cd.comment),
          viewPosition: 0.5,
        });
        runInAction(() => {
          for (const c of version.comments.values()) {
            c.isHighlighted = false;
          }
          cd.comment.isHighlighted = true;
        });
      }}
    >
      {cd.endCircle !== cd.end && (
        <View
          style={{
            position: "absolute",
            left: cd.endCircle * timelineWidth - 8 + padding,
            height: 4,
            width: (cd.end - cd.endCircle) * timelineWidth + 8,
            backgroundColor: userColor,
            borderRadius: 2,
          }}
        />
      )}
      <View
        style={{
          position: "absolute",
          left: cd.startCircle * timelineWidth + 2 + padding,
        }}
      >
        <UserCircle size={14} user={cd.comment.oldestVersion!.author} />
      </View>
    </TouchableOpacity>
  );
}
