import { PropsWithChildren } from "react";
import { Platform, View } from "react-native";
import { useColors } from "../../Colors";
import styles from "../../Styles";

export default function ButtonBox(props: PropsWithChildren<{}>) {
  const colors = useColors().commentContextToggle;
  return (
    <View
      style={[
        styles.row,
        { borderRadius: 8, backgroundColor: colors.background, padding: 4 },
        // @ts-ignore
        Platform.OS === "web" ? { cursor: "pointer" } : {},
      ]}
    >
      {props.children}
    </View>
  );
}
