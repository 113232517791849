import { observer } from "mobx-react-lite";
import { useCallback, useContext, useRef } from "react";
import { GestureResponderEvent, StyleSheet, TouchableOpacity, View } from "react-native";
import { VideoStoreContext } from "../../../../model/ui/VideoStore";
import { useColors } from "../../../Colors";
import styles from "../../../Styles";
import Icon from "../../../components/icon/Icon";
import "./VolumeControlWeb.css";

const VolumeControlWeb = observer(() => {
  const colors = useColors().video.controls;
  const _vsStore = useContext(VideoStoreContext);
  const value = _vsStore.displayVolume;
  const sliderRef = useRef<View>(null);
  const onEvent = useCallback(
    (e: GestureResponderEvent) => {
      sliderRef.current?.measure((_x, _y, width, height) => {
        _vsStore?.setVolume(Math.min(1, Math.max(0, e.nativeEvent.locationX / width)));
      });
    },
    [sliderRef, _vsStore]
  );

  return (
    <View nativeID="volume-control" style={{ flexDirection: "row" }}>
      <TouchableOpacity onPress={_vsStore.toggleMute}>
        <Icon
          name={`volume-${_vsStore.muted || value === 0 ? "mute" : value > 0.5 ? "2" : "1"}`}
          size={30}
          color={colors.text}
          style={{ paddingRight: 4 }}
        />
      </TouchableOpacity>
      <View
        nativeID="volume-slider"
        ref={sliderRef}
        onStartShouldSetResponder={() => true}
        onResponderStart={onEvent}
        onResponderMove={onEvent}
        onResponderEnd={onEvent}
      >
        <View style={[styles.absoluteFill, localStyles.container]}>
          <View style={{ height: 4, backgroundColor: colors.volumeBackground, width: `100%` }} />
        </View>
        <View style={[styles.absoluteFill, localStyles.container]}>
          <View style={{ height: 4, backgroundColor: colors.text, width: `${value * 100}%` }} />
        </View>
        <View style={[styles.absoluteFill, localStyles.container]}>
          <View nativeID="volume-slider-spacer" />
          <View style={{ flexGrow: 1 }}>
            <View
              nativeID="volume-slider-knob"
              // @ts-ignore
              style={{
                backgroundColor: colors.text,
                left: `${value * 100}%`,
                transform: [{ translateX: "-100%" }],
              }}
            />
          </View>
        </View>
      </View>
    </View>
  );
});

export default VolumeControlWeb;

const localStyles = StyleSheet.create({
  container: {
    overflow: "hidden",
    alignItems: "center",
    flexDirection: "row",
  },
});
