import Ionicons from "@expo/vector-icons/Ionicons";
import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons";
import MaterialIcons from "@expo/vector-icons/MaterialIcons";
import React, { Component } from "react";
import { IconName, IconProps } from "./icons";

export default class Icon extends Component<IconProps, { data: IconData }> {
  constructor(props: IconProps) {
    super(props);
    this.state = { data: iconData(props.name) };
  }

  static getDerivedStateFromProps(props: IconProps) {
    return { data: iconData(props.name) };
  }

  render(): JSX.Element {
    const { size, color, style } = this.props;
    const { data } = this.state;
    switch (data.type) {
      case "material":
        return <MaterialIcons name={data.name} size={size} color={color} style={style} />;
      case "material-community":
        return <MaterialCommunityIcons name={data.name} size={size} color={color} style={style} />;
      case "ionicons":
        return <Ionicons name={data.name} size={size} color={color} style={style} />;
    }
  }
}

export type IconData =
  | { type: "material"; name: any }
  | { type: "material-community"; name: any }
  | { type: "ionicons"; name: any };

export function iconData(name: IconName): IconData {
  switch (name) {
    case "add":
      return { type: "material", name: "add" };
    case "add-link":
      return { type: "material-community", name: "link-variant-plus" };
    case "back":
      return { type: "material", name: "arrow-back" };
    case "check":
      return { type: "material", name: "check" };
    case "chevron-right":
      return { type: "material", name: "chevron-right" };
    case "close":
      return { type: "material", name: "close" };
    case "close-circle":
    //return { type: "material", name: "close" };
    case "collapse":
      return { type: "material", name: "expand-more" };
    case "comment":
      return { type: "material", name: "comment" };
    case "copy":
      return { type: "material", name: "content-copy" };
    case "create-new-folder":
      return { type: "material-community", name: "folder-plus-outline" };
    case "delete":
      return { type: "material", name: "delete" };
    case "download":
      return { type: "material", name: "file-download" };
    case "download-file":
      return { type: "material-community", name: "file-download-outline" };
    case "download-directory":
      return { type: "material-community", name: "folder-download-outline" };
    case "edit":
      return { type: "material", name: "edit" };
    case "error":
      return { type: "material", name: "error" };
    case "expand":
      return { type: "material", name: "open-in-full" };
    case "file":
      return { type: "material-community", name: "file-outline" };
    case "folder":
      return { type: "material", name: "folder-open" };
    case "fullscreen":
      return { type: "material", name: "fullscreen" };
    case "home":
      return { type: "material", name: "home" };
    case "image":
      return { type: "material", name: "image" };
    case "link":
      return { type: "material-community", name: "link-variant" };
    case "manage-versions":
      return { type: "ionicons", name: "git-compare-outline" };
    case "pause":
      return { type: "material", name: "pause" };
    case "person":
      return { type: "material", name: "person" };
    case "person-add":
      return { type: "material", name: "person-add" };
    case "permissions-edit":
      return { type: "material", name: "more-horiz" };
    case "play":
      return { type: "material", name: "play-arrow" };
    case "reorder":
      return { type: "material", name: "reorder" };
    case "reply":
      return { type: "material", name: "reply" };
    case "save":
      return { type: "material", name: "save" };
    case "select":
      return { type: "material", name: "check-circle-outline" };
    case "send":
      return { type: "material", name: "send" };
    case "settings":
      return { type: "material", name: "settings" };
    case "share":
      return { type: "material-community", name: "share" };
    case "sort-asc":
      return { type: "material", name: "expand-less" };
    case "sort-desc":
      return { type: "material", name: "expand-more" };
    case "timer":
      return { type: "material", name: "timer" };
    case "upload-manager":
      return { type: "material", name: "file-upload" };
    case "volume-mute":
      return { type: "material", name: "volume-mute" };
    case "volume-1":
      return { type: "material", name: "volume-down" };
    case "volume-2":
      return { type: "material", name: "volume-up" };
  }
}
