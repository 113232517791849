import Version from "../../../model/version/Version";
import InputModal from "../InputModal";

export default function RenameModal(props: { version: Version; visible: boolean; setVisible: (b: boolean) => void }) {
  const { version, visible, setVisible } = props;
  return (
    <InputModal
      title="Rename"
      initialValue={version.name}
      onSubmit={(name) => version.rename(name)}
      visible={visible}
      setVisible={setVisible}
    />
  );
}
