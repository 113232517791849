import { observer } from "mobx-react-lite";
import React, { useMemo, useRef } from "react";
import { DimensionValue, GestureResponderEvent, PanResponder, StyleProp, View, ViewStyle } from "react-native";
import styles from "../../../Styles";

const VideoSlider = observer(
  (props: {
    value: number;
    buffered: number;
    maxValue: number;
    style: StyleProp<ViewStyle>;
    barsStyle?: StyleProp<ViewStyle>;
    playedStyle?: StyleProp<ViewStyle>;
    bufferedStyle?: StyleProp<ViewStyle>;
    backgroundStyle?: StyleProp<ViewStyle>;
    setValue: (n: number) => void;
  }) => {
    const { value, buffered, maxValue, style, barsStyle, playedStyle, bufferedStyle, backgroundStyle, setValue } =
      props;
    const width = useRef(0);

    const panResponder = useMemo(() => {
      const calc = (evt: GestureResponderEvent) =>
        Math.max(0, Math.min(1, evt.nativeEvent.locationX / width.current)) * maxValue;
      return PanResponder.create({
        onStartShouldSetPanResponder: (_evt, _gestureState) => true,
        onPanResponderGrant: (evt, _gestureState) => {
          setValue(calc(evt));
        },
        onPanResponderMove: (evt, _gestureState) => {
          setValue(calc(evt));
        },
        onPanResponderTerminationRequest: (_evt, _gestureState) => true,
        onPanResponderRelease: (evt, _gestureState) => {
          setValue(calc(evt));
        },
      });
    }, [maxValue]);
    return (
      <View
        style={style}
        onLayout={(e) => {
          width.current = e.nativeEvent.layout.width;
        }}
        {...panResponder.panHandlers}
      >
        <View style={barsStyle}>
          <View>
            <View style={[styles.slider, { width: "100%" }, backgroundStyle]} />
            <View
              style={[
                styles.slider,
                { width: (String((buffered / maxValue) * 100) + "%") as DimensionValue },
                bufferedStyle,
              ]}
            />
            <View
              style={[
                styles.slider,
                { width: (String((value / maxValue) * 100) + "%") as DimensionValue },
                playedStyle,
              ]}
            />
          </View>
        </View>
      </View>
    );
  }
);

export default VideoSlider;
