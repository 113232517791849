import { runInAction } from "mobx";
import { store } from "../../Store";
import { VFile, VFileDirectory } from "../../file/VFile";
import DirectoryVersion from "../../version/DirectoryVersion";
import { createVersions } from "./createVersion";

export async function createDirectory(name: string, parentDir: VFileDirectory | null) {
  const r = await createVersions({
    data: [{ ciphertext: { name }, type: "DIRECTORY" }],
    parentDir,
  });
  for (const f of r) {
    const file = new VFile({
      id: f.id,
      key: f.fileKey,
      shares: f.shares,
      type: f.type,
      parentDir,
      versionIds: [f.latestVersion.id],
      createdAt: f.createdAt,
    });
    const version = new DirectoryVersion({
      id: f.latestVersion.id,
      name,
      file,
      encKey: f.key,
      fileTypes: new Set(),
      numberOfChildren: 0,
      createdAt: new Date(f.latestVersion.createdAt),
    });
    const _store = await store;
    runInAction(() => {
      _store.files.set(file.id, file);
      _store.versions.set(version.id, version);
    });
  }
}
