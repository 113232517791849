import { runInAction } from "mobx";
import * as Api from "../Api";
import { ShareFromJson, shareGQLFields, ShareJson } from "../share/Share";
import { store } from "../Store";
import { aesDecrypt, base64Decode, importRawKey, rsaDecrypt } from "../util/CryptoHelper";
import DirectoryVersion from "../version/DirectoryVersion";
import { fileGQLFieldsWithLatestVersion, VFile, VFileJsonWithLatestVersion } from "./VFile";

export async function fetchFiles(params: { parentDir?: DirectoryVersion | null; shareKey?: ArrayBuffer }) {
  const { parentDir, shareKey } = params;
  let files: Promise<VFile>[];
  const _store = await store;
  if (parentDir) {
    // prettier-ignore
    const r: { children: VFileJsonWithLatestVersion[] } = (await Api.gql(`{
      version(id: "${parentDir.id}") {
        children {
          ${fileGQLFieldsWithLatestVersion}
          key
          shares {
            ${shareGQLFields}
            to
          }
        }
      }
    }`)).version;

    files = r.children.map((v) => runInAction(async () => VFile.fromJson(v, { parentDir: parentDir.file })));
  } else {
    const me = _store.me;
    if (me === null) throw new Error("fetchFiles: Not signed in");
    const query = `{
      shares(filter: {to: "${me.id}"}) {
        ${shareGQLFields}
        fileKey
        shareKey
        file {
          ${fileGQLFieldsWithLatestVersion}
          shares {
            ${shareGQLFields}
            to
          }
        }
      }
    }`;
    const r: (ShareJson & {
      fileKey: string;
      file: VFileJsonWithLatestVersion;
    })[] = (await Api.gql(query, `Bearer ${me.token.t}`)).shares;

    files = r.map(async (v) =>
      VFile.fromJson(v.file, {
        key: shareKey
          ? await importRawKey(
              await aesDecrypt(await importRawKey(shareKey), base64Decode(v.fileKey), new ArrayBuffer(16))
            )
          : await importRawKey(await rsaDecrypt(me.privkey, base64Decode(v.fileKey))),
        share: await ShareFromJson({ ...v, to: me.id }),
      })
    );
  }
  const r = (await Promise.all(files)).filter((v) => v !== undefined) as VFile[];
  runInAction(() => parentDir?.childrenIds.replace(r.map((f) => f.id)));
  return r;
}
