import Comment, { CommentJson } from "../comment/Comment";
import { VFile } from "../file/VFile";
import Version, { VersionJson } from "../version/Version";

export class VNotification {
  timestamp: Date;

  constructor(props: { timestamp: Date }) {
    const { timestamp } = props;
    this.timestamp = timestamp;
  }
}

export class CommentNotification extends VNotification {
  comment: Comment;

  constructor(params: { timestamp: Date; comment: Comment }) {
    const { timestamp, comment } = params;
    super({ timestamp });
    this.comment = comment;
  }
}

export class VersionNotification extends VNotification {
  version: Version;

  constructor(params: { timestamp: Date; version: Version }) {
    const { timestamp, version } = params;
    super({ timestamp: timestamp });
    this.version = version;
  }
}

export class FileNotification extends VNotification {
  file: VFile;

  constructor(params: { timestamp: Date; file: VFile }) {
    const { timestamp, file } = params;
    super({ timestamp: timestamp });
    this.file = file;
  }
}

export type NotificationJson = FileNotificationJson | VersionNotificationJson | CommentNotificationJson;

export type NotificationJsonBase = {
  relatedId: string;
  key: string;
};

export type CommentNotificationJson = {
  type: "COMMENT";
  comment: CommentJson;
} & NotificationJsonBase;

export type FileNotificationJson = {
  type: "FILE";
  file: VersionJson;
} & NotificationJsonBase;

export type VersionNotificationJson = {
  type: "VERSION";
  version: VersionJson;
} & NotificationJsonBase;
