import { PropsWithChildren, useState } from "react";
import { View } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import { useColors } from "../../../Colors";
import MenuDropdownItem from "./MenuDropdownItem";

export type DropdownItem = { text: string };

export type MenuDropdownProps<T = DropdownItem> = PropsWithChildren<
  {
    /// Selected item
    selected: T;
    /// A function to set the dropdown value to a new value. Called when a user clicks on a dropdown item.
    setSelected: (params: { item: T; index: number }) => void;
  } & (
    | { data: DropdownItem[] }
    | {
        data: T[];
        renderItem: React.FC<{ item: T; index?: number }>;
      }
  )
>;

/**
 * A dropdown to choose from a set of options.
 * Children are rendered to the right of the dropdown.
 */
export default function MenuDropdown<T>(props: MenuDropdownProps<T>) {
  const { children, selected, setSelected } = props;
  const colors = useColors().menu.dropdown;
  const [open, setOpen] = useState(false);

  return (
    <View style={{ flexDirection: "row", alignItems: "center", zIndex: 1 }}>
      <View style={{ flexGrow: 1 }}>
        <TouchableOpacity onPress={() => setOpen(true)}>
          {"renderItem" in props ? (
            <props.renderItem item={selected as T} />
          ) : (
            <MenuDropdownItem item={selected as DropdownItem} />
          )}
        </TouchableOpacity>
        {open && (
          <View
            style={{
              zIndex: 1,
              position: "absolute",
              backgroundColor: colors.background,
              width: "100%",
              borderRadius: 6,
              padding: 2,
            }}
          >
            {props.data?.map((item, index) => (
              <TouchableOpacity
                onPress={() => {
                  setOpen(false);
                  setSelected({ item: item as any, index });
                }}
              >
                {"renderItem" in props ? (
                  <props.renderItem item={item as T} />
                ) : (
                  <MenuDropdownItem item={item as DropdownItem} />
                )}
              </TouchableOpacity>
            ))}
          </View>
        )}
      </View>
      {children}
    </View>
  );
}
