import { Platform, View } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useColors } from "../../Colors";
import BackButton from "./BackButton";
import { NavbarProps } from "./NavbarProps";

export default function Navbar(props: NavbarProps) {
  const { backTo, hideBackButton, Left, Center, Right } = props;
  const colors = useColors().navBar;
  const insets = useSafeAreaInsets();
  return (
    <View
      style={{
        backgroundColor: colors.background,
        paddingTop: insets.top,
        height: (Platform.OS === "ios" ? 45 : 64) + insets.top,
        flexDirection: "row",
        alignItems: "center",
        borderColor: colors.border,
        borderBottomWidth: 1,
        zIndex: 1,
        paddingLeft: Math.max(16, insets.left),
        paddingRight: Math.max(16, insets.right),
        ...(Platform.OS === "ios"
          ? {}
          : {
              shadowColor: colors.border,
              shadowRadius: 5,
              shadowOpacity: 0.5,
            }),
      }}
    >
      {!hideBackButton && <BackButton to={backTo} />}
      {Left && (
        <View style={{ flexDirection: "row", alignItems: "center", flexGrow: 1, flexShrink: 1 }}>
          <Left />
        </View>
      )}
      {Center && <Center />}
      {Right && <Right />}
    </View>
  );
}
