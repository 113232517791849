// Array.at

function at(n) {
  // ToInteger() abstract op
  n = Math.trunc(n) || 0;
  // Allow negative indexing from the end
  if (n < 0) n += this.length;
  // OOB access is guaranteed to return undefined
  if (n < 0 || n >= this.length) return undefined;
  // Otherwise, this is just normal property access
  return this[n];
}

const TypedArray = Reflect.getPrototypeOf(Int8Array);
for (const C of [Array, String, TypedArray]) {
  if (C.prototype.at === undefined)
    Object.defineProperty(C.prototype, "at", {
      value: at,
      writable: true,
      enumerable: false,
      configurable: true,
    });
}

// Array.findLast

if (Array.findLast === undefined) {
  function findLast() {
    this.reverse().find(...arguments);
  }
  Object.defineProperty(Array, "findLast", {
    value: findLast,
    writable: true,
    enumerable: false,
    configurable: true,
  });
}

// Promise.allSettled

function allSettled(promises) {
  return Promise.all(
    promises.map((p) =>
      p.then((v) => ({ status: "fulfilled", value: v })).catch((e) => ({ status: "rejected", reason: e }))
    )
  );
}

if (Promise.allSettled === undefined) {
  Object.defineProperty(Promise, "allSettled", {
    value: allSettled,
    writable: true,
    enumerable: false,
    configurable: true,
  });
}

// Buffer

global.Buffer = global.Buffer || require("buffer").Buffer;
