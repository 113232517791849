import { observable } from "mobx";
import { IObservableArray } from "mobx/dist/internal";
import config from "../../config";
import * as Api from "../Api";
import { store } from "../Store";
import { CreateTokenError } from "../generated/CreateTokenError";
import { VNotification } from "../notification/VNotification";
import { Permissions } from "../share/Permissions";
import TranscodingServer, {
  TranscodingServerJson,
  getTranscodingServer,
} from "../transcoding-server/TranscodingServer";
import { base64Encode, exportRawKey, keyhash } from "../util/CryptoHelper";
import { Token, TokenJson } from "./Token";
import { User, UserJson, userGQLFields } from "./User";
import { MeUserSharedPrefsJson } from "./loginFromSharedPreferences";

export class MeUser extends User {
  // passwordBasedMasterKey: CryptoKey;
  // passwordBasedTranscodingServersKey: CryptoKey;
  keychain: Keychain;
  privkey: CryptoKey;
  token: Token;

  transcodingServers: IObservableArray<TranscodingServer>;

  get preferredTranscodingServer() {
    if (!this.transcodingServers.at(0))
      this.transcodingServers.push(getTranscodingServer(config.defaultTranscodingServer));
    return this.transcodingServers[0];
  }

  notifications = observable.array<VNotification>();

  private _alwaysImportShares: boolean | null;
  get alwaysImportShares() {
    return this._alwaysImportShares;
  }
  set alwaysImportShares(value: boolean | null) {
    if (this._alwaysImportShares !== value) {
      this._alwaysImportShares = value;
      store.then((_store) => _store.saveMeUsers());
    }
  }

  constructor(params: {
    id: string;
    username: string;
    displayName: string;
    keychain: Keychain;
    pubkey: CryptoKey;
    privkey: CryptoKey;
    token: Token;
    transcodingServers: TranscodingServer[];
    alwaysImportShares: boolean | null;
  }) {
    const { id, username, displayName, keychain, pubkey, privkey, token, transcodingServers, alwaysImportShares } =
      params;
    super({ id, username, displayName, pubkey });
    this.keychain = keychain;
    this.privkey = privkey;
    this.token = token;
    this.transcodingServers = observable.array(transcodingServers);
    this._alwaysImportShares = alwaysImportShares;
  }

  async toJson(): Promise<MeUserSharedPrefsJson> {
    const r: MeUserSharedPrefsJson = {
      id: this.id,
      key: base64Encode(await exportRawKey(this.keychain.key)),
      token: this.token.toJson(),
    };
    if (this.alwaysImportShares !== null) r.alwaysImportShares = this.alwaysImportShares;
    return r;
  }

  createToken = async (scope: any = "all") =>
    (
      await Api.req<TokenJson, CreateTokenError>({
        endpoint: "/user/token",
        token: this.token.t,
        body: JSON.stringify({ scope }),
      })
    ).map(Token.fromJson);

  defaultPermissions() {
    return new Permissions(config.defaultPermissions);
  }
}

export type UserCiphertextJson = {
  privkey: string;
  // Legacy (v1)
  transcoding_server?: string;
  // Legacy (v2)
  transcoding_servers?: TranscodingServerJson[];
};

export type MeUserJson = UserJson & {
  key: string;
  ciphertext: string;
  salt: string;
  transcodingServers: string[];
};

export const meUserGQLFields = `
  ${userGQLFields}
  key
  ciphertext
  salt
  transcodingServers
`;

type Keychain = {
  /** used to decrypt the ciphertext and derive other keys */
  key: CryptoKey;
  notificationsKey: CryptoKey;
  transcodingServersKey: CryptoKey;
  /** used to encrypt share labels */
  shareLabelsKey: CryptoKey;
};

export async function getKeychain(key: CryptoKey): Promise<Keychain> {
  const raw = exportRawKey(key);
  const notificationsKey = raw.then((v) => keyhash(v, "notifications"));
  const transcodingServersKey = raw.then((v) => keyhash(v, "transcoding_servers"));
  const shareLabelsKey = raw.then((v) => keyhash(v, "share_labels"));
  return {
    key,
    notificationsKey: await notificationsKey,
    transcodingServersKey: await transcodingServersKey,
    shareLabelsKey: await shareLabelsKey,
  };
}
