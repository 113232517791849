export function interpolateColor(value: number, outputRange: [string, string]): string {
  "worklet";
  const [a, b] = outputRange.map((color) => {
    if (color.startsWith("rgb")) {
      const r = color.split("(")[1].split(")")[0].split(",") as unknown as number[];
      switch (r.length) {
        case 4:
          // @ts-ignore
          return r;
        case 3:
          r.push(255);
          // @ts-ignore
          return r;
        default:
          throw new Error("invalid rgb color");
      }
    } else if (color.startsWith("#")) {
      switch (color.length) {
        case 9:
          return [
            Number.parseInt(color.slice(1, 3), 16),
            Number.parseInt(color.slice(3, 5), 16),
            Number.parseInt(color.slice(5, 7), 16),
            Number.parseInt(color.slice(7, 9), 16),
          ];
        case 7:
          return [
            Number.parseInt(color.slice(1, 3), 16),
            Number.parseInt(color.slice(3, 5), 16),
            Number.parseInt(color.slice(5, 7), 16),
            255,
          ];
        case 5:
          return [
            Number.parseInt(color[1] + color[1], 16),
            Number.parseInt(color[2] + color[2], 16),
            Number.parseInt(color[3] + color[3], 16),
            Number.parseInt(color[4] + color[4], 16),
          ];
        case 4:
          return [
            Number.parseInt(color[1] + color[1], 16),
            Number.parseInt(color[2] + color[2], 16),
            Number.parseInt(color[3] + color[3], 16),
            255,
          ];
        default:
          throw new Error("invalid hex color");
      }
    }
    throw new Error("invalid color format");
  });
  const rgb = [
    b[0] * value + a[0] * (1 - value),
    b[1] * value + a[1] * (1 - value),
    b[2] * value + a[2] * (1 - value),
    b[3] * value + a[3] * (1 - value),
  ];
  return `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, ${rgb[3]})`;
}
