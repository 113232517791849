import { Cacheable } from "./Cacheable";

export type CacheableResultBase = {
  refetch: () => void;
};

export type CacheableResultSuccess<T extends Cacheable | Cacheable[] | null> = CacheableResultBase & {
  data: T;
  error: undefined;
  isSuccess: true;
  status: "success";
};

export type CacheableResultError = CacheableResultBase & {
  status: "error";
  isSuccess: false;
  data: undefined;
  error: unknown;
};

export type CacheableResultFetching = CacheableResultBase & {
  data: undefined;
  error: undefined;
  status: "fetching";
  isSuccess: false;
};

export type CacheableResult<T extends Cacheable | Cacheable[] | null> =
  | CacheableResultSuccess<T>
  | CacheableResultError
  | CacheableResultFetching;

export type CacheableResult2<T, E> = {
  data: T;
  error: E;
  fetching: boolean;
  refetch: () => void;
};

export function newCacheableResult<T extends Cacheable | Cacheable[] | null, E>(params: {
  data?: T;
  error?: E;
  refetch: () => Promise<void>;
}): CacheableResult<T> {
  const { data, error, refetch } = params;
  if (data !== undefined && !(data instanceof Array && data.length < 1)) {
    return {
      status: "success",
      data,
      error: undefined,
      refetch,
      isSuccess: true,
    };
  }
  if (error !== undefined) {
    return {
      status: "error",
      data: undefined,
      error,
      refetch,
      isSuccess: false,
    };
  }
  return {
    status: "fetching",
    data: undefined,
    error: undefined,
    refetch,
    isSuccess: false,
  };
}
