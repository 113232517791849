import { Platform } from "react-native";
import Svg, { Circle } from "react-native-svg";

const duration = 4000;

export default function StatusRings(props: { rings: { color: string; value: number }[] }) {
  return (
    <Svg viewBox="0 0 100 100">
      {Platform.OS === "web" && (
        <style>{`
          .status-rings {
            transform-origin: center;
            animation-name: status-rings-rotate;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
            transition-duration: 1s;
            transition-property: stroke-dashoffset, stroke-dasharray;
          }
          @keyframes status-rings-rotate {
            form {
              transform: rotate(0deg);
            }
            to {
              transform: rotate(360deg);
            }
          }
        `}</style>
      )}
      {props.rings.reverse().map(({ color, value }, i) => {
        const r = 30 - i * 4;
        return (
          <>
            <Circle
              // @ts-ignore
              class={`status-rings status-rings-${i}`}
              key={i}
              cx={50}
              cy={50}
              r={r}
              fill="none"
              strokeWidth={2}
              stroke={color}
              strokeDasharray={[value * 2 * Math.PI * r, (1 - value) * 2 * Math.PI * r]}
              strokeDashoffset={(Platform.OS === "web" ? value : 0.5) * Math.PI * r}
            />
            {Platform.OS === "web" && (
              <style>{`
                .status-rings-${i} {
                  animation-duration: ${(duration / 30) * r}ms;
                }
              `}</style>
            )}
          </>
        );
      })}
    </Svg>
  );
}
