import { makeAutoObservable, runInAction } from "mobx";
import { createContext } from "react";
import { ContextMenuAction } from "./ContextMenuAction";

export default class ContextMenuStore {
  position: { x: number; y: number } | null = null;
  actions: ContextMenuAction[] = [];

  get visible() {
    return this.position && this.actions.length > 0;
  }

  constructor() {
    makeAutoObservable(this);
  }

  reset() {
    runInAction(() => {
      this.position = null;
      this.actions = [];
    });
  }
}

export const ContextMenuStoreContext = createContext(new ContextMenuStore());
