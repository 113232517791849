import { Text, View } from "react-native";
import { User } from "../../../model/user/User";
import { useColors } from "../../Colors";
import styles from "../../Styles";
import UserCircle from "../../components/user/UserCircle";

const UserModal = (props: { user: User }) => {
  const colors = useColors().modal;
  return (
    <View style={{ minWidth: 220 }}>
      <View style={{ flexDirection: "row", alignItems: "center", paddingBottom: 16 }}>
        <UserCircle size={40} user={props.user} />
        <View style={{ paddingLeft: 8 }}>
          <Text style={[styles.textTitle, { color: colors.text }]}>{props.user.displayName}</Text>
          <Text style={{ color: colors.text }}>@{props.user.username}</Text>
        </View>
      </View>
    </View>
  );
};

export default UserModal;
