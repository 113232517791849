import { StyleProp, ViewStyle } from "react-native";
import Animated, { SharedValue, interpolate, useAnimatedStyle } from "react-native-reanimated";
import Icon from "../icon/Icon";

export default function CollapseIcon(props: {
  collapsed: SharedValue<number>;
  color: string;
  style?: StyleProp<ViewStyle>;
}) {
  const { collapsed, color, style } = props;
  const animatedStyle = useAnimatedStyle(() => ({
    transform: [{ rotate: `${interpolate(collapsed.value, [0, 1], [0, 90])}deg` }],
  }));
  return (
    <Animated.View style={[animatedStyle, style]}>
      <Icon name="collapse" size={16} color={color} />
    </Animated.View>
  );
}
