import { WipComment } from "../../../model/comment/Comment";
import Icon from "../icon/Icon";
import ButtonBox from "./ButtonBox";
import NewCommentTimecode from "./NewCommentTimecode";

export default function NewCommentTimecodeButton(props: { wipComment: WipComment | null | undefined; color: string }) {
  const { wipComment, color } = props;

  return (
    <ButtonBox>
      <Icon name="timer" color={color} size={14} />
      <NewCommentTimecode wipComment={wipComment} color={color} style={{ paddingHorizontal: 2 }} />
    </ButtonBox>
  );
}
