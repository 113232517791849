import AsyncStorage from "@react-native-async-storage/async-storage";
import * as FileSystem from "expo-file-system";
import { IObservableArray, makeAutoObservable, observable, ObservableMap, reaction } from "mobx";
import { createContext } from "react";
import { Platform } from "react-native";
import { QueryClient } from "react-query";
import config from "../config";
import Comment from "./comment/Comment";
import { Commentable } from "./comment/Commentable";
import { CommentSortStrategy, parseCommentSortStrategy } from "./comment/sortComments";
import { VFile } from "./file/VFile";
import { SocketServer } from "./SocketServer";
import TranscodingServer from "./transcoding-server/TranscodingServer";
import { UploadElement } from "./upload/types/UploadElement";
import { TimecodeFormat } from "./upload/util/formatTimecode";
import { loginFromSharedPreferences } from "./user/loginFromSharedPreferences";
import { MeUser } from "./user/MeUser";
import { User } from "./user/User";
import { FileSortStrategy, parseFileSortStrategy } from "./util/useSortedFiles";
import Version from "./version/Version";

export class Store {
  users = new ObservableMap<string, User>();
  meUsers = new ObservableMap<string, MeUser>();
  private _me: MeUser | null = null;
  transcodingServers = new Map<string, TranscodingServer>();

  files = new ObservableMap<string, VFile>();
  versions = new ObservableMap<string, Version>();

  uploads = observable.map<string | null, IObservableArray<UploadElement>>();

  get me() {
    return this._me;
  }
  set me(v: MeUser | null) {
    this._me = v;
    if (v === null) {
      AsyncStorage.removeItem("meUser");
    } else {
      AsyncStorage.setItem("meUser", v.id);
    }
    this.subscriberId = crypto.randomUUID();
    this.files.clear();
    this.versions.clear();
    this.transcodingServers.clear();
    queryClient.clear();
    this.initSocket();
  }

  private _fileSortStrategy: FileSortStrategy = "name_asc";
  get fileSortStrategy() {
    return this._fileSortStrategy;
  }
  set fileSortStrategy(value: FileSortStrategy) {
    if (this._fileSortStrategy !== value) {
      this._fileSortStrategy = value;
      AsyncStorage.setItem("fileSortStrategy", this._fileSortStrategy);
    }
  }

  private _commentSortStrategy: CommentSortStrategy = "timecode";
  get commentSortStrategy() {
    return this._commentSortStrategy;
  }
  set commentSortStrategy(value: CommentSortStrategy) {
    if (this._commentSortStrategy !== value) {
      this._commentSortStrategy = value;
      AsyncStorage.setItem("commentSortStrategy", this._commentSortStrategy);
    }
  }

  newCommentIncludesTimecode = true;
  timecodeFormat: TimecodeFormat = "short";

  private _commentContext: Version | null = null;
  get commentContext() {
    return this._commentContext;
  }
  set commentContext(v: Version | null) {
    if (v && this._commentContext?.wipComment && !v.wipComment) {
      v.wipComment = this._commentContext?.wipComment;
      this._commentContext.wipComment = null;
    }
    this._commentContext = v;
  }
  commentLinkTimecode = true;
  commentReplyTo: Comment | null = null;
  commentReplyLinkTimecode = true;

  subscribedCommentRelations = observable.map<string, { c: Commentable; n: number }>();

  subscriberId = crypto.randomUUID();
  socket: SocketServer | undefined;

  constructor() {
    makeAutoObservable(this);
  }

  async init() {
    this.clearFileCache();
    const keys = await AsyncStorage.getAllKeys();
    const tasks = [this.loadMeUsers(keys), this.loadFileSortStrategy(keys), this.loadCommentSortStrategy(keys)];
    await Promise.all(tasks);
    return this;
  }

  private async loadFileSortStrategy(keys: readonly string[]) {
    if (keys.includes("fileSortStrategy"))
      this._fileSortStrategy = parseFileSortStrategy(await AsyncStorage.getItem("fileSortStrategy"));
  }
  private async loadCommentSortStrategy(keys: readonly string[]) {
    if (keys.includes("commentSortStrategy")) {
      this._commentSortStrategy = parseCommentSortStrategy(await AsyncStorage.getItem("commentSortStrategy"));
    }
  }
  private async loadMeUsers(keys: readonly string[]) {
    // load meUsers from AsyncStorage
    try {
      if (keys.includes("meUsers")) {
        let meUsers: Promise<MeUser>[] = [];
        JSON.parse((await AsyncStorage.getItem("meUsers"))!).forEach(async (data: any) => {
          try {
            meUsers.push(loginFromSharedPreferences(data));
          } catch (e) {
            console.warn("unable to load user", data, e);
          }
        });
        for (let u of meUsers) {
          let user = await u.catch((e) => console.error("Error while loading MeUser", e));
          if (user) {
            this.users.set(user.id, user);
            this.meUsers.set(user.id, user);
          }
        }
        if (keys.includes("meUser")) {
          let s = await AsyncStorage.getItem("meUser");
          let m = this.meUsers.get(s!);
          if (m !== undefined) this.me = m;
        }
        if (this.me === null && this.meUsers.size > 0) this.me = this.meUsers.values().next().value;
      }
    } catch (e) {
      console.error("Error while loading meUsers", e);
    }
    // save changes to meUsers
    reaction(
      (_r) => this.meUsers.values(),
      async (_v, _prev, _r) => await this.saveMeUsers()
    );
  }

  async saveMeUsers() {
    const meUsers = [...this.meUsers.values()];
    console.debug("Saving changes to meUsers", meUsers);
    await AsyncStorage.setItem("meUsers", JSON.stringify(await Promise.all(meUsers.map((v) => v.toJson()))));
  }

  async initSocket() {
    this.socket?.close();
    this.socket = new (await import("./SocketServer")).SocketServer(
      `${config.socket}/v1?${this.me ? `user=${this.me.token.t}` : `session=${this.subscriberId}`}`
    );
    this.socket.start();
  }

  async reset() {
    await AsyncStorage.removeItem("meUsers");
    await AsyncStorage.removeItem("meUser");
    await this.clearFileCache();
  }

  async clearFileCache() {
    if (Platform.OS === "android" || Platform.OS === "ios") {
      await FileSystem.deleteAsync(`${FileSystem.documentDirectory}users`, { idempotent: true });
    }
    if (Platform.OS === "web") {
      // clear file cache
      await caches.delete(config.filecache);
      // remove all other caches in the background
      caches.keys().then((keys) => keys.filter((k) => k != config.filecache).forEach((key) => caches.delete(key)));
    }
  }

  async logout() {
    if (this.me) {
      if (Platform.OS === "android" || Platform.OS === "ios") {
        await FileSystem.deleteAsync(`${FileSystem.documentDirectory}users/${this.me?.id}`, { idempotent: true });
      }
      this.meUsers.delete(this.me.id);
      this.users.clear();
      this.me = this.meUsers.size > 0 ? [...this.meUsers.values()][0] : null;
      if (Platform.OS === "web") await caches.delete("images");
    }
  }
}

export const unsafeStore = new Store();
export const store = unsafeStore.init();
export const StoreContext = createContext<Store>(unsafeStore);

export const queryClient = new QueryClient();
