import { observer } from "mobx-react-lite";
import { FlatList } from "react-native";
import { VFile } from "../../../model/file/VFile";
import { useWindowDimensions } from "../../../model/util/useWindowDimensions.web";
import { useVersions } from "../../../model/version/useVersions";
import { useColors } from "../../Colors";
import { useSearchParams } from "../../navigation/routing";
import VersionSelectorMenuItem from "./VersionSelectorMenuItem";

export const VersionSelectorMenu = observer((props: { file: VFile }) => {
  const { file } = props;
  const colors = useColors().versionSelectorMenu;
  const { data: versions } = useVersions(file);
  const dimensions = useWindowDimensions();
  const data = file.versionIds.map((id, i) => ({ id, version: versions?.at(i), no: i + 1 })).reverse();
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <FlatList
      style={[
        {
          backgroundColor: colors.background,
          maxHeight: dimensions.height - 100, // TODO: use actual navbar height
          borderRadius: 4,
          borderWidth: 1,
          borderColor: colors.border,
        },
        // @ts-ignore
        { width: "min-content" },
      ]}
      data={data}
      keyExtractor={(item, index) => item.id ?? index}
      renderItem={({ item: { version, no } }) => (
        <VersionSelectorMenuItem
          version={version}
          no={no}
          onPress={() => {
            searchParams.set("v", no.toString());
            setSearchParams(searchParams);
          }}
        />
      )}
    />
  );
});
