import { runInAction } from "mobx";
import * as Api from "../Api";
import { store } from "../Store";
import { VFile } from "../file/VFile";
import { comparePermissions } from "../share/comparePermissions";
import { User } from "../user/User";
import { aesEncrypt, base64Encode, exportRawKey, generateAesKey, rsaEncrypt } from "../util/CryptoHelper";
import { graphqlify } from "../util/graphqlify";
import { Permissions } from "./Permissions";
import { LinkShare, UserShare } from "./Share";

/**
 * **Note**: The share is not automatically added to the file's shares. The caller must do this manually.
 */
export async function createUserShare(params: {
  label: string | undefined;
  to: User;
  file: VFile;
  permissions: Permissions;
}): Promise<UserShare> {
  const { label, to, file, permissions } = params;
  const me = (await store).me!;
  const key = rsaEncrypt(to.pubkey, await exportRawKey(file.encKey));
  const parents = file.path.flatMap((f) =>
    [...f.shares.values()]
      .filter(
        (s) =>
          (s instanceof LinkShare || (s instanceof UserShare && s.to.id == me.id)) &&
          (s.permissions.share_user || to == me) &&
          comparePermissions(s.permissions, permissions)
      )
      .map((s) => s.id)
  );
  // prettier-ignore
  const r: { id: string, createdAt: string } = (await Api.gql(`mutation {
    createUserShare(
      to: "${to.id}"
      file: "${file.id}"
      fileKey: "${base64Encode(await key)}"
      permissions: ${graphqlify(permissions.data)}
      parents: ${graphqlify(parents)}
      ) {
        id, createdAt
      }
    }`, `Bearer ${me.token.t}`)).createUserShare;
  const share = new UserShare({
    id: r.id,
    label,
    from: me,
    to: to,
    createdAt: new Date(r.createdAt),
    permissions,
  });
  return share;
}

export async function createLinkShare(params: { file: VFile; permissions: Permissions }): Promise<LinkShare> {
  const { file, permissions } = params;
  const me = (await store).me!;
  const shareKey = generateAesKey();
  const encryptedFileKey = aesEncrypt(await shareKey, await exportRawKey(file.encKey), new ArrayBuffer(16));
  const decryptedShareKey = await exportRawKey(await shareKey);
  const encryptedShareKey = aesEncrypt(file.encKey, decryptedShareKey, new ArrayBuffer(16));
  const parents = file.path.flatMap((f) =>
    [...f.shares.values()]
      .filter(
        (s) =>
          (s instanceof LinkShare || (s instanceof UserShare && s.to.id == me.id)) &&
          s.permissions.share_link &&
          comparePermissions(s.permissions, permissions)
      )
      .map((s) => s.id)
  );
  // prettier-ignore
  const r: { id: string, createdAt: string } = (await Api.gql(`mutation {
      createLinkShare(
        file: "${file.id}"
        fileKey: "${base64Encode(await encryptedFileKey)}"
        shareKey: "${base64Encode(await encryptedShareKey)}"
        permissions: ${graphqlify(permissions.data)}
        parents: ${graphqlify(parents)}
      ) {
        id, createdAt
      }
    }`, `Bearer ${me.token.t}`)).createLinkShare;
  const share = new LinkShare({
    id: r.id,
    from: me,
    encryptedShareKey: await encryptedShareKey,
    decryptedShareKey,
    createdAt: new Date(r.createdAt),
    permissions,
  });
  runInAction(() => {
    file.shares.set(share.id, share);
  });
  return share;
}
