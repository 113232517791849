import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { Text, View } from "react-native";
import { FlatList } from "react-native-gesture-handler";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { StoreContext } from "../../../model/Store";
import { useColors } from "../../Colors";
import Navbar from "../../navigation/navbar/Navbar";
import NotificationView from "./NotificationView";

const NotificationsScreen = observer(() => {
  const _store = useContext(StoreContext);
  const navbarColors = useColors().navBar;
  const insets = useSafeAreaInsets();
  const notifications = _store.me?.notifications ?? [];

  return (
    <View>
      <Navbar
        Center={() => <Text style={{ color: navbarColors.text, fontWeight: "500", fontSize: 16 }}>Notifications</Text>}
      />
      <FlatList data={notifications} renderItem={({ item }) => <NotificationView notification={item} />} />
    </View>
  );
});
export default NotificationsScreen;
