import { useContext } from "react";
import { StoreContext } from "../Store";
import { Dir, UploadElement } from "../upload/types/UploadElement";

export function useUploads(): UploadElementInfo[] {
  const _store = useContext(StoreContext);
  return mapUploadsRecursive([..._store.uploads.values()].flat(), 0);
}

function mapUploadsRecursive(uploads: UploadElement[], depth: number) {
  let r: UploadElementInfo[] = [];
  for (const u of uploads) {
    r.push({ e: u, depth });
    if (u instanceof Dir) {
      r = r.concat(mapUploadsRecursive([...u.files.values()], depth + 1));
    }
  }
  return r;
}

export type UploadElementInfo = { e: UploadElement; depth: number };
