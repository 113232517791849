import { PropsWithChildren } from "react";

export type MenuSectionProps = PropsWithChildren<{
  /// optional title displayed above the section's items
  title?: string;
  /// optional description displayed below the section's items
  description?: string;
}>;

const MenuSection = (props: MenuSectionProps) => <></>;

export default MenuSection;
