import { StyleProp, View, ViewStyle } from "react-native";
import { User } from "../../../model/user/User";
import { useColors } from "../../Colors";
import UserListItem from "./UserListItem";

export default function UserCard(props: { user: User; style?: StyleProp<ViewStyle> }) {
  const { user, style } = props;
  const colors = useColors().menu.dropdown.item;
  return (
    <View style={[{ borderRadius: 4, padding: 8, backgroundColor: colors.background }, style]}>
      <View style={{ flexDirection: "row", alignItems: "center" }}>{user && <UserListItem user={user} />}</View>
    </View>
  );
}
