import { StyleProp, Text, TextStyle } from "react-native";
import { User } from "../../../model/user/User";
import { useUserColor } from "../../Colors";
import styles from "../../Styles";

export default function UserNameText(props: { user: User; style?: StyleProp<TextStyle> }) {
  const { user, style } = props;
  const color = useUserColor(user);
  return <Text style={[styles.textUser, { color }, style]}>{user.displayName}</Text>;
}
