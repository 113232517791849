import { Text, View } from "react-native";
import { useColors } from "../../../Colors";
import { DropdownItem } from "./MenuDropdown";

export default function MenuDropdownItem(props: { item?: DropdownItem }) {
  const { item: data } = props;
  const colors = useColors().menu.dropdown.item;
  return (
    <View style={{ width: "100%", backgroundColor: colors.background, padding: 8 }}>
      <Text style={{ color: colors.text }}>{data?.text}</Text>
    </View>
  );
}
