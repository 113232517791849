import { Err, Ok, Result } from "ts-results";
import config from "../config";
import { base64Encode } from "./util/CryptoHelper";

export async function req<T, E>(params: {
  endpoint: string;
  body?: BodyInit;
  user?: string;
  password?: string;
  method?: string;
  token?: string;
}): Promise<Result<T, Error<E>>> {
  const { endpoint, body, user, password, method, token } = params;

  const headers: HeadersInit = [["Content-Type", "application/json"]];
  if (params.token !== null && params.token !== undefined) {
    headers.push(["Authorization", "Bearer " + params.token]);
  } else if (
    params.user !== null &&
    params.user !== undefined &&
    params.password !== null &&
    params.password !== undefined
  ) {
    headers.push(["Authorization", "Basic " + base64Encode(Buffer.from(params.user + ":" + params.password, "utf-8"))]);
  }

  const r = await fetch(`${config.api}/v1${endpoint}`, {
    method: method ?? (body === null || body === undefined ? "GET" : "POST"),
    headers,
    body,
  })
    .then((r) => Ok(r))
    .catch((e) => Err(e as FetchError));
  if (r.err) return Err({ type: "fetch", data: r.val });

  const json = await r.val
    .json()
    .then((r) => Ok(r))
    .catch((e) => Err(e as JsonError));
  if (json.err) return Err({ type: "json", data: json.val });

  if (!r.val.ok) return Err({ type: "server", data: json.val });
  return Ok(json.val);
}

export async function gql(query: string, auth?: string): Promise<any> {
  const r = await fetch(`${config.api}/graphql`, {
    body: JSON.stringify({ query }),
    method: "POST",
    headers: {
      "Content-Type": "application/graphql",
      ...(auth ? { Authorization: auth } : {}),
    },
  });
  if (!r.ok) throw new Error(`HTTP error: ${r}`);
  const j: GQLResponse = await r.json();
  if (j.errors) throw new Error(`GraphQL error: ${j.errors}`);
  if (!j.data) throw new Error(`GraphQL error: missing data`);
  return j.data;
}

export type GQLResponse = {
  data?: any;
  errors?: {
    message: string;
    locations: {
      line: string;
      column: string;
    }[];
  }[];
};

export type OldApiError = {
  status: number;
  statusText: string;
  body: {
    code: number;
    status: string;
    message: string;
  };
};

export function parseJson<T>(text: string): Result<T, JsonError> {
  try {
    return Ok(JSON.parse(text));
  } catch (e) {
    return Err(e as JsonError);
  }
}

export type JsonError = SyntaxError;

export type Error<T> =
  | { type: "server"; data: T }
  | { type: "json"; data: JsonError }
  | { type: "fetch"; data: FetchError };

export type FetchError = DOMException | TypeError;
