import { useEffect, useRef, useState } from "react";
import { Platform } from "react-native";
import { GestureEvent, PanGestureHandler, PanGestureHandlerEventPayload } from "react-native-gesture-handler";
import Animated, {
  SharedValue,
  interpolate,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from "react-native-reanimated";
import { useHover } from "react-native-web-hooks";
import { useColors } from "../../Colors";

export default function FilesScreenSeparator(props: {
  visible: SharedValue<number>;
  onGestureEvent?: (event: GestureEvent<PanGestureHandlerEventPayload>) => void;
}) {
  const { visible, onGestureEvent } = props;
  const colors = useColors().comments;

  const ref = useRef(null);
  const hover = useHover(ref);
  const [resizing, setResizing] = useState(false);
  const width = useSharedValue(0);

  const visibleStyle = useAnimatedStyle(() => ({
    width: interpolate(width.value, [0, 1], [1, 5]) * visible.value,
  }));
  const outerStyle = useAnimatedStyle(() => ({
    width: interpolate(visible.value, [0, 1], [0, 1]),
  }));
  const seperatorTouchAreaStyle = useAnimatedStyle(() => ({
    width: interpolate(visible.value, [0, 1], [0, 20]),
    left: interpolate(visible.value, [0, 1], [0, -9.5]),
  }));

  useEffect(() => {
    width.value = withTiming(hover || resizing ? 1 : 0, { duration: 50 });
  }, [hover, resizing]);

  return (
    <PanGestureHandler
      onGestureEvent={onGestureEvent}
      onBegan={() => setResizing(true)}
      onCancelled={() => setResizing(false)}
      onEnded={() => setResizing(false)}
    >
      <Animated.View style={[outerStyle, { height: "100%", zIndex: 1 }]}>
        <Animated.View
          ref={ref}
          style={[
            seperatorTouchAreaStyle,
            {
              height: "100%",
              position: "absolute",
              alignItems: "center",
              ...(Platform.OS === "web" && { cursor: "ew-resize" }),
            },
          ]}
        >
          <Animated.View style={[visibleStyle, { height: "100%", backgroundColor: colors.border }]} />
        </Animated.View>
      </Animated.View>
    </PanGestureHandler>
  );
}
