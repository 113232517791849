import { useEffect, useState } from "react";

export type MaybePromise<T> = Promise<T> | T | (() => Promise<T>) | (() => T);

export function useMaybePromise<T>(promise: MaybePromise<T>) {
  const [value, setValue] = useState<T | undefined>();
  useEffect(() => {
    setValue(undefined);
    awaitMaybePromise(promise).then(setValue);
  }, [promise, setValue]);
  return value;
}

export async function awaitMaybePromise<T>(promise: MaybePromise<T>): Promise<T> {
  if (typeof promise === "function") return (promise as () => any)();
  return promise;
}
