import { runInAction } from "mobx";
import * as Api from "../Api";
import { store } from "../Store";
import { aesEncrypt, base64Encode } from "../util/CryptoHelper";
import TranscodingServer from "./TranscodingServer";
import { parseTranscodingServers } from "./parseTranscodingServers";

export async function addTranscodingServer(server: TranscodingServer, position?: number) {
  const me = (await store).me;
  if (!me) throw new Error("Unable to add transcoding server: Not signed in");
  const data = base64Encode(
    await aesEncrypt(
      me.keychain.transcodingServersKey,
      Buffer.from(JSON.stringify(server.toJson())),
      new ArrayBuffer(16)
    )
  );
  // prettier-ignore
  const r: string[] = (await Api.gql(`mutation {
    addTranscodingServer(data: "${data}"${position !== undefined ? `, position: ${position}` : ""})
  }`, `Bearer ${me.token.t}`)).addTranscodingServer;
  const n = await parseTranscodingServers(r, me.keychain.transcodingServersKey);
  runInAction(() => me.transcodingServers.replace(n));
}
