import config from "../../config";
import { VFile } from "../file/VFile";
import { store } from "../Store";
import { fetchVersion } from "./fetchVersion";
import { updateVersion } from "./updateVersion";

export async function getVersion(params: { id: string; file: VFile }) {
  const v = (await store).versions.get(params.id);
  if (v) {
    if (Date.now() - v.lastUpdatedAt > config.staleTime) updateVersion(v);
    return v;
  }
  return fetchVersion(params);
}
