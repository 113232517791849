import { useEffect, useRef, useState } from "react";

export function useRefetchEffect(refetch: () => Promise<void>, lastUpdatedAt: number | undefined, staleTime: number) {
  const [fetching, setFetching] = useState(false);
  const task = useRef<any>();
  useEffect(() => {
    if (lastUpdatedAt === undefined || Date.now() - lastUpdatedAt > staleTime) {
      setFetching(true);
      refetch().then(() => setFetching(false));
    }
    // else {
    //   task.current = setTimeout(() => {
    //     if (lastUpdatedAt === undefined || Date.now() - lastUpdatedAt > staleTime) {
    //       setFetching(true);
    //       refetch().then(() => setFetching(false));
    //     }
    //   }, staleTime);
    // }
  }, [refetch, lastUpdatedAt]);
  // useEffect(() => {
  //   console.log("clearTimeout", staleTime, refetch);
  //   clearTimeout(task.current);
  //   task.current = undefined;
  // }, [lastUpdatedAt]);

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setFetching(true);
  //     refetch().then(() => setFetching(false));
  //   }, lastUpdatedAt - Date.now()+ staleTime);
  //   return () => {
  //     console.log("clear reftech");
  //     clearInterval(timer);
  //   };
  // }, [refetch, lastUpdatedAt, staleTime]);
  return fetching;
}
