import { useCallback, useEffect, useState } from "react";
import { cacheFile } from "../cacheFile";
import { aesDecrypt } from "../util/CryptoHelper";
import ImageVersion from "../version/ImageVersion";

export const useImageData = (v?: ImageVersion) => {
  const [data, setData] = useState<string>();
  const [error, setError] = useState<any>();
  const refetch = useCallback(async () => {
    if (v)
      try {
        const r = await cacheFile(`versions/${v.id}/original`, async () => {
          const fe = (await v.getS3("original")).fetch;
          // return fe.body.pipeThrough(decryptStream({ key: v.encKey, iv: new ArrayBuffer(16), size: v.size }))
          return new Response(await aesDecrypt(v.encKey, await fe.arrayBuffer(), new ArrayBuffer(16)));
        });
        setData(r);
      } catch (e) {
        setError(e);
      }
  }, [v]);
  useEffect(() => {
    refetch();
  }, [v]);
  return { data, status: error ? "error" : data ? "success" : "loading" };
};
