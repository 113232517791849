import React, { ReactNode } from "react";
import { StyleProp, Text, TextStyle, TouchableOpacity, View, ViewStyle } from "react-native";
import { Overlay } from "react-native-elements";
import { useColors } from "../Colors";

export default function ModalView(props: {
  title?: string;
  titleStyle?: StyleProp<TextStyle>;
  onSubmit?: () => void;
  submitText?: string;
  onCancel?: () => void;
  cancelText?: string;
  visible: boolean;
  setVisible: (b: boolean) => void;
  noButtons?: boolean;
  children: ReactNode;
}) {
  const { title, titleStyle, onSubmit, submitText, onCancel, cancelText, visible, setVisible, noButtons, children } =
    props;
  const colors = useColors().modal;
  const textStyle: TextStyle = { color: colors.text, fontSize: 16, fontWeight: "500" };
  const buttonStyle: ViewStyle = { borderRadius: 8, padding: 6 };
  return (
    <Overlay
      isVisible={visible}
      onBackdropPress={() => {
        setVisible(!visible);
        onCancel?.();
      }}
      overlayStyle={{
        maxHeight: "90%",
        margin: 40,
        padding: 10,
        borderRadius: 6,
        backgroundColor: colors.background,
      }}
    >
      {title && (
        <Text style={[{ color: colors.text, fontWeight: "600", fontSize: 24, paddingBottom: 8 }, titleStyle]}>
          {title}
        </Text>
      )}
      {children}
      {!noButtons && (
        <View style={{ zIndex: -1, flexDirection: "row", justifyContent: "space-between" }}>
          <TouchableOpacity
            style={buttonStyle}
            onPress={() => {
              setVisible(false);
              onCancel?.();
            }}
          >
            <Text style={textStyle}>{cancelText ?? "Cancel"}</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={buttonStyle}
            onPress={() => {
              setVisible(false);
              onSubmit?.();
            }}
          >
            <Text style={textStyle}>{submitText ?? "Submit"}</Text>
          </TouchableOpacity>
        </View>
      )}
    </Overlay>
  );
}
