import * as Clipboard from "expo-clipboard";
import { useState } from "react";
import { Platform, Text, TextInput, TouchableOpacity, View } from "react-native";
import { VFile } from "../../../model/file/VFile";
import { useShareLink } from "../../../model/hooks/useShareLink";
import { LinkShare } from "../../../model/share/Share";
import { uuidToBase64 } from "../../../model/util/CryptoHelper";
import { useColors } from "../../Colors";
import styles from "../../Styles";
import Icon from "../../components/icon/Icon";

export default function LinkListItem(props: { share: LinkShare; file: VFile }) {
  const { share } = props;
  const colors = useColors().modal.share.item;
  const { data } = useShareLink(props);
  const [selected, setSelected] = useState(false);
  const onClick = () => {
    onCopy();
    setSelected(true);
  };
  const onCopy = () => {
    if (data) {
      if (Platform.OS === "web") {
        navigator.clipboard.writeText(data);
      } else {
        Clipboard.setUrlAsync(data);
      }
    } else console.error("unable to copy url");
  };
  const reset = () => {
    setSelected(false);
  };
  return (
    <>
      <View
        style={{
          width: 30,
          height: 30,
          justifyContent: "center",
          alignItems: "center",
          borderRadius: 15,
          backgroundColor: colors.circle.background,
        }}
      >
        <Icon name="link" color={colors.circle.text} size={20} />
      </View>
      <View
        style={{
          borderRadius: 4,
          backgroundColor: colors.background,
          padding: 4,
          marginHorizontal: 8,
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        {selected ? (
          <TextInput
            autoFocus
            selectTextOnFocus
            autoCorrect={false}
            style={[
              styles.monospace,
              {
                color: colors.link,
                fontSize: 13,
              },
            ]}
            value={data}
            onEndEditing={reset}
            onBlur={reset}
          />
        ) : (
          <Text
            style={[
              styles.monospace,
              {
                color: colors.link,
                fontSize: 13,
                ...(Platform.OS === "web" && { cursor: "text" }),
              },
            ]}
            onPress={onClick}
          >
            {uuidToBase64(share.id) ?? ""}
          </Text>
        )}
        <TouchableOpacity onPress={onCopy}>
          <Icon name="copy" size={12} color={colors.text} style={{ padding: 2, paddingLeft: 6 }} />
        </TouchableOpacity>
      </View>
    </>
  );
}
