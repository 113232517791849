import { runInAction } from "mobx";
import * as Api from "../Api";
import { VFile } from "../file/VFile";
import { store } from "../Store";
import { aesEncrypt, base64Encode, exportRawKey } from "../util/CryptoHelper";
import Version from "./Version";

export async function moveVersionsToFile(params: {
  versions: Version[];
  file: VFile;
  before: string | null;
  after: string | null;
}) {
  const { versions, file, before, after } = params;
  const _store = await store;
  const me = _store.me!;
  const versionsGQL = (
    await Promise.all(
      versions.map(async (v) => {
        const encryptedKey = await aesEncrypt(file.encKey, await exportRawKey(v.encKey), new ArrayBuffer(16));
        return `{id: "${v.id}", key: "${base64Encode(encryptedKey)}"}`;
      })
    )
  ).join(",");
  // prettier-ignore
  const r: {versionIds:string[]} = (await Api.gql(`mutation {
    moveVersionsToFile(
      file: "${file.id}",
      versions: [${versionsGQL}]
      ${before === null? "" :`, before: "${before}"`}
      ${after  === null? "" : `, after: "${after}"`}
    ) {
      versionIds
    }
  }`, `Bearer ${me.token.t}`)).moveVersionsToFile;
  runInAction(() => {
    file.versionIds.replace(r.versionIds);
  });
}
