import config from "../../config";
import * as Api from "../Api";
import { TranscodingServerJson } from "../transcoding-server/TranscodingServer";
import {
  aesEncrypt,
  base64Encode,
  exportRawKey,
  generateAesKey,
  generateRsaKeyPair,
  keyhash,
  pwhash,
  randomBytes,
  shasum,
} from "../util/CryptoHelper";
import { login } from "./login";
import { MeUser, UserCiphertextJson } from "./MeUser";

export async function signup(
  username: string,
  password: string,
  displayName: string,
  params?: { transcodingServers?: TranscodingServerJson[] }
): Promise<MeUser> {
  const startKey = await shasum(password);
  const masterKey = await keyhash(startKey, "master");
  const saltKey = await keyhash(startKey, "salt");
  const keyKey = await keyhash(await exportRawKey(masterKey), "key");

  const salt = randomBytes(16);
  const encryptedSalt = await aesEncrypt(saltKey, salt, new ArrayBuffer(16));
  const ph = await pwhash(password, salt);

  const keyPair = await generateRsaKeyPair();

  const key = await generateAesKey();
  const encryptedKey = await aesEncrypt(keyKey, await exportRawKey(key), new ArrayBuffer(16));

  const ciphertext: UserCiphertextJson = {
    privkey: base64Encode(await crypto.subtle.exportKey("pkcs8", keyPair.privateKey!)),
  };
  const encryptedCiphertext = await aesEncrypt(key, Buffer.from(JSON.stringify(ciphertext)), new ArrayBuffer(16));

  const transcodingServersKey = await keyhash(await exportRawKey(masterKey), "transcoding_servers");
  const transcodingServers = (params?.transcodingServers ?? [config.defaultTranscodingServer]).map(async (v) =>
    base64Encode(await aesEncrypt(transcodingServersKey, Buffer.from(JSON.stringify(v)), new ArrayBuffer(16)))
  );

  (
    await Api.req({
      endpoint: "/user/signup",
      body: JSON.stringify({
        username: username,
        display_name: displayName,
        salt: base64Encode(encryptedSalt),
        password: base64Encode(ph),
        key: base64Encode(encryptedKey),
        pubkey: base64Encode(await crypto.subtle.exportKey("spki", keyPair.publicKey!)),
        ciphertext: base64Encode(encryptedCiphertext),
        transcoding_servers: await Promise.all(transcodingServers),
      }),
    })
  ).mapErr((e) => console.error("signup failed", e));
  return (await login(username, password)).unwrap();
}
