import { PropsWithChildren } from "react";
import { View } from "react-native";
import ContextMenuActions from "./ContextMenuActions";
import ContextMenuStore, { ContextMenuStoreContext } from "./ContextMenuStore";

export default function ContextMenuProvider(props: PropsWithChildren<{}>) {
  const s = new ContextMenuStore();
  return (
    <ContextMenuStoreContext.Provider value={s}>
      <View style={{ flex: 1 }}>
        {props.children}
        {<ContextMenuActions />}
      </View>
    </ContextMenuStoreContext.Provider>
  );
}
