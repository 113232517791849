import React, { JSXElementConstructor, ReactElement, ReactNode, ReactPortal } from "react";

export function mapReactChildren<
  R,
  T extends string | JSXElementConstructor<any> = string | JSXElementConstructor<any>
>(params: {
  children: ReactNode;
  type?: T;
  map: (element: ReactElement<any, string | JSXElementConstructor<any>> | ReactPortal) => R;
}): R[] {
  const { children, map, type } = params;
  const r: R[] = [];
  React.Children.forEach(children, (element) => {
    if (!React.isValidElement(element)) return;
    if (element.type === React.Fragment) {
      r.push.apply(r, mapReactChildren({ children: element.props.children, map, type }));
      return;
    }
    if (type !== undefined && element.type !== type)
      throw new Error(
        `[${typeof element.type === "string" ? element.type : element.type.name}] is not a [${
          typeof type === "string" ? type : type.name
        }].`
      );
    r.push(map(element));
  });
  return r;
}
