import { Result } from "ts-results";
import * as Api from "../Api";
import { store } from "../Store";
import { DeleteFileError } from "../generated/DeleteFileError";

export async function deleteFile(id: string): Promise<Result<void, Api.Error<DeleteFileError>>> {
  let _store = await store;
  return (
    await Api.req<void, DeleteFileError>({
      endpoint: `/file/${id}`,
      token: (await store).me!.token.t,
      method: "DELETE",
    })
  ).map((_) => {
    _store.files.delete(id);
  });
}
