import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { StyleProp, Text, View, ViewStyle } from "react-native";
import { WipComment } from "../../../model/comment/Comment";
import { VideoStoreContext } from "../../../model/ui/VideoStore";
import TimecodeText from "../../screens/preview/video/TimecodeText";

const NewCommentTimecode = observer(
  (props: { wipComment?: WipComment | null; color: string; style?: StyleProp<ViewStyle> }) => {
    const { wipComment, style, color } = props;
    const vStore = useContext(VideoStoreContext);

    return (
      <View style={[{ flexDirection: "row" }, style]}>
        {typeof wipComment?.endTimecode !== "number" ? (
          <TimecodeText millis={vStore.positionMillis} fps={vStore.fps} color={color} />
        ) : (
          <>
            <TimecodeText millis={wipComment?.startTimecode ?? undefined} fps={vStore.fps} color={color} />
            <Text>{" - "}</Text>
            <TimecodeText millis={wipComment.endTimecode} fps={vStore.fps} color={color} />
          </>
        )}
      </View>
    );
  }
);

export default NewCommentTimecode;
