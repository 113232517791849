export abstract class Socket {
  readonly url: string;
  protected socket?: WebSocket;

  private startPromise: Promise<void> | undefined;
  private pingTimer: number | undefined;

  constructor(url: string) {
    this.url = url;
  }

  start() {
    if (!this.startPromise)
      this.startPromise = new Promise((resolve) => {
        if (this.socket === undefined || this.socket.readyState > WebSocket.OPEN) {
          this.socket = new WebSocket(this.url);
          this.socket.onopen = () => resolve();
          this.socket.onmessage = (ev) => {
            clearInterval(this.pingTimer);
            this.resetPingTimer();
            this.handleEvent(ev);
          };
          this.socket.onclose = this.onclose;
          this.socket.onerror = this.onclose;
        }
        this.resetPingTimer();
      });
    return this.startPromise;
  }

  private onclose = () => {
    this.socket = undefined;
    this.startPromise = undefined;
    clearInterval(this.pingTimer);
  };

  private resetPingTimer() {
    this.pingTimer = setInterval(() => {
      if (this.socket)
        if (this.socket.readyState === WebSocket.OPEN) this.socket.send(JSON.stringify({ type: "ping" }));
        // TODO: retry connect?
        else this.onclose();
    }, 10000) as unknown as number;
  }

  close() {
    this.socket?.close();
  }

  protected abstract handleEvent(e: MessageEvent<any>): void;
}
