import * as React from "react";
import { HashRouter } from "react-router-dom";
import ContextMenuProvider from "./ui/components/context-menu/ContextMenuProvider";
import AppRouter from "./ui/navigation/Router";

export default function Core() {
  return (
    <ContextMenuProvider>
      <HashRouter>
        <AppRouter />
      </HashRouter>
    </ContextMenuProvider>
  );
}
