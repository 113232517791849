import { PropsWithChildren } from "react";
import { Platform, Text, View } from "react-native";
import type { To } from "react-router";
import { useColors } from "../../Colors";
import Navbar from "../../navigation/navbar/Navbar";

/**
 * A menu screen with navbar, usually located on it's own `Route`.
 */
export default function MenuScreen(
  props: PropsWithChildren<{ title: string; navbarLeft?: React.FC; navbarRight?: React.FC; backTo?: To | number }>
) {
  const { children, title, backTo } = props;
  const navbarColors = useColors().navBar;
  return (
    <View style={{ flex: 1 }}>
      <Navbar
        backTo={backTo}
        Left={
          Platform.OS === "web"
            ? () => (
                <>
                  <Text style={{ color: navbarColors.text, fontWeight: "500", fontSize: 16 }}>{title}</Text>
                  {props.navbarLeft && <props.navbarLeft />}
                </>
              )
            : props.navbarLeft
        }
        Right={props.navbarRight}
      ></Navbar>
      {children}
    </View>
  );
}
