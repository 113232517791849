import { useContext, useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "../../ui/navigation/routing";
import { StoreContext } from "../Store";

export default function useLoginRedirect() {
  const navigate = useNavigate();
  const _store = useContext(StoreContext);
  const location = useLocation();
  const isAddAccount = location.pathname === "/login" || location.pathname === "/signup";
  const [searchParams, _setSearchParams] = useSearchParams();
  const redirectUri = searchParams.get("redirect");
  useEffect(() => {
    if (_store.me && isAddAccount) navigate(redirectUri ?? "/", { replace: redirectUri ? false : true });
  }, [_store.me, navigate, redirectUri, isAddAccount]);
}
