import { observer } from "mobx-react-lite";
import React, { createRef, useContext, useEffect, useState } from "react";
import {
  NativeScrollEvent,
  NativeSyntheticEvent,
  Platform,
  SectionList,
  SectionListData,
  Text,
  View,
} from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { StoreContext } from "../../../model/Store";
import Comment from "../../../model/comment/Comment";
import { subscribeComments } from "../../../model/comment/subscribeComments";
import { VFile } from "../../../model/file/VFile";
import DirectoryVersion from "../../../model/version/DirectoryVersion";
import { useVersion } from "../../../model/version/useVersion";
import { useColors } from "../../Colors";
import ErrorScreen from "../../screens/ErrorScreen";
import LoadScreen from "../../screens/LoadScreen";
import CommentView from "./CommentView";
import VersionSeparator from "./VersionSeparator";

const CommentsView = observer(
  (props: {
    file: VFile;
    spaceBottom?: number;
    onScroll?: (event: NativeSyntheticEvent<NativeScrollEvent>) => void;
  }) => {
    const { file, onScroll, spaceBottom } = props;
    const { data: version, status: versionStatus, error } = useVersion(file);
    const colors = useColors().comments;
    const _store = useContext(StoreContext);

    const insets = useSafeAreaInsets();
    const [height, setHeight] = useState(0);
    const [innerHeight, setInnerHeight] = useState(0);
    const hasScrollBar = Platform.OS === "web" && height < innerHeight;

    let sections: SectionListData<Comment>[] = [];

    if (version instanceof DirectoryVersion) {
      // find comments for children
      const files =
        version.childrenIds.map((id) => _store.files.get(id)).filter((v): v is VFile => v !== undefined) ?? [];
      for (const f of files) {
        const v = f.selectedVersion;
        if (v && v.comments.size > 0) {
          if (version.selectedFile?.id === v.file.id)
            sections = [{ key: v.id, version: v, data: v.sortedComments }, ...sections];
          else sections.push({ key: v.id, version: v, data: v.sortedComments });
        }
      }
      if (version.sortedComments.length > 0)
        sections = [{ key: version.id, version, data: version.sortedComments }, ...sections];
    } else sections = [{ key: version?.id, data: version?.sortedComments ?? [] }];

    useEffect(() => {
      if (version) subscribeComments(version, 1).catch((e) => console.error("Error in subscribeComments", e));
    }, [version, version instanceof DirectoryVersion && version.childrenIds.slice()]);

    useEffect(
      () => () => {
        if (version) version.commentsListRef = createRef();
      },
      []
    );

    switch (versionStatus) {
      case "error":
        return <ErrorScreen msg={`Failed to load version: ${error}`} />;
      case "fetching":
        return <LoadScreen msg="Loading version" />;
    }

    if (sections.length < 1 || (sections.length === 1 && sections[0].data.length < 1)) {
      return (
        <View style={{ height: "100%", backgroundColor: colors.background, justifyContent: "center" }}>
          <Text style={{ color: colors.text, textAlign: "center" }}>No comments yet</Text>
        </View>
      );
    }

    return (
      <SectionList
        ref={version?.commentsListRef}
        stickySectionHeadersEnabled
        style={{ height: 0, backgroundColor: colors.background }}
        contentContainerStyle={{ paddingBottom: insets.bottom + (spaceBottom ?? 0) }}
        onScroll={onScroll}
        onContentSizeChange={(w, h) => {
          setInnerHeight(h);
        }}
        onLayout={(e) => setHeight(e.nativeEvent.layout.height)}
        sections={sections}
        keyExtractor={(item, _index) => `${item.id}`}
        onEndReached={() => {
          if (version) subscribeComments(version, 1);
        }}
        renderItem={({ item }) => (
          <View style={{ marginRight: Math.max(insets.right, hasScrollBar ? 0 : 10) }}>
            <CommentView comment={item} style={{ paddingBottom: 8 }} />
          </View>
        )}
        renderSectionHeader={({ section }) => section.version && <VersionSeparator version={section.version} />}
      />
    );
  }
);

export default CommentsView;
