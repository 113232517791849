import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { View } from "react-native";
import { StoreContext } from "../../../../model/Store";
import { VideoStoreContext } from "../../../../model/ui/VideoStore";
import styles from "../../../Styles";
import ContextMenu from "../../../components/context-menu/ContextMenu";
import Icon from "../../../components/icon/Icon";
import TimecodeText from "./TimecodeText";

const CurrentTimecode = observer((props: { color: string }) => {
  const { color } = props;
  const _store = useContext(StoreContext);
  const vStore = useContext(VideoStoreContext);
  return (
    <ContextMenu
      dropdownMenuMode
      actions={[
        {
          title: "Short",
          onPress: () => {
            _store.timecodeFormat = "short";
          },
        },
        {
          title: "Timecode",
          onPress: () => {
            _store.timecodeFormat = "timecode";
          },
        },
        {
          title: "Frames",
          onPress: () => {
            _store.timecodeFormat = "frames";
          },
        },
      ]}
    >
      <View style={styles.row}>
        <TimecodeText millis={vStore.positionMillis} fps={vStore.fps} color={color} />
        <Icon name="collapse" size={16} color={color} />
      </View>
    </ContextMenu>
  );
});

export default CurrentTimecode;
