export class Token {
  t: string;
  expiresAt: Date;

  constructor(params: { t: string; expiresAt: Date }) {
    this.t = params.t;
    this.expiresAt = params.expiresAt;
  }

  static fromJson = (json: TokenJson) =>
    new Token({
      t: json.t,
      expiresAt: new Date(json.expires_at),
    });

  toJson = (): TokenJson => ({
    t: this.t,
    expires_at: this.expiresAt.toISOString(),
  });
}

export type TokenJson = {
  t: string;
  expires_at: string;
};
