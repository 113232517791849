import { useCallback, useRef } from "react";
import { TextInput } from "react-native";
import Input from "../../components/input/Input";
import ModalView from "../ModalView";

export default function TranscodingServerModal(props: {
  title: string;
  name: string;
  setName: (s: string) => void;
  url: string;
  setUrl: (s: string) => void;
  visible: boolean;
  setVisible: (b: boolean) => void;
  onSubmit?: () => void;
  onCancel?: () => void;
}) {
  const { title, name, setName, url, setUrl, visible, setVisible } = props;
  const reset = useCallback(() => {
    setName("");
    setUrl("");
  }, [setName, setUrl]);
  const onCancel = useCallback(() => {
    try {
      props.onCancel?.();
    } finally {
      reset();
    }
  }, [reset, props.onCancel]);
  const onSubmit = useCallback(() => {
    try {
      props.onSubmit?.();
    } finally {
      reset();
    }
  }, [reset, props.onSubmit]);

  const input2Ref = useRef<TextInput>(null);

  return (
    <ModalView visible={visible} setVisible={setVisible} title={title} onSubmit={onSubmit} onCancel={onCancel}>
      <Input
        autoFocus
        value={name}
        placeholder="Name"
        onChangeText={setName}
        containerStyle={{ marginVertical: 4 }}
        onSubmitEditing={() => input2Ref.current?.focus()}
      />
      <Input
        ref={input2Ref}
        value={url}
        placeholder="URL"
        onChangeText={setUrl}
        onSubmitEditing={onSubmit}
        containerStyle={{ marginVertical: 4 }}
      />
    </ModalView>
  );
}
