import { useLocalization } from "@fluent/react";
import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { StyleProp, ViewStyle } from "react-native";
import { StoreContext } from "../../../model/Store";
import { CommentSortStrategy } from "../../../model/comment/sortComments";
import SortDropdown from "../SortDropdown";

const CommentSortDropdown = observer((props: { color: string; style?: StyleProp<ViewStyle> }) => {
  const { color, style } = props;
  const _store = useContext(StoreContext);
  const { l10n } = useLocalization();
  return (
    <SortDropdown<CommentSortStrategy>
      color={color}
      value={_store.commentSortStrategy}
      setValue={(v) => (_store.commentSortStrategy = v)}
      options={[["timecode"], ["date_created_asc", "date_created_desc"]]}
      style={style}
      getTitle={(s) => l10n.getString("sort-comments-" + s.replace(/_asc$/, "").replaceAll(/_/g, "-"))}
    />
  );
});
export default CommentSortDropdown;
