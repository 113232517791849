import * as React from "react";
import { Text, View } from "react-native";
import { useColors } from "../Colors";
import styles from "../Styles";
import Icon from "../components/icon/Icon";

export default function ErrorScreen(props: { msg?: string; numberOfLines?: number }) {
  const colors = useColors().errorScreen;
  return (
    <View style={[styles.container, { backgroundColor: colors.background }]}>
      <Text
        style={{ color: colors.text, padding: 8, paddingBottom: 0, marginBottom: -1, opacity: 0 }}
        ellipsizeMode="middle"
        numberOfLines={props.numberOfLines}
      >
        {props.msg}
      </Text>
      <Icon name="error" size={48} color={colors.accent} />
      <Text
        style={{ color: colors.text, padding: 8, paddingTop: 0, marginTop: -1 }}
        ellipsizeMode="middle"
        numberOfLines={props.numberOfLines}
      >
        {props.msg}
      </Text>
    </View>
  );
}
