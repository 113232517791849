import { Permissions } from "./Permissions";

/**
 * Returns `true` if `a` is equal or more permissive than `b`.
 */
export function comparePermissions(a: Permissions, b: Permissions) {
  return (
    Number(a.owner) >= Number(b.owner) &&
    Number(a.create_file) >= Number(b.create_file) &&
    Number(a.add_version) >= Number(b.add_version) &&
    Number(a.rename) >= Number(b.rename) &&
    Number(a.delete) >= Number(b.delete) &&
    Number(a.create_comment) >= Number(b.create_comment) &&
    Number(a.edit_comment) >= Number(b.edit_comment) &&
    Number(a.delete_comment) >= Number(b.delete_comment) &&
    Number(a.share_user) >= Number(b.share_user) &&
    Number(a.share_link) >= Number(b.share_link) &&
    Number(a.delete_share) >= Number(b.delete_share)
  );
}
