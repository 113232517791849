import config from "../../config";
import Comment from "./Comment";

export type CommentSortStrategy = "timecode" | "date_created_asc" | "date_created_desc";

function isCommentSortStrategy(v: any): v is CommentSortStrategy {
  return ["timecode", "date_created_asc", "date_created_desc"].includes(v);
}

export function parseCommentSortStrategy(v: string | null | undefined): CommentSortStrategy {
  if (isCommentSortStrategy(v)) return v;
  return config.defaultCommentSortStrategy;
}

function commentSortStrategyToFunction(s: CommentSortStrategy): (a: Comment, b: Comment) => number {
  switch (s) {
    case "timecode":
      return compareCommentsByTimecode;
    case "date_created_asc":
      return compareCommentsByDateCreatedAsc;
    case "date_created_desc":
      return compareCommentsByDateCreatedDesc;
  }
}

export function sortComments(comments: Comment[], by: CommentSortStrategy): Comment[] {
  // TODO: more efficient sorting
  return comments.sort(commentSortStrategyToFunction(by));
}

export function compareCommentsByTimecode(a: Comment, b: Comment): number {
  if (a.latestVersion?.startTimecode === undefined) {
    if (b.latestVersion?.startTimecode === undefined) return 0;
    else return -3_600_000;
  } else {
    if (b.latestVersion?.startTimecode === undefined) return 3_600_000;
    else return a.latestVersion.startTimecode - b.latestVersion.startTimecode;
  }
}

export function compareCommentsByDateCreatedAsc(a: Comment, b: Comment): number {
  return a.oldestVersion.createdAt.valueOf() - b.oldestVersion.createdAt.valueOf();
}

export function compareCommentsByDateCreatedDesc(a: Comment, b: Comment): number {
  return -compareCommentsByDateCreatedAsc(a, b);
}
