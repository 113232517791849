import { runInAction } from "mobx";
import * as Api from "../Api";
import { store } from "../Store";
import { aesEncrypt, base64Encode } from "../util/CryptoHelper";
import Comment, { WipComment } from "./Comment";

export async function editComment(params: { comment: Comment; wip: WipComment; currentTimecode?: number }) {
  const { comment, wip, currentTimecode } = params;
  const _store = await store;
  const me = _store.me!;

  const startTimecode = _store.commentReplyLinkTimecode ? currentTimecode : wip.startTimecode ?? undefined;
  const endTimecode = wip.endTimecode ?? undefined;
  const ciphertext = { text: wip.text, startTimecode, endTimecode };
  const encryptedCiphertext = aesEncrypt(
    comment.encKey,
    Buffer.from(JSON.stringify(ciphertext), "utf-8"),
    new ArrayBuffer(16)
  );

  // prettier-ignore
  const r: { createdAt: string } = (await Api.gql(`mutation {
      editComment(
        id: "${comment.id}",
        ciphertext: "${base64Encode(await encryptedCiphertext)}",
      )
    }`, `Bearer ${me.token.t}`)).editComment;
  runInAction(() => {
    comment.versions.set([...comment.versions.keys()].reduce((a, b) => Math.max(a, b)) + 1, {
      text: wip.text,
      author: me,
      startTimecode,
      endTimecode,
      createdAt: new Date(r.createdAt),
    });
  });
}
