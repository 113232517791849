import { Text } from "react-native";
import { useColors } from "../../Colors";
import Icon from "../../components/icon/Icon";

export default function AddButton(props: { type: AddButtonType }) {
  const colors = useColors();
  return (
    <>
      <Icon
        name={props.type === AddButtonType.user ? "person-add" : "add-link"}
        size={24}
        color={colors.text}
        style={{ width: 30, textAlign: "center" }}
      />
      <Text style={{ color: colors.text, paddingLeft: 8 }}>
        {props.type === AddButtonType.user ? "Add User" : "Share Link"}
      </Text>
    </>
  );
}

export enum AddButtonType {
  user,
  link,
}
