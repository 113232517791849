import { autorun, runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { useContext, useEffect, useRef, useState } from "react";
import { Platform, StyleProp, ViewStyle } from "react-native";
import { TouchableWithoutFeedback } from "react-native-gesture-handler";
import Animated, {
  Easing,
  Extrapolate,
  interpolate,
  useAnimatedStyle,
  useSharedValue,
  withDelay,
  withTiming,
} from "react-native-reanimated";
import { useHover } from "react-native-web-hooks";
import Comment from "../../../model/comment/Comment";
import VideoStore, { VideoStoreContext } from "../../../model/ui/VideoStore";
import DirectoryVersion from "../../../model/version/DirectoryVersion";
import CommentViewInner from "./CommentViewInner";

const CommentView = observer((params: { comment: Comment; style?: StyleProp<ViewStyle> }) => {
  const { comment, style } = params;
  const vStore = useContext(VideoStoreContext) as VideoStore | undefined;
  const ref = useRef(null);
  const isHovering = useHover(ref);
  const isHighlighted = isHovering || comment.isHighlighted;
  const [animatedHeight, setAnimatedHeight] = useState(0);
  const yScale = useSharedValue(1);
  const opacity = useSharedValue(1);

  const animatedStyle =
    Platform.OS === "web"
      ? useAnimatedStyle(() => ({
          height: interpolate(yScale.value, [0, 1], [0, animatedHeight]),
          transform: [
            { translateY: interpolate(yScale.value, [0, 0.5, 1], [-4, -animatedHeight * 0.125 - 2, 0]) },
            { scaleY: yScale.value },
          ],
          opacity: interpolate(opacity.value, [0, 1], [0, 1], Extrapolate.CLAMP),
        }))
      : { height: (comment.replyTo ?? comment.version).commentsVisible ? undefined : 0 };

  useEffect(
    () =>
      autorun(() => {
        const duration = 200;
        const fadeDuration = 100;
        const b = comment.version.commentsVisible;
        yScale.value = withTiming(b ? 1 : 0, {
          duration,
          easing: b ? Easing.in(Easing.exp) : Easing.out(Easing.exp),
        });
        opacity.value = withDelay(
          b ? 0 : duration - fadeDuration,
          withTiming(b ? 1 : 0, {
            duration: fadeDuration,
            easing: Easing.bezier(0, 1, 0, 1),
          })
        );
      }),
    [comment.version]
  );

  useEffect(() => {
    runInAction(() => {
      comment.isHighlighted = false;
    });
  }, [isHovering]);

  return (
    <Animated.View style={[{ zIndex: 1, width: "100%", overflow: "hidden" }, animatedStyle]}>
      <TouchableWithoutFeedback
        ref={ref}
        onLayout={Platform.OS !== "web" ? undefined : (e) => setAnimatedHeight(e.nativeEvent.layout.height)}
        style={[{ paddingLeft: 10, ...(Platform.OS === "web" && { cursor: "auto" }) }, style]}
        onPress={() => {
          if (comment.latestVersion?.startTimecode !== undefined) {
            vStore?.setPosition(comment.latestVersion.startTimecode);
            if (process.env.EXPO_PUBLIC_PPRO_EXT ?? "" in ["true", "1"])
              import("../../../extensions/ppro/csi").then(({ setPosition }) => {
                if (comment.latestVersion?.startTimecode !== undefined)
                  setPosition(comment.latestVersion.startTimecode);
              });
          }

          const dir = comment.version.file.parentDir?.selectedVersion;
          if (dir instanceof DirectoryVersion) {
            runInAction(() => {
              dir.selectedFile = comment.version.file;
            });
          }
        }}
      >
        <CommentViewInner comment={comment} isHighlighted={isHighlighted} />
      </TouchableWithoutFeedback>
    </Animated.View>
  );
});

export default CommentView;
