import { useCallback, useContext, useState } from "react";
import { newCacheableResult } from "../cacheable/CacheableResult";
import { useRefetchEffect } from "../cacheable/useRefetchEffect";
import { VFile } from "../file/VFile";
import { StoreContext } from "../Store";
import { fetchVersions } from "./fetchVersions";
import Version from "./Version";

export function useVersions<V extends Version>(file: VFile) {
  const _store = useContext(StoreContext);
  const data: V[] = [];
  for (const id of file.versionIds) {
    const v = _store.versions.get(id);
    if (v) data.push(v as V);
  }

  const [error, setError] = useState<unknown>();

  const refetch = useCallback(async () => {
    try {
      await fetchVersions(file);
      setError(undefined);
    } catch (e) {
      setError(e);
    }
  }, [file]);
  const fetching = useRefetchEffect(refetch, file.versionsLastUpdatedAt, 30000);

  return newCacheableResult({ data, error, refetch });
}
