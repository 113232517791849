import DirectoryVersion from "./DirectoryVersion";
import { downloadDirectoryVersion } from "./downloadDirectoryVersion";
import { downloadFileVersion } from "./downloadFileVersion";
import FileVersion from "./FileVersion";
import Version from "./Version";

export function downloadVersion(v: Version): Promise<void> {
  if (v instanceof FileVersion) return downloadFileVersion(v);
  else if (v instanceof DirectoryVersion) return downloadDirectoryVersion(v);
  else throw new Error("Unsupported version type");
}
