import React, { useEffect, useRef, useState } from "react";
import { Platform, View } from "react-native";
import { Gesture, GestureDetector } from "react-native-gesture-handler";
import Animated, { interpolate, useAnimatedStyle, useSharedValue, withTiming } from "react-native-reanimated";
import { useHover } from "react-native-web-hooks";
import { VFile } from "../../../model/file/VFile";
import { useWindowDimensions } from "../../../model/util/useWindowDimensions.web";
import { useColors } from "../../Colors";
import styles from "../../Styles";
import CommentSortDropdown from "../../components/comments/CommentSortDropdown";
import CommentsView from "../../components/comments/CommentsView";
import NewCommentViewDesktop from "../../components/comments/NewCommentViewDesktop";
import NewCommentViewWrapper from "../../components/comments/NewCommentViewWrapper";
import GalleryContent from "./GalleryContent";
import GalleryPreview from "./GalleryPreview";
import GallerySelector from "./GallerySelector";
import PreviewScreenNavbar from "./PreviewScreenNavbar";

export default function PreviewScreenDesktop(props: { file: VFile }) {
  const { file } = props;
  const commentsColors = useColors().comments;
  const colors = useColors().previewScreen;
  const dimensions = useWindowDimensions();

  const commentsVisible = useSharedValue(1);
  const commentsWidth = useSharedValue(0.375);
  const rightStyle = useAnimatedStyle(() => ({
    flex: commentsWidth.value * (1 / Math.max(commentsWidth.value, 1 - commentsWidth.value)) * commentsVisible.value,
    minWidth: interpolate(commentsVisible.value, [0, 1], [0, 300]),
  }));
  const leftStyle = useAnimatedStyle(() => ({
    flex: (1 - commentsWidth.value) * (1 / Math.max(commentsWidth.value, 1 - commentsWidth.value)),
  }));

  const separatorRef = useRef(null);
  const hover = useHover(separatorRef);
  const [resizing, setResizing] = useState(false);
  const separatorWidth = useSharedValue(0);
  const separatorVisibleStyle = useAnimatedStyle(() => ({
    width: interpolate(separatorWidth.value, [0, 1], [1, 5]) * commentsVisible.value,
  }));
  const seperatorOuterStyle = useAnimatedStyle(() => ({
    width: interpolate(commentsVisible.value, [0, 1], [0, 1]),
  }));
  const seperatorTouchAreaStyle = useAnimatedStyle(() => ({
    width: interpolate(commentsVisible.value, [0, 1], [0, 20]),
    left: interpolate(commentsVisible.value, [0, 1], [0, -9.5]),
  }));
  useEffect(() => {
    separatorWidth.value = withTiming(hover || resizing ? 1 : 0, { duration: 50 });
  }, [hover, resizing]);

  const gesture = Gesture.Pan()
    .onBegin(() => setResizing(true))
    .onEnd((e) => {
      setResizing(false);
    })
    .onChange(({ absoluteX }) => {
      commentsWidth.value = 1 - absoluteX / dimensions.width;
      commentsVisible.value = withTiming(absoluteX > dimensions.width - 150 ? 0 : 1);
    });
  return (
    <View style={[styles.fill, { flex: 1 }]}>
      <PreviewScreenNavbar
        file={file}
        commentsVisible={commentsVisible}
        toggleComments={() => {
          const v = commentsVisible.value;
          commentsVisible.value = withTiming(v === 1 ? 0 : 1);
        }}
      />
      <View style={{ flexDirection: "row", flexGrow: 1, flexShrink: 1 }}>
        {/* left, preview */}
        <Animated.View style={[leftStyle, { minWidth: 300, height: "100%", backgroundColor: colors.background }]}>
          <View style={{ flexGrow: 1, flexShrink: 1 }}>
            {file.type === "DIRECTORY" ? <GalleryPreview file={file} /> : <GalleryContent file={file} />}
          </View>
          {file.type === "DIRECTORY" && <GallerySelector file={file} commentsVisible={commentsVisible} />}
          <NewCommentViewWrapper file={file} Component={NewCommentViewDesktop} commentsVisible={commentsVisible} />
        </Animated.View>

        {/* separator */}
        <Animated.View style={[seperatorOuterStyle, { height: "100%", zIndex: 1 }]}>
          <GestureDetector gesture={gesture}>
            <Animated.View
              ref={separatorRef}
              style={[
                seperatorTouchAreaStyle,
                {
                  height: "100%",
                  position: "absolute",
                  alignItems: "center",
                  ...(Platform.OS === "web" && { cursor: "ew-resize" }),
                },
              ]}
            >
              <Animated.View
                style={[separatorVisibleStyle, { height: "100%", backgroundColor: commentsColors.border }]}
              />
            </Animated.View>
          </GestureDetector>
        </Animated.View>

        {/* right, comments */}
        <Animated.View
          style={[
            rightStyle,
            { height: Platform.OS === "web" ? dimensions.height - 64 : undefined, overflow: "hidden" },
          ]}
        >
          <View style={{ minWidth: 300, height: "100%" }}>
            <View style={styles.row}>
              <CommentSortDropdown color={colors.text} style={{ padding: 12 }} />
            </View>
            <CommentsView file={file} />
          </View>
        </Animated.View>
      </View>
    </View>
  );
}
