import { observer } from "mobx-react-lite";
import React, { useContext, useState } from "react";
import { View, ViewStyle } from "react-native";
import useKeyboardShortcut from "use-keyboard-shortcut";
import { useColors } from "../../Colors";
import ContextMenuActionComponent from "./ContextMenuActionComponent";
import { ContextMenuStoreContext } from "./ContextMenuStore";

const borderRadius = 6;
const borderWidth = 1;
const borderRadius2 = borderRadius - borderWidth;
const space = 6;

const ContextMenuActions = observer(() => {
  const s = useContext(ContextMenuStoreContext);
  const colors = useColors().contextMenu;
  const pos = s.position;
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  useKeyboardShortcut(["Escape"], () => {
    s.reset();
  });

  const spaceForIcon = s.actions.some((a) => a.icon);

  return (
    <>
      {s.visible && (
        <>
          <View
            style={{ width: "100%", height: "100%", position: "absolute" }}
            onStartShouldSetResponder={() => {
              s.reset();
              return false;
            }}
          />
          <View
            style={{
              position: "absolute",
              left: pos!.x - (pos!.x + width + space > document.body.clientWidth ? width : 0),
              top: Math.min(pos!.y, document.body.clientHeight - height) - space,
              borderRadius,
              borderColor: colors.border,
              borderWidth: borderWidth,
              shadowRadius: 12,
              shadowOpacity: 0.2,
            }}
            onLayout={(e) => {
              console.log("context menu layout");
              const { width, height } = e.nativeEvent.layout;
              setWidth(width);
              setHeight(height);
            }}
          >
            {s.actions.map((a, i) => {
              const first = i === 0;
              const last = i === s.actions.length - 1;
              const style: ViewStyle = {
                borderTopLeftRadius: first ? borderRadius2 : 0,
                borderTopRightRadius: first ? borderRadius2 : 0,
                borderBottomLeftRadius: last ? borderRadius2 : 0,
                borderBottomRightRadius: last ? borderRadius2 : 0,
              };
              return <ContextMenuActionComponent key={i} action={a} style={style} spaceForIcon={spaceForIcon} />;
            })}
          </View>
        </>
      )}
    </>
  );
});

export default ContextMenuActions;
