import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { Extrapolate, interpolate, SharedValue, useAnimatedStyle } from "react-native-reanimated";
import { VFile } from "../../../model/file/VFile";
import { FileCardStoreContext } from "../../../model/ui/FileCardStore";
import FileCardDNDMessageText from "./FileCardDNDMessageText";

const FileCardMessageContent = observer(
  (props: {
    file: VFile;
    dragOver: SharedValue<number>;
    dragOverSide: SharedValue<number>;
    dragOverMiddle: SharedValue<number>;
  }) => {
    const fcStore = useContext(FileCardStoreContext);
    const { file, dragOver, dragOverSide, dragOverMiddle } = props;
    // text styles
    const addVersionBeforeTextStyle = useAnimatedStyle(() => ({
      opacity:
        (1 - dragOverMiddle.value) *
        dragOver.value *
        interpolate(dragOverSide.value, [1, -1], [0, 1], Extrapolate.CLAMP),
    }));
    const moveToDirTextStyle = useAnimatedStyle(() => ({
      opacity: dragOverMiddle.value,
    }));
    const addVersionAfterTextStyle = useAnimatedStyle(() => ({
      opacity:
        (1 - dragOverMiddle.value) *
        dragOver.value *
        interpolate(dragOverSide.value, [-1, 1], [0, 1], Extrapolate.CLAMP),
    }));
    return (
      fcStore.hoveringOver && (
        <>
          <FileCardDNDMessageText
            text={addAsVersionsText(1, file.numberOfVersions)}
            style={addVersionBeforeTextStyle}
          />
          <FileCardDNDMessageText text="Move to directory" style={moveToDirTextStyle} />
          <FileCardDNDMessageText
            text={addAsVersionsText(fcStore.hoveringOver.numberOfVersions + 1, file.numberOfVersions)}
            style={addVersionAfterTextStyle}
          />
        </>
      )
    );
  }
);

const addAsVersionsText = (first: number, count: number) =>
  `Add as ${count === 1 ? `version ${first}` : `versions ${first}-${first + count - 1}`}`;

export default FileCardMessageContent;
