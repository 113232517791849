import { observer } from "mobx-react-lite";
import { FlatList, Text, View } from "react-native";
import { useUploads } from "../../../model/hooks/useUploads";
import { useWindowDimensions } from "../../../model/util/useWindowDimensions.web";
import { useColors } from "../../Colors";
import UploadManagerElement from "./UploadManagerElement";

const UploadManager = observer(() => {
  const colors = useColors().uploadManager;
  const dimensions = useWindowDimensions();
  const uploads = useUploads();
  return (
    <View
      style={{
        backgroundColor: colors.background,
        borderRadius: 8,
        padding: 8,
        borderWidth: 1,
        borderColor: colors.border,
        minWidth: 250,
        width: dimensions.width * 0.4,
        maxHeight: dimensions.height - 100, // TODO: based on navbar height
        shadowRadius: 6,
        shadowOpacity: 0.2,
      }}
    >
      <Text style={{ color: colors.text, fontWeight: "600", fontSize: 16 }}>Uploads</Text>
      <View style={{ backgroundColor: colors.border, width: "100%", height: 1, marginVertical: 8 }} />
      <FlatList
        style={{ minHeight: 50 }}
        data={uploads}
        renderItem={({ item, index }) => <UploadManagerElement e={item} />}
      />
    </View>
  );
});

export default UploadManager;
