import { useEffect, useRef, useState } from "react";
import { SharedValue } from "react-native-reanimated";
import { useColors } from "../../Colors";
import AnimatedIcon from "../../components/icon/AnimatedIcon";
import NavbarButton from "../../navigation/navbar/NavbarButton";

export default function CommentsToggle(props: {
  toggleComments: () => void;
  commentsVisible: Readonly<SharedValue<number>>;
}) {
  const { commentsVisible, toggleComments } = props;
  const colors = useColors().navBar;

  const [color, setColor] = useState(commentsVisible.value === 0 ? colors.icon : colors.accent);
  const ref = useRef(null);
  const prevValue = useRef(0);
  const value = useRef(0);

  useEffect(() => {
    commentsVisible.addListener(100, (e) => {
      prevValue.current = value.current;
      value.current = e;
      if (prevValue.current < value.current) setColor(colors.accent);
      else setColor(colors.icon);
    });
  });

  return (
    <NavbarButton onPress={toggleComments}>
      <AnimatedIcon name="comment" size={24} color={color} ref={ref} />
    </NavbarButton>
  );
}
