import { useLocalization } from "@fluent/react";
import { PointerInteractionView } from "@thefunbots/react-native-pointer-interactions";
import { useState } from "react";
import { Platform, View } from "react-native";
import useKeyboardShortcut from "use-keyboard-shortcut";
import { VFileDirectory } from "../../../model/file/VFile";
import { createDirectory } from "../../../model/upload/create/createDirectory";
import { pickFile } from "../../../model/upload/entrypoints/pickFile";
import { uploadDirectory } from "../../../model/upload/entrypoints/uploadDirectory";
import { uploadFileList } from "../../../model/upload/entrypoints/uploadFiles";
import { useColors } from "../../Colors";
import InputModal from "../../modals/InputModal";
import ContextMenu from "../context-menu/ContextMenu";
import { ContextMenuAction } from "../context-menu/ContextMenuAction";
import Icon from "../icon/Icon";

const size = 32;

export default function UploadButton(props: { directory: VFileDirectory | undefined | null }) {
  const { directory } = props;
  const colors = useColors().uploadButton;
  const [visible, setVisible] = useState(false);
  const { l10n } = useLocalization();

  if (Platform.OS === "web") {
    useKeyboardShortcut(["Meta", "Shift", "N"], () => setVisible(true), { overrideSystem: true });
    useKeyboardShortcut(["Shift", "Meta", "N"], () => setVisible(true), { overrideSystem: true });
    useKeyboardShortcut(["Control", "Shift", "N"], () => setVisible(true), { overrideSystem: true });
    useKeyboardShortcut(["Shift", "Control", "N"], () => setVisible(true), { overrideSystem: true });
  }

  const actions: ContextMenuAction[] = [
    {
      title: l10n.getString("upload-files"),
      icon: "file",
      onPress: async () => directory !== undefined && uploadFileList(await pickFile({ multiple: true }), directory),
    },
    {
      title: l10n.getString("upload-directory"),
      icon: "folder",
      onPress: async () =>
        directory !== undefined && uploadDirectory(await pickFile({ multiple: true, directory: true }), directory),
    },
    {
      title: l10n.getString("new-directory"),
      icon: "create-new-folder",
      onPress: () => setVisible(true),
    },
  ];

  return (
    <>
      <ContextMenu dropdownMenuMode actions={actions}>
        <PointerInteractionView pointerMode="lift">
          <View
            style={{
              margin: 4,
              width: size,
              height: size,
              borderRadius: size / 2,
              borderColor: colors.border,
              borderWidth: 1,
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: colors.background,
            }}
          >
            <Icon name="add" size={16} color={colors.text} />
          </View>
        </PointerInteractionView>
      </ContextMenu>
      {directory !== undefined && (
        <InputModal
          title={l10n.getString("new-directory")}
          placeholder={l10n.getString("new-directory")}
          onSubmit={(n) => createDirectory(n.length > 0 ? n : l10n.getString("new-directory"), directory)}
          visible={visible}
          setVisible={setVisible}
        />
      )}
    </>
  );
}
