import { PointerInteractionView } from "@thefunbots/react-native-pointer-interactions";
import { Platform } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import type { To } from "react-router";
import { useColors } from "../../Colors";
import Icon from "../../components/icon/Icon";
import { useNavigate } from "./../routing";

export default function BackButton(props: { to?: To | number; replace?: boolean; state?: any }) {
  const { to, replace, state } = props;
  const navigate = useNavigate();
  const navbar = useColors().navBar;
  return (
    <PointerInteractionView pointerMode="highlight">
      <TouchableOpacity
        onPress={() => {
          // @ts-ignore
          navigate(to !== undefined ? to : -1, { replace, state });
        }}
      >
        <Icon
          name="back"
          size={Platform.OS === "ios" ? 22 : 24}
          color={navbar.icon}
          style={{ padding: 16, marginLeft: -16 }}
          weight="medium"
        />
      </TouchableOpacity>
    </PointerInteractionView>
  );
}
