import { aesDecrypt, base64Decode } from "../util/CryptoHelper";
import TranscodingServer, { getTranscodingServer, TranscodingServerJson } from "./TranscodingServer";

export const parseTranscodingServers = async (d: string[], key: CryptoKey) =>
  (
    await Promise.allSettled(
      d.map(async (s) => {
        const v: TranscodingServerJson = JSON.parse(
          Buffer.from(await aesDecrypt(key, base64Decode(s), new ArrayBuffer(16))).toString("utf-8")
        );
        return getTranscodingServer({ url: v.url, name: v.name });
      })
    )
  )
    .filter((v): v is PromiseFulfilledResult<TranscodingServer> => v.status === "fulfilled")
    .map((v) => v.value);
