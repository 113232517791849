import { useMemo, useRef, useState } from "react";
import { StyleProp, TextInput, TextStyle, TouchableOpacity, View, ViewStyle } from "react-native";
import useKeyboardShortcut from "use-keyboard-shortcut";
import { useMaybePromise } from "../../../model/hooks/useMaybePromise";
import Input from "./Input";

export default function SuggestionsInput<T>(props: {
  /**
   * Compute suggestions for a text value. Called whenever the text changes.
   * @param text current text value
   * @returns A Promise of a list of suggestions with an id: [id, suggestionData]
   */
  getSuggestions: (text: string) => Promise<[string, T][]>;
  renderSuggestion: (suggestion: T, id: string) => JSX.Element;
  /**
   * Called when the text input is submitted while no suggestions are shown.
   * @param text current text value
   * @returns a boolean indicating whether the current value could be successfully submitted and the text input should be cleared.
   */
  onSubmit: (text: string) => Promise<boolean>;
  /**
   * Called when a suggestion is selected. This also occurs
   * @param item suggestion
   * @returns a boolean indicating whether the suggestion was successfully selected and the text input should be cleared.
   */
  onSelect: (item: T, id: string) => Promise<boolean>;
  onChangeText?: (text: string) => void;
  textStyle?: StyleProp<TextStyle>;
  containerStyle?: StyleProp<ViewStyle>;
  suggestionsStyle?: StyleProp<ViewStyle>;
  placeholder?: string;
}) {
  const {
    getSuggestions,
    renderSuggestion,
    onChangeText,
    onSubmit,
    onSelect,
    textStyle,
    containerStyle,
    suggestionsStyle,
    placeholder,
  } = props;
  const [text, setText] = useState("");
  const ref = useRef<TextInput>(null);
  const suggestionsPromise = useMemo(() => getSuggestions(text), [text]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const suggestions = useMaybePromise(suggestionsPromise) ?? [];
  useKeyboardShortcut(["Escape"], () => ref.current?.blur());
  return (
    <>
      <Input
        ref={ref}
        autoFocus
        value={text}
        onChangeText={(text) => {
          setText(text);
          onChangeText && onChangeText(text);
        }}
        onSubmitEditing={async () => {
          const s = (await suggestionsPromise).at(0);
          if (await (s !== undefined ? onSelect(s[1], s[0]) : onSubmit(text))) setText("");
        }}
        onEndEditing={() => setShowSuggestions(false)}
        onBlur={() => setShowSuggestions(false)}
        onFocus={() => setShowSuggestions(true)}
        style={textStyle}
        containerStyle={containerStyle}
        placeholder={placeholder}
      />
      <View style={{ zIndex: 5 }}>
        <View style={[suggestionsStyle]}>
          {showSuggestions &&
            suggestions.map(([id, s]) => (
              <TouchableOpacity key={id} onPress={async () => (await onSelect(s, id)) && setText("")}>
                {renderSuggestion(s, id)}
              </TouchableOpacity>
            ))}
        </View>
      </View>
    </>
  );
}
