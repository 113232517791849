import { useCallback, useContext } from "react";
import { StoreContext } from "../Store";
import { CacheableResult } from "../cacheable/CacheableResult";
import { useCacheable } from "../cacheable/useCacheable";
import { VFile } from "../file/VFile";
import Version from "./Version";
import { fetchVersion } from "./fetchVersion";
import { updateVersion } from "./updateVersion";

export function useVersion<V extends Version>(file?: VFile, no?: number): CacheableResult<V> {
  const id = file?.versionIds.at(no ?? file.selectedVersionNo);
  const _store = useContext(StoreContext);
  const fetch = useCallback(() => {
    if (!file) throw "Cannot get version: File apparently undefined";
    if (!id) throw "Version does not exist";
    return fetchVersion({ id, file }) as Promise<V>;
  }, [file, id]);
  return useCacheable({
    data: id !== undefined ? (_store.versions.get(id) as V) : undefined,
    fetch,
    update: updateVersion,
    enabled: file != undefined && id !== undefined,
  });
}
