import { observer } from "mobx-react-lite";
import { useEffect, useRef, useState } from "react";
import { Text, View } from "react-native";
import { useHover } from "react-native-web-hooks";
import { UploadElementInfo } from "../../../model/hooks/useUploads";
import { useColors } from "../../Colors";

const height = 2;

const UploadManagerElement = observer((props: { e: UploadElementInfo }) => {
  const { e } = props;
  const u = e.e;
  const colors = useColors().uploadManager;
  const stateColors = useColors().versionState;
  const ref = useRef(null);
  const hover = useHover(ref);
  const [name, setName] = useState("");
  useEffect(() => {
    u.name.then((v) => setName(v ?? "/"));
  }, [u.name]);
  return (
    <View
      ref={ref}
      style={{ padding: 8, backgroundColor: hover ? colors.hover : undefined, paddingLeft: 8 + e.depth * 12 }}
    >
      <Text style={{ color: colors.text, paddingBottom: 8 }}>Uploading {name}</Text>
      <View style={{ height, width: "100%", backgroundColor: colors.barsBackground }}>
        <Bar frac={u.fileUploadFrac} color={stateColors.fileUpload} />
        <Bar frac={u.transcodeDownloadFrac} color={stateColors.jobDownload} />
        <Bar frac={u.transcodeFrac} color={stateColors.jobTranscode} />
        <Bar frac={u.transcodeUploadFrac} color={stateColors.jobUpload} />
      </View>
    </View>
  );
});

const Bar = (props: { frac?: number | null; color: string }) => (
  <View style={{ height, width: `${(props.frac ?? 0) * 100}%`, backgroundColor: props.color }} />
);

export default UploadManagerElement;
