import { Platform, StyleSheet } from "react-native";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  fill: {
    height: "100%",
    width: "100%",
  },
  row: { flexDirection: "row", alignItems: "center" },
  /** fab */
  fab: {
    position: "absolute",
    bottom: 15,
    right: 15,
  },

  /** text */
  textTitle: {
    fontSize: 24,
    fontWeight: "600",
  },
  textUser: {
    fontSize: 13,
    fontWeight: "600",
  },
  monospace: { fontFamily: Platform.OS === "ios" ? "Menlo" : "Monospace" },
  /** file card */
  fileCardImage: {
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
  },

  loginInput: {
    borderRadius: 10,
    height: 50,
    alignItems: "center",
    justifyContent: "center",
    marginVertical: 5,
    marginHorizontal: 16,
    padding: 10,
    width: "100%",
  },
  loginWrapper: {
    flex: 1,
    alignSelf: "center",
    alignItems: "center",
    justifyContent: "center",
    width: 380 + 16 * 2,
    maxWidth: "100%",
    padding: 16,
  },

  slider: {
    position: "absolute",
    top: 1,
  },

  /** UserListCard */
  iconListItem: {
    paddingVertical: 8,
    paddingHorizontal: 12,
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
  },

  /** menu */
  menuList: {
    width: "100%",
    alignSelf: "center",
    flexGrow: 1,
    flexShrink: 1,
  },
  menuListContentContainer: {
    padding: 24,
    maxWidth: 700,
    width: "100%",
    alignSelf: "center",
  },

  absoluteFill: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
});

export default styles;
