import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { LayoutAnimation, Text, TouchableOpacity, View } from "react-native";
import { useSharedValue, withTiming } from "react-native-reanimated";
import DirectoryVersion from "../../../model/version/DirectoryVersion";
import Version from "../../../model/version/Version";
import { useColors } from "../../Colors";
import CollapseIcon from "../icon/CollapseIcon";
import Icon from "../icon/Icon";

const VersionSeparator = observer((props: { version: Version }) => {
  const { version } = props;
  const colors = useColors().comments.separator;
  const collapsed = useSharedValue(version.commentsVisible ? 0 : 1);

  useEffect(() => {
    collapsed.value = withTiming(version.commentsVisible ? 0 : 1, { duration: 200 });
  }, [version.commentsVisible]);

  return (
    <View style={{ zIndex: 10, paddingBottom: 8 }}>
      <TouchableOpacity
        onPress={() => {
          LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
          runInAction(() => (version.commentsVisible = !version.commentsVisible));
        }}
      >
        <View
          style={{
            backgroundColor: colors.background,
            paddingHorizontal: 10,
            paddingVertical: 8,
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {version instanceof DirectoryVersion ? (
            <Icon name="folder" size={16} color={colors.text} style={{ paddingRight: 9, marginLeft: -1 }} />
          ) : (
            <Icon name="file" size={16} color={colors.text} style={{ paddingRight: 8 }} />
          )}
          <Text style={{ color: colors.text }} numberOfLines={1} ellipsizeMode="middle">
            {version.name}
          </Text>
          <View style={{ flex: 1 }} />
          <CollapseIcon collapsed={collapsed} color={colors.icon} />
        </View>
      </TouchableOpacity>
    </View>
  );
});

export default VersionSeparator;
