import { Text, TouchableOpacity } from "react-native";
import { useColors } from '../../Colors';
import styles from "../../Styles";

const LoginButton = (props: { title: string, onPress?: () => void }) => {
    const colors = useColors().loginButton;
    return <>
        <TouchableOpacity
            onPress={props.onPress}
            style={[styles.loginInput, { backgroundColor: colors.background }]}
        >
            <Text style={[{ color: colors.text, fontWeight: '700' }]}>{props.title}</Text>
        </TouchableOpacity>
    </>;
};

export default LoginButton;
