import config from "../../config";
import { store, unsafeStore } from "../Store";
import { VFile } from "../file/VFile";
import { User } from "../user/User";
import { getUser } from "../user/getUser";
import { aesDecrypt, base64Decode, base64Encode, base64ToUrl, uuidToBase64 } from "../util/CryptoHelper";
import { Permissions, PermissionsData } from "./Permissions";

export interface ShareData {
  /** encrypted on the server using `from`s AES key */
  label?: string;
  from: User;
  createdAt: Date;
  permissions: Permissions;
}

export abstract class Share implements ShareData {
  id: string;
  label?: string;
  from: User;
  createdAt: Date;
  permissions: Permissions;

  /// Returns `true` if the share is `to` the current user.
  get isToMe(): boolean {
    return this instanceof UserShare && this.to === unsafeStore.me;
  }

  constructor(params: { id: string } & ShareData) {
    const { id, label, from, createdAt, permissions } = params;
    this.id = id;
    this.label = label;
    this.from = from;
    this.createdAt = createdAt;
    this.permissions = permissions;
  }
}

export interface UserShareData extends ShareData {
  to: User;
}

export class UserShare extends Share implements UserShareData {
  to: User;

  constructor(params: { id: string } & UserShareData) {
    const { id, label, from, to, createdAt, permissions } = params;
    super({ id, label, from, createdAt, permissions });
    this.to = to;
  }
}

export interface LinkShareData extends ShareData {
  encryptedShareKey?: ArrayBuffer;
  decryptedShareKey?: ArrayBuffer;
}

export class LinkShare extends Share implements LinkShareData {
  encryptedShareKey?: ArrayBuffer;
  decryptedShareKey?: ArrayBuffer;

  private _searchParams?: Promise<LinkShareSearchParams>;

  /**
   * @param file parent directory VFile
   * @returns Object to be used with `URLSearchParameters`
   */
  searchParams(file?: VFile): Promise<LinkShareSearchParams> {
    if (!this._searchParams) {
      this._searchParams = new Promise(async (resolve, reject) => {
        if (!this.decryptedShareKey) {
          if (!this.encryptedShareKey) {
            reject(new Error("share has no key"));
            return;
          }
          if (!file) {
            reject(new Error("shareKey was not decrypted and no parent file was provided for decrypting"));
            return;
          }
          this.decryptedShareKey = await aesDecrypt(file.encKey, this.encryptedShareKey, new ArrayBuffer(16));
        }
        resolve({
          s: uuidToBase64(this.id)!,
          key: base64ToUrl(base64Encode(this.decryptedShareKey)),
        });
      });
    }
    return this._searchParams;
  }

  async link(file: VFile) {
    return config.baseUrl + file.linkToUrlNoShare + new URLSearchParams(await this.searchParams(file));
  }

  constructor(params: { id: string } & LinkShareData) {
    const { id, label, from, encryptedShareKey, decryptedShareKey, createdAt, permissions } = params;
    super({ id, label, from, createdAt, permissions });
    this.encryptedShareKey = encryptedShareKey;
    this.decryptedShareKey = decryptedShareKey;
  }
}

export type LinkShareSearchParams = { s: string; key: string };

export async function ShareFromJson(data: ShareJson, params?: { decryptedShareKey?: ArrayBuffer }): Promise<Share> {
  const me = (await store).me;
  const from = getUser({ id: data.from });
  const label =
    me && data.label !== undefined && data.from === me.id
      ? aesDecrypt(me.keychain.shareLabelsKey, base64Decode(data.label), new ArrayBuffer(16)).then((v) =>
          Buffer.from(v).toString("utf-8")
        )
      : undefined;
  if (data.to) {
    const to = getUser({ id: data.to });
    return new UserShare({
      id: data.id,
      label: await label,
      from: await from,
      to: await to,
      createdAt: new Date(data.createdAt),
      permissions: new Permissions(data.permissions),
    });
  }
  if (data.shareKey) {
    return new LinkShare({
      id: data.id,
      from: await from,
      encryptedShareKey: base64Decode(data.shareKey),
      decryptedShareKey: params?.decryptedShareKey,
      createdAt: new Date(data.createdAt),
      permissions: new Permissions(data.permissions),
    });
  }
  throw new Error(`Share is neither a link nor an user share`, { cause: data });
}

export type ShareJson = {
  id: string;
  label?: string;
  from: string;
  to?: string;
  shareKey?: string;
  createdAt: string;
  permissions: PermissionsData;
};

export const shareGQLFields = `id, from, createdAt, permissions, shareKey`;
