import DirectoryVersion from "./DirectoryVersion";

export async function downloadDirectoryVersion(v: DirectoryVersion) {
  alert("Downloading directories is not supported yet");
  /*const files = await getFiles({ parentDir: v });

  for (const file of files) {
    try {
      const id = file.versionIds.at(file.selectedVersionNo);
      if (!id) continue;
      const v = getVersion({ id, file });
      if (v instanceof FileVersion) {
        const fe = await fetch(v._getS3Url("original"));
        if (fe.body === null) {
          console.warn(`failed to download ${v.name} (id: ${v.id})`);
          continue;
        }
        const s = fe.body.pipeThrough(decryptStream({ key: v.encKey, iv: new ArrayBuffer(16), size: v.size }));
      }
    } catch (e) {
      console.warn("Error while downloading directory: unable to download file", file, e);
    }
  }*/
}
