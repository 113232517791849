import { runInAction } from "mobx";
import React, { useContext, useRef, useState } from "react";
import { StyleProp, Text, TouchableOpacity, View, ViewStyle } from "react-native";
import { useHover } from "react-native-web-hooks";
import { useColors } from "../../Colors";
import Icon from "../icon/Icon";
import { ContextMenuAction } from "./ContextMenuAction";
import { ContextMenuStoreContext } from "./ContextMenuStore";

export default function ContextMenuActionComponent(props: {
  action: ContextMenuAction;
  style: StyleProp<ViewStyle>;
  spaceForIcon: boolean;
}) {
  const { action, style, spaceForIcon } = props;
  const [pressing, setPressing] = useState(false);
  const colors = useColors().contextMenu;
  const ref = useRef(null);
  const hover = useHover(ref);
  const s = useContext(ContextMenuStoreContext);
  const color = action.disabled ? colors.disabled : action.destructive ? colors.destructive : colors.text;
  return (
    <TouchableOpacity
      activeOpacity={1}
      onPress={() => {
        runInAction(() => s.reset());
        action.onPress?.();
      }}
      style={style}
      onPressIn={() => setPressing(true)}
      onPressOut={() => setPressing(false)}
      disabled={action.disabled}
    >
      <View
        ref={ref}
        style={[
          {
            padding: 8,
            paddingRight: 12,
            backgroundColor: pressing
              ? colors.accent
              : hover && !action.disabled
              ? colors.backgroundHover
              : colors.background,
            flexDirection: "row",
            alignItems: "center",
          },
          style,
        ]}
      >
        {(action.icon || spaceForIcon) && (
          <Icon
            name={action.icon || "add"}
            size={18}
            color={color}
            style={{ paddingRight: 8, marginVertical: -8, opacity: action.icon ? undefined : 0 }}
          />
        )}
        <Text style={{ color }}>{action.title}</Text>
      </View>
    </TouchableOpacity>
  );
}
