import { NavigationProp, StackActions, useNavigation } from "@react-navigation/native";
import { observer } from "mobx-react-lite";
import React, { ForwardedRef, forwardRef, useCallback, useContext, useEffect, useState } from "react";
import { Platform, TouchableOpacity, View } from "react-native";
import Animated, {
  SharedValue,
  interpolate,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from "react-native-reanimated";
import type { NavigateFunction } from "react-router";
import { VFile } from "../../../model/file/VFile";
import { deleteFile } from "../../../model/file/deleteFile";
import { FilesScreenStoreContext } from "../../../model/ui/FilesScreenStore";
import { useVersion } from "../../../model/version/useVersion";
import { useColors } from "../../Colors";
import ContextMenu from "../../components/context-menu/ContextMenu";
import { ContextMenuAction } from "../../components/context-menu/ContextMenuAction";
import RenameModal from "../../modals/rename/RenameModal";
import ShareModal from "../../modals/share/ShareModal";
import { useNavigate } from "../../navigation/routing";
import { interpolateColor } from "../../util/interpolateColor";
import FileCardLowerRow from "./FileCardLowerRow";
import FileCardMessageContent from "./FileCardMessageContent";
import FilePreview, { useGridCardInfo } from "./FilePreview";

export type FileCardInnerProps = {
  file: VFile;
  clicking: SharedValue<number>;
  isHoverContent: boolean;
  hoverDragging: SharedValue<number>;
  dragStartX: SharedValue<number>;
  dragStartY: SharedValue<number>;
  dragOver: SharedValue<number>;
  dragOverSide: SharedValue<number>;
  dragOverMiddle: SharedValue<number>;
  dragReceiveIsOver: SharedValue<number>;
  dragReceiveOverSide: SharedValue<number>;
  dragReceiveOverMiddle: SharedValue<number>;
};

const FileCardInner = observer(
  forwardRef((props: FileCardInnerProps, ref: ForwardedRef<View>) => {
    const {
      file,
      isHoverContent,
      hoverDragging,
      dragStartX,
      dragStartY,
      dragOver,
      dragOverSide,
      dragOverMiddle,
      dragReceiveIsOver,
      dragReceiveOverSide,
      dragReceiveOverMiddle,
    } = props;
    const fsStore = useContext(FilesScreenStoreContext);
    const colors = useColors().fileCard;
    const [renameVisible, setRenameVisible] = useState(false);
    const [shareVisible, setShareVisible] = useState(false);

    const borderRadius = 16;
    const selectedBorderWidth = 4;

    const navigate = Platform.OS === "web" ? useNavigate() : useNavigation();
    const onPress = useCallback(async () => {
      if (!fsStore.toggleSelectFile(file)) {
        Platform.OS === "web"
          ? (navigate as NavigateFunction)(await file.linkToUrl)
          : (navigate as NavigationProp<ReactNavigation.RootParamList>).dispatch(
              StackActions.push(file.navigateScreen, await file.navigationParams)
            );
      }
    }, [fsStore, file]);

    const { data: latestVersion } = useVersion(file, -1);
    // console.log("render FileCardInner", latestVersion?.name, file.versionIds.length);

    const { cardWidth, cardHeight } = useGridCardInfo();

    const selected = useSharedValue(0);
    useEffect(() => {
      selected.value = withTiming(fsStore.selectedFiles?.has(file) ? 1 : 0);
    }, [fsStore.selectedFiles?.has(file)]);

    const contextMenuActions = useContextMenuActions({ file, setRenameVisible, setShareVisible });

    // animated styles

    // styles the entire view
    const outerStyle = useAnimatedStyle(() => ({
      // when dragging this view over other component
      transform: [
        { translateX: hoverDragging.value * dragStartX.value },
        { translateY: hoverDragging.value * dragStartY.value },
        // { scale: interpolate(dragOver.value, [0, 1], [1, 0.45]) },
      ],
    }));
    const scaleStyle = useAnimatedStyle(() => ({
      // when dragging this view
      shadowRadius: interpolate(hoverDragging.value, [0, 1], [0, 40]),
      shadowOpacity: interpolate(hoverDragging.value, [0, 1], [0, 0.4]),
      borderRadius,
      padding: interpolate(selected.value, [0, 1], [selectedBorderWidth, 0]),
      // when dragging this view over other component
      transform: [
        { translateX: -cardWidth / 2 + interpolate(dragOver.value, [0, 1], [0.5 * cardWidth, 0.2 * cardWidth + 10]) },
        {
          translateY: -cardHeight / 2 + interpolate(dragOver.value, [0, 1], [0.5 * cardHeight, 0.1 * cardHeight + 10]),
        },
        { scale: interpolate(dragOver.value, [0, 1], [1, 0.45]) },
      ],
    }));
    const messageStyle = useAnimatedStyle(() => ({
      height: 20,
      transform: [
        { translateY: -cardHeight + interpolate(dragOver.value, [0, 1], [cardHeight, cardHeight * 0.1 + 20]) },
      ],
    }));

    const innerStyle = useAnimatedStyle(() => ({
      borderRadius,
      backgroundColor: colors.background,
      borderWidth: interpolate(selected.value, [0, 1], [0, selectedBorderWidth]),
      borderColor: interpolateColor(selected.value, [colors.transparent, colors.accent]),
    }));

    // styles the preview image
    const imageStyle = useAnimatedStyle(() => ({
      transform: [
        // translate based on position of other view dragging over this receiving view
        {
          translateX: interpolate(
            dragReceiveOverMiddle.value,
            [0, 1],
            [interpolate(dragReceiveOverSide.value, [0, 1], [0, -cardWidth / 4]), 0]
          ),
        },
        {
          translateY: interpolate(
            dragReceiveOverMiddle.value,
            [0, 1],
            [interpolate(dragReceiveIsOver.value, [0, 1], [0, -cardHeight * 0.1]), 0]
          ),
        },
        {
          // smaller if dragging other view over this receiving view
          scale: interpolate(
            dragReceiveIsOver.value,
            [0, 1],
            [1, interpolate(dragReceiveOverMiddle.value, [0, 1], [0.45, 0.6])]
          ),
        },
      ],
    }));

    if (latestVersion === undefined) return <></>;

    return (
      <View ref={ref}>
        <Animated.View style={[outerStyle]}>
          <Animated.View style={scaleStyle}>
            <TouchableOpacity activeOpacity={0.4} onPress={onPress}>
              <ContextMenu actions={contextMenuActions}>
                <Animated.View style={innerStyle}>
                  <Animated.View style={imageStyle}>
                    <FilePreview file={file} />
                  </Animated.View>
                  <FileCardLowerRow file={file} name={latestVersion.name} />
                </Animated.View>
              </ContextMenu>
            </TouchableOpacity>
          </Animated.View>
          {isHoverContent && (
            <Animated.View style={messageStyle}>
              <FileCardMessageContent
                file={file}
                dragOver={dragOver}
                dragOverSide={dragOverSide}
                dragOverMiddle={dragOverMiddle}
              />
            </Animated.View>
          )}
        </Animated.View>
        {/* modals */}
        <ShareModal file={file} visible={shareVisible} setVisible={setShareVisible} />
        <RenameModal version={latestVersion} visible={renameVisible} setVisible={setRenameVisible} />
      </View>
    );
  })
);

export default FileCardInner;

function useContextMenuActions(params: {
  file: VFile;
  setRenameVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setShareVisible: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const { file, setRenameVisible, setShareVisible } = params;
  const fsStore = useContext(FilesScreenStoreContext);

  const onDelete = useCallback(() => {
    console.log(`deleting file ${file.id}: ${file.unsafeLatestVersion?.name}`);
    deleteFile(file.id);
  }, [file]);

  let contextMenuActions: ContextMenuAction[] = [];
  if (fsStore.selectedFiles?.has(file)) {
    contextMenuActions = [
      ...contextMenuActions,
      {
        title: `Rename ${fsStore.selectedFiles.size} files`,
        icon: "edit",
        // TODO
      },
      {
        title: `Share ${fsStore.selectedFiles.size} files`,
        icon: "share",
        // TODO
      },
      {
        title: `Delete ${fsStore.selectedFiles.size} files`,
        icon: "delete",
        destructive: true,
        onPress: () => {
          for (const file of fsStore.selectedFiles ?? []) {
            console.log(`deleting file ${file.id}: ${file.unsafeLatestVersion?.name}`);
            deleteFile(file.id);
          }
          fsStore.toggleSelectMode();
        },
      },
    ];
  } else {
    if (!fsStore.selectedFiles)
      contextMenuActions.push({
        title: "Select",
        icon: "select",
        onPress: () => fsStore.toggleSelectFile(file),
      });
    contextMenuActions = [
      {
        title: "Rename",
        icon: "edit",
        onPress: () => setRenameVisible(true),
      },
      {
        title: "Share",
        icon: "share",
        onPress: () => setShareVisible(true),
      },
      {
        title: "Manage versions",
        icon: "manage-versions",
        // TODO
      },
      {
        title: "Delete",
        icon: "delete",
        destructive: true,
        onPress: onDelete,
      },
    ];
  }
  return contextMenuActions;
}
