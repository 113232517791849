import { Localized } from "@fluent/react";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect } from "react";
import { Platform, Text, View } from "react-native";
import useLoginRedirect from "../../../model/hooks/useLoginRedirect";
import { loginStore } from "../../../model/ui/LoginStore";
import { useColors } from "../../Colors";
import styles from "../../Styles";
import Navbar from "../../navigation/navbar/Navbar";
import { Link, useLocation, useNavigate, useSearchParams } from "../../navigation/routing";
import AuthInput from "./AuthInput";
import ColoredBars from "./ColoredBars";
import LoginButton from "./LoginButton";

const SignupScreen = observer((props: { navbar?: boolean }) => {
  const { navbar } = props;
  const colors = useColors().authScreen;
  const navbarColors = useColors().navBar;
  const navigate = useNavigate();
  const { displayName, username, password, confirmPassword, warnings, passwordScore } = loginStore;
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const loginUrl = `../login?` + searchParams;
  const isAddAccount = location.pathname === "/login" || location.pathname === "/signup";
  useLoginRedirect();
  useEffect(() => {
    loginStore.extendedChecks = true;
  }, []);

  const submit = useCallback(() => {
    loginStore
      .signup()
      .then(() => navigate(loginUrl))
      .catch(() => {});
  }, [loginStore, navigate]);

  return (
    <View style={{ flex: 1 }}>
      {Platform.OS !== "ios" && navbar && (
        <Navbar
          backTo={isAddAccount ? "./../.." : undefined}
          Left={() => <Text style={{ color: navbarColors.text, fontWeight: "500", fontSize: 16 }}>Signup</Text>}
        />
      )}
      <View style={styles.loginWrapper}>
        <Text style={[{ color: colors.text, fontSize: 32, fontWeight: "700", paddingBottom: 32 }]}>VidRe</Text>
        <Localized id="displayname" attrs={{ placeholder: true }}>
          <AuthInput
            autoComplete="name"
            autoCapitalize="words"
            autoCorrect={false}
            onChangeText={(s) => (loginStore.displayName = s)}
            value={displayName}
          />
        </Localized>
        <Localized id="username" attrs={{ placeholder: true }}>
          <AuthInput
            autoComplete="username-new"
            autoCapitalize="none"
            autoCorrect={false}
            onChangeText={(s) => (loginStore.username = s)}
            value={username}
          />
        </Localized>
        <Localized id="password" attrs={{ placeholder: true }}>
          <AuthInput
            autoComplete="new-password"
            autoCapitalize="none"
            autoCorrect={false}
            secureTextEntry
            onChangeText={(s) => (loginStore.password = s)}
            value={password}
          />
        </Localized>
        <Localized id="password-confirm" attrs={{ placeholder: true }}>
          <AuthInput
            autoComplete="new-password"
            autoCapitalize="none"
            autoCorrect={false}
            secureTextEntry
            onChangeText={(s) => (loginStore.confirmPassword = s)}
            onSubmitEditing={submit}
            value={confirmPassword}
          />
        </Localized>
        <ColoredBars
          style={{ width: "100%", height: 2, marginBottom: 10, marginTop: 5 }}
          barStyle={{ marginHorizontal: 1.5 }}
          n={passwordScore}
          colors={useColors().passwordBars}
        />
        <Localized id="signup" attrs={{ title: true }}>
          <LoginButton title="" onPress={submit} />
        </Localized>
        <Link to={loginUrl} style={{ margin: 8 }}>
          <Localized id="go-to-login" attrs={{ title: true }}>
            <Text style={{ color: colors.switchView }}></Text>
          </Localized>
        </Link>
        {[...new Array(5).keys()].map((v, i) => (
          <Text key={i} style={{ color: colors.error, textAlign: "center" }}>
            {[...warnings][v] ?? " "}
          </Text>
        ))}
        {[...warnings].slice(5).map((w, i) => (
          <Text key={i} style={{ color: colors.error, textAlign: "center" }}>
            {w}
          </Text>
        ))}
      </View>
    </View>
  );
});

export default SignupScreen;
