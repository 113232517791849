import * as Api from "../Api";
import { parseTranscodingServers } from "../transcoding-server/parseTranscodingServers";
import { aesDecrypt, base64Decode, importPrivkey, importPubkey, importRawKey } from "../util/CryptoHelper";
import { MeUser, MeUserJson, UserCiphertextJson, getKeychain, meUserGQLFields } from "./MeUser";
import { Token, TokenJson } from "./Token";

export async function loginFromSharedPreferences(data: MeUserSharedPrefsJson): Promise<MeUser> {
  const token = Token.fromJson(data.token);
  // prettier-ignore
  const userResponse: MeUserJson = (await Api.gql(`{
    user(id: "${data.id}") {
      ${meUserGQLFields}
    }
  }`)).user;
  // TODO refresh token

  const key = await importRawKey(base64Decode(data.key));
  const keychainPromise = getKeychain(key);
  const ciphertext: UserCiphertextJson = JSON.parse(
    Buffer.from(await aesDecrypt(key, base64Decode(userResponse.ciphertext), new ArrayBuffer(16))).toString("utf-8")
  );

  const keychain = await keychainPromise;
  const transcodingServers = await parseTranscodingServers(
    userResponse.transcodingServers,
    keychain.transcodingServersKey
  );

  if ((ciphertext.transcoding_servers?.length ?? 0) > 0 || ciphertext.transcoding_server) {
    const i = await import("../transcoding-server/migrateLegacyTranscodingServersFromCiphertext");
    i.migrateLegacyTranscodingServersFromCiphertext({ transcodingServers, ciphertext, token, key });
  }

  return new MeUser({
    id: userResponse.id,
    username: userResponse.username,
    displayName: userResponse.displayName,
    keychain,
    pubkey: await importPubkey(base64Decode(userResponse.pubkey)),
    privkey: await importPrivkey(base64Decode(ciphertext.privkey)),
    token,
    transcodingServers,
    alwaysImportShares: data.alwaysImportShares ?? null,
  });
}

export type MeUserSharedPrefsJson = {
  id: string;
  token: TokenJson;
  key: string;
  alwaysImportShares?: boolean;
};
