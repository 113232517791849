import { useMemo } from "react";
import { useSearchParams } from "../../ui/navigation/routing";
import { base64Decode, base64FromUrl, uuidFromBase64 } from "../util/CryptoHelper";

export function useShareFromUrl():
  | { share?: undefined; shareKey?: undefined }
  | { share: string; shareKey: ArrayBuffer } {
  const [searchParams] = useSearchParams();
  return useMemo(() => {
    try {
      const s = searchParams.get("s");
      if (!s) return {};
      const share = uuidFromBase64(s);
      if (!share) return {};
      const key = searchParams.get("key") ?? undefined;
      if (!key) return {};
      const shareKey = base64Decode(base64FromUrl(key));
      return { share, shareKey };
    } catch (e) {
      console.warn("Error while parsing share from search params");
      return {};
    }
  }, [searchParams.get("s"), searchParams.get("key")]);
}
