import { ReactNode } from "react";
import { Text } from "react-native";
import { TouchableHighlight } from "react-native-gesture-handler";
import { useColors } from "../../../Colors";

export default function MenuButton(
  props: { onPress: () => void | Promise<void> } & (
    | {
        title: string;
        color?: string;
      }
    | { children: ReactNode }
  )
) {
  const { onPress } = props;
  const colors = useColors().menu.item;
  return (
    <TouchableHighlight style={{ padding: 8, borderRadius: 4 }} onPress={onPress}>
      {"children" in props ? props.children : <Text style={{ color: props.color ?? colors.text }}>{props.title}</Text>}
    </TouchableHighlight>
  );
}
