import { observer } from "mobx-react-lite";
import { View } from "react-native";
import { VFile } from "../../../model/file/VFile";
import ImageVersion from "../../../model/version/ImageVersion";
import VideoVersion from "../../../model/version/VideoVersion";
import { useVersion } from "../../../model/version/useVersion";
import ErrorScreen from "../ErrorScreen";
import LoadScreen from "../LoadScreen";
import ImageVersionImage from "./image/ImageVersionImage";
import VideoView from "./video/VideoView";

const GalleryContent = observer((props: { file: VFile }) => {
  const { file } = props;
  const { data: version, status: versionStatus } = useVersion(file ?? undefined);

  switch (versionStatus) {
    case "fetching":
      return <LoadScreen msg="Loading file" />;
    case "error":
      return <ErrorScreen msg="Failed to load version" />;
  }
  if (version instanceof ImageVersion)
    return (
      <View style={{ height: "100%", width: "100%" }}>
        <ImageVersionImage version={version} />
      </View>
    );
  if (version instanceof VideoVersion) return <VideoView version={version} />;
  return <LoadScreen msg={`Unsupported file type: ${version.name}`} />;
});

export default GalleryContent;
