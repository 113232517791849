import { Localized } from "@fluent/react";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect } from "react";
import { Platform, Text, View } from "react-native";
import useLoginRedirect from "../../../model/hooks/useLoginRedirect";
import { loginStore } from "../../../model/ui/LoginStore";
import { useColors } from "../../Colors";
import styles from "../../Styles";
import Navbar from "../../navigation/navbar/Navbar";
import { Link, useLocation, useSearchParams } from "../../navigation/routing";
import AuthInput from "./AuthInput";
import LoginButton from "./LoginButton";

const LoginScreen = observer((props: { navbar?: boolean }) => {
  console.log("render login screen");
  const { navbar } = props;
  const colors = useColors().authScreen;
  const navbarColors = useColors().navBar;
  const { username, password, warnings } = loginStore;
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const signupUrl = `../signup?` + (searchParams ?? "");
  const isAddAccount = location.pathname === "/login" || location.pathname === "/signup";
  useLoginRedirect();
  useEffect(() => {
    loginStore.extendedChecks = false;
  }, []);

  const submit = useCallback(() => {
    loginStore.login().catch((e) => {});
  }, [loginStore]);

  return (
    <View style={{ flex: 1 }}>
      {Platform.OS !== "ios" && navbar && (
        <Navbar
          backTo={isAddAccount ? "./../.." : undefined}
          Left={() => <Text style={{ color: navbarColors.text, fontWeight: "500", fontSize: 16 }}>Login</Text>}
        />
      )}
      <View style={styles.loginWrapper}>
        <Text style={{ color: colors.text, fontSize: 32, fontWeight: "700", paddingBottom: 32 }}>VidRe</Text>
        <Localized id="username" attrs={{ placeholder: true }}>
          <AuthInput
            autoCapitalize="none"
            autoComplete="username"
            autoCorrect={false}
            onChangeText={(s) => (loginStore.username = s)}
            value={username}
          />
        </Localized>
        <Localized id="password" attrs={{ placeholder: true }}>
          <AuthInput
            autoCapitalize="none"
            autoComplete="password"
            autoCorrect={false}
            onChangeText={(s) => (loginStore.password = s)}
            value={password}
            onSubmitEditing={submit}
            secureTextEntry
          />
        </Localized>
        <Localized id="login" attrs={{ title: true }}>
          <LoginButton title="Login" onPress={submit} />
        </Localized>
        <Link to={signupUrl} replace style={{ margin: 8 }}>
          <Localized id="go-to-signup">
            <Text style={{ color: colors.accent }} />
          </Localized>
        </Link>

        {[...new Array(3).keys()].map((v, i) => (
          <Text key={i} style={{ color: colors.error, textAlign: "center" }}>
            {[...warnings].at(v) ?? " "}
          </Text>
        ))}
        {[...warnings].slice(3).map((w, i) => (
          <Text key={i} style={{ color: colors.error, textAlign: "center" }}>
            {w}
          </Text>
        ))}
      </View>
    </View>
  );
});

export default LoginScreen;
