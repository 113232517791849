import { observer } from "mobx-react-lite";
import { Text } from "react-native";
import { useTimecode } from "../../../../model/upload/util/formatTimecode";

const TimecodeText = observer((props: { millis: number | undefined; fps: number | undefined; color: string }) => {
  const { millis, fps, color } = props;
  const s = useTimecode(millis, fps);
  return (
    <>
      {s.split("").map((c, i) => (
        <Text
          key={`${i}`}
          style={{
            color,
            width: c === ":" ? 5 : 8,
            textAlign: "center",
            // @ts-ignore
            userSelect: "none",
          }}
        >
          {c}
        </Text>
      ))}
    </>
  );
});

export default TimecodeText;
