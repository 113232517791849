import { makeObservable, observable } from "mobx";

export class User {
  readonly id: string;
  username: string;
  displayName: string;
  pubkey: CryptoKey;

  // TODO: remove (hook)
  get color() {
    // return useColors().base.orange;
    return "#f70";
  }

  constructor(params: { id: string; username: string; displayName: string; pubkey: CryptoKey }) {
    this.id = params.id;
    this.username = params.username;
    this.displayName = params.displayName;
    this.pubkey = params.pubkey;
    makeObservable(this, {
      username: observable,
      displayName: observable,
    });
  }

  sortFilter(s: string): number {
    const f = (st: string): number => {
      const s = st.toLowerCase();
      const id = this.id.toLowerCase();
      const username = this.username.toLowerCase();
      const displayName = this.displayName.toLowerCase();
      let n = 0;
      if (s == id) n += 100;
      if (s == username) n += 10;
      if (s == displayName) n += 8;
      n += id.split(s).length > 1 ? 50 : 0;
      n += (username.split(s).length - 1) * 5;
      n += (displayName.split(s).length - 1) * 4;
      return n;
    };
    let n = 0;
    n += f(s) * 2;
    s.split(new RegExp("\\s+")).forEach((v) => v.length > 0 && (n += f(v)));
    console.debug("sortFilter", n, this);
    return n;
  }
}

export type UserJson = {
  id: string;
  username: string;
  displayName: string;
  pubkey: string;
};

export const userGQLFields = `
  id
  username
  displayName
  pubkey
`;
