import { GestureResponderEvent } from "react-native";
import { useColors } from "../../Colors";
import Icon from "../../components/icon/Icon";
import { IconName } from "../../components/icon/icons";
import NavbarButton from "./NavbarButton";

export default function NavbarIconButton(props: {
  name: IconName;
  onPress: (e: GestureResponderEvent) => void;
  left?: boolean;
  right?: boolean;
}) {
  const { name, onPress, left, right } = props;
  const colors = useColors().navBar;
  return (
    <NavbarButton onPress={onPress} left={left} right={right}>
      <Icon name={name} size={24} color={colors.icon} />
    </NavbarButton>
  );
}
