import { Localized } from "@fluent/react";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import React, { PropsWithChildren, useCallback, useContext } from "react";
import { Platform, Text, View, ViewProps } from "react-native";
import { StoreContext } from "../../../model/Store";
import { VideoStoreContext } from "../../../model/ui/VideoStore";
import DirectoryVersion from "../../../model/version/DirectoryVersion";
import ImageVersion from "../../../model/version/ImageVersion";
import Version from "../../../model/version/Version";
import VideoVersion from "../../../model/version/VideoVersion";
import { useColors } from "../../Colors";
import styles from "../../Styles";
import { mapReactChildren } from "../../util/mapReactChildren";
import ButtonBox from "./ButtonBox";
import ButtonWrapper from "./ButtonWrapper";
import NewCommentTimecodeButton from "./NewCommentTimecodeButton";

const CommentContextToggle = observer(
  (props: {
    commentContext: Version | null;
    showingDirGallery: boolean;
    commentContextIsDir: boolean;
    toggleCommentContext: () => void;
  }) => {
    const { commentContext, commentContextIsDir, showingDirGallery, toggleCommentContext } = props;
    const _store = useContext(StoreContext);
    const vStore = useContext(VideoStoreContext);
    const wipComment = commentContext?.wipComment;
    const colors = useColors().commentContextToggle;

    const timecodeColor =
      (_store.commentLinkTimecode && vStore.status?.isLoaded) || typeof wipComment?.startTimecode === "number"
        ? colors.accent
        : colors.disabled;

    const toggleIncludeTimecode = useCallback(() => {
      runInAction(() => {
        _store.commentLinkTimecode = !_store.commentLinkTimecode;
      });
    }, [_store]);

    return showingDirGallery || commentContext instanceof VideoVersion || true ? (
      <Localized
        id="comment-context-toggle"
        vars={{
          showingDirGallery: `${showingDirGallery}`,
          type: `${
            commentContextIsDir && commentContext instanceof DirectoryVersion
              ? "directory"
              : commentContext instanceof VideoVersion
              ? "video"
              : commentContext instanceof ImageVersion
              ? "image"
              : "file"
          }`,
        }}
        elems={{
          ContextButton: <ButtonWrapper onPress={toggleCommentContext} textStyle={{ color: colors.text }} />,
          ContextBox: <ContextBox />,
          TimecodeButton: <ButtonWrapper onPress={toggleIncludeTimecode} textStyle={{ color: colors.text }} />,
          TimecodeBox: <NewCommentTimecodeButton wipComment={wipComment} color={timecodeColor} />,
        }}
        nestedElems
      >
        <ViewWithText
          style={[
            styles.row,
            // @ts-ignore
            Platform.OS === "web" ? { userSelect: "none", webkitUserSelect: "none" } : {},
          ]}
        />
      </Localized>
    ) : (
      <></>
    );
  }
);

function ContextBox(props: PropsWithChildren) {
  const { children } = props;
  const colors = useColors().commentContextToggle;
  return (
    <ButtonBox>
      <Text style={{ color: colors.accent }}>{children}</Text>
    </ButtonBox>
  );
}

export function ViewWithText(props: PropsWithChildren<ViewProps & { color: string }>) {
  const children = mapReactChildren({
    children: props.children,
    map: (e) => (e.type === "string" ? <Text style={{ color: props.color }}>{e}</Text> : e),
  });
  return <View {...props}>{children}</View>;
}

export default CommentContextToggle;
