import { observer } from "mobx-react-lite";
import { useRef, useState } from "react";
import { GestureResponderEvent, Text, TouchableOpacity, View } from "react-native";
import { useHover } from "react-native-web-hooks";
import Version from "../../../model/version/Version";
import { useColors } from "../../Colors";

const VersionSelectorMenuItem = observer(
  (props: { no: number; onPress: (event: GestureResponderEvent) => void; version?: Version }) => {
    const { no, onPress, version } = props;
    const colors = useColors().versionSelectorMenu;
    const [pressing, setPressing] = useState(false);
    const badgeColors = useColors().versionBadge;
    const ref = useRef(null);
    const hover = useHover(ref);
    return (
      <TouchableOpacity
        activeOpacity={1}
        onPress={onPress}
        onPressIn={() => setPressing(true)}
        onPressOut={() => setPressing(false)}
      >
        <View
          ref={ref}
          style={{
            backgroundColor: pressing ? colors.accent : hover ? colors.hover : undefined,
            padding: 8,
            flexDirection: "row",
          }}
        >
          <Text numberOfLines={1} style={{ color: badgeColors.text, fontWeight: "600" }}>
            V{no}
          </Text>
          {version?.name !== undefined ? (
            <Text numberOfLines={1} style={{ color: colors.text }}>
              : {version.name}
            </Text>
          ) : (
            <View style={{ width: 150, height: 14, backgroundColor: colors.textDummy }} />
          )}
        </View>
      </TouchableOpacity>
    );
  }
);

export default VersionSelectorMenuItem;
