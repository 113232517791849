import { useCallback, useState } from "react";
import config from "../../config";
import { Cacheable } from "./Cacheable";
import { CacheableResult, newCacheableResult } from "./CacheableResult";
import { useRefetchEffect } from "./useRefetchEffect";

export function useCacheable<T extends Cacheable | null>(params: {
  fetch: () => Promise<T>;
  update: (data: T) => Promise<void>;
  data?: T;
  enabled: boolean;
  /** The minimum time between subsequent refetches in milliseconds */
  staleTime?: number;
}): CacheableResult<T> {
  const { fetch, update, data, enabled } = params;
  const staleTime = params.staleTime ?? config.staleTime;
  const [error, setError] = useState<unknown>();

  const refetch = useCallback(async () => {
    if (enabled ?? true) {
      if (data === undefined) {
        await fetch()
          .then(() => setError(undefined))
          .catch(setError);
      } else if (data !== null) {
        data.lastUpdatedAt = Date.now();
        await update(data)
          .then(() => setError(undefined))
          .catch(setError);
        data.lastUpdatedAt = Date.now();
      }
    }
  }, [enabled, fetch, update]);

  const fetching = useRefetchEffect(refetch, data?.lastUpdatedAt, staleTime);

  return newCacheableResult({ data, error, refetch });
}
