import { PropsWithChildren } from "react";
import { VFile } from "../../model/file/VFile";
import { useMaybePromise } from "../../model/hooks/useMaybePromise";
import { Link } from "../navigation/routing";

export default function FileLink(props: PropsWithChildren<{ file: VFile | null }>) {
  const { file, children } = props;
  const url = useFileLink(file);
  return <Link to={url}>{children}</Link>;
}

export function useFileLink(file: VFile | null) {
  return useMaybePromise(file?.linkToUrl ?? "/") ?? file?.linkToUrlNoShare ?? "/";
}
