import * as Api from "../Api";
import { store } from "../Store";
import { ShareFromJson, ShareJson, shareGQLFields } from "./Share";

export async function fetchShares(file: string) {
  const me = (await store).me;
  // prettier-ignore
  const r: (ShareJson)[] = (await Api.gql(`{
    shares(filter: {file: ${JSON.stringify(file)}}) {
      ${shareGQLFields}
      to
    }
  }`, me ? `Bearer ${me.token.t}`: undefined)).shares;
  return r.map((v) => ShareFromJson(v));
}
