import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { VFile } from "../../../model/file/VFile";
import NavbarIconButton from "../../navigation/navbar/NavbarIconButton";
import ShareModal from "./ShareModal";

const ShareButton = observer((props: { file: VFile | undefined }) => {
  const { file } = props;
  const [visible, setVisible] = useState(false);
  return (
    <>
      <NavbarIconButton name="share" onPress={() => setVisible(!visible)} />
      {file && <ShareModal file={file} visible={visible} setVisible={setVisible} />}
    </>
  );
});

export default ShareButton;
