import { observer } from "mobx-react-lite";
import React, { useId, useRef, useState } from "react";
import { Platform } from "react-native";
import Svg, { ClipPath, Defs, Image, Rect } from "react-native-svg";
import { useMaybePromise } from "../../../model/hooks/useMaybePromise";
import VideoVersion from "../../../model/version/VideoVersion";
import { useColors } from "../../Colors";
import styles from "../../Styles";

const VideoPreview = observer((props: { version: VideoVersion }) => {
  const { version } = props;
  const colors = useColors().fileCard;
  const previewUri = useMaybePromise(version.previewUrl);
  const [previewIndex, setPreviewIndex] = useState(5);
  const height = version.aspectRatio > 16 / 9 ? 160 / version.aspectRatio : 90;
  const top = (90 - height) / 2;
  const divRef = useRef<any>(null);
  const id = useId();

  return (
    <div
      ref={divRef}
      onMouseMove={(e) => {
        if (divRef.current !== null)
          setPreviewIndex(Math.round((e.nativeEvent.offsetX / divRef.current.clientWidth) * 99));
      }}
      onMouseLeave={(_e) => {
        setPreviewIndex(5);
      }}
    >
      <Svg viewBox="0 0 160 90" style={[styles.fileCardImage]}>
        <Defs>
          <ClipPath id={id}>
            <Rect x={0} y={top} width={160} height={height} />
          </ClipPath>
        </Defs>
        <Rect x={0} y={0} width="100%" height="100%" fill={colors.imageBackground} />
        {previewUri !== undefined && (
          <Image
            x={0}
            y={-previewIndex * height + top}
            width={160}
            height={height * 100}
            href={Platform.OS === "web" ? previewUri : { uri: previewUri }}
            clipPath={`url(#${id})`}
          />
        )}
      </Svg>
    </div>
  );
});

export default VideoPreview;
