import { observer } from "mobx-react-lite";
import { useCallback, useContext, useEffect, useState } from "react";
import { View } from "react-native";
import { DraxList, DraxProvider } from "react-native-drax";
import config from "../../../config";
import { StoreContext } from "../../../model/Store";
import TranscodingServer, { getTranscodingServer } from "../../../model/transcoding-server/TranscodingServer";
import { addTranscodingServer } from "../../../model/transcoding-server/addTranscodingServer";
import { useColors } from "../../Colors";
import styles from "../../Styles";
import Divider from "../../components/Divider";
import MenuScreen from "../../components/menu/MenuScreen";
import TranscodingServerModal from "../../modals/transcoding-server/TranscodingServerModal";
import NavbarIconButton from "../../navigation/navbar/NavbarIconButton";
import LoadScreen from "../LoadScreen";
import TranscodingServerListItem from "./TranscodingServerListItem";

const TranscodingServersSettingsScreen = observer(() => {
  const _store = useContext(StoreContext);
  const _me = _store.me;
  const data = _me?.transcodingServers.slice();
  useEffect(() => {
    if (data && data.length < 1 && _me)
      _me.transcodingServers.push(getTranscodingServer(config.defaultTranscodingServer));
  }, [data, _me]);
  const colors = useColors().menu;
  if (!_me) return <LoadScreen msg="Loading user" />;
  return (
    <DraxProvider>
      <MenuScreen title="Transcoding Servers" navbarRight={AddTranscodingServerButton}>
        <DraxList
          style={styles.menuList}
          contentContainerStyle={styles.menuListContentContainer}
          data={data}
          lockItemDragsToMainAxis
          keyExtractor={(item) => item.url}
          ItemSeparatorComponent={() => (
            <View style={{ height: 0 }}>
              <Divider color={colors.divider} />
            </View>
          )}
          onItemReorder={({ fromIndex, fromItem, toIndex, toItem }) => {
            _me.transcodingServers.splice(toIndex, 0, _me.transcodingServers.splice(fromIndex, 1)[0]);
            addTranscodingServer(fromItem, toIndex);
          }}
          renderItemContent={({ item }) => <TranscodingServerListItem data={item} />}
        />
      </MenuScreen>
    </DraxProvider>
  );
});

export default TranscodingServersSettingsScreen;

function AddTranscodingServerButton() {
  const _store = useContext(StoreContext);
  const _me = _store.me;
  const [visible, setVisible] = useState(false);
  const [name, setName] = useState("");
  const [url, setUrl] = useState("");
  const onSubmit = useCallback(() => {
    const tcs = new TranscodingServer({ name, url });
    _me?.transcodingServers.push(tcs);
    addTranscodingServer(tcs);
  }, [name, url, _me]);
  return (
    <>
      <NavbarIconButton right name="add" onPress={() => setVisible(!visible)} />
      <TranscodingServerModal
        title="Add Transcoding Server"
        name={name}
        setName={setName}
        url={url}
        setUrl={setUrl}
        visible={visible}
        setVisible={setVisible}
        onSubmit={onSubmit}
      />
    </>
  );
}
