import * as Api from "../Api";
import BinaryVersion from "./BinaryVersion";
import DirectoryVersion, { DirectoryVersionJson } from "./DirectoryVersion";
import { FileVersionJson } from "./FileVersion";
import ImageVersion from "./ImageVersion";
import Version, { VersionJson, versionGQLFields } from "./Version";
import VideoVersion from "./VideoVersion";

export async function updateVersion(v: Version) {
  // prettier-ignore
  const r: VersionJson = (await Api.gql(`{
    version(id: "${v.id}") {
      ${versionGQLFields}
    }
  }`)).version;
  const file = v.file;
  if (v instanceof BinaryVersion) {
    await BinaryVersion.fromJson(r as FileVersionJson, { file });
  } else if (v instanceof DirectoryVersion) {
    await DirectoryVersion.fromJson(r as DirectoryVersionJson, { file });
  } else if (v instanceof ImageVersion) {
    await ImageVersion.fromJson(r as FileVersionJson, { file });
  } else if (v instanceof VideoVersion) {
    await VideoVersion.fromJson(r as FileVersionJson, { file });
  }
}
