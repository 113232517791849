import { useContext } from "react";
import { StoreContext } from "../../Store";

export type TimecodeFormat = "short" | "timecode" | "frames";

export function formatTimecode(millis: number | undefined, fps: number | undefined, format: TimecodeFormat): string {
  if (millis === undefined || fps === undefined) {
    switch (format) {
      case "short":
        return "--:--";
      case "timecode":
        return "--:--:--:--";
      case "frames":
        return "---";
    }
  }
  const d = new Date(millis);
  switch (format) {
    case "short":
      return String(d.getUTCMinutes()).padStart(2, "0") + ":" + String(d.getUTCSeconds()).padStart(2, "0");
    case "timecode":
      return (
        String(d.getUTCHours()).padStart(2, "0") +
        ":" +
        String(d.getUTCMinutes()).padStart(2, "0") +
        ":" +
        String(d.getUTCSeconds()).padStart(2, "0") +
        ":" +
        String(Math.floor((d.getUTCMilliseconds() * fps) / 1000)).padStart(2, "0")
      );
    case "frames":
      return String(Math.floor((millis * fps) / 1000));
  }
}

export function useTimecode(millis: number | undefined, fps: number | undefined) {
  const _store = useContext(StoreContext);
  return formatTimecode(millis, fps, _store.timecodeFormat);
}
