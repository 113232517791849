import * as FileSystem from "expo-file-system";
import { FileType } from "../file/VFile";

export async function ensureDirExists(path: string) {
  const dirInfo = await FileSystem.getInfoAsync(path);
  if (!dirInfo.exists) {
    console.log('Directory "' + path + '" does not exist, creating...');
    await FileSystem.makeDirectoryAsync(path, { intermediates: true });
  }
}

export function mimeToFileType(type: Blob["type"]): FileType | undefined {
  if (type.startsWith("image")) return "IMAGE";
  if (type.startsWith("video")) return "VIDEO";
}

const videoExtensions = ["mov", "mp4"];
const imageExtensions = ["jpg", "jpeg", "png"];

export function fileExtensionToFileType(name: string): FileType {
  for (const ext of videoExtensions) {
    if (name.endsWith("." + ext)) return "VIDEO";
  }
  for (const ext of imageExtensions) {
    if (name.endsWith("." + ext)) return "IMAGE";
  }

  return "BINARY";
}
