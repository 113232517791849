import streamSaver from "streamsaver";
import { decryptStream } from "../util/CryptoHelper";
import FileVersion from "./FileVersion";

export async function downloadFileVersion(v: FileVersion): Promise<void> {
  const f = (await v.getS3("original")).fetch;
  if (f.body === null) {
    console.warn(`failed to download ${v.name} (id: ${v.id})`);
    return;
  }
  const fileStream = streamSaver.createWriteStream(v.name, {
    size: v.size,
  });
  f.body.pipeThrough(decryptStream({ key: v.encKey, iv: new ArrayBuffer(16), size: v.size })).pipeTo(fileStream);
}
