import React, { forwardRef, useEffect, useState } from "react";
import { View } from "react-native";
import {
  Link as LinkBase,
  LinkProps,
  Route,
  Router,
  Routes,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useColors } from "../Colors";

const Link = forwardRef(
  (props: LinkProps & { highlight?: boolean; opacity?: boolean }, ref: React.Ref<HTMLAnchorElement>) => {
    const colors = useColors();
    const [clicking, setClicking] = useState(false);
    useEffect(() => setClicking(false), []);
    return (
      <LinkBase
        {...props}
        ref={ref}
        draggable={props.draggable ?? false}
        style={{
          userSelect: "none",
          ...props.style,
          backgroundColor: clicking && props.highlight ? colors.black : props.style?.backgroundColor,
        }}
        onTouchStart={(event) => {
          setClicking(true);
          props.onTouchStart?.(event);
        }}
        onMouseDown={(event) => {
          setClicking(true);
          props.onMouseDown?.(event);
        }}
        onTouchEnd={(event) => {
          setClicking(false);
          props.onTouchEnd?.(event);
        }}
        onMouseLeave={(event) => {
          setClicking(false);
          props.onMouseLeave?.(event);
        }}
      >
        <View style={{ opacity: clicking && props.opacity !== false ? (props.highlight ? 0.85 : 0.2) : undefined }}>
          {props.children}
        </View>
      </LinkBase>
    );
  }
);

export { Link, Route, Router, Routes, useLocation, useNavigate, useParams, useSearchParams };
