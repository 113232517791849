import { AVPlaybackStatus, Video } from "expo-av";
import * as FileSystem from "expo-file-system";
import { runInAction } from "mobx";
import { createRef } from "react";
import { store, unsafeStore } from "../Store";
import { cacheFile } from "../cacheFile";
import { VFile } from "../file/VFile";
import { aesDecrypt, exportRawKey, importRawKey } from "../util/CryptoHelper";
import { ensureDirExists } from "../util/fs";
import FileVersion, { FileVersionCiphertextJson, FileVersionJson, FileVersionParams } from "./FileVersion";
import { versionParamsFromJson } from "./Version";
import { VersionState } from "./VersionState";

export default class VideoVersion extends FileVersion {
  aspectRatio: number;
  fps: number;

  get ciphertext(): VideoVersionCiphertextJson {
    return {
      ...super.ciphertext,
      aspect_ratio: this.aspectRatio,
      fps: this.fps,
    };
  }

  get previewUrl() {
    if (this._previewUrl === undefined) this._previewUrl = this.createPreviewUrl();
    return this._previewUrl;
  }
  private _previewUrl: Promise<string> | undefined;

  video = createRef<Video>();
  videoStatus: AVPlaybackStatus | null = null;

  constructor(params: {
    id: string;
    file: VFile;
    name: string;
    originalMimeType: string;
    aspectRatio: number;
    size: number;
    fps: number;
    encKey: CryptoKey;
    state: VersionState;
    createdAt: Date;
  }) {
    const { id, file, name, originalMimeType, aspectRatio, size, fps, encKey, state, createdAt } = params;
    super({
      id,
      file,
      name,
      originalMimeType,
      size,
      encKey,
      state,
      createdAt,
    });
    this.aspectRatio = aspectRatio;
    this.fps = fps;
  }

  static async fromJson(json: FileVersionJson, params: { file: VFile }): Promise<VideoVersion> {
    const { id, ciphertext, encKey, state, createdAt } = (await versionParamsFromJson(
      json,
      params
    )) as FileVersionParams & {
      ciphertext: VideoVersionCiphertextJson;
    };
    const _store = await store;
    const v = _store.versions.get(id) as VideoVersion;
    if (v) {
      return runInAction(() => {
        console.assert(v instanceof VideoVersion);
        console.assert(v.id === id);
        console.assert(v.file === params.file);
        v.size = json.size;
        v.encKey = encKey;
        // TODO: update state
        console.assert(v.createdAt.valueOf() === createdAt.valueOf());
        v.name = ciphertext.name;
        v.originalMimeType = ciphertext.original_mime_type;
        v.aspectRatio = ciphertext.aspect_ratio;
        v.fps = ciphertext.fps;
        return v;
      });
    }
    return runInAction(() => {
      const v = new VideoVersion({
        id,
        file: params.file,
        size: json.size!,
        encKey,
        state,
        createdAt,
        name: ciphertext.name,
        originalMimeType: ciphertext.original_mime_type,
        aspectRatio: ciphertext.aspect_ratio,
        fps: ciphertext.fps,
      });
      _store.versions.set(v.id, v);
      return v;
    });
  }

  private createPreviewUrl = async () => {
    return cacheFile(
      `versions/${this.id}/preview.jpg`,
      async () =>
        new Response(
          await aesDecrypt(
            await importRawKey((await exportRawKey(this.encKey)).slice(0, 16)),
            await (await this.getS3("preview.jpg")).fetch.arrayBuffer(),
            new Uint8Array(16)
          )
        )
    );
  };

  filepath = async (filename: string) => {
    let url = FileSystem.documentDirectory + "users/";
    await ensureDirExists(url);
    url += unsafeStore.me?.id + "/";
    await ensureDirExists(url);
    url += "versions/";
    await ensureDirExists(url);
    url += this.id + "/";
    await ensureDirExists(url);
    return url + filename;
  };
}

export type VideoVersionCiphertextJson = FileVersionCiphertextJson & {
  aspect_ratio: number;
  fps: number;
};
