import { makeObservable, observable, ObservableSet, runInAction } from "mobx";
import { LayoutAnimation } from "react-native";
import { FileType, VFile, VFileDirectory } from "../file/VFile";
import { store, unsafeStore } from "../Store";
import Version, { VersionCiphertextJson, VersionJson, versionParamsFromJson } from "./Version";

export default class DirectoryVersion extends Version {
  declare file: VFileDirectory;

  /** Set of all children's `FileType`s */
  fileTypes: ObservableSet<FileType>;
  /** IDs of all files in the directory. Lazily loaded. */
  childrenIds = observable.array<string>();
  /** Not always required to equal `childrenIds.length` */
  numberOfChildren: number;

  private _selectedFile: VFile | null = null;
  get selectedFile() {
    return this._selectedFile;
  }
  set selectedFile(v: VFile | null) {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
    this._selectedFile = v;
  }

  constructor(params: {
    id: string;
    file: VFile;
    name: string;
    encKey: CryptoKey;
    createdAt: Date;
    fileTypes: Set<FileType>;
    numberOfChildren: number;
    childrenIds?: string[];
  }) {
    const { id, name, file, encKey, createdAt, fileTypes, numberOfChildren, childrenIds } = params;
    super({ id, name, file, encKey, createdAt });
    this.fileTypes = observable.set(fileTypes);
    this.numberOfChildren = numberOfChildren;
    this.childrenIds = observable.array(childrenIds);
    makeObservable(this, {
      fileTypes: true,
      childrenIds: true,
      // @ts-ignore
      _selectedFile: true,
      selectedFile: true,
      numberOfChildren: true,
    });
    let v = unsafeStore.versions.get(id);
    if (v) console.warn("constructing new DirectoryVersion with id that already exists", this);
  }

  static async fromJson(json: DirectoryVersionJson, params: { file: VFile }): Promise<DirectoryVersion> {
    const { id, ciphertext, encKey, createdAt } = await versionParamsFromJson(json, params);
    const v = (await store).versions.get(id) as DirectoryVersion;
    if (v) {
      return runInAction(() => {
        console.assert(v instanceof DirectoryVersion, "DirectoryVersion.fromJson: v instanceof DirectoryVersion");
        console.assert(v.id === id, "DirectoryVersion.fromJson: v.id === id");
        console.assert(v.file === params.file, "DirectoryVersion.fromJson: v.file === params.file");
        v.encKey = encKey;
        console.assert(
          v.createdAt.valueOf() === createdAt.valueOf(),
          "DirectoryVersion.fromJson: v.createdAt.valueOf() === createdAt.valueOf()"
        );
        v.name = ciphertext.name;
        v.fileTypes.replace(json.fileTypes);
        v.numberOfChildren = json.numberOfChildren;
        return v;
      });
    }
    const r = new DirectoryVersion({
      id,
      file: params.file,
      encKey,
      createdAt,
      name: ciphertext.name,
      fileTypes: observable.set(json.fileTypes),
      numberOfChildren: json.numberOfChildren,
      childrenIds: json.childrenIds,
    });
    unsafeStore.versions.set(id, r);
    return r;
  }
}

export type DirectoryVersionJson = VersionJson & {
  fileTypes: FileType[];
  numberOfChildren: number;
  childrenIds?: string[];
};

export type DirectoryVersionCiphertextJson = VersionCiphertextJson;
