import { PropsWithChildren } from "react";
import { Platform, Text, View } from "react-native";
import type { To } from "react-router";
import { useColors } from "../../../Colors";
import { Link } from "../../../navigation/routing";
import Icon from "../../icon/Icon";

export default function MenuLink(props: PropsWithChildren<{ title: string; to: To; state?: any }>) {
  const { children, title, to, state } = props;
  const colors = useColors().menu.item;
  return (
    <Link highlight to={to} state={state} style={{ borderRadius: 4 }}>
      {children ?? (
        <View style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
          <Text style={{ color: colors.text, padding: 8 }}>{title}</Text>
          <Icon
            name="chevron-right"
            color={colors.chevron}
            size={Platform.OS === "ios" ? 14 : 20}
            style={{ paddingHorizontal: 3 }}
          />
        </View>
      )}
    </Link>
  );
}
