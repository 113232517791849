import { useLocalization } from "@fluent/react";
import { StackActions, useNavigation } from "@react-navigation/native";
import { PointerInteractionView } from "@thefunbots/react-native-pointer-interactions";
import { observer } from "mobx-react-lite";
import { useContext, useState } from "react";
import { Platform, Text, TouchableOpacity, View } from "react-native";
import { SharedValue } from "react-native-reanimated";
import { StoreContext } from "../../../model/Store";
import { VFileDirectory } from "../../../model/file/VFile";
import { FilesScreenStoreContext } from "../../../model/ui/FilesScreenStore";
import { FileSortStrategy } from "../../../model/util/useSortedFiles";
import { useColors } from "../../Colors";
import FileLink from "../../components/FileLink";
import SortDropdown from "../../components/SortDropdown";
import UploadButton from "../../components/button/UploadButton";
import Icon from "../../components/icon/Icon";
import UploadManager from "../../components/upload-manager/UploadManager";
import ShareButton from "../../modals/share/ShareButton";
import Navbar from "../../navigation/navbar/Navbar";
import NavbarIconButton from "../../navigation/navbar/NavbarIconButton";
import NavbarUserButton from "../../navigation/navbar/NavbarUserButton";
import { Link } from "../../navigation/routing";
import FileName from "../../util/FileName";
import CommentsToggle from "../preview/CommentsToggle";

const FilesScreenNavbar = observer(
  (props: {
    directory: VFileDirectory | null | undefined;
    commentsVisible: Readonly<SharedValue<number>>;
    toggleComments: () => void;
  }) => {
    const { directory, commentsVisible, toggleComments } = props;
    const fsStore = useContext(FilesScreenStoreContext);
    const _store = useContext(StoreContext);
    const colors = useColors().navBar;
    const { l10n } = useLocalization();
    const [uploadManagerVisible, setUploadManagerVisible] = useState(false);

    const navigation = Platform.OS === "web" ? undefined : useNavigation();

    return (
      <Navbar
        hideBackButton
        Left={() => (
          <>
            {Platform.OS === "web" ? (
              <Link to="/" key="home">
                <Icon name="home" size={20} color={colors.text} />
              </Link>
            ) : (
              <TouchableOpacity onPress={() => navigation?.dispatch(StackActions.popToTop())}>
                <Icon name="home" size={20} color={colors.text} />
              </TouchableOpacity>
            )}
            <Text style={{ color: colors.text, fontWeight: "500" }} key={"home/"} numberOfLines={1}>
              {" / "}
            </Text>
            {(directory?.path ?? []).map((f, i, a) => {
              return [
                Platform.OS === "web" ? (
                  <FileLink file={f} key={f.id}>
                    <FileName file={f} style={{ color: colors.text, fontWeight: "600" }} numberOfLines={1} />
                  </FileLink>
                ) : (
                  <TouchableOpacity onPress={() => navigation?.dispatch(StackActions.pop(a.length - i - 1))}>
                    <FileName file={f} style={{ color: colors.text, fontWeight: "600" }} numberOfLines={1} />
                  </TouchableOpacity>
                ),
                <Text style={{ color: colors.text, fontWeight: "500" }} key={f.id + "/"} numberOfLines={1}>
                  {" / "}
                </Text>,
              ];
            })}

            {/* upload */}
            <UploadButton directory={directory} />
            {/* share */}
            {directory?.showShareMenu && <ShareButton file={directory} />}
          </>
        )}
        Right={observer(() => (
          <>
            {/* sort */}
            <SortDropdown<FileSortStrategy>
              color={colors.text}
              value={_store.fileSortStrategy}
              setValue={(v) => (_store.fileSortStrategy = v)}
              options={[
                ["name_asc", "name_desc"],
                ["date_updated_asc", "date_updated_desc"],
                ["date_uploaded_asc", "date_uploaded_desc"],
              ]}
              getTitle={(s) => l10n.getString("sort-files-" + s.replace(/(_asc|_desc)$/, "").replaceAll(/_/g, "-"))}
            />
            {/* select */}
            <NavbarIconButton name="select" onPress={() => fsStore.toggleSelectMode()} />
            {/* upload manager */}
            {_store.uploads.size > 0 && (
              <View>
                <PointerInteractionView pointerMode="highlight">
                  <TouchableOpacity onPress={() => setUploadManagerVisible(!uploadManagerVisible)}>
                    <Icon
                      name="upload-manager"
                      size={24}
                      color={colors.icon}
                      style={{ padding: 3, marginHorizontal: 11 }}
                    />
                  </TouchableOpacity>
                </PointerInteractionView>
                <View style={{ display: uploadManagerVisible ? "flex" : "none" }}>
                  <View style={{ position: "absolute", right: 0, top: 0 }}>
                    <UploadManager />
                  </View>
                </View>
              </View>
            )}
            {/* comments */}
            <CommentsToggle toggleComments={toggleComments} commentsVisible={commentsVisible} />
            {/* settings */}
            <NavbarUserButton />
          </>
        ))}
      />
    );
  }
);

export default FilesScreenNavbar;
