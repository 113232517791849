import { useCallback, useState } from "react";
import { StyleProp, View, ViewStyle } from "react-native";
import SaveStatusIndicator, { SaveStatus } from "../../SaveStatusIndicator";
import Input from "../../input/Input";

export type StringValueAndSet = { value: string | undefined; setValue: (s: string) => void };
export type MenuInputPropsBase = {
  title: string;
  autoCapitalize?: "none" | "sentences" | "words" | "characters";
  autoCorrect?: boolean;
  secureTextEntry?: boolean;
};
export type MenuInputPropsWithSave = MenuInputPropsBase & { save: () => Promise<void> };
export type MenuInputProps = (MenuInputPropsBase | MenuInputPropsWithSave) &
  ({ initialValue?: string } | StringValueAndSet);

export default function MenuInput(props: MenuInputProps) {
  return "value" in props ? <MenuInputInner {...props} /> : <MenuInputInternalState {...props} />;
}

function MenuInputInternalState(props: MenuInputPropsBase & { initialValue?: string }) {
  const [value, setValue] = useState<string>(props.initialValue ?? "");
  return <MenuInputInner {...props} value={value} setValue={setValue} />;
}

function MenuInputInner(props: (MenuInputPropsBase | MenuInputPropsWithSave) & StringValueAndSet) {
  return "save" in props ? <MenuInputSave {...props} /> : <MenuInputNoSave {...props} />;
}

function MenuInputSave(props: MenuInputPropsWithSave & StringValueAndSet) {
  const [status, setStatus] = useState<SaveStatus>("ok");
  const save = useCallback(() => {
    setStatus("saving");
    props
      .save()
      .then(() => {
        if (status !== "unsaved") setStatus("ok"); // TODO: updated state?
      })
      .catch(() => setStatus("failed"));
  }, [props.save, setStatus]);
  const setValue = useCallback(
    (v: string) => {
      setStatus("unsaved");
      props.setValue(v);
    },
    [props.setValue]
  );
  return (
    <View style={{ flexDirection: "row", alignItems: "center" }}>
      <MenuInputNoSave {...props} setValue={setValue} containerStyle={{ marginRight: 2 }} />
      <SaveStatusIndicator status={status} save={save} />
    </View>
  );
}

function MenuInputNoSave(props: MenuInputPropsBase & StringValueAndSet & { containerStyle?: StyleProp<ViewStyle> }) {
  const { title, autoCorrect, autoCapitalize, secureTextEntry, value, setValue, containerStyle } = props;
  return (
    <Input
      containerStyle={[{ marginVertical: 4, flexGrow: 1 }, containerStyle]}
      placeholder={title}
      autoCorrect={autoCorrect}
      autoCapitalize={autoCapitalize}
      secureTextEntry={secureTextEntry}
      value={value}
      onChangeText={setValue}
    />
  );
}
