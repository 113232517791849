import { runInAction } from "mobx";
import * as Api from "../Api";
import { store } from "../Store";
import Comment from "./Comment";

export async function deleteComment(comment: Comment) {
  const _store = await store;
  const me = _store.me!;
  // prettier-ignore
  const r: { deletedAt: string, versions: {deletedAt: string}[] } = (await Api.gql(`mutation {
    deleteComment(id: "${comment.id}") { deletedAt }
  }`, `Bearer ${me.token.t}`)).deleteComment;
  const deletedAt = new Date(r.deletedAt);
  runInAction(() => {
    comment.deletedAt = deletedAt;
    for (const [k, v] of comment.versions) {
      comment.versions.set(k, {
        author: v.author,
        createdAt: v.createdAt,
        deletedAt,
      });
    }
  });
}
