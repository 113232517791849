import { observer } from "mobx-react-lite";
import { useCallback, useContext, useEffect, useState } from "react";
import { Text, View } from "react-native";
import { StoreContext } from "../../model/Store";
import { VFile } from "../../model/file/VFile";
import { Permissions } from "../../model/share/Permissions";
import { createUserShare } from "../../model/share/createShare";
import { useColors } from "../Colors";
import Checkbox from "../components/input/Checkbox";
import ModalView from "./ModalView";

const ImportShareModal = observer((props: { file: VFile }) => {
  const { file } = props;
  const _store = useContext(StoreContext);
  const colors = useColors().modal;
  const [visible, setVisible] = useState(false);
  const [dontAskAgain, setDontAskAgain] = useState(false);

  const onSubmit = useCallback(async () => {
    if (dontAskAgain) _store.me!.alwaysImportShares = true;
    createUserShare({
      label: undefined,
      to: _store.me!,
      file,
      permissions: new Permissions([...file.shares.values()][0].permissions.data),
    });
  }, [file, dontAskAgain]);

  const onCancel = useCallback(async () => {
    if (dontAskAgain) _store.me!.alwaysImportShares = false;
  }, [dontAskAgain]);

  useEffect(() => {
    if (file.shareCanBeImported) {
      if (_store.me!.alwaysImportShares === null) {
        setVisible(true);
      } else if (Boolean(_store.me!.alwaysImportShares)) {
        createUserShare({
          label: undefined,
          to: _store.me!,
          file,
          permissions: new Permissions([...file.shares.values()][0].permissions.data),
        });
      }
    }
  }, [file, file.shareCanBeImported, onSubmit, onCancel]);

  if (!_store.me) return <></>;
  return (
    <ModalView
      title="Import share"
      submitText="Yes"
      cancelText="No"
      onSubmit={onSubmit}
      onCancel={onCancel}
      visible={visible}
      setVisible={setVisible}
    >
      <Text style={{ color: colors.text }}>Do you want to add this file to your account?</Text>
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        <Checkbox
          value={dontAskAgain}
          setValue={setDontAskAgain}
          size={18}
          colorChecked={colors.accent}
          colorUnchecked={colors.text}
        />
        <Text style={{ color: colors.text }}>Don't ask again.</Text>
      </View>
    </ModalView>
  );
});

export default ImportShareModal;
