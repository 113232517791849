import React from "react";
import { Image } from "react-native";
import { useImageData } from "../../../../model/hooks/useImageData";
import ImageVersion from "../../../../model/version/ImageVersion";
import ErrorScreen from "../../ErrorScreen";
import LoadScreen from "../../LoadScreen";

export default function ImageVersionImage(props: { version: ImageVersion }) {
  const { data: uri, status } = useImageData(props.version);
  switch (status) {
    case "error":
      return <ErrorScreen msg={props.version?.name} numberOfLines={1} />;
    case "idle":
    case "loading":
      return <LoadScreen msg={props.version?.name} numberOfLines={1} />;
  }
  return <Image resizeMode="contain" source={{ uri }} style={{ width: "100%", height: "100%" }} />;
}
