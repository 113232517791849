import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { useCallback, useContext, useEffect, useRef } from "react";
import { GestureEvent, HandlerStateChangeEvent, PanGestureHandlerEventPayload } from "react-native-gesture-handler";
import { SharedValue } from "react-native-reanimated";
import { StoreContext } from "../../../model/Store";
import Comment from "../../../model/comment/Comment";
import { VFile } from "../../../model/file/VFile";
import DirectoryVersion from "../../../model/version/DirectoryVersion";
import { useVersion } from "../../../model/version/useVersion";
import { NewCommentViewProps } from "./NewCommentViewProps";

const NewCommentViewWrapper = observer(
  (props: {
    file: VFile;
    Component: React.FC<NewCommentViewProps>;
    commentsVisible: Readonly<SharedValue<number>>;
    toggleExpand?: () => void;
    separatorOnGestureEvent?: (event: GestureEvent<PanGestureHandlerEventPayload>) => void;
    separatorOnHandlerStateChange?: (event: HandlerStateChangeEvent<PanGestureHandlerEventPayload>) => void;
  }) => {
    const { file, Component, toggleExpand, commentsVisible, separatorOnGestureEvent, separatorOnHandlerStateChange } =
      props;
    const _store = useContext(StoreContext);
    const commentContext = _store.commentContext;
    const { data: version } = useVersion(file);
    const selectedVersion =
      version instanceof DirectoryVersion ? useVersion(version.selectedFile ?? undefined).data ?? version : version;

    const prevSelectedVersion = usePrevious(selectedVersion);

    useEffect(() => {
      runInAction(() => {
        if (
          selectedVersion &&
          // not commenting on selected version or directory
          ((commentContext !== selectedVersion && commentContext !== version) ||
            // selectedVersion in dir just loaded
            (selectedVersion !== version && prevSelectedVersion === version)) &&
          // not replying to a comment on this version
          !(
            commentContext instanceof Comment &&
            (commentContext.version === selectedVersion ||
              commentContext.version === version ||
              commentContext.version.file.parentDir === selectedVersion.file ||
              commentContext.version.file.parentDir === version?.file)
          )
        )
          _store.commentContext = selectedVersion;
      });
    }, [commentContext, selectedVersion, version, prevSelectedVersion]);

    const toggleCommentContext = useCallback(() => {
      if (!version || !selectedVersion) return;
      runInAction(() => {
        _store.commentContext = commentContext === version ? selectedVersion : version;
      });
    }, [_store, commentContext, version, selectedVersion]);

    return (
      <Component
        commentContext={commentContext}
        showingDirGallery={version instanceof DirectoryVersion}
        commentContextIsDir={commentContext === version}
        toggleCommentContext={toggleCommentContext}
        toggleExpand={toggleExpand}
        visible={commentsVisible}
        separatorOnGestureEvent={separatorOnGestureEvent}
        separatorOnHandlerStateChange={separatorOnHandlerStateChange}
      />
    );
  }
);

export default NewCommentViewWrapper;

function usePrevious<T>(value: T) {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}
