import { Text, View } from "react-native";
import { User } from "../../../model/user/User";
import { useColors } from "../../Colors";
import UserCircle from "./UserCircle";
import UserNameText from "./UserNameText";

export default function UserListItem(props: { user: User }) {
  const { user } = props;
  const colors = useColors().menu.dropdown.item;
  return (
    <>
      <UserCircle size={30} user={user} />
      <View style={{ paddingLeft: 8 }}>
        <UserNameText user={user} style={{ fontSize: 14 }} />
        <Text style={{ color: colors.text, fontSize: 13 }}>@{user.username}</Text>
      </View>
    </>
  );
}
