import { observer } from "mobx-react-lite";
import { useContext, useState } from "react";
import { View } from "react-native";
import Comment from "../../../../model/comment/Comment";
import { VideoStoreContext } from "../../../../model/ui/VideoStore";
import VideoVersion from "../../../../model/version/VideoVersion";
import CommentsTimelineItem from "./CommentsTimelineItem";

export const padding = 3;

const CommentsTimeline = observer((props: { version: VideoVersion }) => {
  const { version } = props;
  const _vsStore = useContext(VideoStoreContext);

  const [originalTimelineWidth, setOriginalTimelineWidth] = useState(Number.MAX_VALUE);
  const timelineWidth = originalTimelineWidth - padding * 2;

  const commentsData =
    _vsStore.durationMillis === undefined
      ? []
      : [...version.comments.values()]
          .filter((c) => c.latestVersion?.startTimecode !== undefined)
          .map(
            (c) =>
              new CommentTimelineElementData({
                comment: c,
                videoLength: _vsStore.durationMillis!,
                timelineWidth,
                circleSize: 18,
              })
          )
          .sort((a, b) => a.startCircle - b.startCircle);
  const comments: CommentTimelineElementData[][] = [];
  // for each sorted comment
  for (const c of commentsData) {
    let i = 0;
    // find out row in which comment should be placed
    while (i < comments.length) {
      const row = comments[i];
      const last = row.at(-1);
      if (!last || last.end < c.startCircle) {
        // put into this row
        row.push(c);
        break;
      } else if (last.endCircle < c.startCircle) {
        // put into next row
        i++;
      } else {
        // `c` is directly below item in `row` => put into 2nd next row
        i += 2;
      }
    }
    for (let c = comments.length; c < i; c++) comments.push([]);
    if (comments.length <= i) comments.push([c]);
  }
  return (
    <View
      style={{
        minHeight: 16,
        paddingBottom: 6,
      }}
      onLayout={(e) => {
        setOriginalTimelineWidth(e.nativeEvent.layout.width);
      }}
    >
      {comments.map((row, i) => (
        <View style={{ flexDirection: "row", height: 8 }} key={i}>
          {row.map((cd) => (
            <CommentsTimelineItem cd={cd} version={version} timelineWidth={timelineWidth} />
          ))}
        </View>
      ))}
    </View>
  );
});

export default CommentsTimeline;

export class CommentTimelineElementData {
  comment: Comment;
  startCircle: number;
  endCircle: number;
  end: number;

  constructor(params: { comment: Comment; timelineWidth: number; videoLength: number; circleSize: number }) {
    const cso = params.circleSize / 2 / params.timelineWidth;
    this.comment = params.comment;
    const cm = Math.max(cso, Math.min(1 - cso, params.comment.latestVersion!.startTimecode! / params.videoLength));
    this.startCircle = cm - cso;
    this.endCircle = cm + cso;
    this.end = Math.max((params.comment.latestVersion!.endTimecode ?? 0) / params.videoLength, this.endCircle);
  }
}
