import { useContext } from "react";
import { SharedValue } from "react-native-reanimated";
import { StoreContext } from "../../../model/Store";
import { VFile } from "../../../model/file/VFile";
import { useFileLink } from "../../components/FileLink";
import { AuthModalContext } from "../../modals/auth/AuthModal";
import Navbar from "../../navigation/navbar/Navbar";
import NavbarUserButton from "../../navigation/navbar/NavbarUserButton";
import CommentsToggle from "./CommentsToggle";
import PreviewScreeenHeaderTitle from "./PreviewScreenHeaderTitle";

export default function PreviewScreenNavbar(props: {
  file: VFile;
  commentsVisible: Readonly<SharedValue<number>>;
  toggleComments: () => void;
}) {
  const { file, commentsVisible, toggleComments } = props;
  const _store = useContext(StoreContext);
  const authModalContext = useContext(AuthModalContext);
  const backTo = useFileLink(file.parentDir);

  return (
    <Navbar
      backTo={backTo}
      Left={() => file && <PreviewScreeenHeaderTitle file={file} />}
      Right={() => (
        <>
          <CommentsToggle commentsVisible={commentsVisible} toggleComments={toggleComments} />
          <NavbarUserButton onPress={_store.me ? undefined : () => authModalContext.setAuthVisible(true)} />
        </>
      )}
    ></Navbar>
  );
}
