import { useCallback, useContext } from "react";
import { StoreContext } from "../../../model/Store";
import MenuInput from "../../components/menu/menu-item/MenuInput";
import MenuSection from "../../components/menu/MenuSection";
import SectionMenuScreen from "../../components/menu/SectionMenuScreen";

export default function AccountSettingsScreen() {
  const _store = useContext(StoreContext);
  const _me = _store.me;

  const save = useCallback(async () => {}, [_me]);

  return (
    <SectionMenuScreen title="Account Settings">
      {_me && (
        <>
          <MenuSection title="Display Name">
            <MenuInput title="Display Name" initialValue={_me.displayName} save={save} />
          </MenuSection>
          <MenuSection title="Username">
            <MenuInput title="Username" initialValue={_me.username} save={save} />
          </MenuSection>
          <MenuSection title="Change Password">
            <MenuInput title="Old Password" secureTextEntry />
            <MenuInput title="New Password" secureTextEntry />
            <MenuInput title="Confirm new Password" secureTextEntry />
          </MenuSection>
        </>
      )}
    </SectionMenuScreen>
  );
}
