import { Localized } from "@fluent/react";
import { StyleProp, Text, View, ViewStyle } from "react-native";
import ContextMenu from "./context-menu/ContextMenu";
import Icon from "./icon/Icon";

export type SortDropdownOption<T> = [T] | [T, T];

export default function SortDropdown<T>(props: {
  value: T;
  setValue: (v: T) => void;
  options: SortDropdownOption<T>[];
  color: string;
  style?: StyleProp<ViewStyle>;
  getTitle: (key: T) => string;
}) {
  const { value, setValue, options, color, style, getTitle } = props;
  return (
    <ContextMenu
      dropdownMenuMode
      actions={options.map((o) => ({
        title: getTitle(o[0]),
        icon: value === o[0] ? "sort-asc" : o.at(1) === value ? "sort-desc" : undefined,
        onPress: () => setValue(o[1] && value === o[0] ? o[1] : o[0]),
      }))}
    >
      <View style={[{ alignItems: "center", flexDirection: "row" }, style]}>
        <Localized id="sort-by" vars={{ key: getTitle(value) }}>
          <Text style={{ color }} />
        </Localized>
        <Icon name="collapse" color={color} size={18} />
      </View>
    </ContextMenu>
  );
}
