import { AVPlaybackStatus, Video } from "expo-av";
import { makeObservable, runInAction, when } from "mobx";
import { createContext, createRef } from "react";
import { Platform } from "react-native";
import { unsafeStore } from "../Store";
import { VFile } from "../file/VFile";
import VideoVersion from "../version/VideoVersion";

export default class VideoStore {
  file: VFile;

  video = createRef<Video>();
  private _status: AVPlaybackStatus | null = null;

  private _updatePositionMillis = false;
  private _positionMillis?: number = undefined;

  private _shouldPlay: boolean = false;
  private _muted = false;
  private _volume = 1;

  constructor(params: { file: VFile }) {
    this.file = params.file;
    makeObservable(this, {
      // stored
      file: true,
      video: true,
      //@ts-ignore
      _status: true,
      _positionMillis: true,
      _shouldPlay: true,
      _muted: true,
      _volume: true,
      // getters
      status: true,
      positionMillis: true,
      fps: true,
      shouldPlay: true,
      muted: true,
      volume: true,
      displayVolume: true,
      durationMillis: true,
      bufferedMillis: true,
      canSetPosition: true,
      // functions
      playpause: true,
      setPosition: true,
      backward: true,
      forward: true,
      setRate: true,
      moveFrames: true,
      moveMillis: true,
      adjustVolume: true,
      setVolume: true,
      toggleMute: true,
    });
  }

  get status() {
    return this._status;
  }
  set status(v: AVPlaybackStatus | null) {
    const old = this.status;
    this._status = v;
    if (this.status?.isLoaded && (!old?.isLoaded || old.uri === this.status.uri))
      this._positionMillis = this.status.positionMillis;
    else if (this.positionMillis !== undefined) this.setPosition(this.positionMillis);

    if (Platform.OS === "web") {
      const f = () =>
        requestAnimationFrame(() => {
          runInAction(() => {
            // TODO: likely only works on web
            // @ts-ignore
            const video: HTMLVideoElement = this.video.current?._nativeRef.current?._video;
            this._positionMillis = (video.currentTime ?? 0) * 1000;
            if (this._updatePositionMillis) f();
          });
        });
      const prev = this._updatePositionMillis;
      this._updatePositionMillis = (v?.isLoaded && v.isPlaying) ?? false;
      if (!prev && this._updatePositionMillis) f();
    }
  }

  get positionMillis(): number | undefined {
    return this._positionMillis;
  }

  get fps(): number | undefined {
    // TODO: remove temp fix `?? 25`
    return (this.file?.selectedVersion as VideoVersion)?.fps ?? 25;
  }

  get shouldPlay(): boolean {
    if (this.status?.isLoaded) return this.status.isPlaying;
    return false;
  }

  get muted() {
    return this._muted;
  }
  set muted(value: boolean) {
    this._muted = value;
    when(
      () => this.video.current !== undefined,
      () => this.video.current?.setIsMutedAsync(this.muted)
    );
  }
  get volume(): number {
    return this._volume;
  }
  get displayVolume(): number {
    return this.muted ? 0 : this._volume;
  }

  get durationMillis(): number | undefined {
    if (this.status?.isLoaded) return this.status.durationMillis;
  }
  get bufferedMillis(): number {
    return this.status?.isLoaded ? this.status.playableDurationMillis ?? 0 : 0;
  }

  get canSetPosition() {
    const v = unsafeStore.commentContext?.wipComment?.startTimecode;
    return v === null || v === undefined;
  }

  playpause = () => {
    this.setRate(1);
    if (!this.shouldPlay) this.video.current?.playAsync();
    else this.video.current?.pauseAsync();
  };

  setPosition = async (millis: number) => {
    if (this.canSetPosition && Number.isFinite(millis)) {
      this._positionMillis = millis;
      await this.video.current?.setPositionAsync(millis);
    }
  };

  backward = () => {
    if (!this.status?.isLoaded) return;
    if (this.status.isPlaying) {
      if (this.status.rate < 0 && this.status.rate > -64) this.setRate(this.status.rate * 2);
      else if (this.status.rate > 1) this.setRate(this.status.rate / 2);
      else this.setRate(-1);

      this.video.current?.playAsync();
    } else {
      this.setRate(1);
      this.video.current?.playAsync();
    }
  };

  forward = () => {
    if (!this.status?.isLoaded) return;
    if (this.status.isPlaying) {
      if (this.status.rate > 0) this.setRate(Math.min(this.status.rate * 2, 32));
      else if (this.status.rate < -1) this.setRate(this.status.rate / 2);
      else this.setRate(1);
    } else {
      this.setRate(1);
      this.video.current?.playAsync();
    }
  };

  setRate = (rate: number) => {
    // if (Platform.OS === "web") {
    //   //@ts-ignore
    //   const video: HTMLVideoElement = this.video.current?._nativeRef.current?._video;
    //   video.playbackRate = rate;
    // } else {
    this.video.current?.setRateAsync(rate, false);
    // }
  };

  moveFrames = (frames: number) => {
    if (this.fps) this.moveMillis((frames * 1000) / this.fps);
  };

  moveMillis = (millis: number) => {
    if (this.positionMillis === undefined) return;
    this.setPosition(this.positionMillis + millis);
  };

  setVolume = (value: number) => {
    this.muted = false;
    this._volume = Math.max(Math.min(value, 1), 0);
    when(
      () => this.video.current !== undefined,
      () => this.video.current?.setVolumeAsync(this._volume)
    );
  };

  adjustVolume = (steps: number) => {
    this.setVolume(this.volume + 0.05 * steps);
  };

  toggleFullscreen = () => {
    this.video.current?.presentFullscreenPlayer();
  };

  toggleMute = () => {
    this._muted = !this.muted;
  };

  // newComment(text: string) {
  //   this._wipComment = new WipComment({
  //     text,
  //     version: this.version,
  //     startTimecode: this.newCommentIncludesTimecode && this.status?.isLoaded ? this.status.positionMillis : null,
  //   });
  // }

  // replyToComment(c: Comment | null): void {
  //   if (c?.latestVersion?.startTimecode) {
  //     this.video.current?.setPositionAsync(c?.latestVersion?.startTimecode);
  //     this.newCommentIncludesTimecode = true;
  //   } else {
  //     this.newCommentIncludesTimecode = false;
  //   }
  // }

  // toggleIncludeTimecode(): void {
  //   if (this.wipComment)
  //     if (!this.newCommentIncludesTimecode && this.status?.isLoaded)
  //       this.wipComment.startTimecode = this.status.positionMillis;
  //     else this.wipComment.startTimecode = null;
  // }
}

// @ts-ignore
export const VideoStoreContext = createContext<VideoStore>();
