import { runInAction } from "mobx";
import * as Api from "../Api";
import { store } from "../Store";
import { ShareFromJson, shareGQLFields } from "../share/Share";
import { aesDecrypt, base64Decode, importRawKey, rsaDecrypt } from "../util/CryptoHelper";
import { VersionJson, versionGQLFields } from "../version/Version";
import {
  VFile,
  VFileDirectory,
  VFileJson,
  VFileJsonWithLatestVersion,
  fileGQLFields,
  fileGQLFieldsWithLatestVersion,
} from "./VFile";

export type FetchFileParams = { parentDir: VFileDirectory } | { share: string; shareKey: ArrayBuffer };

export async function fetchFile(id: string, params?: FetchFileParams): Promise<VFile> {
  const me = (await store).me;
  const token = me ? `Bearer ${me.token.t}` : undefined;

  if (params && "parentDir" in params) {
    // prettier-ignore
    const r: VFileJsonWithLatestVersion = (await Api.gql(`{
        file(id: "${id}") {
          ${fileGQLFieldsWithLatestVersion}
           shares {${shareGQLFields}, to}
        }
      }`, token)).file;
    return VFile.fromJson(r, { parentDir: params.parentDir });
  }

  const shareInParams = params && "share" in params;
  if (!me && !shareInParams) throw new Error("Cannot get file: Not signed in & no share provided.");

  // prettier-ignore
  const pt: PathTree<{ fileKey: string }> = (await Api.gql(`{
    filePath(id: "${id}"${shareInParams ? `share: "${params.share}"`:""}) {
      files {
        ${fileGQLFields}
        key
        shares {${shareGQLFields}, to, fileKey}
      }
      versions {
        ${versionGQLFields}
      }
    }
  }`, token)).filePath;
  const rootFileData = pt.files.pop();
  if (!rootFileData) throw new Error("PathTree.files is empty", { cause: pt });
  if (!rootFileData.shares) throw new Error("Root file has no shares.", { cause: rootFileData });
  const s = rootFileData.shares.find(shareInParams ? (v) => v.id === params.share : (v) => v.to === me!.id)!;

  const fileKey = base64Decode(s.fileKey);
  const key = shareInParams
    ? await importRawKey(await aesDecrypt(await importRawKey(params.shareKey), fileKey, new ArrayBuffer(16)))
    : await importRawKey(await rsaDecrypt(me!.privkey, fileKey));
  const share = await ShareFromJson(s, { decryptedShareKey: params?.shareKey });
  return runInAction(async () => {
    let last = VFile.fromJson(rootFileData, { key, share });
    for (const data of pt.files.reverse()) {
      last = VFile.fromJson(data, { parentDir: (await last) as VFileDirectory });
    }
    return last!;
  });
}

type PathTree<S extends Object = {}> = {
  files: VFileJson<S>[];
  versions: VersionJson[];
};
