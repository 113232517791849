import React, { PropsWithChildren } from "react";
import { StyleProp, Text, TextStyle } from "react-native";
import { TouchableWithoutFeedback } from "react-native-gesture-handler";
import Animated, { useAnimatedStyle, useSharedValue, withTiming } from "react-native-reanimated";
import styles from "../../Styles";

export default function ButtonWrapper(
  props: PropsWithChildren<{ onPress: () => void; textStyle: StyleProp<TextStyle> }>
) {
  const { onPress, textStyle } = props;
  const opacity = useSharedValue(1);
  const animatedStyle = useAnimatedStyle(() => ({ opacity: opacity.value }));
  const children = React.Children.map(props.children, (child, i) => {
    return typeof child === "string" ? (
      <Text style={textStyle}>{child}</Text>
    ) : (
      <Animated.View style={animatedStyle}>{child}</Animated.View>
    );
  });
  return (
    <TouchableWithoutFeedback
      style={styles.row}
      onPress={onPress}
      onPressIn={() => (opacity.value = 0.2)}
      onPressOut={() => (opacity.value = withTiming(1))}
    >
      {children}
    </TouchableWithoutFeedback>
  );
}
