import { makeAutoObservable } from "mobx";
import { createContext } from "react";
import { VFile } from "../file/VFile";

export class FileCardStore {
  hoveringOver: VFile | null = null;

  constructor() {
    makeAutoObservable(this, {
      hoveringOver: true,
    });
  }
}

// @ts-ignore
export const FileCardStoreContext = createContext<FileCardStore>();
