import { CSSProperties, PropsWithChildren, useRef } from "react";
import { StyleProp, ViewStyle } from "react-native";

export default function DdDView(
  props: PropsWithChildren<{
    draggable?: boolean;
    style?: StyleProp<ViewStyle>;
    // called on dragged view
    onDragStart?: (e: React.DragEvent<unknown>) => void;
    onDrag?: (e: MyEvent) => void;
    onDragEnd?: () => void;
    // called on drop view
    onDragEnter?: (e: MyEvent) => void;
    onDragOver?: (e: MyEvent & { offsetRatioX: number; offsetRatioY: number }) => void;
    onDragExit?: (e: MyEvent) => void;
    onDrop?: (e: MyEvent & { offsetRatioX: number; offsetRatioY: number }) => void;
  }>
) {
  const { children, draggable, style, onDragStart, onDrag, onDragEnd, onDragEnter, onDragOver, onDragExit, onDrop } =
    props;
  const ref = useRef<HTMLDivElement>(null);
  return (
    <div
      ref={ref}
      draggable={draggable}
      style={{ flexDirection: "column", display: "flex", ...(style as CSSProperties | undefined) }}
      onDragStart={onDragStart}
      onDrag={(e) => {
        const offsetX = e.nativeEvent.offsetX;
        const offsetY = e.nativeEvent.offsetY;
        onDrag?.({
          offsetX,
          offsetY,
          dataTransfer: e.dataTransfer,
        });
        e.preventDefault();
      }}
      onDragOver={(e) => {
        const rect = ref.current!.getBoundingClientRect();
        const offsetX = e.nativeEvent.clientX - rect.x;
        const offsetY = e.nativeEvent.offsetY - rect.y;
        onDragOver?.({
          offsetX,
          offsetY,
          dataTransfer: e.dataTransfer,
          offsetRatioX: offsetX / ref.current!.clientWidth,
          offsetRatioY: offsetY / ref.current!.clientHeight,
        });
        e.preventDefault();
      }}
      onDragEnter={(e) => {
        const rect = ref.current!.getBoundingClientRect();
        if (
          e.nativeEvent.clientX < rect.x ||
          e.nativeEvent.clientX > rect.right ||
          e.nativeEvent.clientY < rect.y ||
          e.nativeEvent.clientY > rect.bottom
        )
          return;
        const offsetX = e.nativeEvent.offsetX;
        const offsetY = e.nativeEvent.offsetY;
        onDragEnter?.({
          offsetX,
          offsetY,
          dataTransfer: e.dataTransfer,
        });
        e.preventDefault();
      }}
      onDragLeave={(e) => {
        const rect = ref.current!.getBoundingClientRect();
        if (
          e.nativeEvent.clientX > rect.x &&
          e.nativeEvent.clientX < rect.right &&
          e.nativeEvent.clientY > rect.y &&
          e.nativeEvent.clientY < rect.bottom
        )
          return;
        const offsetX = e.nativeEvent.offsetX;
        const offsetY = e.nativeEvent.offsetY;
        onDragExit?.({
          offsetX,
          offsetY,
          dataTransfer: e.dataTransfer,
        });
      }}
      onDragEnd={(e) => {
        onDragEnd?.();
        e.preventDefault();
      }}
      onDrop={(e) => {
        const rect = ref.current!.getBoundingClientRect();
        const offsetX = e.nativeEvent.clientX - rect.x;
        const offsetY = e.nativeEvent.offsetY - rect.y;
        onDrop?.({
          offsetX,
          offsetY,
          dataTransfer: e.dataTransfer,
          offsetRatioX: offsetX / ref.current!.clientWidth,
          offsetRatioY: offsetY / ref.current!.clientHeight,
        });
        e.preventDefault();
      }}
    >
      {children}
    </div>
  );
}

type MyEvent = {
  offsetX: number;
  offsetY: number;
  dataTransfer: DataTransfer;
};
