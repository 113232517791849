import { makeObservable, runInAction } from "mobx";
import { VFile } from "../file/VFile";
import { ShareModalStore } from "../ui/ShareModalStore";
import { User } from "../user/User";
import { Permissions } from "./Permissions";
import { UserShareData } from "./Share";
import { createUserShare } from "./createShare";

export class WipUserShare implements UserShareData {
  to: User;
  label?: string;
  from: User;
  createdAt: Date;
  permissions: Permissions;

  file: VFile;
  store: ShareModalStore;

  constructor(params: UserShareData & { file: VFile; store: ShareModalStore }) {
    const { label, from, to, createdAt, permissions, store } = params;
    this.label = label;
    this.from = from;
    this.createdAt = createdAt;
    this.permissions = permissions;
    this.to = to;
    this.file = params.file;
    this.store = store;
    makeObservable(this, { label: true, permissions: true });
  }

  create = async () => {
    const share = await createUserShare({
      label: this.label,
      to: this.to,
      file: this.file,
      permissions: this.permissions,
    });
    runInAction(() => {
      this.store.newUserShares.remove(this);
      this.file.shares.set(share.id, share);
    });
  };
}
