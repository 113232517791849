import { runInAction } from "mobx";
import { useCallback, useContext, useEffect, useState } from "react";
import { StoreContext } from "../Store";
import { CacheableResult2 } from "../cacheable/CacheableResult";
import { useRefetchEffect } from "../cacheable/useRefetchEffect";
import { useSortedFiles } from "../util/useSortedFiles";
import DirectoryVersion from "../version/DirectoryVersion";
import { VFile } from "./VFile";
import { fetchFiles } from "./fetchFiles";

/**
 * Returns a list of files in a directory.
 * @param params.parentDir The parent directory's Version. Root directory if null.
 * @param params.shareKey
 * @returns
 */
export function useFiles(params: {
  parentDir?: DirectoryVersion | null;
  shareKey?: ArrayBuffer;
}): CacheableResult2<VFile[], unknown> {
  // TODO: prevent refetch of new version of directory when a new file is created
  const { parentDir, shareKey } = params;
  const _store = useContext(StoreContext);

  const getData = useCallback(
    () =>
      parentDir === null
        ? [..._store.files.values()].filter((f) => f.parentDir === null)
        : parentDir?.childrenIds.map((id) => _store.files.get(id)).filter((v): v is VFile => v !== undefined) ?? [],
    [parentDir, shareKey]
  );
  const data = useSortedFiles(getData(), _store.fileSortStrategy);
  const [error, setError] = useState<unknown>();

  const refetch = useCallback(async () => {
    try {
      const v = await fetchFiles(params);
      runInAction(() => {
        const data = getData();
        for (const f of data) {
          if (!v.includes(f)) _store.files.delete(f.id);
        } // TODO: file might still be in other DirVersion
      });
      setError(undefined);
    } catch (e) {
      setError(e);
    }
  }, [parentDir, shareKey]);

  useEffect(() => {
    if (!_store.me && !parentDir && !shareKey) setError(new Error("Not signed in"));
  }, [parentDir, shareKey]);

  const fetching = useRefetchEffect(
    refetch,
    data.length > 0 ? data.map((v) => v.lastUpdatedAt).reduce((a, b) => Math.min(a, b)) : undefined,
    60000
  );

  return { data, error, fetching, refetch };
}
