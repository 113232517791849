import { NativeSyntheticEvent, Text, TextInput, TextInputSubmitEditingEventData, View } from "react-native";

export default function MultilineTextInput(props: {
  autoFocus?: boolean;
  text: string;
  color: string;
  editable?: boolean;
  placeholder?: string;
  placeholderTextColor?: string;
  onChangeText: (text: string) => void;
  onSubmitEditing?: (e: NativeSyntheticEvent<TextInputSubmitEditingEventData>) => void;
}) {
  const { autoFocus, text, color, editable, placeholder, placeholderTextColor, onChangeText, onSubmitEditing } = props;

  return (
    <View style={{ flexGrow: 1 }}>
      <Text
        lineBreakMode="middle"
        style={{
          opacity: 0,
          zIndex: -1,
          height: "100%",
          overflow: "hidden",
        }}
      >
        {text + " "}
      </Text>
      <TextInput
        autoFocus={autoFocus}
        multiline
        onSubmitEditing={onSubmitEditing}
        // required for onSubmitEditing
        blurOnSubmit
        editable={editable}
        onChangeText={onChangeText}
        value={text}
        style={{
          color,
          position: "absolute",
          left: 0,
          top: 0,
          minHeight: "100%",
          width: "100%",
          overflow: "hidden",
        }}
        placeholderTextColor={placeholderTextColor}
        placeholder={placeholder}
      />
    </View>
  );
}
