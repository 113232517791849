import { S3ClientConfig } from "@aws-sdk/client-s3";
import { CommentSortStrategy } from "./model/comment/sortComments";
import { PermissionsData } from "./model/share/Permissions";
import { FileSortStrategy } from "./model/util/useSortedFiles";

type Config = {
  api: string;
  socket: string;
  baseUrl: string;
  defaultTranscodingServer: { url: string; name: string };
  s3: {
    /** delay before retrying request on the same server in milliseconds */
    retryDelay: number;
    client: (S3ClientConfig & { endpoint: string })[];
  };
  upload: {
    partSize: number;
    bucket: string;
    maxConcurrentUploads: number;
  };
  defaultPermissions: PermissionsData;
  /** in milliseconds */
  createUserShareDelay: number;
  videoPlayer: {
    volumeStepSize: number;
  };
  /** The minimum time between subsequent refetches in milliseconds */
  staleTime: number;

  defaultFileSortStrategy: FileSortStrategy;
  defaultCommentSortStrategy: CommentSortStrategy;

  /** The `window.caches` key used by `cacheFile()` */
  filecache: string;
};

const config: Config = {
  api: "https://api.vidre.io",
  socket: "wss://socket.vidre.io",
  baseUrl: "https://vidre.app/#",
  defaultTranscodingServer: { url: "transcode.vidre.io", name: "Vidre Transcoding Server" },
  s3: {
    retryDelay: 10000,
    client: [
      {
        region: "eu",
        endpoint: "https://hawking-storage-vidre.vlnt.in",
        forcePathStyle: true,
        credentials: {
          accessKeyId: "vidre_client",
          secretAccessKey: "4JhPj5uCHTpRN3Yof2Xe3hbmgWjveEyUF3JrEMvs",
        },
      },
      {
        region: "eu",
        endpoint: "https://cantor-storage-vidre.vlnt.in",
        forcePathStyle: true,
        credentials: {
          accessKeyId: "vidre_client",
          secretAccessKey: "4JhPj5uCHTpRN3Yof2Xe3hbmgWjveEyUF3JrEMvs",
        },
      },
      // {
      //   region: "eu",
      //   endpoint: "https://kronecker.storage.vidre.io",
      //   forcePathStyle: true,
      //   credentials: {
      //     accessKeyId: "vidre_client",
      //     secretAccessKey: "4JhPj5uCHTpRN3Yof2Xe3hbmgWjveEyUF3JrEMvs",
      //   },
      // },
    ],
  },
  upload: {
    // partSize: 2 ** 26, // 67_108_864 Bytes
    partSize: 2 ** 24, // 16_777_216 Bytes
    // partSize: 2 ** 20, // 1_048_576 Bytes
    bucket: "vidre",
    maxConcurrentUploads: 2,
  },
  defaultPermissions: {
    type: "simple",
    upload: false,
    edit: false,
    comment: true,
    moderate: false,
    share: true,
  },
  createUserShareDelay: 5000,
  videoPlayer: {
    volumeStepSize: 0.05,
  },
  staleTime: 2000,
  defaultFileSortStrategy: "name_asc",
  defaultCommentSortStrategy: "timecode",
  filecache: "files",
};

export default config;
