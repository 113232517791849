import React from "react";
import { Text, View } from "react-native";
import { User } from "../../../model/user/User";
import { useColors, useUserColor } from "../../Colors";
import Icon from "../icon/Icon";

export default function UserCircle(
  props: { size: number; border?: number } & ({ user: User } | { user?: User; color: string })
) {
  const { size, user, border } = props;
  const colors = useColors().userCircle;
  const backgroundColor = user ? useUserColor(user) : colors.background;
  return (
    <View
      style={{
        width: size,
        height: size,
        justifyContent: "center",
        alignItems: "center",
        borderRadius: size / 2,
        backgroundColor,
        borderColor: colors.border,
        borderWidth: border,
      }}
    >
      {user ? (
        <Text
          style={{
            color: colors.text,
            fontWeight: "500",
            fontSize: (size / 20) * 12,
            textAlign: "center",
          }}
        >
          {user.displayName.charAt(0)}
        </Text>
      ) : (
        <Icon
          name="person"
          size={(size / 36) * 24}
          color={
            // @ts-ignore
            props.color
          }
        />
      )}
    </View>
  );
}
