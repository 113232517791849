import { CompleteMultipartUploadCommand } from "@aws-sdk/client-s3";
import config from "../../../config";
import { S3ClientInfo } from "../../S3";
import { MultipartUploadInfo } from "../types/MultipartUploadInfo";

export async function completeMultipartUpload(params: { info: MultipartUploadInfo; client: S3ClientInfo }) {
  const { info, client } = params;
  const { uploadId, path, uploadedParts } = info;
  await client.client.send(
    new CompleteMultipartUploadCommand({
      Bucket: config.upload.bucket,
      Key: path,
      UploadId: uploadId,
      MultipartUpload: {
        Parts: uploadedParts,
      },
    })
  );
}
