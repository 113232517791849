import { CircularProgress } from "@material-ui/core";
import * as React from "react";
import { Platform, Text, View } from "react-native";
import { useColors } from "../Colors";
import styles from "../Styles";

export default function LoadScreen(props: { msg?: string; numberOfLines?: number; backgroundColor?: string }) {
  const colors = useColors().loadScreen;
  return (
    <View style={[styles.container, { backgroundColor: props.backgroundColor ?? colors.background }]}>
      <Text
        style={{ color: colors.text, padding: 8, opacity: 0 }}
        ellipsizeMode="middle"
        numberOfLines={props.numberOfLines}
      >
        {props.msg}
      </Text>
      {Platform.OS === "web" && <CircularProgress style={{ color: colors.accent }} />}
      <Text style={{ color: colors.text, padding: 8 }} ellipsizeMode="middle" numberOfLines={props.numberOfLines}>
        {props.msg}
      </Text>
    </View>
  );
}
