import { runInAction } from "mobx";
import * as Api from "../Api";
import { store } from "../Store";
import { getUser } from "../user/getUser";
import { aesDecrypt, base64Decode, base64Encode, exportRawKey, getRelation, importRawKey } from "../util/CryptoHelper";
import DirectoryVersion from "../version/DirectoryVersion";
import Version from "../version/Version";
import { getVersion } from "../version/getVersion";
import Comment, { CommentJson, CommentVersionCiphertextJson, isExistingCommentVersionJson } from "./Comment";
import { Commentable } from "./Commentable";

const batchSize = 20;

export async function subscribeComments(c: Commentable, recurse: number) {
  if (c.maxExistingRelation + 1 >= c.nextRelation) {
    //console.debug(`subscribe next comments for ${c.id}`);
    try {
      const start = c.nextRelation;
      c.nextRelation += batchSize;
      const relations = await Promise.all(
        [...new Array(batchSize).keys()].map(async (n) => base64Encode(await getRelation(c.id, c.encKey, start + n)))
      );
      const _store = await store;
      for (const [i, r] of relations.entries()) {
        _store.subscribedCommentRelations.set(r, { c, n: start + i });
      }
      const data = relations.map((r) => `{relation: "${r}", ciphertext: ""}`).join(",");

      // prettier-ignore
      const r: CommentJson[] = (await Api.gql(`mutation {
        subscribeComments(data: [${data}], ${_store.me ? "": `subscriptionId: "${_store.subscriberId}"` }) {
          id
          relation
          key
          versions {
            author
            ciphertext
            createdAt
            deletedAt
          }
          deletedAt
        }
      }`, `Bearer ${_store.me?.token.t}`)).subscribeComments;

      for (const cd of r) {
        const relD = _store.subscribedCommentRelations.get(cd.relation);
        let version: Version;
        let replyTo: Comment | undefined;
        if (relD?.c instanceof Version) {
          version = relD?.c;
        } else if (relD?.c instanceof Comment) {
          replyTo = relD?.c;
          version = relD?.c.version;
        } else {
          console.warn("Unknown commentable type:", relD?.c, relD);
          return;
        }

        let comment = c.comments.get(cd.id);
        const encKey = await importRawKey(await aesDecrypt(c.encKey, base64Decode(cd.key), new ArrayBuffer(16)));

        if (comment) {
          comment.encKey = encKey;
        } else {
          comment = new Comment({
            id: cd.id,
            encKey,
            relationNo: relD!.n,
            version,
            replyTo,
            versions: new Map(),
            deletedAt: cd.deletedAt ? new Date(cd.deletedAt) : undefined,
          });
        }

        for (const [i, vd] of cd.versions.entries()) {
          try {
            const author = await getUser({ id: vd.author });
            const createdAt = new Date(vd.createdAt);
            if (isExistingCommentVersionJson(vd)) {
              const dec = Buffer.from(await aesDecrypt(encKey, base64Decode(vd.ciphertext), new ArrayBuffer(16)));
              const { text, startTimecode, endTimecode }: CommentVersionCiphertextJson = JSON.parse(
                dec.toString("utf-8")
              );
              runInAction(() => {
                if (!comment!.versions.get(i)) {
                  comment!.versions.set(i, {
                    author,
                    createdAt,
                    text,
                    startTimecode,
                    endTimecode,
                  });
                }
              });
            } else {
              const deletedAt = vd.deletedAt;
              runInAction(() => {
                comment!.versions.set(i, {
                  author,
                  createdAt,
                  deletedAt: new Date(deletedAt),
                });
              });
            }
          } catch (e) {
            console.error("Failed to parse comment version", vd, base64Encode(await exportRawKey(encKey)), e);
          }
        }
        runInAction(() => c.comments.set(cd.id, comment!));
      }
    } catch (e) {
      c.nextRelation -= batchSize;
      throw e;
    }
  }
  const _store = await store;
  if (c.maxExistingRelation < c.nextRelation && recurse > 0 && c instanceof DirectoryVersion) {
    const files = c.childrenIds.map((id) => _store.files.get(id));
    for (const file of files) {
      if (!file) continue;
      try {
        const v = await getVersion({ id: file.versionIds.at(file.selectedVersionNo)!, file });
        if (v.maxExistingRelation + 1 >= v.nextRelation) subscribeComments(v, 0);
      } catch (e) {
        console.warn("Error while subscribing to child version:", file, e);
      }
    }
  }
}
