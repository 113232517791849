import { observer } from "mobx-react-lite";
import React, { useContext, useEffect } from "react";
import { FlatList, TouchableHighlight } from "react-native";
import { StoreContext } from "../../../model/Store";
import { createLinkShare } from "../../../model/share/createShare";
import { fetchShares } from "../../../model/share/fetchShares";
import { ShareModalStore } from "../../../model/ui/ShareModalStore";
import { User } from "../../../model/user/User";
import { getUser } from "../../../model/user/getUser";
import { useColors } from "../../Colors";
import styles from "../../Styles";
import Divider from "../../components/Divider";
import SuggestionsInput from "../../components/input/SuggestionsInput";
import UserCard from "../../components/user/UserCard";
import AddButton, { AddButtonType } from "./AddButton";
import ShareListItem from "./ShareListItem";

const ShareModalInner = observer((props: { shareModalStore: ShareModalStore }) => {
  const { shareModalStore: sms } = props;
  const _store = useContext(StoreContext);
  const me = _store.me!;
  const colors = useColors().modal;
  const { file } = sms;

  useEffect(() => {
    fetchShares(file.id);
  }, [file]);

  return (
    <>
      <SuggestionsInput<User>
        getSuggestions={async (s) => {
          if (s.trim().length <= 0) return [];
          await getUser({ username: s }).catch(() => {});
          return [..._store.users.entries()]
            .filter(([id, user]) => user.id !== me.id && !sms.allUserSharesMap.has(id))
            .sort(([, a], [, b]) => b.sortFilter(s) - a.sortFilter(s))
            .filter(([_id, user]) => user.sortFilter(s) > 0)
            .slice(0, 4);
        }}
        renderSuggestion={(user) => <UserCard user={user} style={{ margin: 2 }} />}
        onSubmit={async (v) => {
          const user = await getUser({ username: v });
          if (user) {
            sms.createUserShare(user);
            return true;
          }
          return false;
        }}
        onSelect={async (user) => {
          console.log("select");
          sms.createUserShare(user);
          return true;
        }}
        containerStyle={{ padding: 4, backgroundColor: colors.input.background, borderRadius: 8 }}
        textStyle={{ color: colors.text, margin: 3, paddingVertical: 4 }}
        suggestionsStyle={{
          position: "absolute",
          backgroundColor: colors.share.suggestions.background,
          width: "100%",
          borderRadius: 6,
          padding: 2,
        }}
        placeholder="Username or E-Mail"
      />

      <FlatList
        style={{ height: "100%" }}
        data={[AddButtonType.link, ...sms.allShares]}
        renderItem={({ item, index }) =>
          item === AddButtonType.link || item === AddButtonType.user ? (
            <TouchableHighlight
              onPress={() => createLinkShare({ file, permissions: me!.defaultPermissions() })}
              style={styles.iconListItem}
              underlayColor={colors.share.suggestions.underlay}
              activeOpacity={1}
            >
              <AddButton type={AddButtonType.link} />
            </TouchableHighlight>
          ) : (
            <ShareListItem
              // @ts-ignore
              key={item.id ?? index}
              share={item}
              file={file}
            />
          )
        }
        ItemSeparatorComponent={() => <Divider color={colors.share.divider} />}
      />
    </>
  );
});

export default ShareModalInner;
