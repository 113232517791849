import { Localized } from "@fluent/react";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Keyboard, TextInput, TouchableHighlight, TouchableOpacity, View } from "react-native";
import { PanGestureHandler } from "react-native-gesture-handler";
import Animated, {
  Extrapolate,
  interpolate,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from "react-native-reanimated";
import { StoreContext } from "../../../model/Store";
import { createComment } from "../../../model/comment/createComment";
import { VideoStoreContext } from "../../../model/ui/VideoStore";
import { useColors } from "../../Colors";
import { AuthModalContext } from "../../modals/auth/AuthModal";
import LoadCircle from "../../util/LoadCircle";
import ScaleButton from "../button/ScaleButton";
import Icon from "../icon/Icon";
import CommentContextToggle from "./CommentContextToggle";
import NewCommentTimecode from "./NewCommentTimecode";
import { NewCommentViewProps } from "./NewCommentViewProps";

const NewCommentViewMobile = observer((props: NewCommentViewProps) => {
  const {
    commentContext,
    showingDirGallery,
    commentContextIsDir,
    toggleCommentContext,
    toggleExpand,
    visible,
    separatorOnGestureEvent,
    separatorOnHandlerStateChange,
  } = props;
  const wipComment = commentContext?.wipComment;
  const colors = useColors();
  const _store = useContext(StoreContext);
  const vStore = useContext(VideoStoreContext);

  const text = wipComment?.text ?? "";
  const ref = useRef<TextInput>(null);
  const timecodeColor =
    wipComment?.startTimecode ?? true ? colors.newCommentDesktop.enabled : colors.newCommentDesktop.disabled;

  /** Whether the view is expanded (`1` or `0`) */
  const expansion = useSharedValue(0);

  const authModalContext = useContext(AuthModalContext);

  const [isSending, setIsSending] = useState(false);
  const [editable, setEditable] = useState(true);
  const submit = useCallback(async () => {
    if (!_store.me) authModalContext.setAuthVisible(true);
    else {
      ref.current?.blur();
      Keyboard.dismiss();
      if (wipComment?.canSend && commentContext) {
        setIsSending(true);
        setEditable(false);
        await createComment({
          commentContext,
          wipComment,
          currentTimecode: vStore.positionMillis,
        });
      }
      expansion.value = withTiming(0, {}, () => {
        setIsSending(false);
        setEditable(true);
        if (ref.current?.isFocused()) {
          ref.current?.blur();
          Keyboard.dismiss();
        }
      });
    }
  }, [_store, vStore, authModalContext, ref, expansion, wipComment]);
  const onChangeText = useCallback((t: string) => runInAction(() => commentContext?.setComment(t)), [commentContext]);

  const cancelEditing = useCallback(() => {
    ref.current?.blur();
    Keyboard.dismiss;
    expansion.value = withTiming(0);
    runInAction(() => {
      if (commentContext) commentContext.wipComment = null;
    });
  }, []);

  useEffect(() => {
    if (wipComment) {
      ref.current?.focus();
    }
  }, [wipComment]);

  const inputStyle = useAnimatedStyle(() => ({
    paddingHorizontal: interpolate(expansion.value, [0, 1], [0, 2]),
  }));

  const outerStyle = useAnimatedStyle(() => ({
    paddingLeft: interpolate(expansion.value, [0, 1], [16, 0]),
    paddingRight: interpolate(expansion.value, [0, 1], [16, 0]),
    paddingTop: interpolate(expansion.value, [0, 1], [8, 0]),
    paddingBottom: interpolate(expansion.value, [0, 1], [8, 0]),
    height: `${interpolate(expansion.value, [0, 1], [0, 100])}%`,
    minHeight: 46 + 8 * 2,
    transform: [{ translateY: interpolate(visible?.value ?? 1, [0, 1], [46 + 16, 0]) }],
  }));
  const innerStyle = useAnimatedStyle(() => ({
    borderRadius: interpolate(expansion.value, [0, 1], [10, 0]),
    borderWidth: interpolate(expansion.value, [0, 1], [1, 0]),
    shadowRadius: interpolate(expansion.value, [0, 1], [16, 0]),
    shadowOpacity: interpolate(expansion.value, [0, 1], [0.4, 0]),
  }));
  const buttonStyle = useAnimatedStyle(() => ({
    transform: [{ scale: interpolate(expansion.value, [0, 1], [0, 1], Extrapolate.CLAMP) }],
  }));
  const bottomStyle = useAnimatedStyle(() => ({
    height: expansion.value === 0 ? 0 : undefined,
    paddingHorizontal: interpolate(expansion.value, [0, 1], [0, 12]),
    paddingBottom: interpolate(expansion.value, [0, 1], [-2, 8]),
  }));
  const topStyle = useAnimatedStyle(() => ({
    height: interpolate(expansion.value, [0, 1], [0, 40], Extrapolate.CLAMP),
    opacity: interpolate(expansion.value, [0, 1], [0, 1], Extrapolate.CLAMP),
  }));
  const borderStyle = useAnimatedStyle(() => ({
    opacity: interpolate(expansion.value, [0, 1], [0, 1], Extrapolate.CLAMP),
  }));

  return (
    <Animated.View
      style={[
        outerStyle,
        {
          position: "absolute",
          width: "100%",
          bottom: 0,
        },
      ]}
    >
      <Animated.View
        style={[
          innerStyle,
          {
            backgroundColor: colors.newCommentMobile.background,
            borderColor: colors.newCommentMobile.border,
            shadowOffset: { height: 4, width: 0 },
            minHeight: 46,
            height: "100%",
          },
        ]}
      >
        {/* top row */}
        <PanGestureHandler
          onGestureEvent={separatorOnGestureEvent}
          onHandlerStateChange={separatorOnHandlerStateChange}
        >
          <Animated.View style={[topStyle, { flexDirection: "row", alignItems: "center" }]}>
            {/* cancel */}
            <TouchableOpacity onPress={cancelEditing} style={{ padding: 12 }}>
              <Icon name="close" size={24} color={colors.commentsSeparatorMobile.icon} />
            </TouchableOpacity>

            {/* info */}
            <CommentContextToggle
              commentContext={commentContext}
              showingDirGallery={showingDirGallery}
              commentContextIsDir={commentContextIsDir}
              toggleCommentContext={toggleCommentContext}
            />

            <View style={{ flexGrow: 1 }} />

            {/* expand */}
            <TouchableOpacity onPress={toggleExpand} style={{ padding: 12 }}>
              <Icon name="expand" size={18} color={colors.commentsSeparatorMobile.icon} />
            </TouchableOpacity>
          </Animated.View>
        </PanGestureHandler>
        <Animated.View style={[borderStyle, { backgroundColor: colors.newCommentMobile.border, height: 1 }]} />

        {/* text input */}
        <Animated.View style={[inputStyle, { flexGrow: 1, flex: 1, flexDirection: "row" }]}>
          <Localized id="comment-new" attrs={{ placeholder: true }}>
            <TextInput
              ref={ref}
              multiline
              editable={editable}
              focusable={editable}
              onChangeText={onChangeText}
              onFocus={() => (expansion.value = withTiming(1))}
              value={text}
              style={{ padding: 10, color: colors.newCommentMobile.text, flexGrow: 1 }}
              placeholderTextColor={colors.newCommentMobile.placeholder}
              keyboardAppearance="dark"
            />
          </Localized>
        </Animated.View>

        {/* bottom row */}
        <Animated.View style={[bottomStyle, { flexDirection: "row", alignItems: "center" }]}>
          {/* timecode */}
          {wipComment?.startTimecode !== undefined && wipComment?.startTimecode !== null && (
            <TouchableHighlight
              style={{ borderRadius: 5, padding: 5, backgroundColor: colors.commentContextToggle.background }}
              onPress={toggleCommentContext}
            >
              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <Icon name="timer" size={14} color={timecodeColor} />
                <NewCommentTimecode wipComment={wipComment} color={timecodeColor} style={{ paddingHorizontal: 2 }} />
              </View>
            </TouchableHighlight>
          )}
          <View style={{ flexGrow: 1 }} />

          {/* button */}
          <Animated.View style={buttonStyle}>
            {isSending ? (
              <LoadCircle size={35} color={colors.commentContextToggle.disabled} />
            ) : (
              <ScaleButton
                onPress={submit}
                style={{
                  borderRadius: 150,
                  height: 35,
                  width: 35,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Icon
                  name="send"
                  size={20}
                  color={colors.newCommentMobile.send}
                  style={{ transform: [{ translateX: 1.2 }] }}
                />
              </ScaleButton>
            )}
          </Animated.View>
        </Animated.View>
      </Animated.View>
    </Animated.View>
  );
});

export default NewCommentViewMobile;
