import { useContext } from "react";
import { StoreContext } from "../../../model/Store";
import { useColors } from "../../Colors";
import UserCircle from "../../components/user/UserCircle";
import { useLocation, useNavigate } from "../routing";
import NavbarButton from "./NavbarButton";

export default function NavbarUserButton(props: {
  /* Override action when not signed in */
  onPress?: () => void;
}) {
  const { onPress } = props;
  const colors = useColors().navBar;
  const _store = useContext(StoreContext);
  const location = useLocation();

  const navigate = useNavigate();

  return (
    <NavbarButton
      right
      onPress={
        onPress ??
        (() => {
          navigate(
            _store.me ? "/settings" : `/login?${new URLSearchParams({ redirect: location.pathname + location.search })}`
          );
        })
      }
    >
      <UserCircle size={36} user={_store.me ?? undefined} color={colors.icon} border={1} />
    </NavbarButton>
  );
}
