import React, { ReactNode, useEffect } from "react";
import { Easing, Platform, StyleProp, ViewStyle } from "react-native";
import { TouchableWithoutFeedback } from "react-native-gesture-handler";
import Animated, { useAnimatedStyle, useSharedValue, withTiming } from "react-native-reanimated";
import { useColors } from "../../Colors";

export default function ScaleButton(props: {
  mountAnimationDuration?: number | null;
  unmountAnimationDuration?: number | null;
  onPress?: () => void;
  style?: StyleProp<ViewStyle>;
  children: ReactNode;
}) {
  const { mountAnimationDuration, unmountAnimationDuration, onPress, style, children } = props;
  const colors = useColors().scaleButton;
  const scale = useSharedValue(mountAnimationDuration === undefined ? 1 : 0);
  const animatedStyle = useAnimatedStyle(() => ({ transform: [{ scale: scale.value }] }));
  if (mountAnimationDuration !== undefined)
    useEffect(() => {
      scale.value = withTiming(1, { duration: mountAnimationDuration ?? undefined });
    }, []);
  if (unmountAnimationDuration !== undefined)
    useEffect(
      () => () => {
        scale.value = withTiming(0, { duration: unmountAnimationDuration ?? undefined });
      },
      []
    );

  return (
    <TouchableWithoutFeedback
      onPress={onPress}
      onPressIn={!onPress ? undefined : () => (scale.value = withTiming(0.82, { duration: 500 }))}
      onPressOut={
        !onPress ? undefined : () => (scale.value = withTiming(1, { duration: 200, easing: Easing.elastic(2) }))
      }
    >
      <Animated.View
        style={[
          {
            backgroundColor: onPress !== undefined ? colors.active : colors.disabled,
            ...(Platform.OS === "web" && { cursor: "pointer" }),
          },
          style,
          animatedStyle,
        ]}
      >
        {children}
      </Animated.View>
    </TouchableWithoutFeedback>
  );
}
