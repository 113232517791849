import { IObservableArray, makeObservable, observable, ObservableMap, runInAction, toJS } from "mobx";
import { Result } from "ts-results";
import * as Api from "../Api";
import { CacheableC } from "../cacheable/Cacheable";
import { DeleteShareError } from "../generated/DeleteShareError";
import { comparePermissions } from "../share/comparePermissions";
import { LinkShare, Share, ShareFromJson, ShareJson, UserShare } from "../share/Share";
import { store, unsafeStore } from "../Store";
import { aesDecrypt, base64Decode, importRawKey, uuidToBase64 } from "../util/CryptoHelper";
import BinaryVersion from "../version/BinaryVersion";
import DirectoryVersion from "../version/DirectoryVersion";
import ImageVersion from "../version/ImageVersion";
import { versionGQLFields, VersionJson } from "../version/Version";
import VideoVersion from "../version/VideoVersion";

export class VFile extends CacheableC {
  readonly id: string;
  readonly type: FileType;
  parentDir: VFileDirectory | null;
  encKey: CryptoKey;
  // Map share id to share
  shares: ObservableMap<string, Share>;

  readonly createdAt: Date;

  get sharedBy(): Map<string, Share> {
    return new Map(
      [...this.shares.entries()].filter(
        (sh) => sh instanceof UserShare && sh.to === unsafeStore.me && sh.from !== unsafeStore.me
      )
    );
  }
  get sharedByUsers() {
    return [...new Set([...this.sharedBy.values()].map((s) => s.from))];
  }

  versionIds: IObservableArray<string>;

  /** The link share used to load this file or a parent directory */
  loadedFromLinkShare?: LinkShare;

  get shareCanBeImported() {
    if (!unsafeStore.me) return false;
    const lfs = this.loadedFromLinkShare;
    return (
      lfs &&
      this.shares.has(lfs.id) &&
      ![...this.shares.values()].some((s) => s.isToMe && !comparePermissions(lfs.permissions, s.permissions))
    );
  }

  get showShareMenu(): boolean {
    return (
      ([...this.shares.values()].some(
        (s) => s.isToMe && (s.permissions.share_link || s.permissions.share_user || s.permissions.delete_share)
      ) ||
        this.parentDir?.showShareMenu) ??
      false
    );
  }

  selectedVersionNo: number = -1;

  get selectedVersion() {
    const id = this.versionIds.at(this.selectedVersionNo);
    if (id) return unsafeStore.versions.get(id);
  }

  get unsafeLatestVersion() {
    const id = this.versionIds.at(-1);
    if (id) return unsafeStore.versions.get(id);
  }

  versionsLastUpdatedAt?: number;

  get numberOfVersions() {
    return this.versionIds.length;
  }

  get key() {
    return this.id;
  }

  get path(): VFile[] {
    return [...(this.parentDir?.path ?? []), this];
  }

  private _linkToUrl?: Promise<string>;
  get linkToUrl() {
    if (!this._linkToUrl)
      this._linkToUrl = new Promise<string>(async (resolve) => {
        const s = await this.loadedFromLinkShare?.searchParams();
        resolve(this.linkToUrlNoShare + new URLSearchParams(s));
      });
    return this._linkToUrl;
  }
  private _navigationParams?: Promise<Record<string, string>>;
  get navigationParams() {
    if (!this._navigationParams)
      this._navigationParams = new Promise(async (resolve) => {
        const s = await this.loadedFromLinkShare?.searchParams();
        const id = uuidToBase64(this.id)!;
        resolve({ fileId: id, dir: id, ...s });
      });
    return this._navigationParams;
  }

  get navigateScreen() {
    switch (this.type) {
      case "DIRECTORY":
        try {
          if (
            this.unsafeLatestVersion instanceof DirectoryVersion &&
            this.unsafeLatestVersion.fileTypes.size === 1 &&
            this.unsafeLatestVersion.fileTypes.has("IMAGE")
          )
            return "Preview";
        } catch (e) {
          console.error(
            "linkToUrl: VFile.latestVersion error",
            this.unsafeLatestVersion,
            this.unsafeLatestVersion instanceof DirectoryVersion && this.unsafeLatestVersion.fileTypes,
            "toJS",
            toJS(this.unsafeLatestVersion),
            e
          );
        }
        return "Files";
      case "IMAGE":
      case "VIDEO":
      case "BINARY":
        return "Preview";
    }
  }

  get linkToUrlNoShare() {
    switch (this.type) {
      case "DIRECTORY":
        try {
          if (
            this.unsafeLatestVersion instanceof DirectoryVersion &&
            this.unsafeLatestVersion.fileTypes.size === 1 &&
            this.unsafeLatestVersion.fileTypes.has("IMAGE")
          )
            return `/preview/${uuidToBase64(this.id)}?`;
        } catch (e) {
          console.error(
            "linkToUrl: VFile.latestVersion error",
            this.unsafeLatestVersion,
            this.unsafeLatestVersion instanceof DirectoryVersion && this.unsafeLatestVersion.fileTypes,
            "toJS",
            toJS(this.unsafeLatestVersion),
            e
          );
        }
        return `/?dir=${uuidToBase64(this.id)}&`;
      case "IMAGE":
      case "VIDEO":
      case "BINARY":
        return `/preview/${uuidToBase64(this.id)}?`;
    }
  }

  constructor(params: {
    id: string;
    type: FileType;
    parentDir: VFileDirectory | null;
    versionIds: string[];
    key: CryptoKey;
    shares: Map<string, Share>;
    createdAt: Date;
    loadedFromLinkShare?: LinkShare;
  }) {
    const { id, type, parentDir, versionIds, key, shares, createdAt, loadedFromLinkShare } = params;
    super();
    let v = unsafeStore.versions.get(id);
    if (v) console.warn("constructing new VFile with id that already exists", this);
    this.id = id;
    this.type = type;
    this.parentDir = parentDir;
    this.versionIds = observable.array(versionIds);
    this.encKey = key;
    this.shares = new ObservableMap(shares);
    this.createdAt = createdAt;
    this.loadedFromLinkShare = loadedFromLinkShare;
    makeObservable(this, {
      parentDir: true,
      versionIds: true,
      encKey: true,
      sharedBy: true,
      sharedByUsers: true,
      selectedVersionNo: true,
      selectedVersion: true,
    });
  }

  static async fromJson(
    json: VFileJson,
    params: { parentDir: VFileDirectory } | { key: CryptoKey; share?: Share }
  ): Promise<VFile> {
    const fromJson = fileTypeToFromJson(json.type);

    const _store = await store;
    const shares = new Map<string, Share>();
    if ("share" in params && params.share) shares.set(params.share.id, params.share);

    for (const s of json.shares.map((j) => ShareFromJson(j))) {
      const sh = await s.catch((e) =>
        console.error(console.error("Error while loading share of file " + json.id, json.shares, e))
      );
      if (sh) {
        shares.set(sh.id, sh);
      }
    }

    let fileKey: CryptoKey;
    if ("key" in params) {
      fileKey = params.key;
    } else if (json.key) {
      fileKey = await importRawKey(
        await aesDecrypt(params.parentDir.encKey, base64Decode(json.key), new ArrayBuffer(16))
      );
    } else {
      throw new Error("Key must be provided in params or encrypted in JSON");
    }

    const file = runInAction(() => {
      let file = _store.files.get(json.id);

      if (file) {
        console.assert(file.id === json.id);
        console.assert(file.type === json.type);
        file.parentDir = "parentDir" in params ? params.parentDir : null;
        file.versionIds.replace(json.versionIds);
        file.encKey = fileKey;
        file.shares.replace(shares);
      } else {
        file = new VFile({
          id: json.id,
          type: json.type,
          parentDir: "parentDir" in params ? params.parentDir : null,
          versionIds: json.versionIds,
          key: fileKey,
          shares,
          createdAt: new Date(json.createdAt),
          loadedFromLinkShare:
            "share" in params && params.share instanceof LinkShare
              ? params.share
              : "parentDir" in params
              ? params.parentDir.loadedFromLinkShare
              : undefined,
        });
      }
      _store.files.set(json.id, file!);
      return file;
    });
    // TODO: remove @ts-ignore
    // @ts-ignore
    if (json.latestVersion !== undefined) await fromJson(json.latestVersion, { file, fileKey });
    return file;
  }

  async deleteShare(id: string): Promise<Result<void, Api.Error<DeleteShareError>>> {
    return (
      await Api.req<void, DeleteShareError>({
        endpoint: "/share/" + id,
        token: (await store).me!.token.t,
        method: "DELETE",
      })
    ).map((_) => {
      this.shares.delete(id);
    });
  }
}

export type VFileDirectory = VFile & {
  type: "DIRECTORY";
  selectedVersion?: DirectoryVersion;
  latestVersion?: DirectoryVersion;
};

export type VFileJson<S extends Object = {}> = {
  id: string;
  type: FileType;
  key?: string;
  createdAt: string;
  shares: (ShareJson & S)[];
  versionIds: string[];
  latestVersion?: VersionJson;
};

export type VFileJsonWithLatestVersion<S extends Object = {}> = VFileJson<S> & { latestVersion: VersionJson };

export type FileType = "BINARY" | "DIRECTORY" | "IMAGE" | "VIDEO";

export const fileGQLFields = `
  id
  type
  createdAt
  versionIds
`;

export const fileGQLFieldsWithLatestVersion = `
  ${fileGQLFields}
  latestVersion {
    ${versionGQLFields}
  }
`;

export function fileTypeToFromJson(type: FileType) {
  switch (type) {
    case "DIRECTORY":
      return DirectoryVersion.fromJson;
    case "IMAGE":
      return ImageVersion.fromJson;
    case "VIDEO":
      return VideoVersion.fromJson;
    case "BINARY":
      return BinaryVersion.fromJson;
  }
}
