import { reaction, runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useEffect, useRef } from "react";
import { Platform, View } from "react-native";
import { FlatList } from "react-native-gesture-handler";
import Animated, { SharedValue, interpolate, useAnimatedStyle } from "react-native-reanimated";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import useKeyboardShortcut from "use-keyboard-shortcut";
import { VFile } from "../../../model/file/VFile";
import { useFiles } from "../../../model/file/useFiles";
import DirectoryVersion from "../../../model/version/DirectoryVersion";
import { useVersion } from "../../../model/version/useVersion";
import { useColors } from "../../Colors";
import GallerySelectorImage from "./GallerySelectorImage";

const GallerySelector = observer((props: { file: VFile; commentsVisible: Readonly<SharedValue<number>> }) => {
  const { file, commentsVisible } = props;
  const { data: dir } = useVersion(file);
  if (!(dir instanceof DirectoryVersion || !dir)) return <></>;
  const { data: files } = useFiles({ parentDir: dir });
  const colors = useColors().imageSelector;
  const ref = useRef<FlatList<VFile | undefined>>(null);

  useEffect(
    () =>
      reaction(
        () => dir?.selectedFile,
        (arg, prev, r) => {
          if (arg) ref.current?.scrollToItem({ item: arg, viewPosition: 0.5 });
        }
      ),
    [dir]
  );

  const insets = useSafeAreaInsets();
  const animatedStyle = useAnimatedStyle(() => ({
    width: interpolate(commentsVisible.value, [0, 1], [insets.right, 0]),
  }));

  const listData = [...(files ?? []), undefined];

  if (Platform.OS === "web") {
    useKeyboardShortcut(["ArrowLeft"], () => {
      if (!files) return;
      const i = files.findIndex((v) => v.id === dir?.selectedFile?.id) - 1;
      if (i < 0) return;
      const f = files.at(i);
      if (!f) return;
      runInAction(() => {
        if (dir) dir.selectedFile = f;
      });
    });
    useKeyboardShortcut(["ArrowRight"], () => {
      if (!files) return;
      const i = files.findIndex((v) => v.id === dir?.selectedFile?.id) + 1;
      if (i < 0) return;
      const f = files.at(i);
      if (!f) return;
      runInAction(() => {
        if (dir) dir.selectedFile = f;
      });
    });
  }

  return (
    <View style={{ height: 100, backgroundColor: colors.background }}>
      <FlatList
        horizontal
        style={{ paddingLeft: insets.left, paddingRight: insets.right }}
        ref={ref}
        keyExtractor={(item, index) => `${item?.key ?? "spacer"}`}
        data={listData}
        renderItem={({ item, index }) =>
          item === undefined ? (
            <Animated.View style={animatedStyle} />
          ) : (
            <GallerySelectorImage file={item} index={index} />
          )
        }
        showsHorizontalScrollIndicator={false}
      />
    </View>
  );
});

export default GallerySelector;
