import { useCallback, useContext } from "react";
import { StoreContext } from "../Store";
import { CacheableResult } from "../cacheable/CacheableResult";
import { useCacheable } from "../cacheable/useCacheable";
import { VFile, VFileDirectory } from "./VFile";
import { fetchFile } from "./fetchFile";
import { updateFile } from "./updateFile";

/// Please note that changes to `shareKey` will not result in refetching
export function useFile<T extends VFile | null = VFile>(
  id?: T extends null ? null : string,
  params?: { parentDir?: VFileDirectory; share?: string; shareKey?: ArrayBuffer }
): CacheableResult<T> {
  const { parentDir, share, shareKey } = params ?? {};
  const _store = useContext(StoreContext);
  const fetch = useCallback(
    () =>
      id == null
        ? null
        : (fetchFile(
            id,
            share && shareKey ? { share, shareKey } : parentDir ? { parentDir } : undefined
          ) as Promise<T>),
    [id, parentDir, share]
  );
  const data = (id == null ? null : id === undefined ? undefined : _store.files.get(id)) as T | undefined;
  return useCacheable<T>({
    data,
    // @ts-ignore
    fetch,
    // @ts-ignore
    update: updateFile,
    enabled: id !== undefined,
  });
}
