import * as React from "react";
import { StyleProp, View, ViewStyle } from "react-native";

export default function ColoredBars(props: {
  colors: string[];
  n: number;
  style?: StyleProp<ViewStyle>;
  barStyle?: StyleProp<ViewStyle>;
}) {
  const { colors, n, style, barStyle } = props;
  return (
    <View style={[{ flexDirection: "row" }, style]}>
      {colors.map((c, i) => (
        <View key={i} style={[barStyle, { flexGrow: 1, backgroundColor: i <= n ? c : undefined }]} />
      ))}
    </View>
  );
}
