import { runInAction } from "mobx";
import { store } from "../Store";
import { VFile } from "../file/VFile";
import FileVersion, { FileVersionCiphertextJson, FileVersionJson, FileVersionParams } from "./FileVersion";
import { versionParamsFromJson } from "./Version";

export default class BinaryVersion extends FileVersion {
  static async fromJson(json: FileVersionJson, params: { file: VFile }): Promise<BinaryVersion> {
    const { file } = params;
    const { id, encKey, createdAt, ciphertext, state } = (await versionParamsFromJson(json, {
      file,
    })) as FileVersionParams & {
      ciphertext: FileVersionCiphertextJson;
    };
    const _store = await store;
    const v = _store.versions.get(id) as BinaryVersion;
    if (v) {
      return runInAction(() => {
        console.assert(v instanceof BinaryVersion);
        console.assert(v.id === id);
        console.assert(v.file === params.file);
        v.size = json.size;
        v.encKey = encKey;
        // TODO: update state
        console.assert(v.createdAt.valueOf() === createdAt.valueOf());
        v.name = ciphertext.name;
        v.originalMimeType = ciphertext.original_mime_type;
        return v;
      });
    }
    return runInAction(() => {
      const v = new BinaryVersion({
        id,
        file,
        encKey,
        name: ciphertext.name,
        size: json.size,
        originalMimeType: ciphertext.original_mime_type,
        createdAt,
        state,
      });
      _store.versions.set(v.id, v);
      return v;
    });
  }
}
