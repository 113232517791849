import * as Api from "../Api";
import { store } from "../Store";
import { VFile } from "../file/VFile";
import { comparePermissions } from "../share/comparePermissions";
import { graphqlify } from "../util/graphqlify";
import { Permissions, PermissionsData } from "./Permissions";
import { LinkShare, Share, UserShare } from "./Share";

export async function updateShare(params: { share: Share; file: VFile; permissions: Permissions }) {
  const { share, file, permissions } = params;
  const me = (await store).me!;
  const parents = file.path.flatMap((f) =>
    [...f.shares.values()]
      .filter(
        (s) =>
          (s instanceof LinkShare || (s instanceof UserShare && s.to.id == me.id)) &&
          (s.permissions.share_user || (s instanceof UserShare && s.to == me)) &&
          comparePermissions(s.permissions, permissions)
      )
      .map((s) => s.id)
  );
  // prettier-ignore
  const r: { permissions: PermissionsData } = (await Api.gql(`mutation {
    updateShare(
      id: "${share.id}"
      permissions: ${graphqlify(permissions.data)}
      parents: ${graphqlify(parents)}
    ) {
      permissions
    }
  }`, `Bearer ${me.token.t}`)).updateShare;
  share.permissions.data = r.permissions;
}
