// hash

export async function keyhash(key: ArrayBuffer, salt: string): Promise<CryptoKey> {
  let s = Buffer.from(salt, "utf-8");
  let a = new Uint8Array(key.byteLength + s.byteLength);
  a.set(new Uint8Array(key), 0);
  a.set(s, key.byteLength);
  return importRawKey(await crypto.subtle.digest("SHA-256", a));
}

export function pwhash(password: string, salt: ArrayBuffer): Promise<ArrayBuffer> {
  let p = Buffer.from(password, "utf-8");
  let a = new Uint8Array(p.byteLength + salt.byteLength);
  a.set(p, 0);
  a.set(new Uint8Array(salt), p.byteLength);
  return crypto.subtle.digest("SHA-512", a);
}

export function shasum(s: string): Promise<ArrayBuffer> {
  return crypto.subtle.digest("SHA-512", Buffer.from(s, "utf-8"));
}

// random

export function randomBytes(length: number): Uint8Array {
  return crypto.getRandomValues(new Uint8Array(length));
}

// AES

export function aesEncrypt(key: CryptoKey, data: ArrayBuffer, iv: ArrayBuffer): Promise<ArrayBuffer> {
  return crypto.subtle.encrypt({ name: "AES-CBC", iv }, key, data);
}

export function aesDecrypt(key: CryptoKey, data: ArrayBuffer, iv: ArrayBuffer): Promise<ArrayBuffer> {
  return crypto.subtle.decrypt({ name: "AES-CBC", iv }, key, data);
}

export function decryptStream(params: { key: CryptoKey; iv: ArrayBuffer; size: number }) {
  let ivs = [params.iv];
  let streamed = 0;
  return new TransformStream<Uint8Array, Uint8Array>({
    transform: async (chunk, controller) => {
      try {
        streamed += chunk.byteLength;
        const iv = chunk.slice(-16);
        // the crypto API assumes the input to be padded, which is not the case in our stream -> we do the padding
        if (chunk.length >= 16 && streamed < params.size) {
          const padding = new Uint8Array(
            await aesEncrypt(
              params.key,
              new Uint8Array(16).map(() => 16),
              iv
            )
          );
          chunk = new Uint8Array([...chunk, ...padding.slice(0, 16)]);
        }
        let decrypted = await aesDecrypt(params.key, chunk, ivs.at(-1)!);
        ivs.push(iv);

        controller.enqueue(new Uint8Array(decrypted));
      } catch (e) {
        console.error(e);
      }
    },
  });
}

export function generateAesKey(): Promise<CryptoKey> {
  return crypto.subtle.generateKey({ name: "AES-CBC", length: 256 }, true, ["decrypt", "encrypt"]);
}

// RSA

export function generateRsaKeyPair(): Promise<CryptoKeyPair> {
  return crypto.subtle.generateKey(
    {
      name: "RSA-OAEP",
      modulusLength: 4096,
      publicExponent: new Uint8Array([0x01, 0x00, 0x01]),
      hash: "SHA-512",
    },
    true,
    ["decrypt", "encrypt"]
  );
}

export function rsaEncrypt(key: CryptoKey, data: ArrayBuffer): Promise<ArrayBuffer> {
  return crypto.subtle.encrypt({ name: "RSA-OAEP" }, key, data);
}

export function rsaDecrypt(key: CryptoKey, data: ArrayBuffer): Promise<ArrayBuffer> {
  return crypto.subtle.decrypt({ name: "RSA-OAEP" }, key, data);
}

// keys

export function exportRawKey(key: CryptoKey): Promise<ArrayBuffer> {
  return crypto.subtle.exportKey("raw", key);
}

export function importRawKey(key: ArrayBuffer): Promise<CryptoKey> {
  return crypto.subtle.importKey("raw", key, "AES-CBC", true, ["decrypt", "encrypt"]);
}

export function importPubkey(key: ArrayBuffer): Promise<CryptoKey> {
  return crypto.subtle.importKey("spki", key, { name: "RSA-OAEP", hash: "SHA-512" }, false, ["encrypt"]);
}

export function importPrivkey(key: ArrayBuffer): Promise<CryptoKey> {
  return crypto.subtle.importKey("pkcs8", key, { name: "RSA-OAEP", hash: "SHA-512" }, false, ["decrypt"]);
}

// base64

export function base64Encode(data: ArrayBuffer): string {
  return Buffer.from(data).toString("base64");
}
export function base64Decode(s: string): ArrayBuffer {
  return Buffer.from(s, "base64");
}

export function base64ToUrl(s: string): string {
  return s.replaceAll("=", "").replaceAll("+", "-").replaceAll("/", "_");
}

export function base64FromUrl(s: string): string {
  return s
    .replaceAll("-", "+")
    .replaceAll("_", "/")
    .padEnd(Math.ceil(s.length / 3) * 3, "=");
}

// base64 UUID

export function uuidToBase64(uuid: string): string | null {
  try {
    return base64ToUrl(Buffer.from(uuid.replaceAll("-", ""), "hex").toString("base64"));
  } catch {
    return null;
  }
}

export function uuidFromBase64(base64?: string): string | undefined {
  if (base64 === undefined) return;
  try {
    const h = Buffer.from(base64FromUrl(base64), "base64").toString("hex");
    if (h.length !== 32) return undefined;
    const r = `${h.substring(0, 8)}-${h.substring(8, 12)}-${h.substring(12, 16)}-${h.substring(16, 20)}-${h.substring(
      20,
      32
    )}`;
    return r.length === 36 ? r : undefined;
  } catch (e) {
    console.error("Error while parsing uuid", e);
  }
}

// comment relations

export async function getRelation(id: string, key: CryptoKey, n: number) {
  const i = Buffer.from(id, "utf-8");
  const ek = await exportRawKey(key);
  const a = new Uint8Array(i.length + ek.byteLength + 4);
  a.set(i, 0);
  a.set(new Uint8Array(ek), i.length);
  a.set(new Uint8Array(new Int32Array([n]).buffer), i.length + ek.byteLength);
  return crypto.subtle.digest("SHA-512", a);
}
