import { makeAutoObservable } from "mobx";
import { unsafeStore } from "../Store";

export class Permissions implements DetailedPermissionsData {
  data: PermissionsData;

  get create_file(): boolean {
    switch (this.data.type) {
      case "owner":
        return true;
      case "simple":
        return this.data.upload;
      case "detailed":
        return this.data.create_file;
    }
  }
  get add_version(): boolean {
    switch (this.data.type) {
      case "owner":
        return true;
      case "simple":
        return this.data.edit;
      case "detailed":
        return this.data.add_version;
    }
  }
  get rename(): boolean {
    switch (this.data.type) {
      case "owner":
        return true;
      case "simple":
        return this.data.edit;
      case "detailed":
        return this.data.rename;
    }
  }
  get delete(): boolean {
    switch (this.data.type) {
      case "owner":
        return true;
      case "simple":
        return this.data.edit;
      case "detailed":
        return this.data.delete;
    }
  }
  get create_comment(): boolean {
    switch (this.data.type) {
      case "owner":
        return true;
      case "simple":
        return this.data.comment;
      case "detailed":
        return this.data.create_comment;
    }
  }
  get edit_comment(): boolean {
    switch (this.data.type) {
      case "owner":
        return true;
      case "simple":
        return this.data.moderate;
      case "detailed":
        return this.data.edit_comment;
    }
  }
  get delete_comment(): boolean {
    switch (this.data.type) {
      case "owner":
        return true;
      case "simple":
        return this.data.moderate;
      case "detailed":
        return this.data.delete_comment;
    }
  }
  get share_user(): boolean {
    switch (this.data.type) {
      case "owner":
        return true;
      case "simple":
        return this.data.share;
      case "detailed":
        return this.data.share_user;
    }
  }
  get share_link(): boolean {
    switch (this.data.type) {
      case "owner":
        return true;
      case "simple":
        return this.data.share;
      case "detailed":
        return this.data.share_link;
    }
  }
  get delete_share(): boolean {
    switch (this.data.type) {
      case "owner":
        return true;
      case "simple":
        return false;
      case "detailed":
        return this.data.delete_share;
    }
  }

  get owner(): boolean {
    return this.data.type === "owner";
  }
  get simple(): SimplePermissions | undefined {
    if (this.data.type === "simple") return this.data;
  }
  get detailed(): DetailedPermissions | undefined {
    if (this.data.type === "detailed") return this.data;
  }

  get asSimple(): SimplePermissions {
    switch (this.data.type) {
      case "owner":
        return ownerToSimple();
      case "simple":
        return this.data;
      case "detailed":
        return detailedToSimple(this.data);
      default:
        throw new Error(`Unexpected Permissions.type`, { cause: this.data });
    }
  }
  get asDetailed(): DetailedPermissions {
    switch (this.data.type) {
      case "owner":
        return ownerToDetailed();
      case "simple":
        return simpleToDetailed(this.data);
      case "detailed":
        return this.data;
      default:
        throw new Error(`Unexpected Permissions.type`, { cause: this.data });
    }
  }

  constructor(data: PermissionsData) {
    this.data = data;
    makeAutoObservable(this, {
      data: true,
    });
  }

  setOwner(b: boolean) {
    if (b) {
      this.data = { type: "owner" };
    } else {
      this.data = unsafeStore.me!.defaultPermissions().data;
    }
    console.log(this.data.type, this.owner);
  }
  setDetailed(b: boolean) {
    if (b) this.data = this.asDetailed;
    else this.data = this.asSimple;
  }
}

function detailedToSimple(detailed: DetailedPermissions): SimplePermissions {
  return {
    type: "simple",
    upload: detailed.create_file,
    edit: detailed.add_version && detailed.rename && detailed.delete,
    comment: detailed.create_comment,
    moderate: detailed.edit_comment && detailed.delete_comment,
    share: detailed.share_user && detailed.share_link,
  };
}

function simpleToDetailed(simple: SimplePermissions): DetailedPermissions {
  return {
    type: "detailed",
    create_file: simple.upload,
    add_version: simple.edit,
    rename: simple.edit,
    delete: simple.edit,
    create_comment: simple.comment,
    edit_comment: simple.moderate,
    delete_comment: simple.moderate,
    share_user: simple.share,
    share_link: simple.share,
    delete_share: false,
  };
}

function ownerToDetailed(): DetailedPermissions {
  return {
    type: "detailed",
    create_file: true,
    add_version: true,
    create_comment: true,
    delete: true,
    edit_comment: true,
    delete_comment: true,
    rename: true,
    share_link: true,
    share_user: true,
    delete_share: true,
  };
}

function ownerToSimple(): SimplePermissions {
  return {
    type: "simple",
    upload: true,
    edit: true,
    comment: true,
    moderate: true,
    share: true,
  };
}

export type DetailedPermissionsData = {
  /** Create/upload files in a directory (only for dirs). */
  create_file: boolean;
  /** Add new versions to existing files. */
  add_version: boolean;
  /** Rename files in a directory (only for dirs). */
  rename: boolean;
  /** Delete files in a directory (only for dirs). */
  delete: boolean;
  /** Write new comments for dir and files in dir. */
  create_comment: boolean;
  /** Edit comments not owned by the user. Own comments can always be edited. */
  edit_comment: boolean;
  /** Delete comments not written by the user. */
  delete_comment: boolean;
  /** Share with users. Users can always create shares to themselves from link shares. */
  share_user: boolean;
  /** Create new link share. */
  share_link: boolean;
  /** Delete shares not created by the user. */
  delete_share: boolean;
};

export type DetailedPermissions = { type: "detailed" } & DetailedPermissionsData;

export type SimplePermissions = {
  type: "simple";
  /** create_file (only for dirs) */
  upload: boolean;
  /** add_version, rename, delete */
  edit: boolean;
  /** create_comment */
  comment: boolean;
  /** edit_comment, detete_comment */
  moderate: boolean;
  /** share_user, share_link */
  share: boolean;
};

export type PermissionsData = { type: "owner" } | SimplePermissions | DetailedPermissions;
