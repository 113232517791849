import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { StoreContext } from "../../../model/Store";
import { useColors } from "../../Colors";
import Icon from "../../components/icon/Icon";
import MenuDropdown from "../../components/menu/menu-item/MenuDropdown";
import MenuLink from "../../components/menu/menu-item/MenuLink";
import UserCard from "../../components/user/UserCard";

const UsersDropdown = observer(() => {
  const _store = useContext(StoreContext);
  const colors = useColors().menu.dropdown;
  return (
    <MenuDropdown
      data={[..._store.meUsers.values()]}
      selected={_store.me}
      setSelected={({ item }) => (_store.me = item)}
      // TODO: better null handling?
      renderItem={({ item }) => item && <UserCard user={item} style={{ margin: 2 }} />}
    >
      <MenuLink title="Account Settings" to="account">
        <Icon name="settings" color={colors.text} size={24} style={{ padding: 10 }} />
      </MenuLink>
    </MenuDropdown>
  );
});

export default UsersDropdown;
