import TranscodingServer, { getTranscodingServer } from "../transcoding-server/TranscodingServer";

/**
 * VersionState
 *
 * Contains information about the current state of a file or directory.
 *
 * The VersionState is partially stored on the API Server.
 * This only includes a `current` enum and some information regarding how to retrieve more detailed state data and live updates.
 */
export interface VersionState {
  // Also stored on the API Server.
  current: VersionStateEnum;

  // Fracs must be provided by implementers. These are usually related to either the file itself or all files in a directory.
  fileUploadFrac: number | null;
  // Fracs related to transcoding can be received from the Transcoding Server.
  transcodeDownloadFrac: number | null;
  transcodeFrac: number | null;
  transcodeUploadFrac: number | null;

  // TODO: currently mostly not implemented
  eta: number | undefined;

  jobId?: string;

  // Reference to the responsible Transcoding Server.
  // TODO: move to `Fil`
  transcodingServer?: TranscodingServer;
}

export function VersionStateFromServer(data: VersionStateJson): VersionState {
  let transcodingServer = data.job
    ? getTranscodingServer({ url: data.job.transcodingServer, name: undefined })
    : undefined;
  const current = VersionStateEnumFromString(data.current);
  let tf;
  switch (current) {
    case VersionStateEnum.upload:
    case VersionStateEnum.uploadDone:
      tf = 0;
      break;
    case VersionStateEnum.transcode:
      tf = null;
      break;
    case VersionStateEnum.done:
      tf = 1;
      break;
  }
  const r = {
    current,
    fileUploadFrac: data.job ? 1 : null,
    transcodeDownloadFrac: tf,
    transcodeFrac: tf,
    transcodeUploadFrac: tf,
    eta: 0,
    transcodingServer,
    jobId: data.job?.id,
  };
  if (current === VersionStateEnum.transcode && transcodingServer !== undefined) transcodingServer.subscribeToJob(r);
  return r;
}

export enum VersionStateEnum {
  upload,
  uploadDone,
  transcode,
  done,
}

function VersionStateEnumFromString(s: string): VersionStateEnum {
  switch (s) {
    case "UPLOAD":
      return VersionStateEnum.upload;
    case "UPLOAD_DONE":
      return VersionStateEnum.uploadDone;
    case "TRANSCODE":
      return VersionStateEnum.transcode;
    case "DONE":
      return VersionStateEnum.done;
    default:
      console.error("Unknown ServiceStateEnum", s);
      return VersionStateEnum.done;
  }
}

export function versionStateMessage(state: VersionStateEnum): string | undefined {
  switch (state) {
    case VersionStateEnum.upload:
      return "uploading";
    case VersionStateEnum.transcode:
      return "transcoding";
    case VersionStateEnum.done:
      return undefined;
  }
}

export type VersionStateJson = {
  current: string;
  job?: { id: string; transcodingServer: string };
};
