import { observer } from "mobx-react-lite";
import { useContext, useEffect, useMemo } from "react";
import { Platform, View } from "react-native";
import { DraxProvider } from "react-native-drax";
import Animated, { interpolate, useAnimatedStyle, useSharedValue, withTiming } from "react-native-reanimated";
import { StoreContext } from "../../../model/Store";
import { VFileDirectory } from "../../../model/file/VFile";
import { useFile } from "../../../model/file/useFile";
import { useShareFromUrl } from "../../../model/hooks/useShareFromUrl";
import FilesScreenStore, { FilesScreenStoreContext } from "../../../model/ui/FilesScreenStore";
import { uploadDataTransferItemList } from "../../../model/upload/entrypoints/uploadFiles";
import { uuidFromBase64 } from "../../../model/util/CryptoHelper";
import { useWindowDimensions } from "../../../model/util/useWindowDimensions.web";
import DirectoryVersion from "../../../model/version/DirectoryVersion";
import { useVersion } from "../../../model/version/useVersion";
import { useColors } from "../../Colors";
import styles from "../../Styles";
import CommentSortDropdown from "../../components/comments/CommentSortDropdown";
import CommentsView from "../../components/comments/CommentsView";
import DnDView from "../../components/drag-and-drop/DnDView";
import ImportShareModal from "../../modals/ImportShareModal";
import { useNavigate, useSearchParams } from "../../navigation/routing";
import { useResetNavigationState } from "../../navigation/useResetNavigationState";
import { interpolateColor } from "../../util/interpolateColor";
import FilesGrid from "./FilesGrid";
import FilesScreenNavbar from "./FilesScreenNavbar";
import FilesScreenSeparator from "./FilesScreenSeparator";

const FilesScreen = observer(() => {
  const [searchParams, _setSerchParams] = useSearchParams();

  const colors = useColors().filesScreen;
  const dirId = uuidFromBase64(searchParams.get("dir") ?? undefined) ?? null;
  const v = searchParams.get("v");
  const { share, shareKey } = useShareFromUrl();

  const { data: directory, error: dirFileError } = useFile<VFileDirectory | null>(dirId, { share, shareKey });
  const { data: dirVersion } = useVersion<DirectoryVersion>(directory ?? undefined, v ? Number.parseInt(v) : undefined);

  const fsStore = useMemo(() => new FilesScreenStore({}), [dirId]);
  const _store = useContext(StoreContext);
  const navigate = useNavigate();
  useEffect(() => {
    if (!(_store.me || (share && shareKey)) && (dirFileError !== undefined || dirId === null)) {
      console.log("navigate", navigate);
      navigate("/signup?" + new URLSearchParams({ redirect: "/?" + searchParams ?? "" }));
      console.log("navigated", navigate);
    }
  }, [share, shareKey, dirFileError]);

  useResetNavigationState(directory);

  const isReceivingFiles = useSharedValue(0);
  const dimensions = useWindowDimensions();
  const commentsVisible = useSharedValue(0);
  const commentsWidth = useSharedValue(0.375);
  const rightStyle = useAnimatedStyle(() => ({
    flex: commentsWidth.value * (1 / Math.max(commentsWidth.value, 1 - commentsWidth.value)) * commentsVisible.value,
    minWidth: interpolate(commentsVisible.value, [0, 1], [0, 300]),
  }));
  const leftStyle = useAnimatedStyle(() => ({
    flex: (1 - commentsWidth.value) * (1 / Math.max(commentsWidth.value, 1 - commentsWidth.value)),
    backgroundColor: interpolateColor(isReceivingFiles.value, [colors.background, colors.hover]),
  }));

  return (
    <FilesScreenStoreContext.Provider value={fsStore}>
      <DraxProvider>
        <View style={{ flex: 1 }}>
          <FilesScreenNavbar
            directory={directory}
            commentsVisible={commentsVisible}
            toggleComments={() => (commentsVisible.value = withTiming(commentsVisible.value === 1 ? 0 : 1))}
          />
          <View style={{ flexDirection: "row", flex: 1 }}>
            <Animated.View style={[leftStyle, { minWidth: 300 }]}>
              <DnDView
                onDragEnter={(e) => {
                  if (e.dataTransfer.types.some((v) => v === "Files") && directory !== undefined) {
                    isReceivingFiles.value = withTiming(1);
                  }
                }}
                onDragExit={() => {
                  isReceivingFiles.value = withTiming(0, { duration: 200 });
                }}
                onDrop={(e) => {
                  isReceivingFiles.value = 0;
                  if (directory !== undefined) uploadDataTransferItemList(e.dataTransfer.items, directory);
                }}
                style={{ flex: 1, height: "100%" }}
              >
                <FilesGrid parentDir={dirId === null ? null : dirVersion} />
              </DnDView>
            </Animated.View>

            {/* separator */}
            <FilesScreenSeparator
              visible={commentsVisible}
              onGestureEvent={(e) => {
                const { absoluteX } = e.nativeEvent;
                commentsWidth.value = 1 - absoluteX / dimensions.width;
                commentsVisible.value = withTiming(absoluteX > dimensions.width - 150 ? 0 : 1);
              }}
            />

            {/* right, comments */}
            <Animated.View
              style={[
                rightStyle,
                { height: Platform.OS === "web" ? dimensions.height - 64 : undefined, overflow: "hidden" },
              ]}
            >
              <View style={{ minWidth: 300, height: "100%" }}>
                <View style={styles.row}>
                  <CommentSortDropdown color={colors.text} style={{ padding: 12 }} />
                </View>
                {directory && <CommentsView file={directory} />}
              </View>
            </Animated.View>
          </View>

          {directory && <ImportShareModal file={directory} />}
        </View>
      </DraxProvider>
    </FilesScreenStoreContext.Provider>
  );
});

export default FilesScreen;
