import { forwardRef } from "react";
import { StyleProp, TextInput, TextInputProps, View, ViewStyle } from "react-native";
import { useColors } from "../../Colors";

const Input = forwardRef<TextInput, TextInputProps & { containerStyle?: StyleProp<ViewStyle> }>((props, ref) => {
  const colors = useColors().modal.input;
  return (
    <View style={[{ padding: 4, backgroundColor: colors.background, borderRadius: 8 }, props.containerStyle]}>
      <TextInput
        ref={ref}
        {...props}
        style={[
          {
            color: colors.text,
            margin: 3,
            paddingVertical: 4,
            flexGrow: 1,
            maxWidth: "100%",
          },
          props.style,
        ]}
      />
    </View>
  );
});

export default Input;
