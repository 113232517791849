import { observer } from "mobx-react-lite";
import { useEffect, useMemo, useState } from "react";
import { Text, View } from "react-native";
import { PanGestureHandler, State } from "react-native-gesture-handler";
import Animated, { Easing, interpolate, useAnimatedStyle, useSharedValue, withTiming } from "react-native-reanimated";
import { VFile } from "../../../model/file/VFile";
import { useFiles } from "../../../model/file/useFiles";
import DirectoryVersion from "../../../model/version/DirectoryVersion";
import { useVersion } from "../../../model/version/useVersion";
import { useColors } from "../../Colors";
import styles from "../../Styles";
import ErrorScreen from "../ErrorScreen";
import GalleryContent from "./GalleryContent";

const GalleryPreview = observer((props: { file: VFile }) => {
  const colors = useColors().loadScreen; // TODO: colors?
  const { data: dir } = useVersion(props.file);
  if (!(dir instanceof DirectoryVersion)) return <ErrorScreen msg="Expected file to be a directory" />;
  const { data: files } = useFiles({ parentDir: dir });
  const selectedIndex = useMemo(
    () => files?.findIndex((v) => v.id === dir.selectedFile?.id),
    [files, dir.selectedFile?.id]
  );

  const [width, setWidth] = useState(0);
  const animation = useSharedValue(0);
  const animatedStyle = useAnimatedStyle(() => ({
    transform: [{ translateX: interpolate(animation.value, [-1, 1], [-width, width]) }],
  }));

  useEffect(() => {
    if (dir && !dir.selectedFile) dir.selectedFile = files.at(0) ?? null;
  }, [dir, dir?.selectedFile, files.at(0)]);

  if (selectedIndex === undefined || selectedIndex < 0)
    return (
      <View style={styles.container}>
        <Text style={{ color: colors.text, textAlign: "center" }}>No file selected</Text>
      </View>
    );

  const file = dir.selectedFile!;
  const previousFile = files?.[selectedIndex - 1];
  const nextFile = files?.[selectedIndex + 1];

  return (
    <PanGestureHandler
      enableTrackpadTwoFingerGesture
      activeOffsetX={[-10, 10]}
      failOffsetY={10}
      onGestureEvent={(e) => {
        let v = e.nativeEvent.translationX / width;
        if (v < 0 && !nextFile) v = -(1 - Math.E ** v) / 2;
        if (v > 0 && !previousFile) v = (1 - Math.E ** -v) / 2;
        animation.value = v;
      }}
      onHandlerStateChange={(e) => {
        switch (e.nativeEvent.state) {
          case State.BEGAN:
            break;
          case State.END:
            const { translationX, velocityX } = e.nativeEvent;
            const p = 2;
            const d = 1 - Math.abs(translationX / width);
            const duration = (p / Math.abs(velocityX)) * 1000;
            if (previousFile && translationX > 0 && translationX + Math.E ** (2 * velocityX) > width / 2) {
              // TODO: improve easing
              animation.value = withTiming(1, { easing: (v) => 1 - (1 - Math.abs(v)) ** p, duration });
              setTimeout(() => {
                animation.value = 0;
                dir.selectedFile = previousFile;
              }, duration);
            } else if (nextFile && translationX < 0 && -translationX + Math.E ** (2 * -velocityX) > width / 2) {
              // TODO: improve easing
              animation.value = withTiming(-1, { easing: (v) => 1 - (1 - v) ** p, duration });
              setTimeout(() => {
                animation.value = 0;
                dir.selectedFile = nextFile;
              }, duration);
            } else {
              const t = (1 - (1 - Math.E ** -Math.abs(d)) / 2) * 500;
              animation.value = withTiming(0, { easing: Easing.bezier(0.1, 0, 0.3, 1), duration: t });
            }
        }
      }}
    >
      <View
        style={{ width: "100%", height: "100%", overflow: "hidden" }}
        onLayout={(e) => setWidth(e.nativeEvent.layout.width)}
      >
        <Animated.View
          style={[
            animatedStyle,
            {
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            },
          ]}
        >
          {previousFile ? <GalleryContent file={previousFile} /> : <View style={{ width: "100%" }} />}
          <GalleryContent file={file} />
          {nextFile ? <GalleryContent file={nextFile} /> : <View style={{ width: "100%" }} />}
        </Animated.View>
      </View>
    </PanGestureHandler>
  );
});

export default GalleryPreview;
