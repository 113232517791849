import { makeObservable, observable } from "mobx";
import { store, unsafeStore } from "../Store";
import { VFile } from "../file/VFile";
import { LinkShare, UserShare } from "../share/Share";
import { WipUserShare } from "../share/WipUserShare";
import { User } from "../user/User";

export class ShareModalStore {
  readonly file: VFile;
  /** Used to temporarily save user shares before they are actually created on the server. This is useful to reduce requests when editing a share's permissions after creation. */
  newUserShares = observable.array<WipUserShare>();

  get allUserShares() {
    return [
      ...this.newUserShares.values(),
      ...(
        [...this.file.shares.values()].filter((s) => s instanceof UserShare && s.to !== unsafeStore.me) as UserShare[]
      ).sort((a, b) => a.createdAt.valueOf() - b.createdAt.valueOf()),
    ];
  }
  get allUserSharesMap() {
    return new Map(this.allUserShares.map((s) => [s.to.id, s.to]));
  }

  get linkShares() {
    return [...this.file.shares.values()].filter((v) => v instanceof LinkShare) as LinkShare[];
  }

  get allShares(): (LinkShare | UserShare | WipUserShare)[] {
    return [...this.allUserShares, ...this.linkShares];
  }

  constructor(file: VFile) {
    this.file = file;
    makeObservable(this, { newUserShares: true, allUserShares: true, createUserShare: true });
  }

  async createUserShare(to: User, label?: string) {
    const me = (await store).me!;
    const share = new WipUserShare({
      label,
      to,
      from: me,
      createdAt: new Date(),
      permissions: me.defaultPermissions(),
      file: this.file,
      store: this,
    });
    this.newUserShares.push(share);
    return share;
  }

  /**
   * This should be called
   */
  async deinit() {
    for (const share of this.newUserShares.values()) {
      clearTimeout(share.timer);
      share.create();
    }
  }
}
