import config from "../../config";
import { VFile } from "../file/VFile";

export type FileSortStrategy =
  | "name_asc"
  | "name_desc"
  | "date_uploaded_asc"
  | "date_uploaded_desc"
  | "date_updated_asc"
  | "date_updated_desc";

function isFileSortStrategy(v: any): v is FileSortStrategy {
  return [
    "name_asc",
    "name_desc",
    "date_uploaded_asc",
    "date_uploaded_desc",
    "date_updated_asc",
    "date_updated_desc",
  ].includes(v);
}

export function parseFileSortStrategy(v: string | null | undefined): FileSortStrategy {
  if (isFileSortStrategy(v)) return v;
  return config.defaultFileSortStrategy;
}

function fileSortStrategyToFunction(s: FileSortStrategy): (a: VFile, b: VFile) => number {
  switch (s) {
    case "name_asc":
      return compareFilesByNameAsc;
    case "name_desc":
      return compareFilesByNameDesc;
    case "date_uploaded_asc":
      return compareFilesByDateUploadedAsc;
    case "date_uploaded_desc":
      return compareFilesByDateUploadedDesc;
    case "date_updated_asc":
      return compareFilesByDateUpdatedAsc;
    case "date_updated_desc":
      return compareFilesByDateUpdatedDesc;
  }
}

export function useSortedFiles(files: VFile[], by: FileSortStrategy) {
  return files.slice().sort(fileSortStrategyToFunction(by));
}

export function compareFilesByNameAsc(a: VFile, b: VFile) {
  return (a.unsafeLatestVersion?.name ?? "").localeCompare(b.unsafeLatestVersion?.name ?? "");
}
export function compareFilesByNameDesc(a: VFile, b: VFile) {
  return -compareFilesByNameAsc(a, b);
}

export function compareFilesByDateUploadedAsc(a: VFile, b: VFile) {
  return a.createdAt.valueOf() - b.createdAt.valueOf();
}
export function compareFilesByDateUploadedDesc(a: VFile, b: VFile) {
  return -compareFilesByDateUploadedAsc(a, b);
}

export function compareFilesByDateUpdatedAsc(a: VFile, b: VFile) {
  return a.unsafeLatestVersion?.createdAt.valueOf() ?? 0 - (b.unsafeLatestVersion?.createdAt.valueOf() ?? 0);
}
export function compareFilesByDateUpdatedDesc(a: VFile, b: VFile) {
  return -compareFilesByDateUpdatedAsc(a, b);
}
