import { makeAutoObservable, ObservableSet } from "mobx";
import { createContext } from "react";
import { VFile } from "../file/VFile";

export default class FilesScreenStore {
  selectedFiles: ObservableSet<VFile> | null = null;

  constructor(params: {}) {
    makeAutoObservable(this, {
      selectedFiles: true,
    });
  }

  toggleSelectMode() {
    this.selectedFiles = this.selectedFiles === null ? new ObservableSet() : null;
    console.log(this.selectedFiles);
  }

  toggleSelectFile(file: VFile): boolean {
    if (!this.selectedFiles) return false;
    if (this.selectedFiles.has(file)) this.selectedFiles.delete(file);
    else this.selectedFiles.add(file);
    return true;
  }
}

// @ts-ignore
export const FilesScreenStoreContext = createContext<FilesScreenStore>();
