import { makeAutoObservable } from "mobx";
import { FileType, VFile } from "../../file/VFile";
import { fileExtensionToFileType, mimeToFileType } from "../../util/fs";
import { VersionState, VersionStateEnum } from "../../version/VersionState";
import { getVersion } from "../../version/getVersion";

/**
 * Contains information about an uploading file or directory.
 */
export interface UploadElement extends VersionState {
  name: Promise<string | null>;
  size: number;
}

export class Fil implements UploadElement {
  readonly file: File;
  readonly type: FileType;

  current = VersionStateEnum.upload;

  fileUploadFrac = 0;
  transcodeDownloadFrac = 0;
  transcodeFrac = 0;
  transcodeUploadFrac = 0;

  startTime?: number;

  get eta() {
    if (this.startTime !== undefined)
      return ((Date.now() - this.startTime) / this.fileUploadFrac) * (1 - this.fileUploadFrac);
  }
  get name() {
    return Promise.resolve(this.file.name);
  }
  get size() {
    return this.file.size;
  }

  constructor(file: File) {
    this.file = file;
    this.type = mimeToFileType(file.type) ?? fileExtensionToFileType(file.name);
    makeAutoObservable(this);
  }
}

export class Dir implements UploadElement {
  readonly files = new Map<string, UploadElement>();
  readonly file?: VFile;
  private readonly _name?: string;

  get name() {
    if (this._name) return Promise.resolve(this._name);
    if (this.file) {
      const id = this.file.versionIds.at(-1);
      if (id) return getVersion({ id, file: this.file }).then((v) => v.name);
    }
    return Promise.resolve(null);
  }
  get size() {
    return [...this.files.values()].map((v) => v.size).reduce((a, b) => a + b);
  }

  get current() {
    const v = [...this.files.values()];
    if (v.findIndex((v) => v.current === VersionStateEnum.upload) !== -1) return VersionStateEnum.upload;
    if (v.findIndex((v) => v.current === VersionStateEnum.transcode) !== -1) return VersionStateEnum.transcode;
    if (v.findIndex((v) => v.current === VersionStateEnum.done) !== -1) return VersionStateEnum.done;
    return VersionStateEnum.upload;
  }

  get fileUploadFrac() {
    const v = [...this.files.values()];
    return v.map((v) => ((v.fileUploadFrac ?? 0) * v.size) / this.size).reduce((a, b) => a + b);
  }
  get transcodeDownloadFrac() {
    const v = [...this.files.values()];
    return v.map((v) => ((v.transcodeDownloadFrac ?? 0) * v.size) / this.size).reduce((a, b) => a + b);
  }
  get transcodeFrac() {
    const v = [...this.files.values()];
    return v.map((v) => ((v.transcodeFrac ?? 0) * v.size) / this.size).reduce((a, b) => a + b);
  }
  get transcodeUploadFrac() {
    const v = [...this.files.values()];
    return v.map((v) => ((v.transcodeUploadFrac ?? 0) * v.size) / this.size).reduce((a, b) => a + b);
  }

  get eta() {
    return 0;
  }

  constructor(params: { name?: string } | { file: VFile }) {
    if ("file" in params) this.file = params.file;
    else this._name = params.name;
    makeAutoObservable(this);
  }
}
