import { S3Client, S3ClientConfig } from "@aws-sdk/client-s3";
import config from "../config";
import { timeout } from "./util/timeout";

export type S3EndpointInfo = {
  endpoint: string;
  config: S3ClientConfig;
  lastError: number;
};

export type S3ClientInfo = {
  info: S3EndpointInfo;
  client: S3Client;
};

const s3Endpoints: S3EndpointInfo[] = config.s3.client.map((cfg) => ({
  endpoint: cfg.endpoint,
  config: cfg,
  lastError: 0,
}));

/** maps endpoints to `S3Client`s */
const s3Clients = new Map(s3Endpoints.map((v) => [v.endpoint, new S3Client(v.config)]));

export async function pickS3Endpoint(): Promise<S3EndpointInfo> {
  const info = s3Endpoints.sort((a, b) => a.lastError - b.lastError)[0];
  // delay
  await timeout(Math.max(0, config.s3.retryDelay + info.lastError - new Date().valueOf()));
  return info;
}

export async function pickS3Client(): Promise<S3ClientInfo> {
  const info = await pickS3Endpoint();
  let client = s3Clients.get(info.endpoint);
  if (!client) {
    client = new S3Client(info.config);
    s3Clients.set(info.endpoint, client);
  }
  return { info, client };
}
