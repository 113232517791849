import { useLocalization } from "@fluent/react";
import { Text, View } from "react-native";
import { VFile } from "../../../model/file/VFile";
import { useColors } from "../../Colors";
import styles from "../../Styles";
import EllipsisText from "../../components/EllipsisText";
import UserNameText from "../../components/user/UserNameText";

export default function FileCardLowerRow(props: { file: VFile; name: string }) {
  const { file, name } = props;
  const colors = useColors().fileCard;
  const { l10n } = useLocalization();
  const sharedBy = file.sharedByUsers;
  const sharedBy1 = sharedBy.at(0);
  const sharedBy2 = sharedBy.slice(1);

  return (
    <View style={{ padding: 10, paddingTop: 5 }}>
      <View style={{ paddingRight: 36 }}>
        <EllipsisText style={{ color: colors.text }}>{name}</EllipsisText>
      </View>
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        <View style={{ alignItems: "baseline", flexGrow: 1 }}>
          {sharedBy1 && (
            <Text numberOfLines={1} style={{ color: colors.text, fontSize: styles.textUser.fontSize }}>
              {l10n.getString("shared-by")} <UserNameText user={sharedBy1} />
              {sharedBy2.map((s) => (
                <>
                  <Text style={{ color: colors.text, fontSize: styles.textUser.fontSize }}>, </Text>
                  <UserNameText user={s} />
                </>
              ))}
            </Text>
          )}
        </View>
        <View style={{ width: 10 }} />
        <View
          style={{
            backgroundColor: colors.version.background,
            borderRadius: 6,
            paddingHorizontal: 6,
            paddingVertical: 4,
            marginTop: -10,
            justifyContent: "center",
          }}
        >
          <Text style={{ color: colors.text, fontSize: 13, flexShrink: 0 }}>v{file.numberOfVersions}</Text>
        </View>
      </View>
    </View>
  );
}
