import * as Api from "../Api";
import { store } from "../Store";
import { base64Decode, importPubkey } from "../util/CryptoHelper";
import { User, UserJson, userGQLFields } from "./User";

export async function getUser(params: { id: string } | { username: string }): Promise<User> {
  const _store = await store;
  if ("username" in params) {
    const { username } = params;
    for (const user of _store.users.values()) {
      if (user.username === username) return user;
    }
    return userFromJson((await Api.gql(`{user(username: "${username}") {${userGQLFields}}}`)).user);
  } else {
    const { id } = params;
    return _store.users.get(id) ?? userFromJson((await Api.gql(`{user(id: "${id}") {${userGQLFields}}}`)).user);
  }
}

async function userFromJson(json: UserJson): Promise<User> {
  const user = new User({
    id: json.id,
    username: json.username,
    displayName: json.displayName,
    pubkey: await importPubkey(base64Decode(json.pubkey)),
  });
  (await store).users.set(user.id, user);
  return user;
}
