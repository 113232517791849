import { store } from "../../../model/Store";
import { useColors } from "../../Colors";
import MenuButton from "../../components/menu/menu-item/MenuButton";
import MenuLink from "../../components/menu/menu-item/MenuLink";
import MenuSection from "../../components/menu/MenuSection";
import SectionMenuScreen from "../../components/menu/SectionMenuScreen";
import UsersDropdown from "./UsersDropdown";

export default function SettingsScreen() {
  const colors = useColors().settingsScreen;
  return (
    <SectionMenuScreen title="Settings" backTo={-1}>
      <MenuSection title="Account">
        <UsersDropdown />
        <MenuLink title="Add Account" to="add-account/login" state={{ back: true }} />
        <MenuButton title="Logout" color={colors.logout} onPress={async () => (await store).logout()} />
      </MenuSection>
      <MenuSection>
        <MenuLink title="Transcoding Servers" to="transcoding-servers" />
      </MenuSection>
    </SectionMenuScreen>
  );
}
