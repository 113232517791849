import * as Crypto from "expo-crypto";
import { makeObservable, runInAction } from "mobx";
import { Socket } from "../Socket";
import { store, unsafeStore } from "../Store";
import { base64Encode, exportRawKey } from "../util/CryptoHelper";
import FileVersion from "../version/FileVersion";
import { VersionState, VersionStateEnum } from "../version/VersionState";

export default class TranscodingServer extends Socket {
  name?: string;

  private requests = new Map<string, (value: unknown) => void>();

  constructor(params: { url: string; name: string | undefined }) {
    const { name } = params;
    let url = socketUrl(params.url);
    if (!url.endsWith("/socket/v1")) url += "/socket/v1";
    super(url);
    this.name = name;
    makeObservable(this, { name: true });
  }

  protected override async handleEvent(ev: MessageEvent) {
    const e: Event = JSON.parse(ev.data);
    console.debug("received event", e);
    const _store = await store;
    let result: unknown;
    s: switch (e.type) {
      case "job.state":
        result = e.data;
        for (const [_, v] of _store.versions) {
          if (!(v instanceof FileVersion) || v.state.jobId !== e.data.job_id) continue;
          runInAction(() => {
            updateVersionState(v.state, e.data);
            console.log("state", v.state);
          });
        }
        break;
    }
    if (e.request_id !== undefined) {
      const f = this.requests.get(e.request_id);
      if (f !== undefined) {
        this.requests.delete(e.request_id);
        f(result);
      }
    }
  }

  /**
   * Generate a new `request_id`, send the event and wait and return the a response.
   * @param type event type
   * @param data event data
   * @returns the response from the transcoding server with the same `request_id`
   */
  private sendRequest = (type: string, data: Object): Promise<unknown> =>
    new Promise(async (resolve) => {
      const request_id = Crypto.randomUUID();
      this.requests.set(request_id, resolve);
      await this.start();
      this.socket?.send(JSON.stringify({ type, data, request_id }));
    });

  /**
   * Start a new transcoding job
   * @param version The `FileVersion` to transcode
   */
  async startJob(version: FileVersion) {
    const token = (
      await (
        await store
      ).me!.createToken({ version: { update_ciphertext: [version.id], state: { edit: [version.id] } } })
    ).expect("Failed to create token");
    const r = (await this.sendRequest("job.start", {
      input: version.id,
      key: base64Encode(await exportRawKey(version.encKey)),
      token: token.t,
    })) as JobStateData;
    runInAction(() => {
      updateVersionState(version.state, r);
    });
  }

  /**
   * Subscribe to state updates on a transcode job.
   * @param state Reference to the `VersionState` that should be updated.
   */
  async subscribeToJob(state: VersionState) {
    const r = (await this.sendRequest("job.subscribe", { id: state.jobId })) as JobStateData;
    runInAction(() => {
      updateVersionState(state, r);
    });
  }

  /**
   * Encode this `TranscodingServer` to JSON for storage.
   * @returns A JSON object representing this `TranscodingServer`.
   */
  toJson = (): TranscodingServerJson => ({
    url: this.url,
    ...(this.name === undefined ? {} : { name: this.name }),
  });
}

/**
 * Get an existing connection to a transcoding server or create a new one.
 * @returns A reference to the `TranscodingServer`
 */
export function getTranscodingServer(params: { url: string; name: string | undefined }): TranscodingServer {
  const e = unsafeStore.transcodingServers.get(params.url);
  if (e) {
    if (!e?.name && params.name) e.name = params.name;
    return e;
  }
  const n = new TranscodingServer(params);
  unsafeStore.transcodingServers.set(params.url, n);
  return n;
}

/**
 * JSON representation of a `TranscodingServer`
 */
export type TranscodingServerJson = {
  name?: string;
  url: string;
};

/**
 * An event received from the transcoding server.
 */
type Event = {
  request_id?: string;
} & JobStateEvent;

/**
 * An event containing a new job state.
 */
type JobStateEvent = { type: "job.state"; data: JobStateData };
type JobStateData = {
  job_id: string;
  download: PartStateJson;
  transcode: PartStateJson;
  upload: PartStateJson;
  timestamp: string;
};

type PartStateJson =
  | { type: "pending" }
  | { type: "in_progress"; frac: number; eta?: number }
  | { type: "done"; time_elapsed: number };

/**
 * Convert a `PartStateJson` to a fraction between 0 an 1.
 * @param partState
 * @returns A fraction between 0 an 1.
 */
const partStateToFrac = (partState: PartStateJson): number =>
  partState.type === "pending" ? 0 : partState.type === "done" ? 1 : partState.frac;

function socketUrl(url: string) {
  const p = parseUrl(url, "wss");
  return `${p.protocol}//${p.hostname}${p.port && p.port !== "443" ? `:${p.port}` : ""}${
    p.pathname !== "/" ? p.pathname : ""
  }${p.search}${p.hash}`;
}

function parseUrl(url: string, proto?: string) {
  try {
    return new URL(url);
  } catch (e) {
    return new URL(`${proto ?? "https"}://` + url);
  }
}

function updateVersionState(state: VersionState, update: JobStateData) {
  state.jobId = update.job_id;
  state.transcodeDownloadFrac = partStateToFrac(update.download);
  state.transcodeFrac = partStateToFrac(update.transcode);
  state.transcodeUploadFrac = partStateToFrac(update.upload);
  state.current =
    update.upload.type === "done" && update.transcode.type === "done" && update.download.type === "done"
      ? VersionStateEnum.done
      : VersionStateEnum.transcode;
}
