import { runInAction } from "mobx";
import * as Api from "../Api";
import { VFile } from "../file/VFile";
import BinaryVersion from "./BinaryVersion";
import DirectoryVersion from "./DirectoryVersion";
import ImageVersion from "./ImageVersion";
import Version, { versionGQLFields, VersionJson } from "./Version";
import VideoVersion from "./VideoVersion";

export async function fetchVersions<V extends Version>(file: VFile): Promise<(V | undefined)[]> {
  // prettier-ignore
  const r: VersionJson[]= (await Api.gql(`{
        file(id: "${file.id}") {
          versions {
            ${versionGQLFields}
          }
        }
      }`)).file.versions;
  const fromJson =
    file.type === "DIRECTORY"
      ? DirectoryVersion.fromJson
      : file.type === "IMAGE"
      ? ImageVersion.fromJson
      : file.type === "VIDEO"
      ? VideoVersion.fromJson
      : BinaryVersion.fromJson;
  const versions = (
    await Promise.all(
      r.map((v) => {
        return fromJson(v as any, { file }).catch((e) => {
          console.error("Error while parsing Version", e);
        }) as unknown as V;
      })
    )
  ).filter((v) => v !== undefined);
  runInAction(() => {
    file.versionIds.replace(versions.map((v) => v.id));
  });
  return versions;
}
