import { observer } from "mobx-react-lite";
import { useState } from "react";
import { Text, TouchableHighlight, View } from "react-native";
import { VFile } from "../../../model/file/VFile";
import { useColors } from "../../Colors";
import Icon from "../icon/Icon";
import { VersionSelectorMenu } from "./VersionSelectorMenu";

const VersionSelectorButton = observer((props: { file: VFile }) => {
  const { file } = props;
  const colors = useColors().versionBadge;
  const [visible, setVisible] = useState(false);
  return (
    <>
      <TouchableHighlight style={{ borderRadius: 4 }} onPress={() => setVisible(!visible)}>
        <View
          style={{
            backgroundColor: colors.background,
            borderRadius: 4,
            flexDirection: "row",
            padding: 4,
            alignItems: "center",
          }}
        >
          <Text style={{ color: colors.text, fontWeight: "600", fontSize: 12 }}>
            V
            {file.selectedVersionNo < 0
              ? file.versionIds.length - file.selectedVersionNo - 1
              : file.selectedVersionNo + 1}
          </Text>
          <Icon name="collapse" size={10} color={colors.text} />
        </View>
      </TouchableHighlight>
      {visible && (
        <View>
          <View style={{ position: "absolute", top: 0, left: 0, zIndex: 99 }}>
            <VersionSelectorMenu file={file} />
          </View>
        </View>
      )}
    </>
  );
});

export default VersionSelectorButton;
