import React, { JSXElementConstructor, PropsWithChildren, ReactElement, ReactPortal } from "react";
import { SectionList, SectionListData, Text } from "react-native";
import type { To } from "react-router";
import { useColors } from "../../Colors";
import styles from "../../Styles";
import { mapReactChildren } from "../../util/mapReactChildren";
import Divider from "../Divider";
import MenuScreen from "./MenuScreen";
import MenuSection, { MenuSectionProps } from "./MenuSection";

type SectionData = SectionListData<
  ReactPortal | ReactElement<any, string | JSXElementConstructor<any>>,
  { props: MenuSectionProps; isFirst: boolean }
>;

/**
 * A sectioned menu screen with navbar, usually located on it's own `Route`.
 * All valid child elements must be `MenuSection`s, optionally nested in `Fragment`s (`<> </>`).
 * Sections are separated using `<Divider />`s and can have an optional `title` and/or `description`.
 * @param props.title The title displayed in the navbar
 */
export default function SectionMenuScreen(props: PropsWithChildren<{ title: string; backTo?: To | number }>) {
  const { children, title, backTo } = props;
  const sectionsData: SectionData[] = mapReactChildren({
    children,
    type: MenuSection,
    map: ({ props }) => ({
      data: mapReactChildren({ children: props.children, map: (c) => c }),
      props,
      isFirst: false,
    }),
  });
  sectionsData[0].isFirst = true;

  return (
    <MenuScreen title={title} backTo={backTo}>
      <SectionList
        style={styles.menuList}
        contentContainerStyle={styles.menuListContentContainer}
        stickySectionHeadersEnabled={false}
        sections={sectionsData}
        keyExtractor={(_, index) => `${index}`}
        renderItem={({ item }) => item}
        renderSectionHeader={(info) => <SectionHeader {...info} />}
        renderSectionFooter={(info) => <SectionFooter {...info} />}
        // https://stackoverflow.com/a/71456784/18683794
        removeClippedSubviews={false}
        CellRendererComponent={({ children }) => children}
      />
    </MenuScreen>
  );
}

function SectionHeader(info: { section: SectionData }) {
  const { props, isFirst } = info.section;
  const { title } = props;
  const colors = useColors().menu;
  return (
    <>
      {title && (
        <Text
          style={{
            color: colors.section.text,
            fontWeight: "600",
            fontSize: 13,
            paddingHorizontal: 4,
            paddingTop: 12,
          }}
        >
          {title.toLocaleUpperCase()}
        </Text>
      )}
      {(!isFirst || title) && <Divider color={colors.divider} style={{ marginVertical: title ? 6 : 8 }} />}
    </>
  );
}

function SectionFooter(info: { section: SectionData }) {
  const { description } = info.section.props;
  const colors = useColors().menu.section;
  return description ? <Text style={{ color: colors.text }}>{description}</Text> : null;
}
