import { runInAction } from "mobx";
import { useContext } from "react";
import { ContextMenuAction } from "./ContextMenuAction";
import { ContextMenuStoreContext } from "./ContextMenuStore";

export function useContextMenu() {
  const s = useContext(ContextMenuStoreContext);
  return (position: { x: number; y: number }, actions: ContextMenuAction[]) => {
    runInAction(() => {
      s.actions = actions;
      s.position = position;
    });
  };
}
