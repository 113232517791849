import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import React from "react";
import { Platform, TouchableHighlight, View } from "react-native";
import Svg, { Text } from "react-native-svg";
import { VFile } from "../../../model/file/VFile";
import ImageVersion from "../../../model/version/ImageVersion";
import { useVersion } from "../../../model/version/useVersion";
import { useColors } from "../../Colors";
import ErrorScreen from "../ErrorScreen";
import LoadScreen from "../LoadScreen";

const GallerySelectorImage = observer((props: { file: VFile; index: number }) => {
  const { file, index } = props;
  const colors = useColors();
  const dir = file.parentDir;
  const { isSuccess, status, data: version } = useVersion(file, -1);

  if (file.type !== "IMAGE")
    return <ErrorScreen msg={`The requested file (id: ${file.id}) exists, but is not an image`} />;
  if (!isSuccess)
    switch (status) {
      case "error":
        return <ErrorScreen msg="Failed to load directory version" />;
      case "fetching":
        return <LoadScreen msg="Loading directory version" />;
    }
  if (!(version instanceof ImageVersion))
    return <ErrorScreen msg={`The requested version (id: ${version?.id}) exists, but is not an image`} />;

  return (
    <View
      style={{
        backgroundColor: dir?.selectedVersion?.selectedFile === file ? colors.imageSelector.accent : colors.transparent,
        height: "100%",
        width: 100,
      }}
    >
      <View style={{ padding: 2, height: "100%", width: "100%" }}>
        <TouchableHighlight
          style={{ height: "100%", width: "100%" }}
          onPress={() => {
            runInAction(() => {
              if (dir?.selectedVersion) dir.selectedVersion.selectedFile = file;
            });
          }}
        >
          {version && (
            <View
              style={{
                height: "100%",
                width: "100%",
                aspectRatio: 1,
                top: 0,
                left: 0,
                position: "absolute",
                backgroundColor: colors.imageSelector.previewBackground,
              }}
            >
              {/* <ImageVersionImage version={version} /> */}
              <Svg height={"100%"} width={"100"} viewBox="0 0 100 100" style={{ position: "absolute" }}>
                <Text
                  fontWeight={600}
                  x={0}
                  y={42}
                  fontSize={50}
                  {...(Platform.OS === "web" ? { fontFamily: '"Segoe UI", Roboto, Helvetica, Arial, sans-serif' } : {})}
                  fill={colors.imageSelector.number}
                  stroke={colors.imageSelector.numberBorder}
                  strokeWidth={1.5}
                >
                  {index + 1}
                </Text>
              </Svg>
            </View>
          )}
        </TouchableHighlight>
      </View>
    </View>
  );
});

export default GallerySelectorImage;
