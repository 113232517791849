import { useEffect } from "react";
import { Text, View } from "react-native";
import {
  CommentNotification,
  FileNotification,
  VNotification,
  VersionNotification,
} from "../../../model/notification/VNotification";
import { fetchNextNotifications } from "../../../model/notification/fetchNextNotifications";
import { useColors } from "../../Colors";
import UserCircle from "../../components/user/UserCircle";
import ErrorScreen from "../ErrorScreen";

export default function NotificationView(props: { notification: VNotification }) {
  const { notification } = props;
  const colors = useColors().notification;

  useEffect(() => {
    fetchNextNotifications();
  }, []);

  return (
    <View style={{ backgroundColor: colors.background }}>
      {notification instanceof CommentNotification ? (
        <CommentNotificationView notification={notification} />
      ) : notification instanceof FileNotification ? (
        <FileNotificationView notification={notification} />
      ) : notification instanceof VersionNotification ? (
        <VersionNotificationView notification={notification} />
      ) : (
        <ErrorScreen msg="unsupported notification type" />
      )}
    </View>
  );
}

function CommentNotificationView(props: { notification: CommentNotification }) {
  const { notification } = props;
  const colors = useColors().notification;
  if (!notification.comment.latestVersion)
    return <Text style={{ color: colors.text }}>Unable to display notification for new comment on file</Text>;
  return (
    <>
      <UserCircle size={10} user={notification.comment.latestVersion!.author} />
      <Text style={{ color: colors.text }}>{notification.comment.latestVersion!.text}</Text>
    </>
  );
}

function FileNotificationView(props: { notification: FileNotification }) {
  const { notification } = props;
  const colors = useColors().notification;
  if (!notification.file.unsafeLatestVersion)
    return <Text style={{ color: colors.text }}>Unable to display notification for new file</Text>;
  return (
    <>
      <Text style={{ color: colors.text }}>{notification.file.unsafeLatestVersion.name}</Text>
      <Text style={{ color: colors.text }}>uploaded by TODO</Text>
    </>
  );
}

function VersionNotificationView(props: { notification: VersionNotification }) {
  const { notification } = props;
  const colors = useColors().notification;
  return (
    <>
      <Text style={{ color: colors.text }}>Added new version to file "{notification.version.name}"</Text>
    </>
  );
}
