import { zxcvbn, zxcvbnOptions, ZxcvbnResult } from "@zxcvbn-ts/core";
import zxcvbnCommonPackage from "@zxcvbn-ts/language-common";
import zxcvbnEnPackage from "@zxcvbn-ts/language-en";
import { makeAutoObservable, observable } from "mobx";
import { OldApiError } from "../Api";
import { getUser } from "../user/getUser";
import { login } from "../user/login";
import { signup } from "../user/signup";

zxcvbnOptions.setOptions({
  translations: zxcvbnEnPackage.translations,
  graphs: zxcvbnCommonPackage.adjacencyGraphs,
  dictionary: {
    ...zxcvbnCommonPackage.dictionary,
    ...zxcvbnEnPackage.dictionary,
  },
});

export class LoginStore {
  private _displayName = "";
  get displayName() {
    return this._displayName;
  }
  set displayName(s: string) {
    this._displayName = s;
    this.updateZxcvbn();
  }

  private _username = "";
  get username() {
    return this._username;
  }
  set username(s: string) {
    if (s.length < 1) this._warnings.add("Username must not be empty");
    else this._warnings.delete("Username must not be empty");

    this._username = s;
    this._warnings.delete("User already exists");
    this.updateZxcvbn();

    if (this.extendedChecks)
      getUser({ username: s })
        .then((v) => {
          if (this.username === s) this._warnings.add("User already exists");
        })
        .catch((e) => {});
  }

  private _password = "";
  passwordWasChanged = false;
  get password() {
    return this._password;
  }
  set password(s: string) {
    this._password = s;
    this.passwordWasChanged = true;
    this.updatePasswordWarnings();
    this.updateZxcvbn();
  }

  private _confirmPassword = "";
  confirmPasswordWasChanged = false;
  get confirmPassword() {
    return this._confirmPassword;
  }
  set confirmPassword(s: string) {
    this._confirmPassword = s;
    this.confirmPasswordWasChanged = true;
    this.updatePasswordWarnings();
    this.updateZxcvbn();
  }

  private _warnings = observable.set<string>();
  get warnings() {
    let r = new Set(this._warnings);
    if (this._zxcvbnResult?.feedback) r = new Set([...this._warnings, ...this._zxcvbnResult.feedback.suggestions]);
    if (this._zxcvbnResult?.feedback.warning) r.add(this._zxcvbnResult.feedback.warning);
    return r;
  }

  private _extendedChecks = false;
  get extendedChecks() {
    return this._extendedChecks;
  }
  set extendedChecks(v: boolean) {
    this._extendedChecks = v;
    this._password = "";
    this._confirmPassword = "";
    this._warnings.clear();
    if (!v) {
      this._zxcvbnResult = null;
      this.passwordWasChanged = false;
      this.confirmPasswordWasChanged = false;
    }
  }

  private _zxcvbnResult: ZxcvbnResult | null = null;

  get passwordScore() {
    return this._zxcvbnResult?.score ?? 0;
  }

  constructor() {
    makeAutoObservable(this);
  }

  async signup() {
    this._warnings.clear();
    this.passwordWasChanged = true;
    this.confirmPasswordWasChanged = true;
    if (this.username.length < 1) this._warnings.add("Username must not be empty");
    if (this.password.length < 1) this._warnings.add("Password must not be empty");
    if (this.password != this.confirmPassword) this._warnings.add("Passwords do not match");
    if (this.passwordScore < 4) this._warnings.add("Your password is not secure");

    if (this._warnings.size < 1) {
      await signup(this.username, this.password, this.displayName).catch((e: OldApiError) => {
        try {
          this._warnings.replace(new Set([e.body.message]));
        } catch (n) {
          console.error("Unexpected error while handling signup error:", e, n);
        }
        throw e;
      });
    } else throw this.warnings;
    loginStore = new LoginStore();
  }

  async login() {
    console.log("logging in");

    this._warnings.clear();
    if (this.username.length < 1) this._warnings.add("Username must not be empty");
    if (this.password.length < 1) this._warnings.add("Password must not be empty");

    if (this.warnings.size > 0) return;
    await login(this.username, this.password).catch((e: OldApiError) => {
      try {
        this._warnings.add(e.body.message);
      } catch (e) {
        console.error("Unexpected error while handling login error:", e, e);
      }
      throw e;
    });
    loginStore = new LoginStore();
  }

  updatePasswordWarnings() {
    if (this.passwordWasChanged && this.password.length < 1) this._warnings.add("Password must not be empty");
    else this._warnings.delete("Password must not be empty");
    if (this.confirmPasswordWasChanged && this.password !== this.confirmPassword)
      this._warnings.add("Passwords do not match");
    else this._warnings.delete("Passwords do not match");
  }

  updateZxcvbn() {
    if (this.extendedChecks && this.passwordWasChanged) {
      if (this.password.length < 1) {
        this._zxcvbnResult = null;
        return;
      }
      const r = zxcvbn(this.password, ["vidre", this.displayName, this.username]);
      this._zxcvbnResult = r;
      if (r.score < 4) this._warnings.add("Your password is not secure");
      else this._warnings.delete("Your password is not secure");
    }
  }
}

export let loginStore = new LoginStore();
