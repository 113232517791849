import * as Api from "../Api";
import { fileGQLFieldsWithLatestVersion } from "../file/VFile";
import { store } from "../Store";
import { aesDecrypt, base64Decode, importRawKey } from "../util/CryptoHelper";
import { versionGQLFields } from "../version/Version";
import { NotificationJson } from "./VNotification";

export async function fetchNotifications(before?: Date) {
  const _store = await store;
  // prettier-ignore
  const r = (await Api.gql(`{
    notifications(before: "${before ?? new Date().toISOString()}") {
      ciphertext
      comment {
        id
        relation
        key
        versions {
            author
            ciphertext
            createdAt
        }
      }
      file {
        ${fileGQLFieldsWithLatestVersion}
      }
      version {
        ${versionGQLFields}
      }
    }
  }`, `Bearer ${_store.me!.token.t}`)).notifications as NotificationJson[];
  for (const n of r) {
    const key = await importRawKey(
      await aesDecrypt(_store.me!.keychain.notificationsKey, base64Decode(n.key), new ArrayBuffer(16))
    );
    const id = n.relatedId;
    switch (n.type) {
      case "COMMENT":
        break;
      case "FILE":
        break;
      case "VERSION":
        break;
    }
  }
}
