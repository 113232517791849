import { StyleProp, Text, ViewStyle } from "react-native";
import Animated from "react-native-reanimated";
import { useColors } from "../../Colors";

export default function FileCardDNDMessageText(props: {
  text: string;
  style?: StyleProp<Animated.AnimateStyle<StyleProp<ViewStyle>>>;
}) {
  const { text, style } = props;
  const colors = useColors().fileCard;
  return (
    <Animated.View style={[{ position: "absolute" }, style]}>
      <Text
        style={{
          color: colors.accent,
          fontWeight: "500",
          textShadowRadius: 20,
          textShadowColor: colors.dndMessageShadow,
        }}
      >
        {text}
      </Text>
    </Animated.View>
  );
}
